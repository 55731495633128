<ngx-loading
  [show]="checking"
  [config]="{ fullScreenBackdrop: true }"
></ngx-loading>

<div class="modal-header pb-3 pt-5 justify-content-center position-relative">
  <div class="fw-bold size-20">
    {{ "ENTER_PINCODE" | translate }}
  </div>
  <button
    type="button"
    class="btn-close btn-close-white position-absolute top-0 end-0 mt-3 me-3"
    aria-label="Close"
    (click)="modal.close()"
  ></button>
</div>

<div class="modal-body px-4">
  <div class="alert alert-danger w-100 my-3" role="alert" *ngIf="!!error">
    {{ error }}
  </div>
  <pincode-numpad
    (onChange)="onChange($event)"
    [clearSubject]="clearPincode"
  ></pincode-numpad>
  <div class="mt-5 mb-4 w-100">
    <button
      type="submit"
      class="btn btn-default btn-lg w-100"
      (click)="submit()"
      [disabled]="pincode.length !== 6 || checking"
    >
      {{ "CONFIRM" | translate }}
    </button>
  </div>
</div>
