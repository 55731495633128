import {
  trigger,
  transition,
  style,
  query,
  group,
  animate,
  useAnimation,
} from '@angular/animations';
import { moveFromLeft, moveFromRight } from 'ngx-router-animations';

export const authSlideInAnimation = trigger('routerTransition', [
  transition(':enter', useAnimation(moveFromLeft), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
  transition(':leave', useAnimation(moveFromRight), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0',
    },
  }),
  transition(':increment', useAnimation(moveFromRight), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
  transition(':decrement', useAnimation(moveFromLeft), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
]);

export const innerSlideInAnimation = trigger('routerTransition', [
  transition(':leave', useAnimation(moveFromLeft), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
  transition(':enter', useAnimation(moveFromRight), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0',
    },
  }),
  transition(':increment', useAnimation(moveFromRight), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
  transition(':decrement', useAnimation(moveFromLeft), {
    params: {
      enterTiming: '0.35',
      leaveTiming: '0.35',
    },
  }),
]);

// export const innerSlideInAnimation = trigger('routerTransition', [
//   transition(':enter', slideTo('right')),
//   transition(':leave', slideTo('left')),
//   transition(':increment', slideTo('right')),
//   transition(':decrement', slideTo('left')),
// ]);

// function slideTo(direction: string) {
//   const optional = { optional: true };
//   return [
//     query(
//       ':enter, :leave',
//       [
//         style({
//           position: 'absolute',
//           top: 0,
//           [direction]: 0,
//           width: '100%',
//           paddingLeft: '1rem',
//           paddingRight: '1rem',
//         }),
//       ],
//       optional
//     ),
//     query(':enter', [style({ [direction]: '-100%' })], optional),
//     group([
//       query(
//         ':leave',
//         [animate('350ms ease', style({ [direction]: '100%' }))],
//         optional
//       ),
//       query(
//         ':enter',
//         [animate('350ms ease', style({ [direction]: '0%' }))],
//         optional
//       ),
//     ]),
//   ];
// }
