/* eslint-disable */

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

const log = new Logger('ExhibitorComponent');

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent {


  keyword:any;
  selectedCategoryList:any = [];

  categoryList:any = [];
  articleList:any = [];
  isSelectedAll = true

  delayTimer:any;
  isLoadingDelayedSearch = false
  isLoadingArticleList = false

  limit=6;
  page=1;
  totalPages = 1;
  totalResults:any = undefined;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  async ngOnInit() {
    await this.searchWithDelay()
    await this.getArticleCategoryList()
  }

  async getArticleCategoryList(){
    const getArticleCategoryListRes:any = await this.backendService.getArticleCategoryList()
    log.debug("res getArticleCategoryListRes: %o",getArticleCategoryListRes)
    if(getArticleCategoryListRes){
      this.categoryList = getArticleCategoryListRes.results
      log.debug("this.categoryList: %o",this.categoryList)
    }
  }

  resetPaginate(){
    this.page = 1
    this.totalPages = 1;
    this.articleList = []
    this.totalResults = undefined
  }

  async activateClassActive(category:any){
    await this.resetPaginate()
    log.debug("activateClassActive category => %o",category)
    category.active = !category.active;
    if(this.categoryList && (this.categoryList.length == this.categoryList.filter((it:any) => !it.active ).length )){
      this.isSelectedAll = true
    }else if(this.categoryList && (this.categoryList.length == this.categoryList.filter((it:any) => it.active ).length )){
      this.isSelectedAll = true
    }else{
      this.isSelectedAll = false
    }

    log.debug("activateClassActive this.selectedCategoryList => %o",this.selectedCategoryList)

    if(category.active){
      this.selectedCategoryList.push(category)
    }else{
      this.selectedCategoryList = this.selectedCategoryList.filter((it:any) => it.id != category.id )
    }

    this.doSearch(this.keyword)
  }

  async setSelectedAll(){
    await this.resetPaginate()
    this.isSelectedAll = !this.isSelectedAll
    if(!this.isSelectedAll){
      this.categoryList.map( (it:any) => it.active = false)
      this.selectedCategoryList = []
    }else{
      this.categoryList.map( (it:any) => it.active = true)
      this.selectedCategoryList = this.categoryList
    }

    this.doSearch(this.keyword)
  }

  async searchWithDelay(event:any = undefined){
    await this.resetPaginate()
    log.debug("searchWithDelay...")

    var text = event ? event.target.value : undefined;
    const self = this
    clearTimeout(self.delayTimer);
    self.isLoadingDelayedSearch = true
    self.delayTimer = setTimeout(async function() {
      try{
        await self.doSearch(text)
        self.isLoadingDelayedSearch = false
      }catch(err){
        self.isLoadingDelayedSearch = false
      }
    }, 1000);
  }


  async doSearch(
    text:any = undefined
  ) {
    this.isLoadingArticleList = true
    log.debug("doSearch...")
    this.keyword = text

    log.debug("doSearch this.selectedCategoryList => %o",this.selectedCategoryList)
    let selectedCategories:any = []
    if(this.selectedCategoryList.length){
      selectedCategories = this.selectedCategoryList.map((it:any) => it.id) // TODO: multiple
    }else{
      selectedCategories = undefined // TODO: multiple
    }
    log.debug("doSearch selectedCategories => %o",selectedCategories)

    let res:any = await this.backendService.getArticleList(
      text,
      selectedCategories,
      this.limit,
      this.page,
    )
    log.debug("doSearch res => %o",res)
    if(res){
      const results = res.results
      this.articleList = [...this.articleList,...results]
      log.debug("this.articleList: %o",this.articleList)

      this.totalPages = res.totalPages
      this.totalResults = res.totalResults

      this.isLoadingArticleList = false
    }else{
      this.isLoadingArticleList = false
    }
  }

  onScroll() {
    log.debug("onScroll...")

    if(this.page < this.totalPages){
      this.page++;
      this.doSearch(this.keyword)
    }
  }

  backnav() {
    if(document.referrer == ''){
      this._router.navigate(['/']);
    }else{
      this.singletonService.back();
    }
    return false;
  }

  jumpToArticleDetail(article:any){
    let path = '/newsdetail/'+ article.id
    let href = this._router.url;
    if(href.startsWith('/newsdetail/')){ // same route
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]);
      });
    }else{
      this._router.navigate([path]);
    }

  }

}
