/* eslint-disable */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { ErrorHandlerService } from '../@services/error-handler.service';
import { Logger } from '../@services/logger.service';

const log = new Logger('AuthWrapperComponent');

@Component({
  selector: 'authwrapper',
  template: '<div (click)="onClick()"><ng-content></ng-content></div>',
})
export class AuthWrapperComponent implements AfterViewInit, OnDestroy {
  @Output('onClick') onClickCallback = new EventEmitter<void>();
  goSignInModal: any;

  constructor(
    private _authenticationService: AuthenticationService,
    private errorHandlerService:ErrorHandlerService,
    ) {}

  ngAfterViewInit(): void {
    log.debug("ngAfterViewInit...")

    //   const goSignInElement = document.getElementById('gosignin');
    //   log.debug("goSignInElement => %o",goSignInElement)
    //   this.goSignInModal = new bootstrap.Modal(goSignInElement);
  }

  ngOnDestroy(): void {
    // this.goSignInModal.hide();
  }

  onClick() {
    const currentUser = this._authenticationService.currentUserSubject.value
    log.debug("currentUser => $o",currentUser)

    if (!this._authenticationService.currentUserSubject.value) {
      // this.goSignInModal.show();
      this.errorHandlerService.goSignIn()
    } else {
      this.onClickCallback.emit();
    }

    // this.onClickCallback.emit();
  }
}
