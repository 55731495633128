<div class="modal-header p-4 mb-0">
  <h4>
    <span class="text-white fw-bold size-18">{{
      "BUY_COUPON_REWARD" | translate
    }}</span>
  </h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body p-4 text-center">
  <div class="text-center">
    <div class="alert alert-danger mb-4" role="alert" *ngIf="!!error">
      {{ error }}
    </div>
    <div class="pb-3">
      <div>
        <img [src]="focusItem.image" alt="" class="card-image" />
      </div>

      <div class="text-start mt-4">
        <h6 class="title size-18 mb-1 d-flex align-items-center justify-content-between gap-2">
          <span>{{ focusItem.name }}</span>
          <p class="mw-fit-content size-18 text-pink">
            ฿ {{ focusItem.price }}
          </p>
        </h6>
        <p class="text-muted size-14">
          {{ focusItem.description }}
          <br>
        </p>
        <p *ngIf="focusItem.quotaPerUser" class="text-muted size-14">
          Quota:
          {{ focusItem.quotaPerUser }} item/user
        </p>
        <p class="size-12 mt-3 text-secondary">
          {{ "GET_UNTILL" | translate }}
          {{ focusItem.endDate | date : "dd/MM/yy" }}
        </p>
      </div>
    </div>
  </div>

  <div class="mb-1">
    <div class="d-flex flex-column gap-3">
      <ng-container
        *ngIf="success; then successBlock; else buttonBlock"
      ></ng-container>
      <ng-template #successBlock>
        <p class="text-success text-center my-3">
          <i class="bi bi-check-circle me-1 text-success size-18 align-middle"></i>
          {{ "BUY_SUCCESSFULLY" | translate }}
        </p>
        <div class="d-flex flex-column gap-3">
          <button
            type="submit"
            class="btn btn-default btn-lg py-2"
            (click)="goToMyCoupon()"
          >
            {{ "GO_TO_COUPON" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-main btn-lg py-2"
            (click)="modal.dismiss('Close by user')"
          >
            {{ "CLOSE" | translate }}
          </button>
        </div>
      </ng-template>

      <ng-template #buttonBlock>
        <button
          type="submit"
          class="btn btn-default btn-lg py-2"
          (click)="buyItem()"
          [disabled]="loading"
        >
          {{ "BUY" | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</div>
