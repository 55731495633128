<div class="wrapper-100vw">
  <div class="wrapper-top-header border-0 mt-0 mb-0 pb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="backnav()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-1">
          <h6 class="title size-18 text-2-line" style="max-height: 44px;">{{ article?.title }}</h6>
        </div>
        <div class="col-auto align-self-center">
          <button
            class="btn text-white btn-sm pe-0 filter-btn"
            (click)="openModalSocialSharing()"
          >
            <img
              src="assets/img/icon/icon-actions/share-white.png"
              class="align-sub"
              alt="share article"
            />
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="" *ngIf="article">
    <div class="col-12 px-0">
      <div class="card mb-4 overflow-hidden card-theme text-white rounded-0">
        <!-- <div class="overlay-5"></div> -->
        <div
          class="coverimg h-100 w-100 position-absolute opacity-3"
          [style]="
            'background-image: url(' +
            (article?.bannerImage
              ? article?.bannerImage
              : 'assets/img/cover/home-cover.png') +
            ')'
          "
        >
          <img
            [src]="
              article?.bannerImage
                ? article?.bannerImage
                : 'assets/img/cover/home-cover.png'
            "
            alt=""
            style="display: none"
          />
        </div>
        <div class="card-body card-body-height-custom d-flex flex-column">
          <!-- <div class="row mb-5">
            <div class="col">
              <button
                type="button"
                class="btn text-white btn-44 back-btn"
                (click)="backnav()"
              >
                <i class="bi bi-chevron-left size-20"></i>
              </button>
            </div>
            <div class="col-auto" (click)="openModalSocialSharing()"></div>
          </div>
          <div class="mt-auto mb-4">
            <h4 class="mb-1 w-100 text-white font-weight-700 text-2-line">
              {{ article?.title }}
            </h4>
            <p class="text-2-line">
              {{ article?.description }}
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="container pb-5">
    <div *ngIf="article">
      <div>
        <h5 class="mt-3 mb-3 font-weight-700">{{ article?.title }}</h5>
        <div class="d-flex justify-content-between pb-3 mb-3 border-bottom">
          <p class="text-secondary size-14">
            {{ article?.category ? article?.category.title : "" }}
          </p>
          <p
            class="d-flex align-items-center justify-content-start text-secondary size-14"
          >
            <i class="bi bi-calendar4 size-16 text-secondary me-2"></i>
            {{ article?.createdAt | date : "dd/MM/yyyy" }}
          </p>
        </div>
      </div>

      <div>
        <ng-container
          *ngIf="!!article?.descriptionHTML; then htmlContent; else textContent"
        ></ng-container>
        <ng-template #htmlContent>
          <p class="text-secondary" [innerHtml]="article.descriptionHTML"></p
        ></ng-template>
        <ng-template #textContent>
          <p class="text-secondary">
            {{ article?.description }}
          </p></ng-template
        >
      </div>

      <div
        class="position-relative"
        *ngIf="article?.images && article?.images.length"
        (click)="singletonService.openGallery(article?.images)"
      >
        <div class="row mt-3">
          <div class="col-12" *ngIf="article?.images[0]">
            <figure class="overflow-hidden rounded-15 text-center">
              <img [src]="article?.images[0]" alt="" class="w-100 mx-auto" />
            </figure>
          </div>
          <div class="col-12" *ngIf="article?.images.length >= 2">
            <div class="row">
              <div class="col-6 mb-3" *ngIf="article?.images[1]">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    [src]="article?.images[1]"
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
              </div>
              <div class="col-6" *ngIf="article?.images[2]">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    [src]="article?.images[2]"
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="article?.images.length > 3"
          class="more-image-container d-flex align-items-center px-2 end-0"
        >
          <i class="bi bi-image me-1"></i>
          <p class="size-12">+{{ article?.images.length - 3 }}</p>
        </div>
      </div>

      <!-- <div class="mb-5 mt-4 d-flex justify-content-around px-5">
        <div class="text-center" (click)="openModalSocialSharing()">
          <button
            class="btn btn-info text-white avatar avatar-40 p-0 rounded-circle ms-1"
          >
            <i class="bi bi-share"></i>
          </button>

          <p class="mt-2 text-secondary">{{ "SHARE" | translate }}</p>
        </div>
      </div> -->
    </div>

    <!-- Suggestion Article -->
    <div class="border-top" *ngIf="suggestArticleList.length">
      <div class="mt-3 mb-1">
        <h6 class="title size-16">{{ "SUGGESTION_ARTICLE" | translate }}</h6>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <swiper
            class="cardswiper"
            [slidesPerView]="'auto'"
            [spaceBetween]="15"
            [pagination]="true"
          >
            <ng-template swiperSlide *ngFor="let article of suggestArticleList">
              <div class="slide-wrapper" (click)="jumpToArticleDetail(article)">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    [src]="
                      article?.coverImage
                        ? article?.coverImage
                        : 'assets/img/cover/mock-image.png'
                    "
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
                <div class="news-content">
                  <h6 class="size-16 text-white text-truncate">
                    {{ article?.title }}
                  </h6>

                  <ng-container
                    *ngIf="
                      !!article?.descriptionHTML;
                      then htmlContent;
                      else textContent
                    "
                  ></ng-container>
                  <ng-template #htmlContent>
                    <p
                      class="size-12 text-secondary text-2-line" style="max-height: 36px;"
                      [innerHtml]="article.descriptionHTML"
                    ></p
                  ></ng-template>
                  <ng-template #textContent>
                    <p class="size-12 text-secondary text-2-line" style="max-height: 36px;">
                      {{ article?.description }}
                    </p></ng-template
                  >

                  <!-- <p class="size-12 text-secondary text-2-line">
                    {{ article?.description }}
                  </p> -->
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</div>
