import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { User } from 'src/models/user.model';

@Component({
  selector: 'app-modal-setup-pincode',
  templateUrl: './modal-setup-pincode.component.html',
  styleUrls: ['./modal-setup-pincode.component.scss'],
})
export class ModalSetupPincodeComponent {
  currentPincode = '';
  firstPincode = '';
  secondPincode = '';
  error = '';
  submitting = false;
  done = false;
  clearPincode: Subject<void> = new Subject();

  constructor(
    private authenticationService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    private modal: NgbActiveModal,
    public modalService: NgbModal
  ) {}

  onChange(pin: string) {
    if (pin.length > 1) this.error = '';
    this.currentPincode = pin;
  }

  submit() {
    this.error = '';
    if (this.currentPincode.length !== 6) return;
    if (this.firstPincode.length !== 6) {
      this.firstPincode = this.currentPincode;
      this.clearPincode.next();
    } else {
      this.secondPincode = this.currentPincode;
      if (this.firstPincode === this.secondPincode) {
        this.savePin(this.firstPincode);
      } else {
        this.error = 'pin ไม่ตรงกัน';
        this.currentPincode = '';
        this.firstPincode = '';
        this.secondPincode = '';
        this.clearPincode.next();
      }
    }
  }

  async savePin(pin: string) {
    try {
      this.submitting = true;
      const payload = { pin };
      const user = (await this.backendService.savePin(payload)) as User;
      this.tokenStorageService.saveUser(user);
      this.authenticationService.currentUserSubject.next(user);
      this.done = true;
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.submitting = false;
    }
  }

  close() {
    this.modal.close('success');
  }
}
