<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div>
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
          </div>
          <h2>
            <span class="size-18 text-white fw-bold text-capitalize">
              {{ "NAME_CARD_LIST" | translate }}
            </span>
          </h2>
          <div>
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" [routerLink]="['/qr-code-scanner']">
              <i class="bi bi-sliders size-22"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mb-5 mt-3 pb-5">
      <div class="col-12">

        <!-- <div class="card mb-2" *ngFor="let nameCard of nameCardList">
          <div class="card-body p-0">
            <div class="row p-3" [routerLink]="'/namecard/' + nameCard.user.id ">
              <div class="col-auto">
                <div
                  class="avatar avatar-46 shadow-green card rounded-10 p-1"
                >
                  <div class="icons">
                    <img
                      [src]="nameCard.user.profile && nameCard.user.profile.picture ? nameCard.user.profile.picture : 'assets/img/categories2.png'"
                      class="align-sub"
                      alt="coin"
                    />
                  </div>
                </div>
              </div>
              <div class="col align-self-center ps-0">
                <p class="mb-0">
                  {{nameCard.user.profile.firstName}} {{nameCard.user.profile.lastName}}
                </p>
                <p class="text-secondary text-muted size-12 mb-0">
                  {{ "EVENT" | translate }}: {{nameCard.eventCode}}
                  <br>
                  {{ "DATE" | translate }}: {{nameCard.contactDate | date: 'dd/MM/yyyy'}}
                </p>
              </div>
              <div class="col align-self-end text-end"></div>
            </div>
          </div>
        </div> -->

        <div *ngFor="let nameCard of nameCardList">
          <app-card-namecard [user]="nameCard.user"></app-card-namecard>
        </div>

        <div class="text-center">
          <div *ngIf="!nameCardList.length">
            <div class="my-5 py-3">
              <div class="card bg-transparent">
                <div class="card-body p-0">
                  <div class="empty-wrapper text-center">
                    <img src="assets/img/empty/empty-img.png" alt="empty icon">
                    <p class="text-center mt-2 fw-normal size-14 mt-2">
                      {{"NAME_CARD_LIST_NONE" | translate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="search-results"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        ></div>

      </div>
    </div>
  </div>

</div>
