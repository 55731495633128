import { ModalBuycouponComponent } from './../../@components/modal-buycoupon/modal-buycoupon.component';
/* eslint-disable */
import { Component } from '@angular/core';
import { isNull } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { environment } from 'src/environments/environment';
import { PointResult, PointType } from 'src/models/point.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRedeemcouponComponent } from 'src/app/@components/modal-redeemcoupon/modal-redeemcoupon.component';
import { SingletonService } from 'src/app/@services/singleton.service';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('CouponDetailComponent');
@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.scss'],
})
export class CouponDetailComponent {
  couponId: string;
  loading = true;
  point: PointResult;
  coupon: any;
  nftRemainAmount = 0;
  isNoLimit = false;

  // ----------- start bundle params ------------------
  couponBundleId: string;
  couponBundle: any;

  // ----------- end bundle params ------------------

  item:any
  itemType:any // 'coupon' || 'bundle'

  constructor(
    private backendService: BackendService,
    private route: ActivatedRoute,
    private _route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    public location: Location,
    private modalService: NgbModal,
    public singletonService: SingletonService,
    public translateConfigService: TranslateConfigService
  ) {
    this.couponId = this.route.snapshot.paramMap.get('couponId')!;
    this.couponBundleId = this.route.snapshot.paramMap.get('couponBundleId')!;

    this.singletonService.initRequestConsent(this._route.snapshot.queryParams);
  }

  ngOnInit() {
    this.mount();
  }

  async mount() {
    try {
      await this.getAvailablePoint();
      await this.getAvailableNfts();
      if(this.couponId){
        this.itemType = 'coupon'
        await this.getCouponDetail();
        log.debug("this.coupon.remainAmount => %o",this.coupon.remainAmount)

        this.item = this.coupon
      }
      if(this.couponBundleId){
        this.itemType = 'bundle'
        await this.getCouponBundleDetail();
        log.debug("this.couponBundle.remainAmount => %o",this.couponBundle.remainAmount)

        this.item = this.couponBundle
      }
    } catch (error) {
      this.errorHandlerService.error(error);
    } finally {
      this.loading = false;

      log.debug("this.isNoLimit => %o",this.isNoLimit)
      log.debug("this.item => %o",this.item)

    }
  }

  async getAvailablePoint() {
    // eslint-disable-next-line no-useless-catch
    try {
      const query = { eventCode: environment.eventCode, isOnlyAvailable: true };
      const tempPoint = (await this.backendService.getAllPoint(
        query
      )) as PointType;
      this.point = tempPoint.results.find((result) => result.token.isDefault)!;
    } catch (error) {
      throw error;
    }
  }

  async getAvailableNfts() {
    try {
      const nft = (await this.backendService.getAllNfts({
        eventCode: environment.eventCode,
      })) as any;
      this.nftRemainAmount = nft?.infos?.remainAmount || 0;
    } catch (err) {
      throw err;
    }
  }

  async getCouponDetail() {
    try {
      this.coupon = await this.backendService.getCouponById(this.couponId);
      log.debug("this.coupon => %o",this.coupon)
      this.isNoLimit = isNull(this.coupon?.remainAmount);
    } catch (error) {
      throw error;
    }
  }

  async getCouponBundleDetail() {
    try {
      this.couponBundle = await this.backendService.getCouponBundleById(this.couponBundleId);
      log.debug("this.couponBundle => %o",this.couponBundle)
      this.isNoLimit = isNull(this.couponBundle?.remainAmount);
    } catch (error) {
      throw error;
    }
  }

  openRedeemModal() {
    const modalRef = this.modalService.open(ModalRedeemcouponComponent, {
      centered: true,
      size: 'sm',
      scrollable: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.focusItem = this.item;
    modalRef.componentInstance.itemType = this.itemType; // 'coupon' || 'bundle'
    modalRef.result.then(
      (data) => {
        this.mount();
      },
      (reason) => {
        this.mount();
      }
    );
  }

  openBuyModal() {
    const modalRef = this.modalService.open(ModalBuycouponComponent, {
      centered: true,
      size: 'sm',
      scrollable: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.focusItem = this.item;
    modalRef.componentInstance.itemType = this.itemType; // 'coupon' || 'bundle'
    modalRef.result.then(
      (data) => {
        this.mount();
      },
      (reason) => {
        this.mount();
      }
    );
  }

  backnav() {
    this.singletonService.back();
  }
}
