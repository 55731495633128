/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';
import { WheelReward } from '../../../models/wheelReward.model';
import Swal from 'sweetalert2';
import { TranslateConfigService } from '../../@services/translate-config.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Router } from '@angular/router';
import {
  WheelCoreComponent,
  TextAlignment,
  TextOrientation,
} from '../../@components/wheel-core/wheel-core.component';
import { ConfettiComponent } from '../../@components/confetti/confetti.component';
import { TranslateService } from '@ngx-translate/core';
// import {
//   WheelCoreComponent,
//   TextAlignment,
//   TextOrientation,
// } from 'src/app/@components/wheel-core/wheel-core.component';

const log = new Logger('WheelSpinnerComponent');

enum STATE {
  IDLE,
  SPINNING,
}

@Component({
  selector: 'app-wheel-spinner',
  templateUrl: './wheel-spinner.component.html',
  styleUrls: ['./wheel-spinner.component.scss'],
})
export class WheelSpinnerComponent implements OnInit {
  // @ViewChild(NgxWheelComponent, { static: false }) wheel: any;

  @ViewChild(WheelCoreComponent, { static: false }) wheel: any;
  @ViewChild(ConfettiComponent, { static: false }) confetti: any;

  COLORS = ['#7582FC', '#F355FF'];

  wheelWidth: number;
  wheelHeight: number;
  spinDuration = 4;
  couponRemains = 0;

  isLoading = false;
  currentState = STATE.IDLE;
  idToLandOn: any;
  textOrientation: TextOrientation = TextOrientation.HORIZONTAL;
  textAlignment: TextAlignment = TextAlignment.CENTER;

  tmpPrizes: any[];
  prizes: any[];
  prizeGot: WheelReward;
  lang: string;
  disableBtn = false;

  wheelDetail: any;

  protected readonly STATE = STATE;

  // labels
  congratulationsText = 'Congratulations';
  youHaveGotText = 'You have got';
  closeText = 'Close';
  viewRewardText = 'View reward';

  constructor(
    private _router: Router,
    private _backendService: BackendService,
    private _langService: TranslateConfigService,
    private _errorHandlerService: ErrorHandlerService,
    private _translator: TranslateService
  ) {}

  async ngOnInit() {
    this.lang = this._langService.getCurrentLanguage();
    await this.initLabels();
    this.initWheelConfig();
    await this.reload();
  }

  async initLabels() {
    this._translator
      .get('SPIN_THE_WHEEL_PAGE.CONGRATULATIONS')
      .subscribe((res: string) => {
        this.congratulationsText = res;
      });
    this._translator
      .get('SPIN_THE_WHEEL_PAGE.YOU_HAVE_GOT')
      .subscribe((res: string) => {
        this.youHaveGotText = res;
      });
    this._translator
      .get('SPIN_THE_WHEEL_PAGE.CLOSE_BUTTON')
      .subscribe((res: string) => {
        this.closeText = res;
      });
    this._translator
      .get('SPIN_THE_WHEEL_PAGE.VIEW_REWARD_BUTTON')
      .subscribe((res: string) => {
        this.viewRewardText = res;
      });
  }

  initWheelConfig() {
    /*this.wheelWidth =
      window.innerWidth < 1200
        ? window.innerWidth < 819
          ? window.innerWidth * 0.9
          : window.innerWidth * 0.68
        : window.innerWidth * 0.25;*/
    this.wheelWidth = 350; // window.innerWidth * 0.9;

    this.wheelHeight = this.wheelWidth;

    log.debug(`w  %o`, this.wheelWidth);
  }

  async reload() {
    try {
      this.isLoading = true;
      await this.fetchAvailablePrizes();
      // await this.fetchCouponRemains();
      await this.getCouponRemains();
    } catch (error) {
      this.prizes = [];
    } finally {
      this.isLoading = false;
    }
  }

  async fetchAvailablePrizes() {
    // TODO : Fetch this from backend
    this.wheelDetail = await this.getWheelList();

    log.debug(`this.wheelDetail %o`, this.wheelDetail);
    if (!this.wheelDetail) {
      return;
    }

    const _prizes: WheelReward[] = this.wheelDetail.pool.map((pool: any) => ({
      id: pool?._id,
      nameTh: `${pool?.reward?.title}`,
      nameEn: `${pool?.reward?.title}`,
      imageUrl:
        pool.reward.images.length > 0 ? pool.reward.images[0] : undefined,
    }));

    // const _prizes = [
    //   { id: '0001', nameTh: '10 พ้อยท์', nameEn: '10 Points' },
    //   { id: '0002', nameTh: 'คูปองข้าวไข่เจียว', nameEn: 'Omelet rice coupon' },
    //   { id: '0003', nameTh: 'คูปองชาไข่มุก', nameEn: 'Bubble tea coupon' },
    //   { id: '0004', nameTh: '15 พ้อยท์', nameEn: '15 Points' },
    //   { id: '0005', nameTh: 'เสื้องาน 0110', nameEn: '0110 t-shirt' },
    //   { id: '0006', nameTh: 'ปากกาที่ระลึก', nameEn: 'Souvenir pen' },
    // ];

    this.tmpPrizes = _prizes.map((value) => {
      // @ts-ignore
      return new WheelReward(
        value.id,
        value.nameTh,
        value.nameEn,
        value.imageUrl
      );
    });
    await this.preparePrizes();
  }

  async preparePrizes() {
    this.prizes = this.tmpPrizes.map((value, index) => {
      return {
        fillStyle: this.COLORS[index % 2],
        text: `${value.getName(this.lang)}`,
        id: value.id,
        textFillStyle: 'white',
        textFontSize: this.wheelWidth / 28,
      };
    });
  }

  // async fetchCouponRemains() {
  //   log.debug('Fetch coupon remains ' + this.couponRemains);
  //   // TODO : fetch coupon remains from backend
  //   // this.couponRemains = this.couponRemains === 0 ? 3 : this.couponRemains;
  //   await this.getCouponRemains();
  // }

  after() {
    log.debug('After spinning');
    this.currentState = STATE.IDLE;
    Swal.fire({
      title: this.congratulationsText,
      text: this.youHaveGotText + ' ' + this.prizeGot.getName(this.lang),
      imageUrl: this.prizeGot.imageUrl,
      imageHeight: 100,
      imageAlt: '',
      confirmButtonText: this.viewRewardText,
      cancelButtonText: this.closeText,
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      customClass: {
        container: 'spin-the-wheel',
      },
      didOpen: () => {
        this.showConfetti();
      },
    }).then(async (result) => {
      await this.getCouponRemains();
      this.currentState = STATE.IDLE;
      if (result.isConfirmed) {
        log.debug('user click confirm button');
        await this._router.navigate(['/mycouponreward']);
      }
    });
  }

  async spin() {
    log.debug('Before spin reset the wheel');
    this.wheel.reset();
    log.debug('Fetching result from backend');
    // this.couponRemains -= 1;
    this.currentState = STATE.SPINNING;
    await this.fetchUserSpinResult();

    // tslint:disable-next-line:no-shadowed-variable
    await new Promise((resolve) => setTimeout(resolve, 0));
    this.wheel.spin();
  }

  async fetchUserSpinResult() {
    const reward = await this.getWheelsReward();
    // const reward = { _id : '6452260fcd6c128251d64b8d'};
    this.idToLandOn = reward._id;
    this.prizeGot = this.tmpPrizes.find((obj) => obj.id === this.idToLandOn);
  }

  // backend api

  async getWheelList() {
    try {
      const wheels = (await this._backendService.getWheelList({
        wheelType: 'in-app',
      })) as any;
      return wheels?.results?.[0];
    } catch (error) {
      this._errorHandlerService.error(error, true);
    }
  }

  async getWheelsReward() {
    try {
      const reward = (await this._backendService.getWheelsReward(
        this.wheelDetail.id
      )) as any;
      return reward;
    } catch (error) {
      this._errorHandlerService.error(error, true);
    }
  }

  async getCouponRemains() {
    if (!this.wheelDetail) {
      return;
    }
    try {
      this.disableBtn = true;
      const info = (await this._backendService.getWheelInfo(
        this.wheelDetail.id
      )) as any;
      this.couponRemains = info?.remains || 0;
    } catch (error) {
      this._errorHandlerService.error(error, true);
    } finally {
      this.disableBtn = false;
    }
  }

  async back() {
    await this._router.navigate(['/']);
  }

  showConfetti() {
    this.confetti.run(document.getElementsByClassName('swal2-popup')[0]);
  }
}
