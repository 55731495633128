import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthlayoutRoutingModule } from './authlayout/authlayout-routing.module';
import { WalletRoutingModule } from './walletlayout/wallet-routing.module';
import { InnerRoutingModule } from './innerlayout/inner-routing.module';

const routes: Routes = [
  {
    path: '**',
    redirectTo: '/pagenotfound',
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes),
    WalletRoutingModule,
    AuthlayoutRoutingModule,
    InnerRoutingModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
