<div class="wrapper-content wrapper-100vw">
  <div class="container">
    <div class="wrapper-top-header border-0 mb-0">
      <div class="d-flex justify-content-between">
        <div class="content-header">
          <h6 class="text-white fw-normal mb-1 size-14">
            <span *ngIf="currentUser && currentUser.profile">
              {{ "HI" | translate }},
              {{ currentUser.profile.firstName }}
              {{ currentUser.profile.lastName }}
            </span>
            <span
              *ngIf="
                currentUser &&
                currentUser.profile &&
                currentUser.profile.isPress
              "
            >
              ({{ "IS_PRESS" | translate }})
            </span>
          </h6>
          <h2>
            <span class="size-32 fw-bold me-1 text-logo">LINX</span>
            <span class="size-20 text-white fw-bold">WALLET</span>
          </h2>
        </div>
        <div class="avatar-profile">
          <div
            class="active-profile image-profile-preview aura-effect"
            *ngIf="
              currentUser && currentUser.profile && currentUser.profile.picture
            "
            [routerLink]="['/profile']"
          >
            <div class="position-relative">
              <img
                [src]="currentUser.profile.picture"
                class="avatar-profile-img"
                alt="avatar profile"
              />

              <span *ngIf="!!notiBadge" class="badge-count-noti">{{
                notiBadge
              }}</span>

              <!-- <div class="wrapper-badge-role"> -->
              <!-- <span class="badge-avatar-role silver-role">Silver</span> -->
              <!-- <span class="badge-avatar-role gold-role">Gold</span> -->
              <!-- <span class="badge-avatar-role platinum-role">Platinum</span> -->
              <!-- </div> -->
            </div>
          </div>
          <div
            class="active-profile image-profile-preview aura-effect"
            *ngIf="
              !currentUser ||
              !currentUser.profile ||
              !currentUser.profile.picture
            "
            (click)="jumpToProfileSetting()"
          >
            <img
              class="avatar-profile-img avatar-default-profile-img"
              [src]="'assets/img/dashboard/avatar.png'"
              alt="avatar profile"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- if logged in -->
    <div *ngIf="currentUser" class="wrapper-info-dashboard">
      <div class="your-balance">
        <h6 class="size-16">{{ "YOUR_POINT_BALANCE" | translate }}</h6>
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="d-flex align-items-center text-truncate"
            *ngIf="!tokenLoading && defaultToken"
          >
            <img
              [src]="defaultToken.token.logo"
              width="35"
              height="35"
              alt="coin"
            />
            <h1
              class="text-white data-amount-balance size-40 fw-bold ms-2 text-truncate me-2"
            >
              {{ defaultToken.balance | numeral : "0,0" }}
            </h1>
          </div>
          <div class="wrapper-btn-my-wallet">
            <button
              routerLink="/wallet"
              type="button"
              class="btn btn-purple text-capitalize text-white size-14 btn-default d-flex align-items-center"
            >
              {{ "MY_WALLET" | translate
              }}<i class="bi bi-chevron-right size-12 text-white ms-1 lh-1"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="wrapper-actions d-flex justify-content-around">
        <a class="box-action" (click)="openSendModal()">
          <div class="box-img">
            <img src="assets/img/dashboard/send.png" alt="send img" />
          </div>
          <p>{{ "SEND" | translate }}</p>
        </a>
        <a class="box-action" (click)="openReceiveModal()">
          <div class="box-img">
            <img src="assets/img/dashboard/recive.png" alt="recive img" />
          </div>
          <p>{{ "RECEIVE" | translate }}</p>
        </a>
        <a
          class="box-action"
          data-bs-toggle="modal"
          data-bs-target="#menumodal"
        >
          <div class="box-img">
            <img src="assets/img/dashboard/my-qr.png" alt="my qr img" />
          </div>
          <p>{{ "MY_QR" | translate }}</p>
        </a>
        <a class="box-action" [routerLink]="['/qr-code-scanner']">
          <div class="box-img">
            <img src="assets/img/dashboard/scan.png" alt="scan img" />
          </div>
          <p>{{ "SCAN" | translate }}</p>
        </a>
      </div>
    </div>
    <!-- end logged in -->

    <!-- if not log in -->
    <div *ngIf="!currentUser" class="wrapper-info-blur">
      <div class="wrapper-info-blur-inner">
        <div class="pt-2">
          <div class="text-white size-16">
            {{ "PLEASE_SIGN_IN_OR_SIGN_UP" | translate }}
          </div>
          <div class="text-white size-20">
            {{ "TO_ACCESS_YOUR_ACCOUNT" | translate }}
          </div>
        </div>
        <div class="wrapper-actions-button">
          <a routerLink="/signup" class="btn btn-outline-main size-16">{{
            "SIGNIN.SIGNUP" | translate
          }}</a>
          <a routerLink="/signin" class="btn btn-default size-16">{{
            "SIGNIN.SIGNIN" | translate
          }}</a>
        </div>
      </div>
    </div>
    <!-- end not log in -->

    <div class="banner-cover">
      <img
        [routerLink]="['/aboutus']"
        src="assets/img/dashboard/banner-cover.png"
        alt="banner cover"
      />
    </div>

    <div class="wrapper-our-services card">
      <h6 class="size-14 text-center text-white fw-600 pt-3 pb-4">
        {{ "OUR_SERVICE" | translate }}
      </h6>
      <swiper
        class="swiper-our-services"
        [slidesPerView]="'auto'"
        [spaceBetween]="15"
        [pagination]="false"
        [navigation]="false"
        [scrollbar]="{ draggable: true }"
      >
        <ng-template swiperSlide>
          <div class="slide-wrapper">
            <div class="services-box" routerLink="/qrticket">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/qr-ticket.png"
                  alt="qr ticket"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "QR_TICKET" | translate }}
              </p>
            </div>
            <div class="services-box" [routerLink]="['/exhibitor']">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/exhibitors.png"
                  alt="exhibitors"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "EXHIBITOR" | translate }}
              </p>
            </div>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="slide-wrapper">
            <div class="services-box" routerLink="/quest-hunting-list">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/quest-hunting.png"
                  alt="coupon reward"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "QUEST_HUNTING_1" | translate }}<br />{{
                  "QUEST_HUNTING_2" | translate
                }}
              </p>
            </div>
            <div class="services-box" [routerLink]="['/highlightroom']">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/highlight-rooms.png"
                  alt="highlight rooms"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "HIGHLIGHT_ROOMS_1" | translate }}
                <br />
                {{ "HIGHLIGHT_ROOMS_2" | translate }}
              </p>
            </div>
          </div>
        </ng-template>

        <ng-template swiperSlide>
          <div class="slide-wrapper">
            <div class="services-box" routerLink="/couponreward">
              <div class="services-img  w-30px mx-auto position-relative">
                <img
                  src="assets/img/dashboard/services/coupon-reward.png"
                  alt="coupon reward"
                />
                <span *ngIf="!!myCouponRewardBadge" class="badge-count-noti">{{
                  myCouponRewardBadge
                }}</span>
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "COUPON" | translate }}
                <br />
                {{ "REWARD" | translate }}
              </p>
            </div>
            <div class="services-box" [routerLink]="['/nft-gallery']">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/gallery.png"
                  alt="gallery"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "NFT_GALLERY" | translate }}
              </p>
            </div>
          </div>
        </ng-template>

        <ng-template swiperSlide>
          <div class="slide-wrapper">
            <div class="services-box" [routerLink]="['/myexhibitornote']">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/bookmarks.png"
                  alt="bookmarks"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "BOOKMARKS" | translate }}
              </p>
            </div>
            <div class="services-box" routerLink="/namecards">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/namecards.png"
                  alt="namecards"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "NAMECARD" | translate }}
              </p>
            </div>
          </div>
        </ng-template>

        <ng-template swiperSlide>
          <div class="slide-wrapper">
            <div class="services-box" routerLink="/agenda">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/agenda.png"
                  alt="agenda"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "AGENDA" | translate }}
              </p>
            </div>
            <div class="services-box" routerLink="/wheel-spinner">
              <div class="services-img">
                <div class="w-30px mx-auto position-relative">
                  <img
                    src="assets/img/dashboard/services/mini-gachapon.png"
                    alt="spin the wheel"
                  />
                  <span *ngIf="!!inAppWheelBadge" class="badge-count-noti">{{
                    inAppWheelBadge
                  }}</span>
                </div>
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "SPIN_THE_WHEEL_1" | translate }}
                <br />
                {{ "SPIN_THE_WHEEL_2" | translate }}
              </p>
            </div>
          </div>
        </ng-template>

        <ng-template swiperSlide>
          <div class="slide-wrapper">
            <div class="services-box" routerLink="/speaker">
              <!--routerLink="/quest-hunting-list"-->
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/speakers.png"
                  alt="speakers"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "SPEAKERS" | translate }}
              </p>
            </div>
            <div class="services-box" [routerLink]="['/aboutus']">
              <div class="services-img">
                <img
                  src="assets/img/dashboard/services/about-us.png"
                  alt="about us"
                />
              </div>
              <p class="services-text mb-0 mt-3 text-white size-12">
                {{ "ABOUT_US" | translate }}
              </p>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>

    <div class="wrapper-news" *ngIf="articleList && articleList.length">
      <div class="row mt-4 mb-3">
        <div class="col d-flex align-self-center">
          <h6 class="title size-20">{{ "NEWS" | translate }}</h6>
        </div>
        <div class="col-auto d-flex align-self-center" [routerLink]="['/news']">
          <a class="small text-view-more size-14">{{
            "SHOW_ALL" | translate
          }}</a>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <swiper
            class="swiper-parent"
            [slidesPerView]="'auto'"
            [spaceBetween]="15"
            [pagination]="true"
          >
            <ng-template swiperSlide *ngFor="let article of articleList">
              <div
                class="slide-wrapper"
                [routerLink]="['/newsdetail/' + article.id]"
              >
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    [src]="
                      article.coverImage
                        ? article.coverImage
                        : 'assets/img/cover/mock-image.png'
                    "
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
                <div class="news-content">
                  <h6 class="size-16 text-white text-truncate">
                    {{ article.title }}
                  </h6>
                  <!-- <p class="size-12 text-secondary text-2-line">
                    {{ article.description }}
                  </p> -->

                  <ng-container
                    *ngIf="
                      !!article?.descriptionHTML;
                      then htmlContent;
                      else textContent
                    "
                  ></ng-container>
                  <ng-template #htmlContent>
                    <p
                      class="size-12 text-secondary text-2-line" style="max-height: 36px;"
                      [innerHtml]="article.descriptionHTML"
                    ></p
                  ></ng-template>
                  <ng-template #textContent>
                    <p class="size-12 text-secondary text-2-line" style="max-height: 36px;">
                      {{ article?.description }}
                    </p></ng-template
                  >
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</div>
