import { Router } from '@angular/router';
/* eslint-disable */
import { BackendService } from 'src/app/@services/backend.service';
import { Component } from '@angular/core';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { UserService } from 'src/app/@services/user.service';
import { environment } from 'src/environments/environment';
import { UserQrCode } from 'src/models/user.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SingletonService } from 'src/app/@services/singleton.service';
import { Logger } from 'src/app/@services/logger.service';
import Swal from 'sweetalert2';

const log = new Logger('ModalReceivePointComponent');

@Component({
  selector: 'app-modal-receive-point',
  templateUrl: './modal-receive-point.component.html',
  styleUrls: ['./modal-receive-point.component.scss'],
})
export class ModalReceivePointComponent {
  loading = true;
  error = '';
  exchangeTokens:any = []
  selectedExchangeToken:any;
  amount:any = 10;

  constructor(
    private userService: UserService,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    public modal: NgbActiveModal,
    public singletonService: SingletonService,
    public _router: Router,
  ) {}

  ngOnInit() {
    this.getExchangeTokens();
  }

  selectAmount(_amount:any){
    this.amount = _amount
  }

  selectToken(token:any){
    this.selectedExchangeToken = token;
  }

  async getExchangeTokens() {
    try {
      this.loading = true;
      this.exchangeTokens = (await this.backendService.getExchangeTokens(
        environment.eventCode,
      ));
      log.debug("this.exchangeTokens => %o",this.exchangeTokens);
      if(this.exchangeTokens && this.exchangeTokens.length){
        this.selectedExchangeToken = this.exchangeTokens[0];
      }
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  }



  confirmTx(){
    Swal.fire({
      // icon: 'warning',
      title: 'ยืนยันการเติมเงิน',
      text: 'คุณต้องการเติมเหรียญ ' + this.selectedExchangeToken.eventToken.name + ' เป็นจำนวนเงิน ' + this.amount + ' บาท ใช่หรือไม่?',
      // text: 'Please wait a moment (2/6)',
      showDenyButton: true,
      showConfirmButton: true,
      showCancelButton: false,
      customClass: 'popup-confirm-custom popup-content-custom',
      denyButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน',
      // didOpen: () => {
      //   Swal.showLoading()
      // }
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // await this.createExchangeOrder();
        this.modal.dismiss()
        this._router.navigate(['/receive'],{
          queryParams: {
            id: this.selectedExchangeToken.id,
            amount: this.amount,
          },
        });
      }
    });
  }
}
