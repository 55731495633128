import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GalleryFileType, GalleryImage} from '../../../models/galleryImage.model';
import {SingletonService} from '../../@services/singleton.service';
import {HttpClient} from '@angular/common/http';
import {BackendService} from '../../@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('NftGalleryDetailComponent');

@Component({
  selector: 'app-nft-gallery-detail',
  templateUrl: './nft-gallery-detail.component.html',
  styleUrls: ['./nft-gallery-detail.component.scss']
})
export class NftGalleryDetailComponent implements OnInit {

  id: string;
  isLoading = true;
  galleryImage: GalleryImage;

  protected readonly GalleryFileType = GalleryFileType;

  constructor(
    private http: HttpClient,
    public singletonService: SingletonService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _backendService: BackendService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.id = this._route.snapshot.paramMap.get('id')!;
    await this.fetchNFTGalleryImage();
    this.isLoading = false;
  }

  async fetchNFTGalleryImage() {
    const result: any = await this._backendService.getUserGalleryImage(this.id);
    log.debug(`result %o`,result);
    this.galleryImage = new GalleryImage(result);
    // this.galleryImage = new GalleryImage(_video);
  }

  async back() {
    this._router.navigate(['nft-gallery']);
  }
}
