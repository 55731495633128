/* eslint-disable */
import { trigger, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { SingletonService } from 'src/app/@services/singleton.service';
import 'dayjs/locale/th';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';

dayjs.extend(relativeTime);
dayjs.extend(buddhistEra);

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('deleteAnimation', [
      transition(
        ':leave',
        animate(250, style({ transform: 'translateX(100%)' }))
      ),
    ]),
  ],
})
export class NotificationComponent implements OnInit {
  loading = true;
  isOnlyUnread = false;
  page = 1;
  loadingMore = false;
  activeTab = 'ALL'; // ALL, UNREAD
  notifications: any[] = [];
  notificationsPageInfo: any;
  selectModeEnable = false;

  constructor(
    private _router: Router,
    public singletonService: SingletonService,
    private errorHandlerService: ErrorHandlerService,
    private backendService: BackendService,
    private translateConfigService: TranslateConfigService
  ) {}

  ngOnInit() {
    this.mount();
  }

  async mount() {
    try {
      this.loading = true;
      this.notifications = await this.getNotifications();
    } catch (error) {
      this.errorHandlerService.error(error, true);
    } finally {
      setTimeout(() => (this.loading = false), 200);
    }
  }

  async getNotifications() {
    try {
      const lang = this.translateConfigService.getCurrentLanguage()!;
      const query = {
        page: this.page,
        limit: 10,
        sortBy: 'createdAt:desc',
        isOnlyUnread: this.isOnlyUnread.toString(),
      };
      const payload = (await this.backendService.getNotifications(
        query
      )) as any;
      this.notificationsPageInfo = payload;
      const displayNotifications = payload?.results?.map((noti: any) => ({
        ...noti,
        timeAgo: dayjs(noti.createdAt).locale(lang).fromNow(),
        selected: false,
      }));
      return displayNotifications;
    } catch (error) {
      throw error;
    }
  }

  readNotifications(notification: any) {
    try {
      this.notifications = [...this.notifications].map((noti: any) => {
        if (noti.id === notification.id) {
          return { ...noti, isReaded: true };
        }
        return noti;
      });
      this.backendService.readNotifications([notification.id]);
    } catch (error) {
      this.errorHandlerService.error(error, true);
    }
  }

  handleClickNotification(notification: any) {
    if (!this.selectModeEnable) {
      this.readNotifications(notification);
      this.singletonService.handleNavigateNotification(notification);
    } else {
      this.handleSelectNotification(notification);
    }
  }

  handleChangeTab(tab: string) {
    this.page = 1;
    this.isOnlyUnread = tab === 'UNREAD';
    this.activeTab = tab;
    this.mount();
  }

  toggleSelectModeEnable() {
    this.selectModeEnable = !this.selectModeEnable;
    this.handleDeselectNotification();
  }

  handleSelectNotification(notification: any) {
    this.notifications = [...this.notifications].map((noti: any) => {
      if (noti.id === notification.id) {
        return { ...noti, selected: !noti.selected };
      }
      return noti;
    });
  }

  handleDeselectNotification() {
    if (!this.selectModeEnable) return;
    this.notifications = [...this.notifications].map((noti: any) => {
      return { ...noti, selected: false };
    });
  }

  handleDeleteNotification() {
    try {
      const deleteList = this.notifications
        .filter((noti: any) => noti.selected)
        .map((noti: any) => noti.id);
      this.notifications = [...this.notifications].filter(
        (noti: any) => !deleteList.includes(noti.id)
      );
      this.backendService.deletedNotifications(deleteList);
    } catch (error) {
      this.errorHandlerService.error(error, true);
    } finally {
      this.toggleSelectModeEnable();
    }
  }

  backnav() {
    this.singletonService.back();
  }

  async onScrollEnd() {
    if (this.notifications.length === this.notificationsPageInfo.totalResults) {
      return;
    }
    this.page++;
    this.loadingMore = true;
    const notiPayload = await this.getNotifications();
    this.notifications = [...this.notifications, ...notiPayload];
    this.loadingMore = false;
  }
}
