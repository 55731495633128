/* eslint-disable */

import { Injectable } from '@angular/core';
import { BrowserQRCodeReader } from '@zxing/browser';
import { Observable } from 'rxjs';

declare const qrcode: any;

@Injectable({
  providedIn: 'root',
})
export class QrCodeReader {

  constructor() {}

  decode(file: any): Observable<string> {
    return new Observable((observer: any) => {
      const codeReader = new BrowserQRCodeReader();
      const reader:any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        codeReader.decodeFromImageUrl(reader.result.toString()).then((res) => {
          observer.next(res.getText());
          observer.complete();
        });
      };
    });
  }

  // decode(file: any): Observable<string> {

  //   return new Observable((observer:any) => {

  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = (e: any) => {
  //       const data = e.target.result;
  //       qrcode.callback = (res:any) => {
  //         observer.next(res);
  //         observer.complete();
  //       };
  //       qrcode.decode(data);
  //     };

  //   });
  // }
}
