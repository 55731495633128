import { Router } from '@angular/router';
/* eslint-disable */

import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Logger } from 'src/app/@services/logger.service';
import { UserService } from 'src/app/@services/user.service';
import { environment } from 'src/environments/environment';
import { UserQrCode } from 'src/models/user.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SingletonService } from 'src/app/@services/singleton.service';
import { ModalMynamecardComponent } from '../modal-mynamecard/modal-mynamecard.component';
import { BackendService } from 'src/app/@services/backend.service';

const log = new Logger('ModalMoremenuComponent');

@Component({
  selector: 'modal-moremenu',
  templateUrl: './modal-moremenu.component.html',
  styleUrls: ['./modal-moremenu.component.scss'],
})
export class ModalMoremenuComponent {
  loading = false;
  error = '';
  userQrCode: UserQrCode;

  currentUser: any;
  currentUserSubscription: any;

  unusedBadge = 0;

  constructor(
    private _backendService: BackendService,
    public modal: NgbActiveModal,
    private _userService: UserService,
    private _errorService: ErrorHandlerService,
    private _authenticationService: AuthenticationService,
    public singletonService: SingletonService,
    private modalService: NgbModal,
    private _router: Router
  ) {}

  ngOnInit() {
    this.currentUserSubscription =
      this._authenticationService.currentUserSubject.subscribe(
        (_currentUser) => {
          this.currentUser = _currentUser;
          // log.debug('this.currentUser => %o', this.currentUser);
        }
      );

    if (this.currentUser) {
      this.getQrCode();
      this.getUnusedCouponBadge();
    }
  }

  ngOnDestroy(): void {
    // log.debug('ngOnDestroy... %o');
    if (this.currentUserSubscription)
      this.currentUserSubscription.unsubscribe();
  }

  async getQrCode() {
    try {
      this.loading = true;
      this.userQrCode = (await this._userService.requestQrCode({
        eventCode: environment.eventCode,
      })) as UserQrCode;
    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  }

  openModalMyNamecard() {
    this.modalService.open(ModalMynamecardComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'sm',
    });
  }

  async getUnusedCouponBadge() {
    try {
      const coupons =
        (await this._backendService.getUnusedCouponBadge()) as any;
      this.unusedBadge = coupons?.totalResults || 0;
    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
    }
  }

  jumpToRoute(route: any) {
    this._router.navigate([route]);
  }
}
