<div class="wrapper-100vw">
  <div class="wrapper-top-header border-0 mt-0 mb-0 pb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="backnav()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-1">
          <h6 class="title size-18">{{ "MY_COUPON" | translate }}</h6>
        </div>
        <div class="col-auto align-self-center"></div>
      </div>
    </div>
  </div>

  <div class="container">
    <ngx-loading
      [show]="loading"
      [config]="{ fullScreenBackdrop: true }"
    ></ngx-loading>
  </div>

  <ng-container>
    <div class="my-point-container" *ngIf="!balanceLoading">
      <div class="my-point px-4 text-light">
        <div class="size-14 fw-bold w-100">
          <div class="d-flex justify-content-between align-items-center">
            <b class="size-12 text-capitalize">{{ "MY_POINT" | translate }}</b>
            <span>
              {{ point.results[0].balance | numeral : "0,0.00" }}
              <img
                [src]="
                  defaultToken.token.logo || 'assets/img/dashboard/coin-53x53.png'
                "
                width="20"
                height="20"
                class="align-sub ms-1 rounded-circle"
                alt="coin"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="my-point my-nft px-4 text-light">
        <div class="size-14 fw-bold w-100">
          <div class="d-flex justify-content-between align-items-center">
            <b class="size-12">{{ "MY_NFT" | translate }}</b>
            <span>
              {{ nftRemainAmount | numeral : "0,0" }}
              <img
                src="assets/img/icon/nft-icon.png"
                width="20"
                height="20"
                class="align-sub ms-1 rounded-circle"
                alt="nft"
              />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mb-2 mt-3">
        <div class="col-12 px-0">
          <swiper
            class="connectionwiper"
            [slidesPerView]="'auto'"
            [spaceBetween]="0"
            [pagination]="false"
          >
            <ng-container *ngFor="let category of categories">
              <ng-template swiperSlide>
                <div
                  class="tag border"
                  [ngClass]="{ active: activeCategory === category }"
                  (click)="handleActiveCategory(category)"
                >
                  <span class="tag-inner">
                    <span class="text-uppercase">{{ category }}</span>
                  </span>
                </div>
              </ng-template>
            </ng-container>
          </swiper>
        </div>
      </div>

      <div *ngIf="categoryLoading; then loadingBlock; else contentBlock"></div>
      <ng-template #loadingBlock> {{ "LOADING" | translate }} </ng-template>

      <ng-template #contentBlock>
        <div
          infinite-scroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onScrollEnd()"
          *ngIf="!loading"
        >
          <div *ngIf="!coupons.length">
            <div class="text-center">
              <div class="my-5 py-3">
                <div class="card bg-transparent">
                  <div class="card-body p-0">
                    <div class="empty-wrapper text-center">
                      <img
                        src="assets/img/empty/empty-brochure.png"
                        alt="empty brochure"
                      />
                      <p class="text-center mt-2 fw-normal size-14">
                        {{ "NO_COUPON" | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngFor="let coupon of coupons">
            <card-mycoupon
              [coupon]="coupon.coupon"
              [buttonDisabled]="activeCategory !== 'Valid'"
              (buttonClick)="openRewardModal(coupon)"
            ></card-mycoupon>
          </ng-container>
        </div>
        <div *ngIf="loadingMore">{{ "LOADING" | translate }}...</div>
      </ng-template>
    </div>
  </ng-container>
</div>
