import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { ModalSetupPincodeComponent } from '../modal-setup-pincode/modal-setup-pincode.component';

@Component({
  selector: 'app-modal-verify-pincode',
  templateUrl: './modal-verify-pincode.component.html',
  styleUrls: ['./modal-verify-pincode.component.scss'],
})
export class ModalVerifyPincodeComponent {
  pincode = '';
  error = '';
  checking = false;
  clearPincode: Subject<void> = new Subject();

  constructor(
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    public modal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.checkAlreadyHavePinCode();
  }

  checkAlreadyHavePinCode() {
    const currentUser = this.authenticationService.currentUserSubject.value;
    if (!currentUser) return;
    const isPinned = currentUser.profile.isPinned;
    if (!isPinned) {
      setTimeout(() => {
        this.modalService.open(ModalSetupPincodeComponent, {
          centered: true,
          backdrop: 'static',
        });
      }, 0);
    }
  }

  onChange(pin: string) {
    if (pin.length > 1) this.error = '';
    this.pincode = pin;
  }

  async submit() {
    try {
      this.checking = true;
      const payload = { pin: this.pincode };
      await this.backendService.verifyPin(payload);
      this.modal.close(true);
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.checking = false;
      this.clearPincode.next();
    }
  }
}
