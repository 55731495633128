import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';

@Component({
  selector: 'app-modal-mynamecard',
  templateUrl: './modal-mynamecard.component.html',
  styleUrls: ['./modal-mynamecard.component.scss']
})
export class ModalMynamecardComponent implements OnInit {

  currentUser:any;

  constructor(
    public modal: NgbActiveModal,
    public _authenticationService: AuthenticationService,
    public errorHandlerService: ErrorHandlerService,

  ) { }

  ngOnInit() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    if(!this.currentUser) this.errorHandlerService.goSignIn()
  }

}
