<!-- input -->
<div class="wrapper-100vw">

  <div class="wrapper-top-header mt-0 pb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center px-0">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="backnav()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center px-1">
          <div class="input-group">
            <span class="input-group-text">
              <img src="assets/img/icon/search.png" alt="search icon">
            </span>
            <input
                type="text"
                class="form-control rounded-10"
                value=""
                placeholder="{{'SEARCH'| translate}}"
                (keyup)="searchWithDelay($event)"
              />
          </div>
        </div>
        <div class="col-auto align-self-center px-0">
          <button class="btn text-white btn-sm pe-0 filter-btn">
            <i class="bi bi-sliders size-22"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper-suggestion-exhibitor border-bottom mb-4 pb-2">
    <div class="container">
      <div class="mt-2 mb-1">
        <h6 class="title size-16">{{"SUGGEST_EXHIBITOR" | translate}}</h6>
        <h6 class="text-secondary mt-1 size-14">{{"SUGGESTION_FOR_YOU" | translate}}</h6>
      </div>
      <div class="row my-3">
        <div class="col-12">

          <swiper
            class="swiper-parent"
            [slidesPerView]="'auto'"
            [spaceBetween]="15"
            [pagination]="true"
          >

            <ng-template swiperSlide *ngFor="let suggestExhibitor of suggestExhibitorList">
              <div class="slide-wrapper" (click)="jumpToExhibitorDetail(suggestExhibitor)">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    [src]="(suggestExhibitor.coverImage ? suggestExhibitor.coverImage : 'assets/img/cover/mock-image.png')"
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
                <div class="wrapper-category">
                  <span class="span-category">HIGHLIGHT</span>
                </div>
                <div class="news-content">
                  <h6 class="size-16 text-white text-truncate">
                    {{suggestExhibitor.name}}
                  </h6>
                  <p class="size-12 text-secondary text-2-line" style="max-height: 36px;">
                    {{suggestExhibitor.description}}
                  </p>
                </div>
              </div>
            </ng-template>

            <div *ngIf="isLoadingSuggestExhibitorList">
              <div class="text-center my-5 py-3 empty-wrapper">
                <!-- <img src="assets/img/empty/empty-search-list.png" alt="empty search list"> -->
                <div class="spinner-border"></div>
                <p class="text-center fw-normal size-14">{{ "LOADING" | translate }}...</p>
              </div>
            </div>

          </swiper>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="mt-3 mb-3">
      <h6 class="title size-16">
        {{ "EXHIBITOR" | translate }}
      </h6>
      <h6 class="text-secondary mt-1 size-14" *ngIf="totalResults !== undefined">
        ({{ "TOTAL_RESULTS" | translate }}: {{totalResults}})
      </h6>
    </div>
    <div class="row mb-2 mt-3" *ngIf="!isLoadingExhibitorList">
      <div class="col-12 px-0">
        <!-- swiper categories -->
        <swiper
          class="connectionwiper"
          [slidesPerView]="'auto'"
          [spaceBetween]="0"
          [pagination]="false"
        >
          <ng-template swiperSlide>
            <div class="tag border" (click)="setSelectedAll()" [ngClass]="isSelectedAll ? 'active' : ''">
              <span class="tag-inner justify-content-center">
                <span class="text-uppercase">{{ "ALL" | translate }}</span>
              </span>
            </div>
          </ng-template>

          <ng-template swiperSlide *ngFor="let category of categoryList">
            <div class="tag border"
              (click)="activateClassActive(category);"
              [ngClass]="(category && category.active ? 'active' : '')">
              <span class="tag-inner justify-content-center">
                <span class="text-uppercase">{{category.title}}</span>
              </span>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>

    <div class="exhibitor-container" *ngIf="!isLoadingDelayedSearch">

      <card-exhibitor
        *ngFor="let exhibitor of exhibitorList"
        (click)="jumpToExhibitorDetail(exhibitor)"
        [exhibitor]="exhibitor"
        class="exhibitor-item"
      ></card-exhibitor>

      <div class="text-center">
        <div *ngIf="!exhibitorList.length && !isLoadingExhibitorList">
          <div class="my-5 py-3">
            <div class="card bg-transparent">
              <div class="card-body p-0">
                <div class="empty-wrapper text-center">
                  <img src="assets/img/empty/empty-search-list.png" alt="empty search list">
                  <p class="text-center mt-2 fw-normal size-14">
                    {{"WE_CANT_FIND_ANY_MATCH" | translate}} <br>
                    {{"PLEASE_TRY_OTHER_WORDS" | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="isLoadingDelayedSearch || isLoadingExhibitorList">
      <div class="text-center my-5 py-3 empty-wrapper">
        <!-- <img src="assets/img/empty/empty-search-list.png" alt="empty search list"> -->
        <div class="spinner-border"></div>
        <p class="text-center fw-normal size-14">{{ "LOADING" | translate }}...</p>
      </div>
    </div>

    <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    ></div>
  </div>

</div>




