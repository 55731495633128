import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Logger } from 'src/app/@services/logger.service';
import { environment } from 'src/environments/environment';
import { PointType } from 'src/models/point.model';
import { TransectionType } from 'src/models/tokenTransection.model';
import { User } from 'src/models/user.model';

const log = new Logger('PointComponent');

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.scss'],
})
export class PointComponent implements OnInit {
  balanceLoading = true;
  historyLoading = false;
  point: PointType;
  transection: TransectionType;
  error: '';
  activeTab = 0;
  currentUser: User;

  constructor(
    private _authenticationService: AuthenticationService,
    private _backendService: BackendService,
    private _errorService: ErrorHandlerService
  ) {}

  async ngOnInit() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    await this.getAllAvailablePoint();
    if (this.point?.results?.[0]) {
      await this.getAllPointTransactions(this.point.results[0].token.id);
    }
  }

  async getAllAvailablePoint() {
    try {
      this.balanceLoading = true;
      const query = { eventCode: environment.eventCode, isOnlyAvailable: false };
      this.point = (await this._backendService.getAllPoint(query)) as PointType;
      log.debug(`this.point %o`,this.point);
    } catch (err) {
      const error = this._errorService.error(err);
      log.debug(`error %o`,error);
      this.error = error.message;
      // if (error.code === 401) {
      //   this._errorService.goSignIn();
      // }
    } finally {
      this.balanceLoading = false;
    }
  }

  reloadCallback() {
    this.getAllAvailablePoint();
    this.getAllPointTransactions(this.point.results[this.activeTab].token.id);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTab = tabChangeEvent.index;
    this.getAllPointTransactions(this.point.results[this.activeTab].token.id);
  }

  async getAllPointTransactions(token: string) {
    try {
      this.historyLoading = true;
      this.transection = (await this._backendService.getAllPointTransactions({
        token,
        page: 1,
        limit: 5,
      })) as TransectionType;
    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
    } finally {
      setTimeout(() => (this.historyLoading = false), 500);
    }
  }
}
