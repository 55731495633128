<!-- <div class="modal-header">
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div> -->
<div class="modal-header py-3 align-items-center justify-content-between">
  <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h3 class="text-white size-20">{{"REVIEW" | translate}}</h3>
  <button class="btn btn-top-right-arrow" type="button">
  </button>
</div>
<div class="modal-body px-4 pb-3">

  <div class="alert alert-danger mb-4" role="alert" *ngIf="!!error">
    {{ error }}
  </div>

  <div class="mt-2 mb-1">
    <p>{{"RATING" | translate}}</p>
    <div class="text-center">
      <span class="size-24">
        <i (click)="setPoint(1)" class="bi text-warning mx-1" [ngClass]="point >= 1 ? 'bi-star-fill' : 'bi-star'"></i>
        <i (click)="setPoint(2)" class="bi text-warning mx-1" [ngClass]="point >= 2 ? 'bi-star-fill' : 'bi-star'"></i>
        <i (click)="setPoint(3)" class="bi text-warning mx-1" [ngClass]="point >= 3 ? 'bi-star-fill' : 'bi-star'"></i>
        <i (click)="setPoint(4)" class="bi text-warning mx-1" [ngClass]="point >= 4 ? 'bi-star-fill' : 'bi-star'"></i>
        <i (click)="setPoint(5)" class="bi text-warning mx-1" [ngClass]="point >= 5 ? 'bi-star-fill' : 'bi-star'"></i>
      </span>
    </div>
  </div>
  <div class="mt-3 mb-1">
    <p>{{"REVIEW_CONTENT" | translate}}</p>
    <div>
      <textarea
        [(ngModel)]="comment"
        class="form-control textarea-h-auto"
        id="text"
        name="text"
        maxlength="120"
        placeholder="{{exhibitorId ? 'เขียนรีวิวเกี่ยวกับบูธจัดงานนี้สั้นๆ...' : 'เขียนรีวิวเกี่ยวกับงานอีเวนท์นี้สั้นๆ...'}}"
        rows="5"
      ></textarea>
      <div class="text-end size-12 text-secondary mt-1">{{comment.length}}/120</div>
    </div>
  </div>
  <div class="mt-3 mb-1">
    <p>{{"ADDITIONAL_PICTURE" | translate}}</p>
    <div class="upload-container">
      <div class="text-secondary d-flex align-items-center">
        <input type="file" id="files" name="files" multiple
          (change)="handleFileInput($event)"
          class="invisible-input-file"/>
        <i class="bi bi-camera size-20 lh-1"></i>
        <p class="ms-2">{{"ADD_PICTURE" | translate}}</p>
      </div>
    </div>
  </div>
  <div class="mt-3 mb-1" id="input-images-wrapper">
    <div id="input-images" class="show-img-preview"></div>
  </div>
</div>

<div class="modal-footer py-3">
  <button
    [disabled]="isLoading"
    (click)="reviewExhibitor()"
    class="btn btn-default w-100"
    >{{"POST" | translate}}
  </button>
</div>
