/* eslint-disable */

import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import { environment } from 'src/environments/environment';
import {
  CouponCategoryType,
  CouponResult,
  CouponType,
} from 'src/models/event.model';
import { PointResult, PointType } from 'src/models/point.model';
import { SingletonService } from 'src/app/@services/singleton.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('CouponrewardComponent');

@Component({
  selector: 'app-couponreward',
  templateUrl: './couponreward.component.html',
  styleUrls: ['./couponreward.component.scss'],
})
export class CouponrewardComponent implements OnInit {
  balanceLoading = true;
  point: PointType;
  defaultToken: PointResult;

  loading = true;
  error = '';
  categories: CouponCategoryType;
  activeCategory: string | null = null;

  coupons: CouponResult[];
  page = 1;
  loadingMore = false;
  categoryLoading = false;
  nftRemainAmount = 0;

  unusedBadge = 0;

  loadingConfig = {
    animationType: ngxLoadingAnimationTypes.wanderingCubes,
    primaryColour: '#4b0082',
    secondaryColour: '#ffd42a',
    backdropBorderRadius: '3px',
  };
  loadingTemplate!: TemplateRef<any>;

  isLoadingBundleList:any = false
  bundles:any = []

  constructor(
    private _backendService: BackendService,
    private _errorService: ErrorHandlerService,
    private _translateConfigService: TranslateConfigService,
    private _router: Router,
    private singletonService: SingletonService
  ) {}

  async ngOnInit() {
    this.balanceLoading = true;
    await this.getAvailablePoint();
    await this.getAvailableNfts();
    this.balanceLoading = false;
    await this.getCouponBundle();
    await this.getAvailableCoupons();
    await this.getUnusedCouponBadge();
  }

  async getCouponBundle(){
    try {
      const res =
        (await this._backendService.getCouponBundles(
          environment.eventCode,
        )) as any;
      log.debug("getCouponBundles res => %o",res)

      if(res){
        this.bundles = res.results;
      }

    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
    }
  }

  async getAvailablePoint() {
    try {
      const query = { eventCode: environment.eventCode, isOnlyAvailable: true };
      this.point = (await this._backendService.getAllPoint(query)) as PointType;

      const defaultToken = this.point.results.find((result) => {
        return result.token.isDefault;
      });
      this.defaultToken = defaultToken || this.point.results?.[0] || null;
      log.debug("this.defaultToken => %o",this.defaultToken)
    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
    }
  }

  async getAvailableNfts() {
    try {
      const nft = (await this._backendService.getAllNfts({
        eventCode: environment.eventCode,
      })) as any;
      this.nftRemainAmount = nft?.infos?.remainAmount || 0;
      log.debug("this.nftRemainAmount => %o",this.nftRemainAmount)
    } catch (err) {
      throw err;
    }
  }

  async getUnusedCouponBadge() {
    try {
      const coupons =
        (await this._backendService.getUnusedCouponBadge()) as any;
      this.unusedBadge = coupons?.totalResults || 0;
      log.debug("this.unusedBadge => %o",this.unusedBadge)

    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
    }
  }

  async getAvailableCoupons() {
    this.loading = true;
    await this.getCouponCategories();
    const couponPayload = await this.getCoupons(null);
    this.coupons = couponPayload?.results || [];
    this.loading = false;
  }

  async getCouponCategories() {
    try {
      this.categories =
        (await this._backendService.getCouponCategories()) as CouponCategoryType;
      log.debug("this.categories => %o",this.categories)

    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
    }
  }

  async handleActiveCategory(categoryId: string | null) {
    this.activeCategory = categoryId;
    this.page = 1;
    this.categoryLoading = true;
    const couponPayload = await this.getCoupons(categoryId);
    this.coupons = couponPayload?.results || [];
    this.categoryLoading = false;
  }

  async getCoupons(categoryId: string | null) {
    try {
      const queryParams = {
        eventCode: environment.eventCode,
        limit: 30,
        page: this.page,
        lang: this._translateConfigService.getCurrentLanguage(),
        sortBy: 'createdAt:desc',
      };
      if (!!categoryId) {
        Object.assign(queryParams, { category: categoryId });
      }
      const coupons = (await this._backendService.getCoupons(
        queryParams
      )) as CouponType;
      log.debug("coupons => %o",coupons)

      return coupons;
    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
      return {} as CouponType;
    }
  }

  async onScrollEnd() {
    this.page++;
    this.loadingMore = true;
    const couponPayload = await this.getCoupons(this.activeCategory);
    this.coupons = [...this.coupons, ...couponPayload?.results];
    this.loadingMore = false;
  }

  /* --- deprecated --- */

  // openRedeemModal(coupon: CouponResult) {
  //   const modalRef = this._modalService.open(ModalRedeemcouponComponent, {
  //     centered: true,
  //     size: 'sm',
  //     scrollable: true,
  //     backdrop: 'static',
  //   });
  //   modalRef.componentInstance.focusCoupon = coupon;
  //   modalRef.result.then(
  //     (data) => {},
  //     async (reason) => {
  //       if (reason === 'Close by user') {
  //         this.page = 1;
  //         await this.getAvailablePoint();
  //         await this.getAvailableCoupons();
  //       }
  //     }
  //   );
  // }

  openCouponDetail(couponId: string,isBundle:any = false) {
    if(isBundle){
      const couponBundleId = couponId
      this._router.navigate([`/coupon-bundle-detail/${couponBundleId}`]);
    }else{
      this._router.navigate([`/coupon-detail/${couponId}`]);
    }
  }

  backnav() {
    if (document.referrer == '') {
      this._router.navigate(['/']);
    } else {
      this.singletonService.back();
    }
    return false;
  }
}
