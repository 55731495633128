/* eslint-disable */

import { Router } from '@angular/router';
import { PrivacypolicyComponent } from './../../innerlayout/privacypolicy/privacypolicy.component';
import { TermsandconditionsComponent } from './../../_authlayout/termsandconditions/termsandconditions.component';
import { QuestionnaireComponent } from './../questionnaire/questionnaire.component';
import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAuth } from 'src/models/auth.model';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { Logger } from 'src/app/@services/logger.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { ConfirmPasswordValidator } from 'src/validators/confirm-password.validator';
import { FormService } from 'src/app/@services/form.service';
import { OtpCheckingComponent } from '../otp-checking/otp-checking.component';

const log = new Logger('ModalSignupUserinfoComponent');
@Component({
  selector: 'app-modal-signup-userinfo',
  templateUrl: './modal-signup-userinfo.component.html',
  styleUrls: ['./modal-signup-userinfo.component.scss'],
})
export class ModalSignupUserinfoComponent implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Thailand];

  @Input() userInfo: UserAuth;
  @Input() paperWalletCode: string;

  paperWalletPhone = '';

  infoForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  error = '';

  isLoading = false;

  avatarPreview = '';

  isShow: boolean = false;
  isShowRecomfirm: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private _formBuilder: UntypedFormBuilder,
    private _authenticationService: AuthenticationService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private _errorHandlerService: ErrorHandlerService,
    public formService: FormService,
    private modalService: NgbModal
  ) {
    this.passwordForm = this._formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmPasswordValidator('password', 'confirmPassword'),
      }
    );

    this.infoForm = this._formBuilder.group({
      picture: [null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobilePhone: ['', Validators.required],
      email: [
        '',
        [
          // Validators.required,
          Validators.email,
          // Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      isPrivilege: [false],
      isAgreement: [false, Validators.requiredTrue],
      isPress: [false],
    });
  }

  ngOnInit() {
    log.debug('userInfo => %o', this.userInfo);
    this.setInitialInfo();
  }

  showPassword() {
    this.isShow = !this.isShow;
  }

  showConfirm() {
    this.isShowRecomfirm = !this.isShowRecomfirm;
  }

  setInitialInfo() {
    if (this.userInfo) {
      const { profile, email, mobilePhone } = this.userInfo;

      const patch = {
        firstName: profile?.firstName || '',
        lastName: profile?.lastName || '',
        email: email || '',
        mobilePhone: (mobilePhone ? mobilePhone.substring(1) : '') || '',
      };
      this.infoForm.patchValue(patch);

      if (!!email) {
        this.infoForm.controls['email'].disable();
      }

      if (!!mobilePhone) {
        this.infoForm.controls['mobilePhone'].disable();
      }
    }
  }

  getSubmitFormData() {
    const formData: any = new FormData();
    if (this.infoForm.value && this.infoForm.value.picture)
      formData.append('picture', this.infoForm.value.picture);
    if (this.infoForm.value && this.infoForm.value.firstName)
      formData.append('firstName', this.infoForm.value.firstName);
    if (this.infoForm.value && this.infoForm.value.lastName)
      formData.append('lastName', this.infoForm.value.lastName);
    if (this.infoForm.value && this.infoForm.value.mobilePhone)
      formData.append('mobilePhone', `0${this.infoForm.value.mobilePhone}`);
    this.paperWalletPhone = `0${this.infoForm.value.mobilePhone}`;
    if (this.infoForm.value && this.infoForm.value.email)
      formData.append('email', this.infoForm.value.email);

    log.debug(
      'this.infoForm.value.isPrivilege => %o',
      this.infoForm.value.isPrivilege
    );
    log.debug(
      'this.infoForm.value.isAgreement => %o',
      this.infoForm.value.isAgreement
    );
    log.debug(
      'this.infoForm.value.isPrivacyPolicy => %o',
      this.infoForm.value.isPrivacyPolicy
    );

    if (this.infoForm.value && this.infoForm.value.isPrivilege != undefined) {
      formData.append(
        'isPrivilege',
        this.infoForm.value.isPrivilege ? true : false
      );
    }
    if (this.infoForm.value && this.infoForm.value.isAgreement != undefined) {
      formData.append(
        'isAgreement',
        this.infoForm.value.isAgreement ? true : false
      );
      formData.append(
        'isPrivacyPolicy',
        this.infoForm.value.isAgreement ? true : false
      );
    }
    if (this.infoForm.value && this.infoForm.value.isPress != undefined) {
      formData.append('isPress', this.infoForm.value.isPress ? true : false);
    }

    if (!!this.paperWalletCode) {
      if (this.passwordForm.value.password) {
        formData.append('password', this.passwordForm.value.password);
      }
      if (this.paperWalletCode) {
        formData.append('paperWalletCode', this.paperWalletCode);
      }
    }

    for (var pair of formData.entries()) {
      log.debug('FormData => ' + pair[0] + ', ' + pair[1]);
    }

    return formData;
  }

  async onSubmitNormalSignUp() {
    try {
      this.isLoading = true;
      this.error = '';

      const formData = this.getSubmitFormData();

      const signUpResponse = await this._authenticationService.signUpwithData(
        formData
      );
      if (signUpResponse) {
        const user = signUpResponse;
        log.debug('user => %o', user);
        // update current user info
        this.tokenStorageService.saveUser(user);
        this._authenticationService.currentUserSubject.next(user);
        log.debug(
          'this._authenticationService.currentUserSubject.value => %o',
          this._authenticationService.currentUserSubject.value
        );
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(QuestionnaireComponent, {
          centered: true,
          size: 'xl',
          scrollable: true,
        });
        this.isLoading = false;
      }
    } catch (error: any) {
      const _error = this._errorHandlerService.error(error).message;
      log.debug('_error => %o', _error);
      this.error = _error;
      this.isLoading = false;
    }
  }

  async onSubmitWithPaperWalletCode() {
    try {
      this.isLoading = true;
      this.error = '';
      const formData = this.getSubmitFormData();
      // const checked = await this.checkPaperWalletOtp();
      // if (checked === 'success') {
      const signUpResponse =
        await this._authenticationService.signUpwithPaperWallet(formData);

      if (signUpResponse) {
        let loginData = await this._authenticationService.login(
          this.paperWalletPhone,
          this.passwordForm.value.password
        );
        if (loginData) {
          this.modalService.dismissAll();
          this.router.navigate(['/']);
        }
      }

      // }
    } catch (error: any) {
      const _error = this._errorHandlerService.error(error).message;
      log.debug('_error => %o', _error);
      this.error = _error;
      this.isLoading = false;
    }
  }

  checkPaperWalletOtp() {
    const modalRef = this.modalService.open(OtpCheckingComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.registerType = 'paperWallet';
    modalRef.componentInstance.username = this.paperWalletPhone;
    modalRef.componentInstance.password = this.passwordForm.value.password;
    const checked = modalRef.result.then(
      (data) => {
        return data;
      },
      (_) => {}
    );
    return checked;
  }

  openAgreement() {
    // this._modalService.open(PrivacypolicyComponent, {centered: true, size: 'xl', scrollable:true, backdrop:'static'})
    const url = this.router.createUrlTree(['/termsandconditions']);
    window.open(url.toString(), '_blank');
  }

  openPolicy() {
    // this._modalService.open(TermsandconditionsComponent, {centered: true, size: 'xl', scrollable:true, backdrop:'static'})
    const url = this.router.createUrlTree(['/privacypolicy']);
    window.open(url.toString(), '_blank');
  }

  async onInputFileChange(e: any) {
    const files = e.target.files;
    const file = Array.from(files)[0] as File;
    this.avatarPreview = await this.fileToBase64(file);
    this.infoForm.patchValue({ picture: file });
  }

  fileToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  removeAvatar() {
    this.infoForm.patchValue({ picture: null });
    this.avatarPreview = '';
  }

  dismissModal() {
    this.modal.dismiss('Cross click');
    if (!!this.paperWalletCode) {
      setTimeout(() => this.router.navigate(['/signin']), 0);
    }
  }
}
