/* eslint-disable */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('ModalAddreviewexhibitorComponent');

@Component({
  selector: 'modal-addreviewexhibitor',
  templateUrl: './modal-addreviewexhibitor.component.html',
  styleUrls: ['./modal-addreviewexhibitor.component.scss'],
})
export class ModalAddreviewexhibitorComponent {
  @Input("exhibitorId") exhibitorId:any = undefined;
  @Input("eventCode") eventCode:any = undefined;
  @Output() dataService = new EventEmitter();

  isLoading:any = false

  type:any = "exhibitor";
  comment:any = '';
  point:any;
  images:any;

  error:any;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
  }


  async ngOnInit() {
    log.debug("modal work! this.exhibitorId: %o",this.exhibitorId)
    log.debug("modal work! this.eventCode: %o",this.eventCode)

    this.type = this.exhibitorId ? 'exhibitor' : 'event'

  }

  setPoint(point:any){
    this.point = point
  }

  async canSubmit(){
    let canSubmit:any = false

    let condition01 = await this.isAvailablePoint()

    canSubmit = (condition01)
    return canSubmit
  }

  async isAvailablePoint(){
    if(this.point > 0 && this.point <= 5) return true
    else{
      this.error = "โปรดคลิกดาวเพื่อให้คะแนน"
      log.warn("this.error:",this.error)
      return false
    }
  }

  async reviewExhibitor(){

    if(await this.canSubmit()){
      this.isLoading = true
      const formData:any = new FormData();

      if(this.exhibitorId) formData.append('eventExhibitor', this.exhibitorId);
      if(this.eventCode) formData.append('eventCode', this.eventCode);

      if(this.images && this.images.length){
        for (var x = 0; x < this.images.length; x++) {
          // fd.append("fileToUpload[]", document.getElementById('fileToUpload').files[x]);
          formData.append('images', this.images[x]);
        }
      }

      if(this.type) formData.append('type', this.type);
      if(this.comment) formData.append('comment', this.comment);
      if(this.point) formData.append('point', this.point);

      // Display the key/value pairs
      for (var pair of formData.entries()) {
        log.debug("reviewExhibitor => " + pair[0]+ ', ' + pair[1]);
      }

      const res = await this.backendService.reviewExhibitor(formData)
      if(res){
        log.debug("res reviewExhibitor => %o",res)
        this.isLoading = false
        this.dataService.emit(true)
        this.modalService.dismissAll()
      }else{
        this.isLoading = false
      }

    }else{
      // this.singletonService.fire("error","Please fill out the required fields completely.")
    }

  }

  async handleFileInput(event:any){
    log.debug("handleFileInput event => %o",event)
    let files:any = event.target.files
    const fileListArr = Array.from(files)

    log.debug("handleFileInput files => %o",files)
    log.debug("handleFileInput fileListArr => %o",fileListArr)

    await this.removeAllImagePreviewElement()

    if(files.length){
      // let files = files.item(0);
      // let isNotSupportFileExtension = await this.singletonService.isNotSupportFileExtension(file)
      // if(isNotSupportFileExtension){
      //   await this.clearFile_featuredImage()
      //   return
      // }
      // let isOverSizeFile = await this.singletonService.isOverSizeFile(file)
      // if(isOverSizeFile){
      //   await this.clearFile_featuredImage()
      //   return
      // }

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const isNotSupportFileExtension = await this.isNotSupportFileExtension(file)
        if(isNotSupportFileExtension){
          fileListArr.splice(index, 1)
        }else{
          this.addPreviewFromFile(file,"input-images",index)
        }
      }
      this.images = fileListArr
      // this.images = files

      log.debug("handleFileInput this.images => %o",this.images)

    }

  }

  removeAllImagePreviewElement(){
    const elements:any = document.getElementsByClassName('img-preview');
    log.debug("removeAllImagePreviewElement elements => %o",elements)
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }
  }

  addPreviewFromFile(file:any,wrapperElementId:any,index:any){
    const wrapperImagesElement:any = document.getElementById(wrapperElementId)
    log.debug("addPreviewFromFile wrapperImagesElement %o",wrapperImagesElement)

    const reader = new FileReader();
    reader.onload = (event:any) => {
      log.debug("reader.result %o",reader.result)
      const url = event.target.result

      // create img review
      const previewElementParent:any = document.createElement('div');
      previewElementParent.setAttribute("class", "wrapper-img-preview");
      const previewElement:any = document.createElement('img');
      previewElement.setAttribute('src', url);
      previewElement.setAttribute("class", "img-preview");
      previewElement.setAttribute('height', 100);
      previewElement.setAttribute('width', 100);
      previewElementParent.appendChild(previewElement);
      wrapperImagesElement.appendChild(previewElementParent);

      // add click event to img review => click to remove file
      previewElementParent.addEventListener('click', (e:any) => {
        this.removeFile(previewElementParent,index)
      });
    };
    reader.readAsDataURL(file);
  }

  removeFile(previewElement:any,index:any){
    if(confirm("Are you sure to remove?")) {
      previewElement.remove()
      this.images.splice(index, 1)
    }
  }


  async isNotSupportFileExtension(file:any){
    let fileType = file.type.split("/").pop()
    log.debug("fileType => %o",fileType)
    let isNotSupportExtention = (["jpg","jpeg","png","gif"].indexOf(fileType) == -1)
    if(isNotSupportExtention){
      // this.fire("error","("+fileType+") File extension is not support.","Only inlist: " + ["jpg","jpeg","png","gif"])
      return true
    }else{
      return false
    }
  }

}
