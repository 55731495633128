/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

import {Location} from '@angular/common';
const log = new Logger('QuestHuntingListComponent');

@Component({
  selector: 'app-quest-hunting-list',
  templateUrl: './quest-hunting-list.component.html',
  styleUrls: ['./quest-hunting-list.component.scss']
})
export class QuestHuntingListComponent implements OnInit {

  keyword:any;
  selectedCategoryList:any = [];

  categoryList:any = [];
  questList:any = [];
  isSelectedAll = true

  delayTimer:any;
  isLoadingDelayedSearch = false
  isLoadingQuestList = false

  limit=10;
  page=1;
  totalPages = 1;
  totalResults:any = undefined;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _location: Location,
  ) { }

  async ngOnInit() {
    await this.searchWithDelay()
    await this.getQuestCategoryList()
  }

  async getQuestCategoryList(){
    this.isLoadingQuestList = true
    const getQuestCategoryListRes:any = await this.backendService.getQuestCategoryList()
    log.debug("res getQuestCategoryListRes: %o",getQuestCategoryListRes)
    if(getQuestCategoryListRes){
      this.categoryList = getQuestCategoryListRes.results
      log.debug("this.categoryList: %o",this.categoryList)
    }
  }

  resetPaginate(){
    this.page = 1
    this.totalPages = 1;
    this.questList = []
    this.totalResults = undefined
  }

  async searchWithDelay(event:any = undefined){
    await this.resetPaginate()
    log.debug("searchWithDelay...")

    var text = event ? event.target.value : undefined;
    const self = this
    clearTimeout(self.delayTimer);
    self.isLoadingDelayedSearch = true
    self.delayTimer = setTimeout(async function() {
      try{
        await self.doSearch(text)
        self.isLoadingDelayedSearch = false
      }catch(err){
        self.isLoadingDelayedSearch = false
      }
    }, 1000);
  }

  async doSearch(
    text:any = undefined
  ) {
    this.isLoadingQuestList = true
    log.debug("doSearch...")
    this.keyword = text

    log.debug("doSearch this.selectedCategoryList => %o",this.selectedCategoryList)
    let selectedCategories:any = []
    if(this.selectedCategoryList.length){
      selectedCategories = this.selectedCategoryList.map((it:any) => it.id) // TODO: multiple
    }else{
      selectedCategories = undefined // TODO: multiple
    }
    log.debug("doSearch selectedCategories => %o",selectedCategories)

    let res:any = await this.backendService.getQuestList(
      text,
      selectedCategories,
      this.limit,
      this.page,
    )
    log.debug("doSearch res => %o",res)
    if(res){
      const results = res.results
      this.questList = [...this.questList,...results]
      log.debug("this.questList: %o",this.questList)

      this.totalPages = res.totalPages
      this.totalResults = res.totalResults

      this.isLoadingQuestList = false
    }else{
      this.isLoadingQuestList = false
    }
  }

  onScroll() {
    log.debug("onScroll...")

    if(this.page < this.totalPages){
      this.page++;
      this.doSearch(this.keyword)
    }
  }

  async activateClassActive(category:any){
    await this.resetPaginate()
    log.debug("activateClassActive category => %o",category)
    category.active = !category.active;
    if(this.categoryList && (this.categoryList.length == this.categoryList.filter((it:any) => !it.active ).length )){
      this.isSelectedAll = true
    }else if(this.categoryList && (this.categoryList.length == this.categoryList.filter((it:any) => it.active ).length )){
      this.isSelectedAll = true
    }else{
      this.isSelectedAll = false
    }

    log.debug("activateClassActive this.selectedCategoryList => %o",this.selectedCategoryList)

    if(category.active){
      this.selectedCategoryList.push(category)
    }else{
      this.selectedCategoryList = this.selectedCategoryList.filter((it:any) => it.id != category.id )
    }

    this.doSearch(this.keyword)
  }

  async setSelectedAll(){
    await this.resetPaginate()
    this.isSelectedAll = !this.isSelectedAll
    if(!this.isSelectedAll){
      this.categoryList.map( (it:any) => it.active = false)
      this.selectedCategoryList = []
    }else{
      this.categoryList.map( (it:any) => it.active = true)
      this.selectedCategoryList = this.categoryList
    }

    this.doSearch(this.keyword)
  }

  jumpQuestDetail(quest:any){
    let path = '/quest-hunting/'+ quest.questType + '/'+ quest._id
    let href = this._router.url;
    if(href.startsWith('/quest-hunting/')){ // same route
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]);
      });
    }else{
      this._router.navigate([path]);
    }

  }

  backnav() {
      this._router.navigate(['/']);
  }

}
