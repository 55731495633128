/* eslint-disable */

import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { FormService } from 'src/app/@services/form.service';
import { Logger } from 'src/app/@services/logger.service';
const log = new Logger('DetectLinkPageComponent');

@Component({
  selector: 'app-modal-socials-sharing',
  templateUrl: './modal-socials-sharing.component.html',
  styleUrls: ['./modal-socials-sharing.component.scss']
})
export class ModalSocialsSharingComponent {
  @Input("url") url:any;

  constructor(
    public modal: NgbActiveModal,
    public formService: FormService,
    private modalService: NgbModal,
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    public _router: Router,
  ) {
    if(!this.url) this.url = window.location.href
    log.debug("this.url -> %o",this.url)
  }
}
