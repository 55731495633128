import { environment } from 'src/environments/environment';
/* eslint-disable */

import { QrCodeModalComponent } from './../qr-code-modal/qr-code-modal.component';
import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { ViewportScroller } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'src/app/@services/logger.service';
import { UserService } from 'src/app/@services/user.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { FormService } from 'src/app/@services/form.service';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
const log = new Logger('QuestionnaireComponent');
@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent {
  @ViewChild('modalBody') modalBody?: ElementRef

  questionForm!: FormGroup
  formData!: FormGroup
  storageQuestion:Array<any> = []
  data:any;
  stateChange:any = []
  error:Boolean = false
  errorText:any

  public loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate!: TemplateRef<any>;

  constructor(
    private modalService : NgbModal,
    private fb :FormBuilder,
    public modal: NgbActiveModal,
    private _userService: UserService,
    private _storageService: TokenStorageService,
    public formService: FormService,
    private viewportScroller: ViewportScroller,
    private translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private _errorHandlerService: ErrorHandlerService,
    ) {
    log.debug('hello from QuestionnaireComponent...')
  }

  ngOnInit() {

    this.modalService.dismissAll()
    const modalRef = this.modalService.open(QrCodeModalComponent, {centered:true,backdrop:'static'})
    modalRef.componentInstance.qrLogin = false
    modalRef.componentInstance.code = environment.eventCode
    modalRef.componentInstance.survey = true

    // ------------------- old flow -------------------------------
    // log.debug('hello from QuestionnaireComponent... yyyy')
    // const currentUser = this._authenticationService.currentUserSubject.value;
    // log.debug('currentUser: %o',currentUser)


    // this.formData = this.fb.group({})
    // this._userService.listQuestion().then((res:any) => {
    //   this.data = res.results;
    //   log.debug('listQuestion this.data => ',this.data)
    //   this.generateForm(res.results)
    // })
  }

  onChange(event:any,question:any){
    log.debug('event =>',event.target.checked)
    const formArray: FormArray = this.formData.get(question) as FormArray;

    // const formArray: FormArray = this.formData.get(event) as FormArray
    if(this.storageQuestion.includes(event.target.value)){
      this.storageQuestion = this.storageQuestion.filter(x => x !== event.target.value)
      log.debug('full storage => ',this.storageQuestion)
      let i: number = 0;
      formArray.controls.forEach((ctrl:any = FormControl) => {
        if(ctrl.value == event.target.value) {
          log.debug('deleted this value')
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          log.debug('filter storage => ',this.storageQuestion)
          return;
        }
        i++
      })
    }else{
      formArray.push(new FormControl(event.target.value))
      this.storageQuestion.push(event.target.value)
    }
  }



  onSubmit() {
    this.checkInvalid()
    if(this.formData.invalid){
      // log.debug('error send form',this.formData.invalid)
      this.scrollToTop()
      this.error = true;
      this.errorText = this.translateService.instant('ERROR_MSG.ERROR_QUESTION')
      if(this.errorText.includes(',')){
        this.errorText = this.errorText.split(',')[0]
      }
      return
    }
    // toast

    // toast
    // log.debug('form data value',this.formData.value);
    let arrAnswer:any = []

    for(let i in this.data){
      // log.debug('question => ',i);
      if(this.data[i].questionType === 'short'){
        let setData = {
          question: this.data[i].id,
          answer: this.formData.value['question'+i]
        }
        arrAnswer.push(setData)
      }
      if(this.data[i].questionType === 'choice'){
        // let id = this.data[i].choices.filter((data:any) => this.formData.value['question'+i] === this.data[i].choices._id)
        let _id = this.formData.controls['question'+i].value
        // log.debug('data i =>',this.data[i].choices);
        let _answer = this.data[i].choices.find((x:any) => x._id === _id)

        let setData = {
          question: this.data[i].id,
          answer: [_answer]
        }
        arrAnswer.push(setData)

      }
      if(this.data[i].questionType === 'multi-choices'){
        let _answer:any = []
        let _id = this.formData.controls['question'+i].value
        for(let id of _id){
          let choice = this.data[i].choices.find((x:any) => x._id === id)
          if(choice){
            _answer.push(choice)
          }
        }
        // log.debug('data i =>',this.data[i].choices);
        let setData = {
          question: this.data[i].id,
          answer: _answer
        }
        arrAnswer.push(setData)
      }
      // log.debug('check validate => ',this.formData.controls)
    }

    let dataQuestion = {
      eventCode: 'event-01',
      answers: arrAnswer
    }

    this._userService.sendSurvey(dataQuestion).then((res) => {
      this.modalService.dismissAll()
      const modalRef = this.modalService.open(QrCodeModalComponent, {centered:true,backdrop:'static'})
      modalRef.componentInstance.qrLogin = false
      modalRef.componentInstance.code = dataQuestion.eventCode
      modalRef.componentInstance.survey = true
    }).catch(err => {
      this.error = true;

      const _error = this._errorHandlerService.error(err).message;
      log.debug("_error => %o",_error)
      this.errorText = _error

    })

  }

  generateForm(data:any) {
    for(let i in data){
      if(data[i].questionType === 'multi-choices'){
        if(data[i].isRequired === true){
          this.formData.addControl('question'+i,
            new FormArray([
              // new FormGroup({
              //   name: new FormControl(),
              //   value: new FormControl(),
              // })
            ],Validators.required)
          )
        }else{
          this.formData.addControl('question'+i,
            new FormArray([
              // new FormGroup({
              //   name: new FormControl(),
              //   value: new FormControl(),
              // })
            ])
          )
        }

        // log.debug('Check form Data =>>> : ',this.formData.get('question'+i));
        // let saveChoiceArray = this.formData.get('question'+i) as FormArray;
        // for(let k of data[i].choices){
        //   saveChoiceArray.push(
        //     this.fb.group({
        //       name: k.title,
        //       value: k._id,
        //       checked: false
        //     })
        //   )
        //   // log.debug('Check form Data get questtion =>>> : ',form);
        // }
        // log.debug('Check form Data =>>> : ',this.formData.controls['question7'].value);

      }else{
        let userData:any = this._storageService.getUser()
        // log.debug('userData',userData.profile)
        if(data[i].isRequired === true){
          this.formData.addControl('question'+i, new FormControl('',Validators.required))
        }else{
          this.formData.addControl('question'+i, new FormControl(''))
        }
      }
    }

  }

  checkInvalid(){
    const invalid = [];
    const controls = this.formData.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
          invalid.push(name);
      }
    }
    // log.debug('checkInvalid', invalid)
    // toast

    // toast
  }

  scrollToTop(){
    this.viewportScroller.scrollToPosition([0,0]);
    this.modalBody!.nativeElement.scrollTop = 0
  }

}
