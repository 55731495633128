import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from 'src/app/@services/authentication.service';
import { Logger } from '../@services/logger.service';

const log = new Logger('AuthGuard'); // log.debug


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserSubject.value;
    log.debug("currentUser from Authguard => %o",currentUser)

    if (currentUser) {
      // role not authorised so redirect to not-authorized page
      // if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
      //   this._router.navigate(['/not-authorized']);
      //   return false;
      // }
      return true;
    }

    this._router.navigate(['/auth'], {
      // queryParams: { returnurl: state.url },
    });
    return false;
  }
}
