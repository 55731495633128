import { SingletonService } from './../../@services/singleton.service';
/* eslint-disable */

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddreviewexhibitorComponent } from 'src/app/@components/modal-addreviewexhibitor/modal-addreviewexhibitor.component';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';
const log = new Logger('ExhibitordetailComponent');
import {Location} from '@angular/common';

@Component({
  selector: 'app-reviewus',
  templateUrl: './reviewus.component.html',
  styleUrls: ['./reviewus.component.scss'],
})
export class ReviewusComponent {
  eventCode:any = 'event-01'

  event:any
  eventReviewList:any = []

  currentUser:any
  currentUserSubscription:any

  isLoading = false;

  limit=5;
  page=1;
  totalPages = 1;
  totalResults:any = undefined;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: NgbModal,
    public singletonService: SingletonService,
    private _location: Location,
  ) {


  }

  async ngOnInit() {
    this.currentUserSubscription = this._authenticationService.currentUserSubject.subscribe((_currentUser) => {
      this.currentUser = _currentUser
      log.debug("this.currentUser => %o",this.currentUser)
    })

    await this.getEventByCode()
    await this.getEventReviewList()
  }

  ngOnDestroy(): void {
    if(this.currentUserSubscription) this.currentUserSubscription.unsubscribe();
  }

  async getEventByCode(){
    const getEventByCodeRes:any = await this.backendService.getEventByCode(this.eventCode)
    log.debug("res getEventByCodeRes: %o",getEventByCodeRes)
    if(getEventByCodeRes){
      this.event = getEventByCodeRes
      log.debug("this.event: %o",this.event)
    }
  }

  async getEventReviewList(){
    this.eventReviewList = [];
    this.isLoading = true
    const getEventReviewListRes:any = await this.backendService.getEventReviewList(
      this.eventCode,
      this.limit,
      this.page,
    )
    log.debug("res getEventReviewListRes: %o",getEventReviewListRes)
    if(getEventReviewListRes){
      const results = getEventReviewListRes.results
      this.eventReviewList = [...this.eventReviewList,...results]
      log.debug("this.eventReviewList: %o",this.eventReviewList)

      this.totalPages = getEventReviewListRes.totalPages
      this.totalResults = getEventReviewListRes.totalResults

      this.isLoading = false
    }else{
      this.isLoading = false
    }
  }

  backnav() {
    this.singletonService.back();
  }

  openModalReview(){
    let modalRef = this.modalService.open(ModalAddreviewexhibitorComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.eventCode = 'event-01';
    modalRef.componentInstance.dataService.subscribe((isSuccess:any)=>{
      log.debug("ModalAddreviewexhibitorComponent isSuccess: %o",isSuccess)
      if(isSuccess){
        // this.isBookmarked = true
        this.getEventReviewList()
      }
    })
  }
}
