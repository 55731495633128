import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import { LanguageType } from 'src/types/language.type';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-languagesetting',
  templateUrl: './languagesetting.component.html',
  styleUrls: ['./languagesetting.component.scss'],
})
export class LanguagesettingComponent implements OnInit {
  currentLanguage: any;

  constructor(
    private _translateConfigService: TranslateConfigService,
    private _router:Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  ngOnInit() {
    this.currentLanguage = this._translateConfigService.getCurrentLanguage()!;
  }

  async setCurrentLanguage(lang: any) {
    await this._translateConfigService.setLanguage(lang);
    this.currentLanguage = lang;
  }

  backnav() {
    this.singletonService.back();
  }
}
