/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-speaker',
  templateUrl: './speaker.component.html',
  styleUrls: ['./speaker.component.scss']
})
export class SpeakerComponent implements OnInit {

  constructor (
    public singletonService: SingletonService
  ) { }

  ngOnInit(): void {

  }

  backnav() {
    this.singletonService.back();
  }
}
