import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { SingletonService } from 'src/app/@services/singleton.service';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import { UserService } from 'src/app/@services/user.service';

@Component({
  selector: 'app-stamp-by-self',
  templateUrl: './stamp-by-self.component.html',
  styleUrls: ['./stamp-by-self.component.scss'],
})
export class StampBySelfComponent {
  language = 'en';

  constructor(
    private singletonService: SingletonService,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService,
    private translateConfigService: TranslateConfigService
  ) {}

  async ngOnInit() {
    this.language = this.translateConfigService.getCurrentLanguage();
    const ticketId = await this.getTikketId();
    const stampBySelf = await this.stampBySelf(ticketId);
    if (stampBySelf?.id) {
      this.singletonService.successAlert(
        this.translateService.instant('TIMESTAMP_SUCCESSFULLY')
      );
      this.router.navigate(['/dashboard']);
    }
  }

  async getTikketId() {
    try {
      const data = { eventCode: 'event-01' };
      const ticket = (await this.userService.requestQrCode(data)) as any;
      return ticket.id;
    } catch (error: any) {
      this.errorService.error(error, true);
      this.router.navigate(['/dashboard']);
      return null;
    }
  }

  async stampBySelf(token: string) {
    try {
      const stampResponse = (await this.backendService.stampBySelf(
        token
      )) as any;
      return stampResponse;
    } catch (error: any) {
      this.errorService.error(error, true);
      this.router.navigate(['/dashboard']);
      return null;
    }
  }
}
