<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button
              class="btn btn-sm btn-arrow-back ps-0"
              type="button"
              (click)="back()"
            >
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{ "AGENDA" | translate }}
          </span>
        </h2>
      </div>
    </div>
  </div>
  <!-- NOTE : กำหนดการนี้ไม่ต้องแปล -->
  <!-- main page content -->
  <div class="main-container container">
    <!-- timeline -->
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow-sm mb-4">
          <div class="card-header">
            <h4 class="my-1 mb-1">{{"DAY_HEADER" | translate}} - 01</h4>
            <h6 class="my-1">25 {{"MAY" | translate}} 2023</h6>
          </div>
          <div class="card-body ">
            <ul class="list-group list-group-flush w-100 bubble-sheet log-information">
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">{{"REGISTRATION" | translate}}</span>
                  <br><small class="text-white-50">10:00 - 10:30</small></p>
              </li>
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">{{"WELCOMING_SPEECH_BY" | translate}} MC</span>
                  <br><small class="text-white-50">10:30 - 10:35</small></p>
              </li>
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">{{"WELCOMING_SPEECH" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/cee.png" alt="" style="background-color: #999;">
                    <span class="ms-2 text-muted">Chatpawee<br>Trichachawanwon</span>
                  </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CEO, Sociallab and Ceemeagain</small></span>
                <br><small class="text-white-50">10:35 - 10:45</small></p>
              </li>
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">{{"WELCOMING_SPEECH_BY" | translate}} Thai real restate association<br>
                  {{"TOPIC" | translate}} : {{"THAI_REAL_ESTATE" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <small class="ms-2 text-muted">คุณ พรนริศ ชวนไชยสิทธิ์</small>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">นายกสมาคมอสังหาริมทรัพย์ไทย</small></span>
                  <br><small class="text-white-50">10:45 - 10:50</small></p>
              </li>
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">{{"TOPIC" | translate}} : NIA {{"WITH_THE_PROMOTION_THAI_GO" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <small class="ms-2 text-muted">คุณ ปริวรรต วงษ์สำราญ</small>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">ผู้อำนวยการฝ่ายพัฒนาผู้ประกอบการนวัตกรรม สำนักงานนวัตกรรมแห่งชาติ (NIA)</small></span>
                <br><br><span class="text-muted">{{"TOPIC" | translate}} : Depa {{"WITH_THE_PROMOTION_OF_SOFTPOWER" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <span class="ms-2 text-muted">ผศ.ดร. ณัฐพล<br>นิมมานพัชรินทร์</span>
                  </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">ผู้อำนวยการสำนักงานส่งเสริมเศรษฐกิจดิจิทัล สำนักงานส่งเสริมเศรษฐกิจดิจิทัล (Depa)</small></span>
                <br><br><span class="text-muted">{{"TOPIC" | translate}} : TAT {{"ADAPTING_TO_THE_WORLD" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/nithee.jpg" alt="">
                    <span class="ms-2 text-muted">คุณ นิธี สีแพร</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">รองผู้ว่าการการท่องเที่ยวแห่งประเทศไทย ด้านดิจิทัล วิจัย และพัฒนา การท่องเที่ยวแห่งประเทศไทย (ททท.)</small></span>
                  <br><small class="text-white-50">10:50 - 11:05</small></p>
              </li>
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">{{"TOPIC" | translate}} : กสทช. กับการพัฒนาโครงข่ายโทรคมนาคมด้วยเทคโนโลยี เพื่อเศรษฐกิจไทย</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/thanapant.jpg" alt="">
                    <small class="ms-2 text-muted">พลอากาศโท<br>ดร.ธนพันธุ์ หร่ายเจริญ </small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">กรรมการกิจการกระจายเสียง กิจการโทรทัศน์ และกิจการโทรคมนาคมแห่งชาติ (กสทช.)</small></span>
                  <br><small class="text-white-50">11:05 - 11:15</small></p>
              </li>
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">{{"OPENING_CEREMONY" | translate}}</span>
                  <br><small class="text-white-50">11:15 - 11:20</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-warning rounded-circle"></span>
                <p><span class="text-muted">{{"SHOW_FROM" | translate}} Drone Academy</span>
                  <br><small class="text-white-50">11:20 - 11:25</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-warning rounded-circle"></span>
                <p><span class="text-muted">{{"PHOTO_SESSION" | translate}} 1 2 3 {{"AND" | translate}} 4</span>
                  <br><small class="text-white-50">11:25 - 11:40</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-warning rounded-circle"></span>
                <p><span class="text-muted">VIP {{"AND" | translate}} Press Tour</span>
                  <br><small class="text-white-50">11:40 - 12:00</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"THE_FUTURE_OF" | translate}} LET (Panel Discussion)</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <span class="ms-2 text-muted">คุณ กัญญ์พิชา<br>วรไพสิฐกุล</span>
                  </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">ตัวแทน บริษัท ซีที เอเซีย โรโบติกส์ จำกัด ตำแหน่ง Planning and Development Director บริษัท ล็อกซเล่ย์ อีโวลูชั่น เทคโนโลยี จำกัด</small></span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/krishnayot.jpg" alt="">
                    <span class="ms-2 text-muted">คุณ กฤษณยศ<br>บูรณะสัมฤทธิ</span>
                  </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">(ประธานกรรมการบริหาร) บริษัท คลาวด์เซค เอเชีย จำกัด</small></span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/pongpan.jpg" alt="">
                    <span class="ms-2 text-muted">คุณ พงพันธ์<br>ด่านพิษณุพันธ์</span>
                  </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">(Co-Founder) บริษัท ไอดิโอ เทค จำกัด</small></span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/worakit.jpg" alt="">
                    <span class="ms-2 text-muted">คุณ วรกิตติ์<br>วาณิชยรรยง</span>
                  </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">(ประธานเจ้าหน้าที่บริหาร) บริษัท ฮาโก้ อิเลคทริค (ประเทศไทย) จำกัด</small></span>
                  <br><small class="text-white-50">12:00 - 12:15</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : ADid - The Key to Knock Every Home, Everywhere</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/yuthaporn_chitakasem.jpg" alt="">
                    <span class="ms-2 text-muted">Yuthaporn<br>Chitakasem</span>
                  </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CEO and Managing Director, Loxley Evolution Technology Co., Ltd.</small></span>
                  <br><small class="text-white-50">12:15 - 12:30</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"INNOVATE_TO_CREATE_A_SUSTAINABLE" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/non_arkara.jpg" alt="">
                    <span class="ms-2 text-muted">Non Arkara</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Senior Expert in Smart City Promotion, Digital Economy Promotion Agency (depa)</small></span>
                  <br><small class="text-white-50">13:20 - 13:40</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : "Thailand Digital Transformation การเปลี่ยนผ่านประเทศไทยไปสู่ดิจิทัล"</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/thanapant.jpg" alt="">
                    <small class="ms-2 text-muted">พลอากาศโท<br>ดร.ธนพันธุ์ หร่ายเจริญ </small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">กรรมการกิจการกระจายเสียง กิจการโทรทัศน์ และกิจการโทรคมนาคมแห่งชาติ (กสทช.)</small></span>
                  <br><small class="text-white-50">13:40 - 14:10</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : PM 2.5, {{"A_GLOBAL_HEALTH_CRISIS" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/karl.png" alt="">
                    <span class="ms-2 text-muted">Karl von Luckwald</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Thailand Head, Smart Air FIlter</small></span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/sam_michael.jpg" alt="">
                    <span class="ms-2 text-muted">Sam Michael</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Executive Director, AtmosAir Solutions</small></span>
                  <br><small class="text-white-50">14:10 - 14:50</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"BIODIVERSITY_CONSERVATION" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/keita.jpg" alt="">
                    <span class="ms-2 text-muted">Dr. Keita Ono</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Head of Big Win Fun, Innospace</small></span>
                  <br><small class="text-white-50">14:50 - 15:10</small></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow-sm mb-4">
          <div class="card-header">
            <h4 class="my-1 mb-1">{{"DAY_HEADER" | translate}}  - 02</h4>
            <h6 class="my-1">26 {{"MAY" | translate}}  2023</h6>
          </div>
          <div class="card-body ">
            <ul class="list-group list-group-flush w-100 bubble-sheet log-information">
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">{{"WELCOMING_SPEECH" | translate}}</span>
                  <br><small class="text-white-50">11:15 - 11:30</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-warning rounded-circle"></span>
                <p><span class="text-muted">{{"LIV_24_FUTURE_OF_SECURITY" | translate}}</span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/niramol.jpg" alt="">
                      <small class="ms-2 text-muted">Niramol<br>Direkmahamongkol</small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Senior Director, LIV-24</small></span>
                  <br><small class="text-white-50">11:30 - 12:00</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"MMORPG_DIGITAL" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/zenith.jpg" alt="">
                    <span class="ms-2 text-muted">Zenith Ajhanwong</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CEO Shin-A</small></span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex mb-custom">
                    <img class="rounded-circle mb-2" width="48" height="48" src="https://0110techland.co/assets/images/speakers/shotiwan.jpg" alt="">
                    <small class="ms-2 text-muted" style="margin-top: .3rem!important;">Shotiwan<br>Wattanalarp</small>
                    </div>
                    <br><div class="ms-2"><small class="text-secondary">CEO, Maxion</small></div>
                  </figure>
                  <br><small class="text-white-50">13:00 - 13:30</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : Hacking the Now: {{"EXPLORING_THE_CURRENT_STATE" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/varin.jpg" alt="">
                    <span class="ms-2 text-muted">Dr.Varin Khera</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Co-Founder and CEO,Cloudsec Asia</small></span>
                  <br><small class="text-white-50">13:30 - 13:50</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"GAIT_REHABILITATION" | translate}}</span>
                  <figure class="mt-4 mb-0">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/krit_kunplin.jpg" alt="">
                      <span class="ms-2 text-muted">Krit Kunplin</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CTO, DazzBott TECH</small></span>
                  <br><small class="text-white-50">13:50 - 14:20</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"THE_FUTURE_OF_SMART_HOME" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/anthony_chow.jpg" alt="">
                    <span class="ms-2 text-muted">Anthony Chow</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CEO and Co-Founder, Iglooco</small></span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/kwanchai.jpg" alt="">
                      <small class="ms-2 text-muted">Kwanchai<br>Mongkolkittaveepol</small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CEO, Nextate technology</small></span>
                  <br><small class="text-white-50">14:20 - 14:40</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : NIA {{"AND" | translate}} Global Startup Hub</span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/pacharawan.jpg" alt="">
                      <small class="ms-2 text-muted">Pacharawan<br>Aksornsawas</small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Innovation Counsellor, NIA</small></span>
                  <br><small class="text-white-50">14:40 - 15:00</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TECHNOLOGY_AND_THE_FUTURE_OF_MENTAL" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/warat_panyatiyakul.jpg" alt="">
                    <span class="ms-2 text-muted">Warat Panyatiyakul</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Business Developer & Counseling Psychologist, Me Hug</small></span>
                  <br><small class="text-white-50">15:00 - 15:20</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"SAFEGUARD_YOU_FAMILY" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/john.jpg" alt="">
                    <span class="ms-2 text-muted">John Rattanaveroj</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">President of D Tech Association</small></span>
                  <br><small class="text-white-50">15:20 - 15:40</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">DIPROM PATHFINDER 2023</span>
                  <!-- <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <span class="ms-2 text-muted">คุณซี ฉัตรปวีณ์<br>ตรีชัชวาลวงศ์</span>
                  </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">กรรมการผู้จัดการ บริษัท โซเชียลแล็บ จำกัด และนักข่าว พิธีกร ด้านไอที Ceemeagain</small></span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <span class="ms-2 text-muted">ดร.วรวิทย์ จิรัฐิติเจริญ</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">ผู้อำนวยการกองส่งเสริมผู้ประกอบการและธุรกิจใหม่ กรมส่งเสริมอุตสาหกรรม</small></span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <span class="ms-2 text-muted">สิวาพร วงษ์มา</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Assistant Director, Product Strategy and Development, Index Living Mall</small></span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <span class="ms-2 text-muted">ชลลักษณ์ มหาสุวีระชัย</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">ประธานเจ้าหน้าที่บริหาร, NocNoc</small></span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <span class="ms-2 text-muted">TBC</span>
                  </figure> -->
                  <br><small class="text-white-50">15:40 - 18:45</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"FRACTIONAL_OWNERSHIP" | translate}}</span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/ekapak.png" alt="">
                      <small class="ms-2 text-muted">Ekapak<br>Nirapathpongporn</small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Co-founder and CEO, Fraction</small></span>
                  <br><small class="text-white-50">18:45 - 19:05</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"WINNER_ANNOUNCEMENT" | translate}}</span>
                  <br><small class="text-white-50">19:05 - 19:15</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : The Gadget Destination {{"OF_TOMORROW_WITH" | translate}} Jaymart</span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/teerawat_chanvichitkul.jpg" alt="">
                      <span class="ms-2 text-muted">Teerawat<br>Chanvichitkul</span>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Vice President: Head Of Marketing, Jaymart Mobile</small></span>
                  <br><small class="text-white-50">19:15 - 19:40</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : Phygital Innovation: {{"THE_UPCOMING_TREND" | translate}}</span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                      <span class="ms-2 text-muted">Woraphot<br>Tharasiriskul</span>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CTO, JVentures Co., Ltd.</small></span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                      <span class="ms-2 text-muted">Kittipon<br>leepipattanawit</span>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CEO, KMARS studio</small></span>
                  <figure class="mt-4 mb-0">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                      <span class="ms-2 text-muted">Nat Chittamai</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Community Builder and Content Creator, GM Learning Club</small></span>
                  <br><small class="text-white-50">19:40 - 20:10</small></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow-sm mb-4">
          <div class="card-header">
            <h4 class="my-1 mb-1">{{"DAY_HEADER" | translate}} - 03</h4>
            <h6 class="my-1">27 {{"MAY" | translate}}  2023</h6>
          </div>
          <div class="card-body ">
            <ul class="list-group list-group-flush w-100 bubble-sheet log-information">
              <li class="list-group-item">
                <div class="avatar avatar-15 border-pink rounded-circle"></div>
                <p><span class="text-muted">Welcoming session</span>
                  <br><small class="text-white-50">11:15 - 11:30</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : Sustainable Feast: {{"NOURISHING_THE_FUTURE_OF_FOOD" | translate}}</span>
                  <figure class="mt-4 mb-0">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/passana_kongklieng.jpg" alt="">
                      <small class="ms-2 text-muted text-break">Passana Kongklieng</small>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Marketing Executive, Yindii</small></span>
                  <br><small class="text-white-50">11:30 - 12:00</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"HOW_ASIAN_FOOD_TECH_COMPANIES" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/max_rye_.jpg" alt="">
                    <span class="ms-2 text-muted">Max Ryen</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Chief Strategist , TurtleTree</small></span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/chonchayong.jpg" alt="">
                      <span class="ms-2 text-muted text-break">Chonchayong<br>Trairatkeyoon</span>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Founder & CEO, IncredibleBio (Formerly JuiceInnov8)</small></span>
                  <br><small class="text-white-50">13:15 - 14:00</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"REDUCING_FOOD_WASTE" | translate}}</span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/varitthorn.jpg" alt="">
                      <small class="ms-2 text-muted text-break">Varitthorn<br>Mungkornthongsakul</small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CEO and Co-Founder,Oho!</small></span>
                  <br><small class="text-white-50">14:00 - 14:20</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"A_WIN_WIN_SOLUTION_FOR_FARMER" | translate}}</span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/jakkarin.jpg" alt="">
                      <small class="ms-2 text-muted text-break">Jakkarin<br>Chatrungrueangchai</small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Owner, Farmday</small></span>
                  <br><small class="text-white-50">14:20 - 14:40</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} {{"FOSTERING_AGRICULTURAL_TRANSFORMATION" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/montha.jpg" alt="">
                    <span class="ms-2 text-muted">Montha Kaihirun</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Innovation Counsellor Manager, NIA</small></span>
                  <br><small class="text-white-50">14:40 - 15:00</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"MICRO_VERTICAL_FARMING" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/piyorot_vana.jpg" alt="">
                    <span class="ms-2 text-muted">Piyorot Piyachan</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Founder and CEO, Vana Farming</small></span>
                  <br><small class="text-white-50">15:00 - 15:20</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"THE_FUTURE_OF" | translate}} Kitchen Automation</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/user.png" alt="">
                    <span class="ms-2 text-muted">Chaiwat Suwanrati</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Project advisor, Applicad</small></span>
                  <br><small class="text-white-50">15:20 - 15:40</small></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow-sm mb-4">
          <div class="card-header">
            <h4 class="my-1 mb-1">{{"DAY_HEADER" | translate}}  - 04</h4>
            <h6 class="my-1">28 {{"MAY" | translate}}  2023</h6>
          </div>
          <div class="card-body ">
            <ul class="list-group list-group-flush w-100 bubble-sheet log-information">
              <li class="list-group-item">
                <div class="avatar avatar-15 border-success rounded-circle"></div>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"THE_FUTURE_OF_TOURISM" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/nithee.jpg" alt="">
                    <span class="ms-2 text-muted">Nithee Seeprae</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Deputy Governor for Digitalisation, Research and Development Tourism Authority of Thailand</small></span>
                  <br><small class="text-white-50">11:30 - 12:00</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"TRAVEL_ENABLING_TECH" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/henry.jpg" alt="">
                    <span class="ms-2 text-muted">Henry Lane</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Owner, Corvecto</small></span>
                  <br><small class="text-white-50">13:30 - 14:00</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"CO_OWNERSHIP_AND_UTILITIES" | translate}}</span>
                  <figure class="mt-4 mb-0">
                    <div class="d-flex align-items-center">
                      <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/ekapak.png" alt="">
                      <small class="ms-2 text-muted">Ekapak<br>Nirapathpongporn</small>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Co-founder and CEO, Fraction</small></span>
                  <br><small class="text-white-50">14:00 - 14:25</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"WEB_FOR_SOCIAL_GOOD" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/anish_kapoor.jpg" alt="">
                    <span class="ms-2 text-muted">Anish Kapoor</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CTO, Metabase</small></span>
                  <br><small class="text-white-50">14:25 - 14:45</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : Smart City {{"AND" | translate}} Robotics Applications</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <div class="d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/dr_ekarin_vasanasong.jpg" alt="">
                    <span class="ms-2 text-muted">Dr. Ekarin<br>Vasanasong</span>
                    </div>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">CEO of Botlink Company Limited</small></span>
                  <br><small class="text-white-50">14:45 - 15:15</small></p>
              </li>
              <li class="list-group-item">
                <span class="avatar avatar-15 border-success rounded-circle"></span>
                <p><span class="text-muted">{{"TOPIC" | translate}} : {{"A_CORPORATE_VISION" | translate}}</span>
                  <figure class="mt-4 mb-0 d-flex align-items-center">
                    <img class="rounded-circle" width="48" height="48" src="https://0110techland.co/assets/images/speakers/joseph_hong.jpg" alt="">
                    <span class="ms-2 text-muted">Joseph Hong</span>
                  </figure>
                  <span class="ms-2 mt-2 mb-0 d-block lh-sm"><small class="text-secondary">Managing Director, Bosch Thailand & Laos PDR</small></span>
                  <br><small class="text-white-50">15:15 - 15:45</small></p>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- main page content ends -->
</div>
