/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalQrcodeRewardComponent } from 'src/app/@components/modal-qrcode-reward/modal-qrcode-reward.component';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Logger } from 'src/app/@services/logger.service';
import { SingletonService } from 'src/app/@services/singleton.service';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import Swal from 'sweetalert2';

const log = new Logger('QuestHuntingComponent');
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-quest-hunting',
  templateUrl: './quest-hunting.component.html',
  styleUrls: ['./quest-hunting.component.scss']
})
export class QuestHuntingComponent implements OnInit {

  itemId:any
  questtype:any

  isLoadingQuest = false
  isLoadingClaimReward = false

  questInfo:any

  method:any;
  executeCode:any;

  currentUser:any
  currentUserSubscription:any

  constructor(
    private _router: Router,
    private modalService: NgbModal,
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _route: ActivatedRoute,
    public singletonService: SingletonService,
    public _translateConfigService: TranslateConfigService,
    public errorHandlerService: ErrorHandlerService,
    public translateService: TranslateService,
    private _location: Location,
  ) {
    this.itemId = this._route.snapshot.paramMap.get('questid')!;
    this.questtype = this._route.snapshot.paramMap.get('questtype')!;

    this.method = this._route.snapshot.queryParamMap.get('method');
    this.executeCode = this._route.snapshot.queryParamMap.get('executeCode');

    log.debug("this.itemId: %o",this.itemId)
    log.debug("this.questtype: %o",this.questtype)
    log.debug("this.method: %o",this.method)
    log.debug("this.executeCode: %o",this.executeCode)

    this.singletonService.initRequestConsent(this._route.snapshot.queryParams);
   }

  async ngOnInit() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    this.currentUserSubscription = this._authenticationService.currentUserSubject.subscribe(async (_currentUser) => {
      this.currentUser = _currentUser;
      log.debug('this.currentUser => %o', this.currentUser);
    });

    if(this.method == 'execute' && this.executeCode){
      try{
        const executeCompleteQuestRes:any = await this.backendService.executeCompleteQuest(this.executeCode);
        log.debug("executeCompleteQuestRes: %o",executeCompleteQuestRes)
        if(executeCompleteQuestRes){
          this.singletonService.successAlert(this.translateService.instant('QUEST_EXECUTE_SUCCESS'))
        }
      }catch(error:any){
        log.debug("error on claimReward: %o",error)
        this.isLoadingClaimReward = false
        this.errorHandlerService.error(error,true)
      }
      // this.singletonService.successAlert('Congratulations, you have completed the quest.')
    }

    await this.reloadData()

  }

  ngOnDestroy(): void {
    if(this.currentUserSubscription) this.currentUserSubscription.unsubscribe();
  }

  async reloadData(){
    if(this.questtype == 'quest-book'){
      await this.getQuestBookInfosById()
    }else{
      await this.getQuestInfosById()
    }
  }

  async getQuestInfosById(){
    this.isLoadingQuest = true
    const getQuestInfosByIdRes:any = await this.backendService.getQuestInfosById(this.itemId)
    log.debug("res getQuestInfosByIdRes: %o",getQuestInfosByIdRes)
    if(getQuestInfosByIdRes){
      this.questInfo = getQuestInfosByIdRes
      log.debug("this.questInfo: %o",this.questInfo)
    }else{
      this.isLoadingQuest = false
    }
  }

  async getQuestBookInfosById(){
    this.isLoadingQuest = true
    const getQuestBookInfosByIdRes:any = await this.backendService.getQuestBookInfosById(this.itemId)
    log.debug("res getQuestBookInfosByIdRes: %o",getQuestBookInfosByIdRes)
    if(getQuestBookInfosByIdRes){
      this.questInfo = getQuestBookInfosByIdRes
      log.debug("this.questInfo: %o",this.questInfo)
      this.isLoadingQuest = false
    }else{
      this.isLoadingQuest = false
    }
  }

  jumpToInnerQuest(eventQuest:any){
    const questType = 'quest'
    let path = '/quest-hunting/'+ questType + '/'+ eventQuest._id
    let href = this._router.url;
    log.debug("jumpToInnerQuest path: %o",path)

    if(href.startsWith('/quest-hunting/')){ // same route
      log.debug("jumpToInnerQuest same route...")
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]);
      });
    }else{
      this._router.navigate([path]);
    }
  }

  backnav() {
    if(this.questtype == 'quest' && this.questInfo.eventQuestParent){
      const questType = 'quest'
      let path = '/quest-hunting/'+ questType + '/'+ this.questInfo.eventQuestParent
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]); //  sub-quest => parent-quest
      });
    }else if(this.questtype == 'quest' && this.questInfo.eventQuestParent == null){
      const questType = 'quest-book'
      let path = '/quest-hunting/'+ questType + '/'+ this.questInfo.eventQuestBook._id
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]); //  parent-quest => quest-book
      });
    }else if(this.questtype == 'quest-book'){
      this._router.navigate(['/quest-hunting-list']); // quest-book => quest-list
    }else{
      this.singletonService.back();
    }
    return false;
  }

  openQrCodeRewardModal() {
    log.debug("this.currentUser: %o",this.currentUser)

    const modalRef = this.modalService.open(ModalQrcodeRewardComponent, {
      centered: true,
      size: "sm",
      backdrop: false
    });
    modalRef.componentInstance.userId = this.currentUser.id
    modalRef.componentInstance.questId = this.itemId

    modalRef.result.then(
      (data) => {
        log.debug("reloadData from closed modal... data %o",data)
        if (data === 'success') {
          this.reloadData()
        }
      },
      (_) => {}
    );
  }

  jumpToQRScaner(){
    this._router.navigate(['/qr-code-scanner']);
  }



  async claimReward() {
    this.isLoadingClaimReward = true
    await this.singletonService.processingSwal()

    let res:any

    setTimeout(async () => {
      res = true

      try{
        if(this.questtype == 'quest-book'){
          log.debug("claim quest-book reward")
          res = await this.backendService.claimRewardQuestBook(this.itemId)
        }else{
          log.debug("claim quest reward")
          res = await this.backendService.claimRewardQuest(this.itemId)
        }

        log.debug("res: %o",res)
        if(res && res.code != 1111){
          await this.reloadData()
          await Swal.close()
          await Swal.fire({
            icon: "success",
            title: "Claim reward success!",
            // text: 'Receive Name  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum.',
            showDenyButton: false,
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'Okay',
            customClass: "popup-success-custom popup-content-custom",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
            }
          })

        }else{
          this.isLoadingClaimReward = false
          const error = res
          this.errorHandlerService.error(error,true)
        }
      }catch(error:any){
        log.debug("error on claimReward: %o",error)
        this.isLoadingClaimReward = false
        this.errorHandlerService.error(error,true)
      }

    }, 2000);

  }

  confirmToClaimReward() {
    Swal.fire({
      icon: "warning",
      title: "Are you sure to claim the quest rewards?",
      showDenyButton: false,
      showConfirmButton: true,
      showCancelButton: true,
      customClass: "popup-content-custom",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.claimReward()
      }

    })
  }



}
