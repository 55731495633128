import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';
/* eslint-disable */

const log = new Logger('ModalGalleryComponent');

@Component({
  selector: 'app-modal-gallery',
  templateUrl: './modal-gallery.component.html',
  styleUrls: ['./modal-gallery.component.scss']
})
export class ModalGalleryComponent {
  @Input('images') images: any;

  sliderImages:any = []

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
  ) {}


  async ngOnInit() {
    log.debug("this.images => %o",this.images)
    this.sliderImages = this.images.map((image:any, index:any) => {
      return {
        image: image,
        thumbImage: image,
        // alt: 'alt of image-' + index,
        // title: 'title of image-' + index
      }
    })
  }

}
