import { Component, OnInit } from '@angular/core';
import { GalleryImage } from '../../../models/galleryImage.model';
import { HttpClient } from '@angular/common/http';
import { SingletonService } from '../../@services/singleton.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../../@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('NftGalleryTarotComponent');
@Component({
  selector: 'app-nft-gallery-tarot',
  templateUrl: './nft-gallery-tarot.component.html',
  styleUrls: ['./nft-gallery-tarot.component.scss'],
})
export class NftGalleryTarotComponent implements OnInit {
  id: string;
  isLoading = true;
  url: string;
  nft: any;
  amount = 0;

  constructor(
    private http: HttpClient,
    public singletonService: SingletonService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _backendService: BackendService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.id = this._route.snapshot.paramMap.get('id')!;
    await this.fetchNFTGalleryImage();
    this.isLoading = false;
  }

  async fetchNFTGalleryImage() {
    const result: any = await this._backendService.getUserTarotGalleryAmount(
      this.id
    );
    log.debug(`result %o`, result);
    this.nft = result.nft;
    this.amount = result.amount;
    // this.galleryImage = new GalleryImage(_video);
  }

  async back() {
    await this._router.navigate(['nft-gallery']);
  }
}
