<div class="modal-header py-3 align-items-center justify-content-between">
  <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h3 class="sign-in-questionaire size-20">{{"SIGNIN.QUESTIONAIRE" | translate}}</h3>
  <!-- <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
  <button class="btn btn-top-right-arrow" type="button">
  </button>
</div>
<div #modalBody class="modal-body">
  <div *ngIf="error" class="px-4 w-100">
    <div  class="alert alert-danger d-flex align-items-center w-100 my-3" role="alert">
      <i class="bi bi-exclamation-triangle size-26"></i>
      <div class="ms-3 text-start size-14">
        {{errorText}}
      </div>
    </div>
  </div>

  <div [formGroup]="formData" class="row formbody py-3 px-1 px-sm-2 px-md-4">
    <div class="form-group" *ngFor="let _data of data; let i = index">
      <!-- <input id="{{'question'+i}}" type="text" formControlName="{{'question'+i}}"> -->
      <div *ngIf="data[i].questionType == 'short'">
        <label class="text-uppercase mb-2 size-14 header-label" for="{{'question'+i}}">{{_data.title}} <span class="text-danger">{{_data.isRequired === false ? '' : '*'}}</span></label>
        <input (keyup)="error = false" class="form-control" type="text" id="{{'question'+i}}" formControlName="{{'question'+i}}" [class.is-invalid]="formService.isControlInvalid(formData,'question'+i)">
      </div>
      <div *ngIf="data[i].questionType == 'choice'">
        <label class="text-uppercase mb-2 size-14 header-label" for="{{'question'+i}}">{{_data.title}} <span class="text-danger">{{_data.isRequired === false ? '' : '*'}}</span></label>
        <select (change)="error = false" class="form-select select-custom" name="{{'question'+i}}" id="{{'question'+i}}" formControlName="{{'question'+i}}" [class.is-invalid]="formService.isControlInvalid(formData,'question'+i)">
          <option disabled selected value="">
            --- {{"SELECT" | translate}} ---
          </option>
          <option *ngFor="let choice of _data.choices" [value]="choice._id">{{choice.title}}</option>
        </select>
        <div class="invalid-feedback ps-2" [class.is-invalid]="formService.isControlInvalid(formData,'question'+i)" >
          {{"PLEASE_SELECT_ANSWER" | translate}}
        </div>
      </div>
      <div *ngIf="data[i].questionType == 'multi-choices'">
        <label class="text-uppercase mb-2 size-14 header-label mb-3">{{_data.title}} <span class="text-danger">{{_data.isRequired === false ? '' : '*'}}</span></label>
        <div class="choice-list">
          <div *ngFor="let item of data[i].choices; let k = index" >
            <label for="{{item._id}}" class="choice-selector" [ngClass]="storageQuestion.includes(item._id) ? 'active' : ''">
              {{item.title}}
            </label>
            <input class="form-control" type="checkbox" id="{{item._id}}" [value]="item._id" (click)="onChange($event,'question'+i); error = false">
          </div>
        </div>
      </div>
      <div *ngIf="data[i].questionType == 'paragraph'">
        <label class="text-uppercase mb-2 size-14 header-label" for="{{'question'+i}}">{{_data.title}} <span class="text-danger">{{_data.isRequired === false ? '' : '*'}}</span></label>
        <textarea (keyup)="error = false" class="form-control" name="{{'question'+i}}" id="{{'question'+i}}" formControlName="{{'question'+i}}" cols="30" rows="10" [class.is-invalid]="formService.isControlInvalid(formData,'question'+i)"></textarea>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center flex-column pt-3 pb-2">
  <div class="signin-footer">
    <button type="button" (click)="onSubmit()" class="btn btn-default btn-lg w-100">{{"SIGNIN.NEXT" | translate}}</button>
  </div>
  <div class="option">
    <div class="footer-power size-12 text-secondary">
      {{"POWERED_BY"| translate}} Linxlab.io
    </div>
  </div>
</div>
<!-- <ngx-loading
  [show]="loading"
  [config]="{
    animationType: ngxLoadingAnimationTypes.wanderingCubes,
    primaryColour: '#4b0082',
    secondaryColour: '#ffd42a',
    backdropBorderRadius: '3px'
  }"
  [template]="loadingTemplate">
</ngx-loading> -->
