<div class="wrapper-100vw">
  <div class="wrapper-top-header mt-0 pb-3 mb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="backnav()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-0">
          <h6 class="title size-16">
            {{ "QUEST_HUNTING.MY_HUNTING" | translate }}
          </h6>
        </div>
        <div class="col-auto align-self-center"></div>
      </div>
    </div>
  </div>

  <div *ngIf="questInfo" class="quest-hunting-info container border-bottom">
    <div class="row mb-2 mt-2">
      <div class="col-12">
        <div class="quest-hunting-container">
          <div class="row-quest-hunting">
            <div class="d-flex justify-content-start gap-2 w-100 mb-2">
              <div
                class="wrapper-quest-hunting-img d-flex flex-column justify-content-start"
              >
                <div class="img-quest-hunting">
                  <img
                    alt="cover image"
                    [src]="
                      questInfo.image
                        ? questInfo.image
                        : 'assets/img/cover/mock-image.png'
                    "
                  />
                </div>
              </div>

              <div class="wrapper-quest-hunting-info">
                <div
                  class="quest-hunting-name d-flex justify-content-between gap-2 w-100 align-items-start"
                >
                  <span class="size-18 fw-bold text-white">
                    {{ questInfo.title }}
                  </span>
                  <button
                    type="button"
                    class="btn btn-share-quest-hunting p-0"
                    (click)="singletonService.openModalSocialSharing()"
                  >
                    <img
                      src="assets/img/icon/icon-actions/share-white.png"
                      alt="share icon"
                    />
                  </button>
                </div>
                <div
                  class="wrapper-quest-hunting-tag d-flex align-items-center gap-2 mt-1"
                >
                  <div *ngIf="questInfo.category" class="tag border active">
                    <span class="tag-inner justify-content-center">
                      <span class="text-uppercase">
                        {{ questInfo.category.title }}
                      </span>
                    </span>
                  </div>
                  <!-- <div class="tag border active">
                    <span class="tag-inner justify-content-center">
                      <span class="text-uppercase">Tag</span>
                    </span>
                  </div> -->
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div
                class="quest-hunting-desc text-white fw-normal my-3 size-14 lh-sm"
              >
                <!-- Get discount coupons worth a total of 30 point (Limit 500 rights) -->
                {{ questInfo.shortDescription }}
              </div>
              <div
                class="date-quest-hunting text-secondary text-left d-flex align-items-center"
              >
                <i class="bi bi-calendar4 size-16 text-secondary"></i>
                <span class="size-14 text-secondary ms-2">
                  <!-- 24 May 2023, 22:00 - 28 May 2023, 22:00 -->
                  {{ questInfo.startEventDate | date : "dd MMM YYYY, HH:mm" }}
                  -
                  {{ questInfo.closeEventDate | date : "dd MMM YYYY, HH:mm" }}
                </span>
              </div>
              <div
                *ngIf="questInfo.questInfos"
                class="quest-hunting-progress-bar my-3"
              >
                <div class="quest-hunting-progress-bar-bg">
                  <div
                    class="quest-hunting-progress-bar-inner"
                    [ngStyle]="{
                      width:
                        questInfo.questInfos.amountOfQuests !== 0 ||
                        questInfo.isCompleted
                          ? '' +
                            (questInfo.questInfos.amountOfCompletedQuests *
                              100.0) /
                              questInfo.questInfos.amountOfQuests +
                            '%'
                          : '0' + '%'
                    }"
                  ></div>
                </div>
              </div>
              <div
                *ngIf="questInfo.questInfos"
                class="quest-hunting-progress-count"
              >
                <div class="size-14 text-secondary">
                  <!-- Reward 70/500 -->
                </div>
                <div class="d-flex justify-content-start align-items-center">
                  <i class="bi bi-check-circle size-18 lh-1 text-white"></i>
                  <span class="ms-2 size-14 text-white">
                    <!-- 2/4 Completed -->

                    <span *ngIf="questInfo.questInfos.amountOfQuests !== 0">
                      {{ questInfo.questInfos.amountOfCompletedQuests }}/{{
                        questInfo.questInfos.amountOfQuests
                      }}
                      {{ "COMPLETE" | translate }}
                    </span>
                    <span *ngIf="questInfo.questInfos.amountOfQuests === 0">
                      <span *ngIf="questInfo.isCompleted">
                        {{ "COMPLETE" | translate }}
                      </span>
                      <span *ngIf="!questInfo.isCompleted">
                        {{ "INCOMPLETE" | translate }}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="questInfo" class="quest-hunting-about container border-bottom">
    <div class="row">
      <div class="col-12">
        <div
          class="accordion quest-hunting-accordion my-3"
          id="accordionDetail"
        >
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                {{ "ABOUT" | translate }}
                {{ "QUEST_HUNTING.QUEST_HUNTING" | translate }}
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              data-bs-parent="#accordionDetail"
            >
              <div class="accordion-body px-0 pb-0">
                <div class="detail-quest-hunting size-14 text-secondary">
                  <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum auctor urna fermentum commodo risus mattis pellentesque. Dictumst eget. -->
                  {{ questInfo.description }}
                </div>

                <div
                  *ngIf="questInfo.condition"
                  class="condition-quest-hunting mt-3"
                >
                  <h4 class="fw-bold size-16 text-white">
                    {{ "CONDITION" | translate }}
                  </h4>
                  <span class="mt-2" [innerHtml]="questInfo.condition"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="questInfo && questInfo.eventQuests && questInfo.eventQuests.length"
    class="quest-hunting-list container"
  >
    <h6 class="text-white size-18 fw-bold mt-3 mb-2 pb-1">
      {{ "QUEST_HUNTING.QUEST_HUNTING" | translate }}
    </h6>

    <!-- <div class="card custom-card mb-3" routerLink="/quest-hunting-sub">
      <div class="wrapper-quest-hunting-info card-body">
        <div class="quest-hunting-name d-flex justify-content-between gap-2 w-100">
          <span class="size-16 fw-bold text-white">
            Quest Name
          </span>
          <button type="button" class="btn p-0">
            <i class="bi bi-chevron-right size-14 text-secondary"></i>
          </button>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 pb-1">
          <div class="quest-hunting-progress-bar-bg">
            <div class="quest-hunting-progress-bar-inner" style="width: 66.66%;"></div>
          </div>
        </div>
        <div class="quest-hunting-progress-count">
          <div class="d-flex justify-content-start align-items-center">
            <i class="bi bi-check-circle size-18 lh-1 text-secondary"></i>
            <span class="ms-2 size-14 text-secondary">2/3 Completed</span>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="card custom-card mb-3" routerLink="/quest-hunting-sub">
      <div class="wrapper-quest-hunting-info card-body">
        <div class="quest-hunting-name d-flex justify-content-between gap-2 w-100">
          <span class="size-16 fw-bold text-white">
            Quest Name
          </span>
          <button type="button" class="btn p-0">
            <i class="bi bi-chevron-right size-14 text-secondary"></i>
          </button>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 pb-1">
          <div class="quest-hunting-progress-bar-bg">
            <div class="quest-hunting-progress-bar-inner" style="width: 0%;"></div>
          </div>
        </div>
        <div class="quest-hunting-progress-count">
          <div class="d-flex justify-content-start align-items-center">
            <i class="bi bi-check-circle size-18 lh-1 text-secondary"></i>
            <span class="ms-2 size-14 text-secondary">0/2 Completed</span>
          </div>
        </div>
      </div>
    </div> -->

    <div
      *ngFor="let eventQuest of questInfo.eventQuests"
      class="card custom-card mb-3"
      (click)="jumpToInnerQuest(eventQuest)"
    >
      <div class="wrapper-quest-hunting-info card-body">
        <div
          class="quest-hunting-name d-flex justify-content-between gap-2 w-100"
        >
          <span class="size-16 fw-bold text-white">
            {{ eventQuest.title }}
          </span>
          <button type="button" class="btn p-0">
            <i class="bi bi-chevron-right size-14 text-secondary"></i>
          </button>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 pb-1">
          <div class="quest-hunting-progress-bar-bg">
            <div
              class="quest-hunting-progress-bar-inner"
              [ngStyle]="{
                width:
                  eventQuest.questInfos.amountOfQuests !== 0 ||
                  eventQuest.isCompleted
                    ? '' +
                      (eventQuest.questInfos.amountOfCompletedQuests * 100.0) /
                        eventQuest.questInfos.amountOfQuests +
                      '%'
                    : '0' + '%'
              }"
            ></div>
          </div>
        </div>
        <div class="quest-hunting-progress-count">
          <div class="d-flex justify-content-start align-items-center">
            <i
              class="bi bi-check-circle size-18 lh-1"
              [ngClass]="
                eventQuest.isCompleted && !eventQuest.isRewarded
                  ? 'text-pink'
                  : 'text-secondary'
              "
            ></i>
            <span class="ms-2 size-14 text-secondary">
              <!-- 2/2 Completed -->

              <span *ngIf="eventQuest.questInfos.amountOfQuests !== 0">
                {{ eventQuest.questInfos.amountOfCompletedQuests }}/{{
                  eventQuest.questInfos.amountOfQuests
                }}
                {{ "COMPLETE" | translate }}
              </span>
              <span *ngIf="eventQuest.questInfos.amountOfQuests === 0">
                <span *ngIf="eventQuest.isCompleted">
                  {{ "COMPLETE" | translate }}
                </span>
                <span *ngIf="!eventQuest.isCompleted">
                  {{ "INCOMPLETE" | translate }}
                </span>
              </span>
            </span>
          </div>
          <div
            *ngIf="eventQuest.isCompleted && !eventQuest.isRewarded"
            class="status-quest"
          >
            <span
              class="btn btn-outline-main text-pink size-14 py-0 px-2 rounded-5"
              >{{ "CLAIM" | translate }}</span
            >
          </div>
          <div
            *ngIf="eventQuest.isCompleted && eventQuest.isRewarded"
            class="status-quest"
          >
            <span
              class="btn btn-outline-main text-secondary size-14 py-0 px-2 rounded-5 disabled"
              >{{ "CLAIMED" | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="card custom-card mb-3" routerLink="/quest-hunting-sub">
      <div class="wrapper-quest-hunting-info card-body">
        <div class="quest-hunting-name d-flex justify-content-between gap-2 w-100">
          <span class="size-16 fw-bold text-white">
            Quest Name
          </span>
          <button type="button" class="btn p-0">
            <i class="bi bi-chevron-right size-14 text-secondary"></i>
          </button>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 pb-1">
          <div class="quest-hunting-progress-bar-bg">
            <div class="quest-hunting-progress-bar-inner" style="width: 100%;"></div>
          </div>
        </div>
        <div class="quest-hunting-progress-count">
          <div class="d-flex justify-content-start align-items-center">
            <i class="bi bi-check-circle size-18 lh-1 text-secondary"></i>
            <span class="ms-2 size-14 text-secondary">2/2 Completed</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <div *ngIf="questInfo && questInfo?.rewards && questInfo?.rewards.length" class="quest-hunting-reward container">
    <h6 class="text-white size-18 fw-bold mt-4 mb-2 pb-1">
      {{ "REWARD" | translate }}
    </h6>
    <p class="size-14 text-secondary">
      {{ "QUEST_REWARD_HINT" | translate }}
    </p>

    <div class="row my-3">
      <div class="col-12">
        <swiper
          class="swiper-reward-quest"
          [slidesPerView]="'auto'"
          [spaceBetween]="12"
          [pagination]="false"
        >
          <ng-template swiperSlide *ngFor="let reward of questInfo.rewards">
            <div
              class="slide-wrapper d-flex flex-column"
              [ngClass]="
                !reward.isRemainRewardAmount || !reward.isRemainLimitAmount
                  ? 'out-of-stock-reward'
                  : ''
              "
            >
              <figure class="overflow-hidden rounded-15 text-center">
                <img
                  [src]="
                    reward.reward &&
                    reward.reward.images &&
                    reward.reward.images.length
                      ? reward.reward.images[0]
                      : 'assets/img/cover/mock-image.png'
                  "
                  alt=""
                  class="w-100 mx-auto"
                />
              </figure>
              <div class="text-content-reward">
                <h6 class="size-16 text-white text-truncate">
                  {{ reward.reward.title }}
                </h6>
                <p class="size-12 text-white mt-1 mb-0">
                  [x{{ reward.amount }}]
                </p>
                <p class="size-10 text-secondary mt-1 mb-2">
                  {{ reward.reward.description }}
                </p>
                <hr class="my-2" />
                <div class="size-10 text-secondary mb-1">
                  {{ "REMAIN" | translate }}: {{ reward.remainLimitAmount }}
                </div>
                <div class="size-10 text-secondary">
                  {{ "REMAIN_IN_POOL" | translate }}:
                  {{ reward.remainRewardAmount }}
                </div>
              </div>
            </div>
          </ng-template>

          <!-- <ng-template swiperSlide>
            <div class="slide-wrapper d-flex flex-column">
              <figure class="overflow-hidden rounded-15 text-center">
                <img
                  src="assets/img/cover/mock-image.png"
                  alt=""
                  class="w-100 mx-auto"
                />
              </figure>
              <div class="text-content-reward">
                <h6 class="size-16 text-white text-truncate text-center">
                  Name reward
                </h6>
              </div>
            </div>
          </ng-template> -->

          <!-- <ng-template swiperSlide>
            <div class="slide-wrapper d-flex flex-column">
              <figure class="overflow-hidden rounded-15 text-center">
                <img
                  src="assets/img/cover/mock-image.png"
                  alt=""
                  class="w-100 mx-auto"
                />
              </figure>
              <div class="text-content-reward">
                <h6 class="size-16 text-white text-truncate text-center">
                  Name reward
                </h6>
              </div>
            </div>
          </ng-template> -->

          <!-- <div>
            <div class="text-center my-5 py-3 empty-wrapper">
              <img src="assets/img/empty/empty-search-list.png" alt="empty search list">
              <p class="text-center fw-normal size-14">{{ "LOADING" | translate }}...</p>
            </div>
          </div> -->
        </swiper>
      </div>
    </div>

    <div
      *ngIf="currentUser"
      class="wrapper-reward-btn-actions gap-3 d-flex flex-column pb-5"
    >
      <!-- <button class="btn btn-default" type="button" (click)="processingSwal()">
        Btn Processing
      </button> -->

      <button
        *ngIf="questtype !== 'quest-book' && !questInfo.isRewarded"
        class="btn btn-default"
        type="button"
        (click)="jumpToQRScaner()"
        [disabled]="questInfo.isCompleted"
      >
        {{ "SCAN_QR_TO_COMPLETE" | translate }}
      </button>
      <button
        *ngIf="questtype !== 'quest-book' && !questInfo.isRewarded"
        class="btn btn-default"
        type="button"
        (click)="openQrCodeRewardModal()"
        [disabled]="questInfo.isCompleted"
      >
        {{ "SHOW_YOUR_QR_TO_STAFF" | translate }}
      </button>
      <button
        *ngIf="!!questInfo?.rewards.length && !questInfo.isRewarded"
        class="btn btn-default"
        type="button"
        (click)="confirmToClaimReward()"
        [disabled]="!questInfo.isCompleted || questInfo.isRewarded"
      >
        {{ "CLAIM_REWARD" | translate }}
      </button>

      <button
        *ngIf="questInfo.isRewarded"
        class="btn btn-default"
        type="button"
        [routerLink]="'/mycouponreward'"
      >
        {{ "SPIN_THE_WHEEL_PAGE.VIEW_REWARD_BUTTON" | translate }}
      </button>

      <!-- <button class="btn btn-default" type="button" (click)="openQrCodeRewardModal()">
        QR-Code Reward
      </button> -->
    </div>

    <!-- if not log in -->
    <div *ngIf="!currentUser" class="wrapper-info-blur mb-4">
      <div class="wrapper-info-blur-inner">
        <div class="pt-2">
          <div class="text-white size-16">
            {{ "PLEASE_SIGN_IN_OR_SIGN_UP" | translate }}
          </div>
          <div class="text-white size-20">
            {{ "TO_ACCESS_YOUR_ACCOUNT" | translate }}
          </div>
        </div>
        <div class="wrapper-actions-button">
          <a routerLink="/signup" class="btn btn-outline-main size-16">{{
            "SIGNIN.SIGNUP" | translate
          }}</a>
          <a routerLink="/signin" class="btn btn-default size-16">{{
            "SIGNIN.SIGNIN" | translate
          }}</a>
        </div>
      </div>
    </div>
    <!-- end not log in -->
  </div>
</div>
