/* eslint-disable */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'autoprefixer';
import { environment } from 'src/environments/environment';
import { Logger } from './logger.service';
import { TranslateConfigService } from './translate-config.service';

const log = new Logger('BackendService');

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(
    private _http: HttpClient,
    private _translateConfigService: TranslateConfigService
  ) {}

  getAllPoint(query: GetAllPointQuery) {
    const { eventCode, isOnlyAvailable } = query;
    const url = `${environment.apiUrl}/wallet-server/wallet/all-tokens?eventCode=${eventCode}&isOnlyAvailable=${isOnlyAvailable}`;
    return this._http.get(url).toPromise();
  }

  getTotalIncomeAndExpenseByTokenId(token: string) {
    const url = `${environment.apiUrl}/wallet-server/wallet/total-income-and-expense/${token}`;
    return this._http.get(url).toPromise();
  }

  getAllPointTransactions(query: getPointTransactionsQuery) {
    const { token, eventCode, limit, page, type } = query;
    const param = !!eventCode ? `eventCode=${eventCode}` : `token=${token}`;
    const queryParams = queryParamsToUrl({
      limit: limit!,
      page: page!,
      type: type!,
      sortBy: 'createdAt:desc',
    });
    const url = `${environment.apiUrl}/wallet-server/wallet/all-transactions${queryParams}&${param}`;
    return this._http.get(url).toPromise();
  }

  transferToken(payload: TransferTokenPayload) {
    const url = `${environment.apiUrl}/wallet-server/wallet/transfer-token`;
    return this._http.post(url, payload).toPromise();
  }

  markCouponAsUsed(couponId: string) {
    const url = `${environment.apiUrl}/wallet-server/wallet/used/${couponId}`;
    return this._http.post(url, {}).toPromise();
  }

  getCouponCategories() {
    const url = `${environment.apiUrl}/platform-event/event-coupon-categories`;
    return this._http.get(url).toPromise();
  }

  getCouponBundles(eventCode: any, limit: any = 100, page: any = 1) {
    let url = `${environment.apiUrl}/platform-event/event-coupon-bundles?`;
    if (eventCode) url += `&eventCode=${eventCode}`;
    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.get(url).toPromise();
  }

  getCoupons(query: getCouponsQuery) {
    const queryParams = queryParamsToUrl(query);
    const url = `${environment.apiUrl}/platform-event/event-coupons${queryParams}`;
    return this._http.get(url).toPromise();
  }

  getCouponById(coupon: string) {
    let url = `${environment.apiUrl}/platform-event/event-coupons/${coupon}?`;
    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;
    return this._http.get(url).toPromise();
  }

  getCouponBundleById(couponBundleId: string) {
    let url = `${environment.apiUrl}/platform-event/event-coupon-bundles/${couponBundleId}?`;
    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;
    return this._http.get(url).toPromise();
  }

  getInAppWheelBadge() {
    const queryParams = queryParamsToUrl({
      wheelType: 'in-app',
      eventCode: environment.eventCode,
    });
    const url = `${environment.apiUrl}/wallet-server/wallet/wheels/infos${queryParams}`;
    return this._http.get(url).toPromise();
  }

  getUnusedCouponBadge() {
    const queryParams = queryParamsToUrl({
      isUsed: 'false',
      isExpired: 'false',
      eventCode: environment.eventCode,
    });
    const url = `${environment.apiUrl}/wallet-server/wallet/all-coupons${queryParams}`;
    return this._http.get(url).toPromise();
  }

  getExhibitorCategoryList() {
    let url = `${environment.apiUrl}/platform-event/event-exhibitor-categories?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.get(url).toPromise();
  }

  getQuestCategoryList() {
    let url = `${environment.apiUrl}/platform-event/event-quest-book-categories?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.get(url).toPromise();
  }

  getQuestInfosById(questId: any) {
    let url = `${environment.apiUrl}/platform-event/event-quests/${questId}?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.get(url).toPromise();
  }

  getQuestBookInfosById(questBookId: any) {
    let url = `${environment.apiUrl}/platform-event/event-quest-books/${questBookId}?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.get(url).toPromise();
  }

  claimRewardQuest(questId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/quests/claim-reward/${questId}?`;
    return this._http.post(url, {}).toPromise();
  }

  claimRewardQuestBook(questBookId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/quest-books/claim-reward/${questBookId}?`;
    return this._http.post(url, {}).toPromise();
  }

  getMyQuestList(status: any, limit: any = 10, page: any = 1) {
    let url = `${environment.apiUrl}/wallet-server/wallet/quests?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    if (status) url += `&status=${status}`;
    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;

    return this._http.get(url).toPromise();
  }

  getSuggestExhibitorList(exhibitorId: any) {
    let url = `${environment.apiUrl}/platform-event/event-exhibitors/suggest/${exhibitorId}?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.get(url).toPromise();
  }

  getExhibitorInfos(exhibitorId: any) {
    let url = `${environment.apiUrl}/platform-event/event-exhibitors/${exhibitorId}?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.get(url).toPromise();
  }

  reviewExhibitor(formData: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/feedbacks/create?`;

    // const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    // if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.post(url, formData).toPromise();
  }

  bookmarkExhibitor(exhibitorId: any, text: any) {
    const data = {
      text,
    };
    let url = `${environment.apiUrl}/wallet-server/wallet/exhibitors/bookmark/${exhibitorId}?`;
    if (exhibitorId) url += `&exhibitorId=${exhibitorId}`;

    // const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    // if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.post(url, data).toPromise();
  }

  unbookmarkExhibitor(exhibitorId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/exhibitors/unbookmark/${exhibitorId}?`;

    // const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    // if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.post(url, {}).toPromise();
  }

  getBookmarkedExhibitorList(
    exhibitorId: any = undefined,
    eventCode: any = 'event-01'
  ) {
    let url = `${environment.apiUrl}/wallet-server/wallet/exhibitors/bookmark?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;
    if (eventCode) url += `&eventCode=${eventCode}`;
    if (exhibitorId) url += `&exhibitorId=${exhibitorId}`;

    log.debug('getBookmarkedExhibitorList url => %o', url);

    return this._http.get(url).toPromise();
  }

  getBrochureExhibitorList(eventExhibitor: any) {
    let url = `${environment.apiUrl}/platform-event/event-exhibitor-brochures?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;
    if (eventExhibitor) url += `&eventExhibitor=${eventExhibitor}`;

    return this._http.get(url).toPromise();
  }

  getEventByCode(eventCode: any) {
    let url = `${environment.apiUrl}/platform-event/events/code/${eventCode}?`;
    return this._http.get(url).toPromise();
  }

  getContactList(limit: any = 10, page: any = 1) {
    let url = `${environment.apiUrl}/wallet-server/wallet/contacts?`;
    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;
    return this._http.get(url).toPromise();
  }

  checkContact(userId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/contacts/check/${userId}?`;
    return this._http.get(url).toPromise();
  }

  addContact(userId: any, eventCode: any) {
    const data = {
      eventCode: eventCode,
    };
    let url = `${environment.apiUrl}/wallet-server/wallet/contacts/add/${userId}?`;
    return this._http.post(url, data).toPromise();
  }

  removeContact(userId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/contacts/remove/${userId}?`;
    return this._http.post(url, {}).toPromise();
  }

  getUserById(userId: any) {
    let url = `${environment.apiUrl}/wallet-server/users/${userId}?`;
    return this._http.get(url).toPromise();
  }

  getUserInfoToGetPrize(userId: any) {
    let url = `${environment.apiUrl}/wallet-server/users?limit=10`;
    return this._http.get(url).toPromise();
  }

  getUserInfoToDeleteAccount(userId: any) {
    let url = `${environment.apiUrl}/wallet-server/users/me/delete-account`;
    return this._http.delete(url).toPromise();
  }

  getEventReviewList(eventCode: any, limit: any = 10, page: any = 1) {
    let url = `${environment.apiUrl}/platform-event/event-feedbacks?`;
    if (eventCode) url += `&eventCode=${eventCode}`;
    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;
    return this._http.get(url).toPromise();
  }

  bookmarkBrochureExhibitor(brochureId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/brochures/bookmark/${brochureId}?`;

    // const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    // if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.post(url, {}).toPromise();
  }

  unbookmarkBrochureExhibitor(brochureId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/brochures/unbookmark/${brochureId}?`;

    // const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    // if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.post(url, {}).toPromise();
  }

  getBookmarkedBrochureExhibitorList(eventCode: any = 'event-01') {
    let url = `${environment.apiUrl}/wallet-server/wallet/brochures?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;
    if (eventCode) url += `&eventCode=${eventCode}`;

    return this._http.get(url).toPromise();
  }

  getExhibitorFeedbacks(eventExhibitor: any, limit: any = 10, page: any = 1) {
    let url = `${environment.apiUrl}/platform-event/event-feedbacks?`;
    if (eventExhibitor) url += `&eventExhibitor=${eventExhibitor}`;
    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;

    log.debug('getExhibitorFeedbacks url => %o', url);

    // const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    // if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.get(url).toPromise();
  }

  getExhibitorList(
    keyword: any,
    categories: any,
    limit: any = 10,
    page: any = 1,
    isSuggested: any = null
  ) {
    let url = `${environment.apiUrl}/platform-event/event-exhibitors/search?`;
    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    if (keyword) url += `&keyword=${keyword}`;
    if (categories) url += `&categories=${categories}`;
    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;
    if (isSuggested) url += `&isSuggested=${isSuggested}`;
    log.debug('getExhibitorList url => %o', url);

    return this._http.get(url).toPromise();
  }

  getQuestList(
    keyword: any,
    categories: any,
    limit: any = 10,
    page: any = 1,
    eventCode: any = 'event-01'
  ) {
    let url = `${environment.apiUrl}/platform-event/event-quest-books/search?`;
    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    if (keyword) url += `&keyword=${keyword}`;
    if (categories) url += `&categories=${categories}`;
    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;
    if (eventCode) url += `&eventCode=${eventCode}`;
    log.debug('getQuestList url => %o', url);

    return this._http.get(url).toPromise();
  }

  executeCompleteQuest(executeCode: any) {
    let url = `${environment.apiShortenLinkUrl}/g/q/execute/${executeCode}?`;

    // const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    // if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.post(url, {}).toPromise();
  }

  confirmConsent(logId: any) {
    let url = `${environment.apiShortenLinkUrl}/g/i/consent/${logId}?`;

    // const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    // if (currentLanguage) url += `&lang=${currentLanguage}`;

    return this._http.post(url, {}).toPromise();
  }

  getArticleList(
    keyword: any,
    categories: any,
    limit: any = 10,
    page: any = 1
    // isSuggested: any = null
  ) {
    let url = `${environment.apiUrl}/platform-event/event-articles/search?`;
    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    if (keyword) url += `&keyword=${keyword}`;
    if (categories) url += `&categories=${categories}`;
    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;
    // if (isSuggested) url += `&isSuggested=${isSuggested}`;
    log.debug('getArticleList url => %o', url);

    return this._http.get(url).toPromise();
  }

  getSuggestArticleList(
    articleId: any,
    limit: any = 10,
    page: any = 1
    // isSuggested: any = nulldata
  ) {
    let url = `${environment.apiUrl}/platform-event/event-articles/suggest/${articleId}?`;
    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    if (limit) url += `&limit=${limit}`;
    if (page) url += `&page=${page}`;
    // if (isSuggested) url += `&isSuggested=${isSuggested}`;
    log.debug('getSuggestArticleList url => %o', url);

    return this._http.get(url).toPromise();
  }

  getArticleInfos(articleId: any) {
    let url = `${environment.apiUrl}/platform-event/event-articles/${articleId}?`;
    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    log.debug('getArticleInfos url => %o', url);
    return this._http.get(url).toPromise();
  }

  getArticleCategoryList() {
    let url = `${environment.apiUrl}/platform-event/event-article-categories?`;
    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;
    return this._http.get(url).toPromise();
  }

  getMyRedeemedCoupons(query: getMyRedeemedCouponsQuery) {
    const queryParams = queryParamsToUrl({
      ...query,
      sortBy: 'createdAt:desc',
    });
    const url = `${environment.apiUrl}/wallet-server/wallet/all-coupons${queryParams}`;
    return this._http.get(url).toPromise();
  }

  redeemCoupon(couponId: string) {
    const url = `${environment.apiUrl}/wallet-server/wallet/redeem/${couponId}`;
    return this._http.post(url, {}).toPromise();
  }

  buyCoupon(purchases: any, type: any = 'coupon') {
    let url = `${environment.apiUrl}/wallet-server/wallet/coupon/purchases`; // TODO: change real endpoint api
    const data = {
      purchases: purchases,
      type: type,
    };
    return this._http.post(url, data).toPromise();
  }

  buyBundleCoupon(bundleId: string, type: any = 'bundle') {
    let url = `${environment.apiUrl}/wallet-server/wallet/coupon/purchases`; // TODO: change real endpoint api
    const data = {
      bundleId: bundleId,
      type: type,
    };
    return this._http.post(url, data).toPromise();
  }

  savePin(payload: { pin: string }) {
    const url = `${environment.apiUrl}/wallet-server/wallet/pin/save`;
    return this._http.post(url, payload).toPromise();
  }

  verifyPin(payload: { pin: string }) {
    const url = `${environment.apiUrl}/wallet-server/wallet/pin/verify`;
    return this._http.post(url, payload).toPromise();
  }

  getWheelList(query: BaseQueryParam) {
    const lang = this._translateConfigService.getCurrentLanguage()!;
    const queryParams = queryParamsToUrl({
      ...query,
      lang,
    });
    let url = `${environment.apiUrl}/platform-event/event-wheels${queryParams}`;
    return this._http.get(url).toPromise();
  }

  getWheelsReward(wheelId: string) {
    const url = `${environment.apiUrl}/activity-event/wheels/get-reward/${wheelId}`;
    return this._http.post(url, {}).toPromise();
  }

  getWheelInfo(wheelId: string) {
    let url = `${environment.apiUrl}/wallet-server/wallet/wheels/infos/${wheelId}`;
    return this._http.get(url).toPromise();
  }

  getUserGalleryImages(tags?: string, limit = 10, page = 1) {
    let url = `${environment.apiUrl}/wallet-server/wallet/photos?limit=${limit}&page=${page}`;
    if (tags) {
      url += `&tags=${tags}`;
    }
    return this._http.get(url).toPromise();
  }

  getUserTarotGalleryImages(limit = 100, page = 1) {
    let url = `${environment.apiUrl}/wallet-server/my-nfts/?limit=${limit}&page=${page}`;
    return this._http.get(url).toPromise();
  }
  getUserTarotGalleryImage(id: string) {
    const url = `${environment.apiUrl}/wallet-server/nft/${id}`;
    return this._http.get(url).toPromise();
  }

  getUserTarotGalleryAmount(id: string) {
    const url = `${environment.apiUrl}/platform-event/event-user-NFTs/${id}`;
    return this._http.get(url).toPromise();
  }

  getUserGalleryImage(imageId: string) {
    const url = `${environment.apiUrl}/wallet-server/wallet/photos/${imageId}`;
    return this._http.get(url).toPromise();
  }

  registerToken(token: any, eventCode: string, topics: string) {
    // const qs = '?' + new URLSearchParams(params).toString()
    let url = `${environment.apiUrl}/wallet-server/auth/message-register`;
    return this._http
      .post(url, {
        token,
        eventCode,
        topics,
      })
      .toPromise();
  }

  getNotifications(query: BaseQueryParam) {
    const language = this._translateConfigService.getCurrentLanguage()!;
    const queryParams = queryParamsToUrl({
      ...query,
      lang: language,
    });
    const url = `${environment.apiUrl}/wallet-server/wallet/notifications${queryParams}`;
    return this._http.get(url).toPromise();
  }

  readNotifications(notificationIds: string[]) {
    const url = `${environment.apiUrl}/wallet-server/wallet/notifications/readed`;
    return this._http.post(url, { notificationIds }).toPromise();
  }

  deletedNotifications(notificationIds: string[]) {
    const url = `${environment.apiUrl}/wallet-server/wallet/notifications/deleted`;
    return this._http.delete(url, { body: { notificationIds } }).toPromise();
  }

  getNotificationBadge() {
    const url = `${environment.apiUrl}/wallet-server/wallet/notifications/count`;
    return this._http.get(url).toPromise();
  }

  checkIsRoleStaff(eventCode: any) {
    const url = `${environment.apiUrl}/wallet-server/users/event-code/${eventCode}`;
    return this._http.get(url).toPromise();
  }

  getDashboardToken() {
    const url = `${environment.apiUrl}/wallet-server/auth/request-user-token`;
    return this._http.get(url).toPromise();
  }

  getWallWheelInfos(customActionCode: any) {
    const data = {
      code: customActionCode,
    };
    let url = `${environment.apiUrl}/activity-event/wheels/check-code?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    log.debug('getWheelInfos data => %o', data);
    log.debug('getWheelInfos url => %o', url);

    return this._http.post(url, data).toPromise();
  }

  spinWallWheel(customActionCode: any, eventWheelId: any) {
    const data = {
      code: customActionCode,
      eventWheelId: eventWheelId,
    };
    let url = `${environment.apiUrl}/activity-event/wheels/use-code?`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    log.debug('getWheelInfos data => %o', data);
    log.debug('getWheelInfos url => %o', url);

    return this._http.post(url, data).toPromise();
  }

  getAllNfts(query: BaseQueryParam) {
    // const language = this._translateConfigService.getCurrentLanguage()!;
    const queryParams = queryParamsToUrl({
      ...query,
      // lang: language,
    });
    const url = `${environment.apiUrl}/wallet-server/wallet/all-nfts${queryParams}`;
    return this._http.get(url).toPromise();
  }

  getExchangeTokens(eventCode: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/exchange/tokens?`;
    if (eventCode) url += `&eventCode=${eventCode}`;

    return this._http.get(url).toPromise();
  }

  createExchangeOrder(amountIn: any, exchangeId: any) {
    const data = {
      amountIn: amountIn,
      exchangeId: exchangeId,
    };
    let url = `${environment.apiUrl}/wallet-server/wallet/exchange/orders?`;
    return this._http.post(url, data).toPromise();
  }

  checkOrderTransaction(orderId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/exchange/order/check/${orderId}`;
    return this._http.post(url, {}).toPromise();
  }

  checkPurchaseTransaction(purchaseId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/coupon/purchase/check/${purchaseId}`;
    return this._http.post(url, {}).toPromise();
  }

  checkLimitCouponBundle(itemId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/coupon-bundles/check-limit/${itemId}`;
    return this._http.get(url).toPromise();
  }

  checkLimitCoupon(itemId: any) {
    let url = `${environment.apiUrl}/wallet-server/wallet/coupons/check-limit/${itemId}`;
    return this._http.get(url).toPromise();
  }

  stampBySelf(ticketId: string) {
    const url = `${environment.apiUrl}/platform-event/event-attendances/stamp-by-self/${ticketId}`;
    return this._http.post(url, {}).toPromise();
  }

  checkPaperWalletCode(code: string) {
    const url = `${environment.apiUrl}/platform-event/event-user-temps/code/${code}`;
    return this._http.get(url).toPromise();
  }
}

type BaseQueryParam = {
  [key: string]: string | number;
};

type GetAllPointQuery = {
  eventCode: string;
  isOnlyAvailable: boolean;
};

type getPointTransactionsQuery = {
  token?: string;
  eventCode?: string;
  limit?: number;
  page?: number;
  type?: string;
};

type TransferTokenPayload = {
  to: string;
  type: string;
  token: string;
  amount: number;
};

type getCouponsQuery = {
  limit: number;
  page: number;
  category?: string;
  eventCode: string;
  lang?: string;
};

type getMyRedeemedCouponsQuery = {
  limit: number;
  page: number;
  eventCode: string;
  lang?: string;
};

const queryParamsToUrl = (params: { [key: string]: string | number }) => {
  if (!params) return '';
  const keys = Object.keys(params);
  const stringParam = keys
    .map((key, i) => {
      const symbol = i === 0 ? '?' : '&';
      if (!params[key]) return '';
      return `${symbol}${key}=${params[key]}`;
    })
    .join('');
  return stringParam;
};
