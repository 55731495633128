<div class="modal-body">
  <div class="mt-3 mb-1">
    <div class="col-11 col-sm-11 mt-auto mx-auto py-4">
      <div class="row">
        <div class="col-12 d-grid">
          <button
            type="submit"
            class="btn btn-default btn-lg"
            (click)="signIn()"
          >
            {{"SIGN_IN" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
