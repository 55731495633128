import { ActivatedRoute } from '@angular/router';
/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { SingletonService } from 'src/app/@services/singleton.service';
import { UserService } from 'src/app/@services/user.service';
import Swal from 'sweetalert2';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('ReceiveComponent');

@Component({
  selector: 'app-receive',
  templateUrl: './receive.component.html',
  styleUrls: ['./receive.component.scss']
})
export class ReceiveComponent implements OnInit {

  interval:any

  id:any;
  amount:any;
  itemType:any;

  loading:any = false
  checkingTxLoading:any = false

  transaction:any;
  isSuccess = false

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    public singletonService: SingletonService,
    public _router: Router,
    public _route: ActivatedRoute,
  ) {
    this.id = this._route.snapshot.queryParams['id'];
    this.amount = this._route.snapshot.queryParams['amount'];
    this.itemType = this._route.snapshot.queryParams['itemType'];
  }

  async ngOnInit() {
    if(this.itemType == 'coupon'){
      await this.buyCoupon()
    }else if(this.itemType == 'bundle'){
      await this.buyBundleCoupon()
    }else{
      await this.createExchangeOrder();
    }
  }

  async buyCoupon() {
    const datas = [{
        "eventCouponId": this.id,
        "amount": 1
    }]

    try {
      this.loading = true;
      const res = await this.backendService.buyCoupon(datas);
      log.debug(" buyCoupon res => %o",res)
      this.transaction = res
      if(this.transaction){
        log.debug("start interval...");
        await this.setIntervalCheckTx();
      }
    } catch (err: any) {
      const error = this.errorService.error(err.error);
    } finally {
      this.loading = false;
    }
  }

  async buyBundleCoupon() {
    try {
      this.loading = true;
      const res = await this.backendService.buyBundleCoupon(this.id);
      log.debug(" buyBundleCoupon res => %o",res)
      this.transaction = res
      if(this.transaction){
        log.debug("start interval...");
        await this.setIntervalCheckTx();
      }
      // this.success = true;
    } catch (err: any) {
      const error = this.errorService.error(err.error);
    } finally {
      this.loading = false;
    }
  }


  async createExchangeOrder() {
    try {
      this.loading = true;
      // await this.singletonService.processingSwal('กำลังดำเนินการเติมเงิน', 'กรุณารอสักครู่');
      this.transaction = (await this.backendService.createExchangeOrder(
        this.amount,
        this.id
      ));
      log.debug("createExchangeOrder this.transaction => %o",this.transaction);
      if(this.transaction){
        log.debug("start interval...");
        await this.setIntervalCheckTx();
      }
      // Swal.close();
    } catch (err) {
      const error = this.errorService.error(err);
      // Swal.close();
    } finally {
      this.loading = false;
      // Swal.close();
    }
  }

  setIntervalCheckTx(){ // TODO: setInterval check depend on itemType
    this.interval = setInterval(async () => {
      try {
        this.checkingTxLoading = true;
        // await this.singletonService.processingSwal('กำลังดำเนินการเติมเงิน', 'กรุณารอสักครู่');
        let res:any
        if(this.itemType == 'coupon' || this.itemType == 'bundle'){
          res = (await this.backendService.checkPurchaseTransaction(
            this.transaction.purchase.id
          ));
        }else{
          res = (await this.backendService.checkOrderTransaction(
            this.transaction.order.id
          ));
        }
        log.debug("checkOrderTransaction res => %o",res);
        if(res.state == 'deposit'){
          this.successPayment()
        }
        // Swal.close();
      } catch (err) {
        const error = this.errorService.error(err);
        this.checkingTxLoading = false;
        // Swal.close();
      } finally {
        this.checkingTxLoading = false;
        // Swal.close();
      }
    }, 10 * 1000);
  }

  successPayment(){
    if(this.interval){
      clearInterval(this.interval);
    }
    this.isSuccess = true
  }

  ngOnDestroy(): void {
    if(this.interval){
      clearInterval(this.interval);
    }
  }

  generateQrcode() {
    Swal.fire({
      title: "Generate a QR-code Completed!",
      icon: 'success',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonColor: '#4B0082',
      confirmButtonText: this.translateService.instant('OK'),
      customClass: "popup-success-custom popup-content-custom",
    }).then((result) => {
    });
  }

}
