import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Logger } from './logger.service';
const log = new Logger('FormService');
@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  isControlValid(form:FormGroup ,controlName: string): boolean {
    const control = form.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(form:FormGroup ,controlName: string): boolean {
    const control = form.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(form:FormGroup ,validation: string, controlName: string): boolean {
    const control = form.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(form:FormGroup ,controlName: string): boolean {
    const control = form.controls[controlName];
    return control.dirty || control.touched;
  }
}
