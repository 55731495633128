<div class="card card-container mb-3" (click)="buttonClick.emit()">
  <div class="v-label px-2 text-light">
    {{ coupon.category.title | uppercase }}
  </div>
  <div class="w-100">
    <div class="d-flex p-3 card-description-row justify-content-between gap-2">
      <div class="">
        <h6 class="title size-16 mb-2">{{ coupon.name }}</h6>
        <p class="text-secondary size-14">
          {{ coupon.description }}
        </p>
      </div>
      <div class="">
        <div class="wrapper-coupon-img">
          <ng-container *ngIf="coupon.image">
            <img [src]="coupon.image" class="card-image" />
          </ng-container>
          <ng-container *ngIf="!coupon.image">
            <img
              src="assets/img/dashboard/services/coupon-reward.png"
              class="card-image empty-coupon-img"
              alt="coupon reward"
            />
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row p-3 gap-3">
      <div class="col align-self-center">
        <div class="d-flex flex-row" style="margin-left: -3px;">
          <ng-container *ngFor="let condition of coupon.redeemConditions">
            <h6 class="title mb-1 me-2" *ngIf="condition.type === 'token'">
              <img
                [src]="
                  condition.ref.logo || 'assets/img/dashboard/coin-53x53.png'
                "
                width="20"
                height="20"
                alt="coin"
                class=""
              />
              <span class="align-middle size-14">
                {{ condition.amount | numeral : "0,0" }}
                {{ condition.ref.name }}
              </span>
            </h6>
          </ng-container>
        </div>
        <p class="d-flex align-items-center justify-content-start text-secondary size-12">
          <i class="bi bi-calendar4 size-14 text-secondary me-2"></i>
          {{ "GET_UNTILL" | translate }}
          {{ coupon.endDate| date: 'dd/MM/yyyy'}}
        </p>
      </div>
      <div class="col-2 align-self-center text-end">
        <button
          type="button"
          class="btn text-white btn-sm ps-0 back-btn"
        >
          <i class="bi bi-chevron-right text-white size-16"></i>
        </button>

        <!-- <ng-container
          *ngIf="!coupon.remainAmount; then emptyBlock; else buttonBlock"
        ></ng-container>
        <ng-template #emptyBlock>
          <button
            type="button"
            class="btn btn-dark w-100 text-capitalize"
            disabled
          >
            {{ "FULLY_REDEEMED" | translate }}
          </button>
        </ng-template>

        <ng-template #buttonBlock>
          <button
            class="btn btn-default text-capitalize fw-bold"
            (click)="buttonClick.emit()"
          >
            {{ buttonText }}
          </button>
        </ng-template> -->
      </div>
    </div>
  </div>
</div>
