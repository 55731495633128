<div class="modal-header py-3 align-items-center justify-content-center px-1">
  <!-- <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button> -->
  <h3 class="header-text-normal text-white size-20" *ngIf="!survey">{{"MY_QR_CODE_TICKET" | translate}}</h3>
  <h3 class="header-text-success text-white size-20" *ngIf="survey">{{"SIGNIN.SUCCESS" | translate}}</h3>
  <!-- <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
  <button class="btn btn-top-right-arrow" type="button"></button>
</div>
<div class="modal-body">
 <span class="long-text" *ngIf="survey">
    {{"SIGNIN.SUCCESS_TEXT" | translate}}
  </span>

  <div class="text-center">
    <div class="small mb-3 mt-2 text-secondary qr-footer-text">
      <span>{{"SIGNIN.QR_JOINING" | translate}}</span>
    </div>
    <div class="wrapper-qr-code mb-3">
      <div>
        <img src="{{qrUrl}}" class="qr-code-img">
        <!-- <qrcode
          [qrdata]="'https://github.com/Cordobo/angularx-qrcode'"
          [allowEmptyString]="!qrFlag"
          [cssClass]="'center'"
          [colorDark]="'#000000ff'"
          [colorLight]="'#ffffffff'"
          [elementType]="'img'"
          [errorCorrectionLevel]="'M'"
          [margin]="4"
          [scale]="1"
          [width]="200"
        ></qrcode> -->
      </div>
    </div>
    <div class="wrapper-btn-actions gap-4 d-flex justify-content-center">
      <!-- <a (click)="singletonService.copyMessage(ticketId)" class="btn-action">
        <img src="assets/img/icon/icon-actions/copy.png" alt="copy" />
      </a>
      <a class="btn-action">
        <img src="assets/img/icon/icon-actions/share.png" alt="share" />
      </a> -->
      <a (click)="singletonService.downloadMediaFromUrl(qrUrl)" class="btn-action">
        <img src="assets/img/icon/icon-actions/download.png" alt="download" />
      </a>
    </div>
  </div>
  <div class="option">
    <div class="footer-button">
      <button (click)="openSignIn()" class="btn btn-default btn-lg w-100">
        {{"SIGNIN.GO_TO_WALLET" | translate}}
      </button>
    </div>
    <div class="footer-power">
      {{"POWERED_BY" | translate}} Linxlab.io
    </div>
  </div>
</div>
<ngx-loading
  [show]="loading"
  [config]="{
    animationType: ngxLoadingAnimationTypes.wanderingCubes,
    primaryColour: '#4b0082',
    secondaryColour: '#ffd42a',
    backdropBorderRadius: '3px'
  }"
  [template]="loadingTemplate">
</ngx-loading>

