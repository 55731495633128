export const environment = {
  firebase: {
    projectId: 'linx-platform',
    appId: '1:175287624543:web:4bb3a7b03eda9e586e4cea',
    storageBucket: 'linx-platform.appspot.com',
    apiKey: 'AIzaSyDeEuwpg2Kux3w--dcDCLCsUNB1wbvU52s',
    authDomain: 'linx-platform.firebaseapp.com',
    messagingSenderId: '175287624543',
    measurementId: 'G-G12RZGXK7K',
  },
  fcmKey:
    'BPU6GLaYLBnOcizvY2q8i7SJU2p0n8-FVZOdRIIv33p0E8RNXEwYBCh2rcCwkAqDTM6c5D0QQ7Oc7DohpfEZPNI',
  production: false,
  sentry: {
    enable: true,
    environment: 'development',
    dsn: 'https://4bd7b3f60572488884a5743d8737123d@o4504678368411648.ingest.sentry.io/4505105013932032',
    tracesSampleRate: 0.1, // only 10% of request
  },
  apiUrl: 'https://api.dev.linxlab.io/v1',
  apiShortenLinkUrl: 'https://api.dev.linxlab.io',

  localStoragePrefix: '0110-user',

  eventCode: 'event-01',

  dashboardUrl: 'https://dashboard.event.dev.linxlab.io',
  whitelistUrls: [
    'localhost:4200',
    'linxevent-wallet-dev.web.app',
    'linxevent-wallet-uat.web.app',
    'linxevent-wallet-prod.web.app',
    '0110.wallet.dev.linxlab.io',
    '0110.wallet.uat.linxlab.io',
    '0110.wallet.linxlab.io',
  ],

  customActionCodeSplitter: '_-_',
  actionPrefixs: [
    {
      key: 'UR',
      format: 'UR{SPLITTER}{USER_ID}',
      note: 'add friend',
    },
    {
      key: 'EX',
      format: 'EX{SPLITTER}{EVENT_CODE}{SPLITTER}{EXHIBITOR_ID}',
      note: 'redirect to event exhibitor detail',
    },
  ],

  googleKey: 'AIzaSyAiNIrc7oxX6OlpFds-sKP-r-WEyUo4MGE',
  googleClientID:
    '912232120931-0hjmaebm41apo2lq6jv8a5hdlu0cnr6b.apps.googleusercontent.com',
  facebookId: '192898523460682',
};
