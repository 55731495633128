<div id="qr-scanner-with-camera">
  <zxing-scanner
    [(device)]="currentDevice"
    (scanSuccess)="onCodeResult($event)"
    [formats]="formatsEnabled"
    [tryHarder]="false"
    (camerasFound)="onCamerasFound($event)"
    formatsEnabled
  ></zxing-scanner>
  <select
    class="form-select select-device form-select-sm"
    (change)="onDeviceSelectChange($event)"
  >
    <option value="" [selected]="!currentDevice">{{"NO_DEVICE_SELECT" | translate}}</option>
    <option
      *ngFor="let device of availableDevices"
      [value]="device.deviceId"
      [selected]="currentDevice && device.deviceId === currentDevice.deviceId"
    >
      {{ device.label }}
    </option>
  </select>
  <div class="select-img">
    <label for="select-img">
      <i class="bi bi-card-image"></i>
    </label>
    <input
      type="file"
      accept="image/*"
      id="select-img"
      hidden
      (change)="onFileChange($event)"
    />
  </div>
</div>
