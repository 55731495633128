import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-quest-hunting-sub',
  templateUrl: './quest-hunting-sub.component.html',
  styleUrls: ['./quest-hunting-sub.component.scss']
})
export class QuestHuntingSubComponent implements OnInit {

  constructor(
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) { }

  ngOnInit() {
  }

  backnav() {
    if(document.referrer == ''){
      this._router.navigate(['/']);
    }else{
      this.singletonService.back();
    }
    return false;
  }

}
