import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-help-and-support',
  templateUrl: './help-and-support.component.html',
  styleUrls: ['./help-and-support.component.scss']
})
export class HelpAndSupportComponent implements OnInit {

  constructor(
    private _router:Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) { }

  ngOnInit() {
  }

  backnav() {
    this.singletonService.back();
  }

}
