<div class="row">
    <div class="col-auto">
        <a href="javascript:void(0)" target="_self" class="btn text-white btn-44" (click)="menuopen()">
            <i class="bi bi-list"></i>
        </a>
    </div>
    <div class="col text-center">
        <div class="logo-small">
            <img src="assets/img/logo.png" alt="" />
            <h5><span class="text-secondary fw-light">{{"FINANCE_WALLET_01" | translate}}</span><br />{{"FINANCE_WALLET_02" | translate}}</h5>
        </div>
    </div>
    <div class="col-auto">
        <a routerLink="/profile" routerLinkActive="active" class="btn text-white btn-44">
            <i class="bi bi-person-circle"></i>
            <span class="count-indicator"></span>
        </a>
    </div>
</div>
