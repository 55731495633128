<div class="wrapper-review-content mb-4">
  <div class="row mb-2 gx-2">
    <div class="col-auto position-relative">
      <div class="card">
        <div class="card-body p-0">
          <figure class="avatar avatar-50 rounded-circle">
            <img [src]="feedback.userDetails && feedback.userDetails.profile && feedback.userDetails.profile.picture ? feedback.userDetails.profile.picture : 'assets/img/dashboard/avatar.png'" [ngClass]="feedback.userDetails && feedback.userDetails.profile && feedback.userDetails.profile.picture ?'':'avatar-default-profile-img'" alt="" />
          </figure>
        </div>
      </div>
    </div>
    <div class="col">
      <a class="mb-0 text-normal d-block text-color-theme">
        {{feedback.userDetails.profile.firstName}} {{feedback.userDetails.profile.lastName}}
      </a>
      <span class="text-muted size-10">
        {{feedback.updatedAt | date: 'dd/MM/yyyy HH:mm'}}
      </span>
    </div>
  </div>
  <div>
    <p class="mb-2">
      <span class="small">
        <i class="bi text-warning" [ngClass]="feedback.point >= 1 ? 'bi-star-fill' : 'bi-star'"></i>
        <i class="bi text-warning" [ngClass]="feedback.point >= 2 ? 'bi-star-fill' : 'bi-star'"></i>
        <i class="bi text-warning" [ngClass]="feedback.point >= 3 ? 'bi-star-fill' : 'bi-star'"></i>
        <i class="bi text-warning" [ngClass]="feedback.point >= 4 ? 'bi-star-fill' : 'bi-star'"></i>
        <i class="bi text-warning" [ngClass]="feedback.point >= 5 ? 'bi-star-fill' : 'bi-star'"></i>
      </span>
    </p>
    <p class="text-secondary">
      {{feedback.comment}}
    </p>
    <div *ngIf="feedback.images && feedback.images.length" class="d-flex position-relative show-imgs-preview" (click)="singletonService.openGallery(feedback.images)">
      <div *ngIf="feedback.images[0]" class="wrapper-img-preview">
        <img [src]="feedback.images[0]" />
      </div>
      <div *ngIf="feedback.images[1]" class="wrapper-img-preview">
        <img [src]="feedback.images[1]" />
      </div>
      <div *ngIf="feedback.images[2]" class="wrapper-img-preview">
        <img [src]="feedback.images[2]" />
      </div>
      <div *ngIf="feedback.images.length > 3" class="more-image-container d-flex align-items-center px-2">
        <i class="bi bi-image me-1"></i>
        <p class="size-12">+{{feedback.images.length - 3}}</p>
      </div>
    </div>
  </div>
</div>

