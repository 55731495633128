<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{"LANGUAGE" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center">

    <div class="card py-2">
      <div
        class="row px-3 lang-container"
        (click)="setCurrentLanguage('th')"
      >
        <div class="col align-self-center">ไทย</div>
        <div class="col text-end align-self-center">
          <i
            *ngIf="currentLanguage !== 'th'"
            class="bi bi-square size-20 text-checkbox"
          ></i>
          <i
            *ngIf="currentLanguage === 'th'"
            class="bi bi-check-square size-20 text-checkbox"
          ></i>
        </div>
      </div>
      <div class="lang-divider"></div>
      <div
        class="row px-3 lang-container"
        (click)="setCurrentLanguage('en')"
      >
        <div class="col align-self-center">English</div>
        <div class="col text-end align-self-center">
          <i
            *ngIf="currentLanguage !== 'en'"
            class="bi bi-square size-20 text-checkbox"
          ></i>
          <i
            *ngIf="currentLanguage === 'en'"
            class="bi bi-check-square size-20 text-checkbox"
          ></i>
        </div>
      </div>
    </div>

  </div>

</div>

