<main class="main-content" #mainPage>
  <div class="main-container container px-0">
    <div class="clearfix"></div>
    <div [@routerTransition]="getRouteAnimationState()">
      <router-outlet></router-outlet>
    </div>
    <div class="clearfix"></div>
  </div>
</main>
<staticfooter class="footer" #FooterEl></staticfooter>

<modal-moremenu></modal-moremenu>
