/* eslint-disable */

import { QuestionnaireComponent } from './../../@components/questionnaire/questionnaire.component';
import { QrCodeModalComponent } from './../../@components/qr-code-modal/qr-code-modal.component';
import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OtpCheckingComponent } from 'src/app/@components/otp-checking/otp-checking.component';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { FormService } from 'src/app/@services/form.service';
import { Logger } from 'src/app/@services/logger.service';
import { UserService } from 'src/app/@services/user.service';
import { ModalSignupUserinfoComponent } from 'src/app/@components/modal-signup-userinfo/modal-signup-userinfo.component';
import { environment } from 'src/environments/environment';
import { UserWithToken } from 'src/models/authToken.model';
import { ForgotPasswordComponent } from 'src/app/@components/forgot-password/forgot-password.component';
const log = new Logger('SigninComponent');
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  @Input() transferEmail: any;

  loginForm: UntypedFormGroup;
  submitting = false;
  error = '';
  formInvalid = {
    emailOrPhone: '',
    password: '',
  };

  isSupportBrowser: boolean = false;
  isInAppBrowser: boolean;

  public passwordTextType: boolean = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _errorHandlerService: ErrorHandlerService,
    private _socialAuthService: SocialAuthService,
    private _userService: UserService,
    public formService: FormService,
    private modalService: NgbModal,
    private _modalService: NgbModal,
    private _ngZone: NgZone,
    private singletonService: SingletonService,
    private router: Router
  ) {
    this.loginForm = this._formBuilder.group({
      emailOrPhone: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  backnav() {
    this.singletonService.back();
  }

  home() {
    this.router.navigate(['/']);
  }

  ngOnInit(): void {
    // const returnurl = this._router.url
    // log.debug('signIn returnurl =>', returnurl);

    this.tooltipSetup();

    this.checkPlatformBrowser();
    this.transferEmail = this._route.snapshot.queryParams['transferEmail'];

    if (this.transferEmail != '' && this.transferEmail != null) {
      this.loginForm.get('emailOrPhone')?.setValue(this.transferEmail);
    }

    // const modalRef = this._modalService.open(ModalSignupUserinfoComponent, {
    //   centered: true,
    //   size: 'xl',
    //   scrollable: true,
    // });
  }

  ngAfterViewInit() {
    // this.googleSetup();
    try {
      this.googleSetup();
    } catch (error) {
      log.info(`error: googleSetup()`);
      log.debug(error);
    }
  }

  checkPlatformBrowser() {
    this.isInAppBrowser = this.singletonService.isInAppฺBrowser();
    this.isSupportBrowser = this.singletonService.isPlatformBrowser();
  }

  openInSystemBrowser() {
    window.open(this.router.url, '_system');
  }

  tooltipSetup() {
    const tooltiptriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltiptriggerList.map(function (e: any) {
      return new bootstrap.Tooltip(e);
    });
  }

  googleSetup() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: environment.googleClientID,
      callback: this.signInWithGoogle.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });

    log.debug(`init google`);
    // @ts-ignore
    google.accounts.id.renderButton(document.getElementById('google-button'), {
      theme: 'outline',
      size: 'large',
      shape: 'circle',
      type: 'icon',
    });

    log.debug(`render button`);
  }

  onLoginFormChange() {
    this.loginForm.valueChanges.subscribe(() => {
      this.formInvalid = { emailOrPhone: '', password: '' };
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.error = '';
    if (!!this.f.emailOrPhone.errors) {
      this.formInvalid.emailOrPhone = 'Enter invalid email or phone';
      return;
    }
    if (!!this.f.password.errors) {
      this.formInvalid.password = 'Enter invalid Password';
      return;
    }
    this.submitting = true;

    // this._authenticationService.login(
    //   this.f.email.value,
    //   this.f.password.value
    // );

    let data = {
      username: this.f.emailOrPhone.value,
      password: this.f.password.value,
    };
    const res: any = this._authenticationService
      .login(data.username, data.password)
      .then(async (res: any) => {
        if (res) {
          log.debug('signIn res =>', res);
          // this.loading = false;
          let userInfo = res.user;

          if (userInfo && userInfo.registerType == 'mobilePhone') {
            if (userInfo.withMobilePhone.isVerified === true) {
              if (userInfo.profile.isAgreement === true) {
                // let surveyChecking: any =
                //   await this._userService.getSurveyChecking();
                // if (surveyChecking.totalResults > 0) {
                this.modalService.dismissAll();
                const modalRef = this.modalService.open(QrCodeModalComponent, {
                  centered: true,
                  backdrop: 'static',
                  scrollable: true,
                });
                // } else {
                //   const modalRef = this.modalService.open(
                //     QuestionnaireComponent,
                //     {
                //       centered: true,
                //       backdrop: 'static',
                //       size: 'xl',
                //       scrollable: true,
                //     }
                //   );
                // }
              } else {
                this.modalService.dismissAll();
                const modalRef = this.modalService.open(
                  ModalSignupUserinfoComponent,
                  {
                    centered: true,
                    backdrop: 'static',
                    size: 'xl',
                    scrollable: true,
                  }
                );
                modalRef.componentInstance.userInfo = userInfo;
              }
            } else {
              this.modalService.dismissAll();
              let modalRef = this.modalService.open(OtpCheckingComponent, {
                centered: true,
                backdrop: 'static',
              });
              modalRef.componentInstance.typeLogin = userInfo.registerType;
              modalRef.componentInstance.user = userInfo;
            }
          }
          if (userInfo && userInfo.registerType == 'email') {
            if (userInfo.withEmail.isVerified === true) {
              if (userInfo.profile.isAgreement === true) {
                // let surveyChecking: any =
                //   await this._userService.getSurveyChecking();
                // if (surveyChecking.totalResults > 0) {
                this.modalService.dismissAll();
                const modalRef = this.modalService.open(QrCodeModalComponent, {
                  centered: true,
                  backdrop: 'static',
                  scrollable: true,
                });
                // } else {
                //   const modalRef = this.modalService.open(
                //     QuestionnaireComponent,
                //     {
                //       centered: true,
                //       backdrop: 'static',
                //       size: 'xl',
                //       scrollable: true,
                //     }
                //   );
                // }
              } else {
                this.modalService.dismissAll();
                const modalRef = this.modalService.open(
                  ModalSignupUserinfoComponent,
                  {
                    centered: true,
                    backdrop: 'static',
                    size: 'xl',
                    scrollable: true,
                  }
                );
                modalRef.componentInstance.userInfo = userInfo;
              }
            } else {
              this.modalService.dismissAll();
              let modalRef = this.modalService.open(OtpCheckingComponent, {
                centered: true,
                backdrop: 'static',
              });
              modalRef.componentInstance.typeLogin = userInfo.registerType;
              modalRef.componentInstance.user = userInfo;
            }
          }
        }
      })
      .catch((error: any) => {
        const _error = this._errorHandlerService.error(error).message;
        log.debug('_error => %o', _error);
        this.error = _error;
        this.submitting = false;
      });
  }

  async signInWithFacebook() {
    try {
      this.error = '';
      this.submitting = true;
      const user = await this._socialAuthService.signIn(
        FacebookLoginProvider.PROVIDER_ID
      );
      //   {
      //     "id": "3022994027834245",
      //     "name": "Ikhalas Mannoon",
      //     "photoUrl": "https://graph.facebook.com/3022994027834245/picture?type=normal",
      //     "firstName": "Ikhalas",
      //     "lastName": "Mannoon",
      //     "authToken": "EAACvcKZC39EoBAIQWf35IviX5LiZAZBN3nHalDLUdhvTd87jGZALRkHJQ3DHLu7ogGMLUt1tXuBGknNYzrTd6tmpSsBQIZCjYpakmeutUUSPllD4CncGVtbt5lbSYcQdwoy1BHfMOoaYrQhJDhQpVeeNbXZCvD4N3iBqvMOoS8leHW5XMlsMZCdXXsP73x8FYkFuNp9DUXBflpLQf5IWSn1",
      //     "response": {
      //         "name": "Ikhalas Mannoon",
      //         "picture": {
      //             "data": {
      //                 "height": 50,
      //                 "is_silhouette": false,
      //                 "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3022994027834245&height=50&width=50&ext=1681981697&hash=AeRqDgkkI4-l3wuA_wg",
      //                 "width": 50
      //             }
      //         },
      //         "first_name": "Ikhalas",
      //         "last_name": "Mannoon",
      //         "id": "3022994027834245"
      //     },
      //     "provider": "FACEBOOK"
      // }

      const bodyPayload = {
        accessToken: user.authToken,
      };
      const response = await this._authenticationService.facebookFetcher(
        bodyPayload
      );
      this.socailAuthenticationSetup(response);
    } catch (error: any) {
      const _error = this._errorHandlerService.error(error).message;
      log.debug('_error => %o', _error);
      this.error = _error;
    } finally {
      this.submitting = false;
    }
  }

  async signInWithGoogle(user: GoogleAuthUser) {
    //   {
    //     "clientId": "912232120931-0hjmaebm41apo2lq6jv8a5hdlu0cnr6b.apps.googleusercontent.com",
    //     "client_id": "912232120931-0hjmaebm41apo2lq6jv8a5hdlu0cnr6b.apps.googleusercontent.com",
    //     "credential": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk4NmVlOWEzYjc1MjBiNDk0ZGY1NGZlMzJlM2U1YzRjYTY4NWM4OWQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NzkzOTc0MTgsImF1ZCI6IjkxMjIzMjEyMDkzMS0waGptYWVibTQxYXBvMmxxNmp2OGE1aGRsdTBjbnI2Yi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTU0NjM0MDY5MjUyMDExNDg0NyIsImVtYWlsIjoiaWtoYWxhcy53b3JrQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhenAiOiI5MTIyMzIxMjA5MzEtMGhqbWFlYm00MWFwbzJscTZqdjhhNWhkbHUwY25yNmIuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJuYW1lIjoiSWtoYWxhcyBNYW5ub29uIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FHTm15eFpHa1RtY2lxZGZ2dlhYOTRDNmdFQjJWcTlJTEw5MXg2UkJ2Uk5aPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IklraGFsYXMiLCJmYW1pbHlfbmFtZSI6Ik1hbm5vb24iLCJpYXQiOjE2NzkzOTc3MTgsImV4cCI6MTY3OTQwMTMxOCwianRpIjoiNDk0OTY4MGIwM2FkNDA2YWMwMWQ3YTEzMWE2ODI5OGUxYjVlZTcxOSJ9.mj4xpYUhIddTyLuYhaKQdFT2VY42P9I0mXL7cXicrJPtlT7vMBwgVvPbzy0s281EmKRBcK4jnWFgo12ZPTqgtN9SP4zr-qExebdTCDd_dRuqjRf1_2gtMr4PzKsv3DtWeuHbHVW-JutxtwvndDlyQMwnQy02eCfuK3XLih2tmsNU2dRawpQBCxKL1igLZEz6ZTn378R2dI2NpunFpzoXdwU_05ZT1BDETyg1QTI0tHFmYo57ogLSgswAz2Mm8qDyQSMtEpj9DSSXIdYRikPdbk1x9bRFNzEjWm45agAJTAUc5jmUaDiUp0Ej5VYRGblothYPj4DMzqJBEChbLvrHBA",
    //     "select_by": "btn"
    // }
    try {
      this.error = '';
      this.submitting = true;
      const bodyPayload = {
        idToken: user.credential,
      };
      const response = await this._authenticationService.googleFetcher(
        bodyPayload
      );
      await this.socailAuthenticationSetup(response);
    } catch (error: any) {
      log.debug('_error => %o', error);
      this._errorHandlerService.error(error, true);
    } finally {
      this.submitting = false;
    }
  }

  async socailAuthenticationSetup(userWithToken: UserWithToken) {
    try {
      await this._authenticationService.doAfterLogin(userWithToken);
      const {
        user: { profile },
      } = userWithToken;

      const servayStatus =
        await this._authenticationService.checkSurveyStatus();

      if (!profile.isAgreement || !profile.isPrivacyPolicy) {
        this._ngZone.run(() => {
          const modalRef = this._modalService.open(
            ModalSignupUserinfoComponent,
            {
              centered: true,
              size: 'xl',
              scrollable: true,
            }
          );
          modalRef.componentInstance.userInfo = userWithToken.user;
        });
        return;
      }

      this._modalService.dismissAll();
      if (!servayStatus.results.length) {
        log.debug('xx GO TO SURVAY MODAL');
        this._ngZone.run(() => {
          this.modalService.open(QuestionnaireComponent, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
            scrollable: true,
          });
        });
        log.debug('yyyyy');
      } else {
        log.debug('xxx GO TO QRCODE MODAL');
        this._ngZone.run(() => {
          this.modalService.open(QrCodeModalComponent, {
            centered: true,
            backdrop: 'static',
            scrollable: true,
          });
        });
      }
    } catch (error: any) {
      log.debug('socailAuthenticationSetup error: %o', error);

      const _error = this._errorHandlerService.error(error).message;
      log.debug('_error => %o', _error);
      this.error = _error;
    }
  }

  openForgotPasswordModal() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ForgotPasswordComponent, {
      centered: true,
      backdrop: 'static',
    });
  }
}

type GoogleAuthUser = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};
