<div class="wrapper-100vw">
  <div class="container">
    <ngx-loading
      [show]="submitting"
      [config]="{ fullScreenBackdrop: true }"
    ></ngx-loading>

    <div class="row pt-3">
      <div class="col-2 align-self-center">
        <button type="button" class="btn text-white btn-44" (click)="backnav()">
          <i class="bi bi-arrow-left"></i>
        </button>
      </div>
      <div class="col align-self-center text-center px-0">
        <h6 class="title size-16">
          <div *ngIf="isPinned; then isTrue; else isFalse"></div>
          <ng-template #isTrue>{{ "CHANGE_PIN" | translate }}</ng-template>
          <ng-template #isFalse>{{ "SET_PIN" | translate }}</ng-template>
        </h6>
      </div>
      <div class="col-2 align-self-center"></div>
    </div>

    <div class="alert alert-danger my-3" role="alert" *ngIf="!!error">
      {{ error }}
    </div>

    <div *ngIf="changeMode; then changeModeLayout; else createModeLayout"></div>
    <ng-template #changeModeLayout>
      <div class="mt-5 mb-3 text-center">
        <h4>{{ "ENTER_OLD_PIN" | translate }}</h4>
      </div>
      <pincode-numpad
        (onChange)="onVerifyPincode($event)"
        [clearSubject]="clearPincode"
      ></pincode-numpad>
    </ng-template>

    <ng-template #createModeLayout>
      <div class="mt-5 text-center">
        <h4 *ngIf="firstPincode.length !== 6">
          {{ "ENTER_YOUR_PINCODE" | translate }}
        </h4>
        <h4 *ngIf="firstPincode.length === 6">
          {{ "VERIFY_YOUR_PINCODE" | translate }}
        </h4>
      </div>
      <pincode-numpad
        (onChange)="onSetChange($event)"
        [clearSubject]="clearPincode"
      ></pincode-numpad>
    </ng-template>
  </div>
</div>
