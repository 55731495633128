<div class="row h-100">
    <div class="col-11 col-sm-11 mx-auto">
        <!-- header -->
        <div class="row">
            <header class="header">
                <div class="row">
                    <div class="col">
                        <div class="logo-small">
                            <img src="assets/img/logo.png" alt="" />
                            <h5><span class="text-secondary fw-light">{{"FINANCE_WALLET_01" | translate}}</span><br />{{"FINANCE_WALLET_02" | translate}}</h5>
                        </div>
                    </div>
                    <div class="col-auto align-self-center">
                        <a routerLink="/signin">{{"SIGN_IN" | translate}}</a>
                    </div>
                </div>
            </header>
        </div>
        <!-- header ends -->
    </div>
    <div class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
        <h1 class="mb-3"><span class="text-secondary fw-light">{{"CHANGE_YOUR_PASSWORD_01" | translate}}</span><br/>{{"CHANGE_YOUR_PASSWORD_02" | translate}}</h1>
        <p class="text-secondary mb-4">{{"THANK_FOR_CONFIRM_EMAIL" | translate}}</p>

        <div class="form-group form-floating mb-3 is-valid">
            <input type="password" class="form-control" value="maxartkiller" id="passowrd1" placeholder="New Password">
            <label class="form-control-label" for="passowrd1">{{"NEW_PASSWORD" | translate}}</label>
        </div>

        <div class="form-group form-floating is-invalid mb-3">
            <input type="password" class="form-control " id="password" placeholder="Confirm Password">
            <label class="form-control-label" for="password">{{"CONFIRM_PASSWORD" | translate}}</label>
            <button type="button" class="text-danger tooltip-btn" data-bs-toggle="tooltip"
                data-bs-placement="left" title="Enter valid Password" id="passworderror">
                <i class="bi bi-info-circle"></i>
            </button>
        </div>
    </div>
    <div class="col-11 col-sm-11 mt-auto mx-auto py-4">
        <div class="row ">
            <div class="col-12 d-grid">
                <a routerLink="/thankyou" class="btn btn-default btn-lg">{{"UPDATE" | translate}}</a>
            </div>
        </div>
    </div>
</div>
