import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
/* eslint-disable */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Logger } from '../@services/logger.service';
import { AuthenticationService } from '../@services/authentication.service';
import { TokenStorageService } from '../@services/token-storage.service';

const log = new Logger('ErrorInterceptor'); // log.debug

// import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    // private singletonService:SingletonService,
    private _authenticationService: AuthenticationService,
    private _tokenStorageService: TokenStorageService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Node.js Express back-end */
    log.debug(`clone request and retry %o`, request.url);
    return request.clone({
      headers: request.headers.set('Authorization', `${token}`),
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        log.debug('[ErrorInterceptor] err %o', err);

        if ([401, 403].indexOf(err.status) !== -1) {
          let account = this._authenticationService.currentUserSubject.value;
          if (account) {
            const refreshToken = this._tokenStorageService.getRefreshToken();
            log.debug('old refreshToken %o', refreshToken);

            this._authenticationService
              .refreshTokenPromise(refreshToken)
              .then((tokens: any) => {
                log.debug('new tokens %o', tokens);
                if (!tokens) {
                  this._authenticationService.logout();
                  this._router.navigate(['/']);
                  return true;
                } else {
                  log.debug(`success call refresh-token`);
                  location.reload();
                  return true;
                  // return next.handle(this.addTokenHeader(request, tokens.access.token));
                }
              });
          } else {
            const pathname: any = window.location.pathname;
            log.debug('Not found logged in account pathname: %o', pathname);

            // if(err.url.indexOf('/g/i/consent') !== -1){ // flow consent
            //   this.errorHandlerService.goSignIn(true)
            // }else{
            if (pathname == '/') {
              // do nothing
              log.debug('do nothing');
            } else if (pathname.startsWith('/quest-hunting')) {
              log.debug('-- paths matched!');
              this.errorHandlerService.goSignIn(true);
            } else if (
              pathname == '/' ||
              ['/home', '/dashboard', '/signin', '/signup'].indexOf(pathname) <
                0
            ) {
              // paths not match
              log.debug('paths not match in list');
              this.errorHandlerService.goSignIn();
            } else {
              // do nothing
              log.debug('do nothing');
            }
            // }
          }
        } else if ([404].indexOf(err.status) !== -1) {
          this._router.navigate(['/pagenotfound']);
        } else {
          const pathname: any = window.location.pathname;
          if (pathname.startsWith('/paperwallet')) {
            this.errorHandlerService.error(err, true);
            this._router.navigate(['/signin']);
          }
        }

        return throwError(err);
      })
    );
  }
}
