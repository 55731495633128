<div class="wrapper-100vw">
  <div class="wrapper-top-header mt-0 pb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center px-0">
          <button
            type="button"
            class="btn text-white back-btn ps-0"
            (click)="backnav()"
          >
            <i class="bi bi-chevron-left"></i>
          </button>
        </div>
        <div class="col align-self-center px-1">
          <div class="text-center">
            <h6 class="title size-16">
              {{"REVIEW_BY_CUSTOMER" | translate}}
            </h6>
            <span class="text-secondary" *ngIf="totalResults !== undefined">
              ({{ "TOTAL_RESULTS" | translate }}: {{totalResults}})
            </span>
          </div>
        </div>
        <div class="col-auto align-self-center px-0">
          <button
            class="btn text-white filter-btn pe-0"
            (click)="openModalReview()"
          >
            <i class="bi bi-plus-lg"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>

    <!-- <div *ngIf="currentUser" class="card my-3">
      <div class="card-body py-4 px-3 text-center">
        <figure class="avatar avatar-60 rounded-15">
          <img [src]="currentUser.profile ? currentUser.profile.picture : 'assets/img/user2.jpg'" alt="" />
        </figure>

        <h4 class="mt-4 mb-3">REVIEW EXHIBITOR</h4>

        <span
          class="size-24"
          (click)="openModalReview()"
        >
          <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
          <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
          <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
          <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
          <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
        </span>
      </div>
    </div> -->

    <div class="container">
      <div *ngIf="!isLoading" class="px-3">
        <card-exhibitor-review
          *ngFor="let feedback of exhibitorFeedbackList"
          [feedback]="feedback">
        </card-exhibitor-review>

        <div
          class="search-results"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        ></div>
      </div>
      <div class="text-center py-5 empty-wrapper" *ngIf="isLoading">
        {{"LOADING" | translate}}...
      </div>
    </div>

  </div>

</div>

