<div class="card custom-card mb-3 overflow-hidden text-white">
  <div class="card-body custom-card-body">
    <div class="d-flex gap-3 h-100">

      <div class="d-flex flex-column justify-content-around">
        <div class="img-wrapper">
          <img [src]="(exhibitor.coverImage ? exhibitor.coverImage : 'assets/img/cover/mock-image.png')" alt="cover image" />
        </div>
        <div class="text-uppercase text-white bg-pink size-12 text-center rounded-20">
          {{exhibitor.category.title}}
        </div>
      </div>

      <div class="wrapper-info-exhibitor d-flex justify-content-between flex-column">
        <div class="">
          <div class="text-white size-16 fw-bold lh-sm text-2-line mb-1" style="max-height: 40px;">{{exhibitor.name}}</div>
          <div class="size-14 text-secondary text-truncate">
            {{exhibitor.feedbackCount}}
            {{ "REVIEW" | translate }}
          </div>
        </div>
        <div class="text-secondary lh-sm size-14 text-3-line mt-2 mb-auto">
          {{exhibitor.description}}
        </div>
      </div>
    </div>

  </div>
</div>
