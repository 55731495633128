import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSignupUserinfoComponent } from 'src/app/@components/modal-signup-userinfo/modal-signup-userinfo.component';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('PaperwalletComponent');

@Component({
  selector: 'app-paperwallet',
  templateUrl: './paperwallet.component.html',
  styleUrls: ['./paperwallet.component.scss'],
})
export class PaperwalletComponent {
  code = '';
  checking = true;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private backendService: BackendService
  ) {}

  async ngOnInit() {
    this.code = this.route.snapshot.paramMap.get('code')!;
    const checked = await this.isValidCode();
    log.debug(this.code);
    if (!!this.code && !!checked) {
      this.openSignUpModal();
    }
  }

  async isValidCode() {
    this.checking = true;
    const checked = this.backendService.checkPaperWalletCode(this.code);
    this.checking = false;
    return checked;
  }

  // backnav() {
  //   this.singletonService.back();
  // }

  // onDecodeQrCode(code: string) {
  //   log.debug(code);
  //   this.code = code;
  //   this.checkTempCode();
  // }

  // async checkTempCode() {
  //   try {
  //     this.checking = true;
  //     const checked = await this.authenticationService.checkPaperWalletCode(
  //       this.code
  //     );

  //     if (checked) {
  //       this.openSetPasswordModal();
  //     }
  //   } catch (error) {
  //     this.errorHandlerService.error(error, true);
  //   } finally {
  //     this.checking = false;
  //   }
  // }

  openSignUpModal() {
    const modalRef = this.modalService.open(ModalSignupUserinfoComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.paperWalletCode = this.code;
  }
}
