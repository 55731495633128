import { SingletonService } from 'src/app/@services/singleton.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Logger } from 'src/app/@services/logger.service';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import Swal from 'sweetalert2';

const log = new Logger('WallWheelSpinnerComponent');

@Component({
  selector: 'app-wall-wheel-spinner',
  templateUrl: './wall-wheel-spinner.component.html',
  styleUrls: ['./wall-wheel-spinner.component.scss']
})
export class WallWheelSpinnerComponent implements OnInit {

  customActionCode: any;
  wheelInfos: any = [];

  isLoading = false;
  isSpinLoading = false;

  // labels
  congratulationsText = 'Congratulations';
  youHaveGotText = 'You have got';
  closeText = 'Close';
  viewRewardText = 'View reward';

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _backendService: BackendService,
    private singletonService: SingletonService,
    private _langService: TranslateConfigService,
    private _errorHandlerService: ErrorHandlerService,
    private _translator: TranslateService,
  ) {
    this.customActionCode = this.route.snapshot.paramMap.get('customActionCode')!;
  }


  async ngOnInit() {

    await this.reloadData()

  }

  async reloadData(){
    this.isLoading = true
    log.debug("doCustomAction this.customActionCode => %o",this.customActionCode)

    try{
      this.wheelInfos = await this._backendService.getWallWheelInfos(this.customActionCode)
      log.debug("doCustomAction this.wheelInfos => %o",this.wheelInfos)
      this.isLoading = false
    } catch (err) {
      log.debug("getWallWheelInfos err => %o",err)
      const error = this._errorHandlerService.error(err,true);
      // this._router.navigate(['/']);
    } finally {
      this.isLoading = false;
    }


  }

  async startSpin(wheelInfo:any){
    this.isSpinLoading = true

    try {
      const res:any = await this._backendService.spinWallWheel(this.customActionCode,wheelInfo.wheel.id)
      if(res){
        this.isSpinLoading = false;
        const reward:any = res.rewardRef
        await this.singletonService.processingSwal('กำลังหมุนวงล้อ','กรุณารอสักครู่')
        setTimeout(() => {
          Swal.close();
          Swal.fire({
            title: this.congratulationsText,
            text: this.youHaveGotText + ' ' + reward.title,
            imageUrl: reward.images.length ? reward.images[0] : '' ,
            imageHeight: 100,
            imageAlt: '',
            confirmButtonText: this.viewRewardText,
            // cancelButtonText: this.closeText,
            // showCancelButton: true,
            showConfirmButton: true,
            reverseButtons: true,
            customClass: {
              container: 'spin-the-wheel',
            },
            didOpen: () => {
              // this.showConfetti();
            },
          }).then(async (result) => {
              // this._router.navigate(['/mycouponreward']);
              this._router.navigate(['/mycouponreward'], {
                queryParams: { returnurl: '/' },
              });
          });

        }, 15000);
      }else{
      this.isSpinLoading = false;
      }
    } catch (error) {
      this._errorHandlerService.error(error);
      this.isSpinLoading = false;
    }

  }

  backnav() {
    this._router.navigate(['/']);
  }

}
