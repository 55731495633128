<div class="wrapper-100vw">
  <div class="wrapper-top-header mt-0 pb-3 mb-0">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="back()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-0">
          <h6 class="title size-16">{{ nft ? nft.name : "" }}</h6>
        </div>
        <div class="col-auto align-self-center"></div>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="text-center">
    <div class="text-center my-5 py-3 empty-wrapper">
      <div class="spinner-border"></div>
      <p class="text-center fw-normal size-14 mt-3">
        {{ "LOADING" | translate }}
      </p>
    </div>
  </div>

  <ng-container *ngIf="!isLoading">
    <img [src]="nft.logo" alt="" class="w-100 coverimg" />
    <div class="container mt-4">
      <div class="row">
        <div class="col-6">
          <h6>{{ "INFORMATION" | translate }}</h6>
          <h5 class="mt-2">{{ nft.name }}</h5>
        </div>
        <div class="col-6 text-end">
          <h6>{{ "AMOUNT" | translate }}</h6>
          <h5 class="mt-2">{{ amount }}</h5>
        </div>
        <div class="col-6 text-center mt-5">
          <div
            (click)="singletonService.downloadMediaFromUrl(nft.logo, nft.name)"
            class=""
          >
            <img
              src="assets/img/icon/icon-actions/download.png"
              alt="download"
            />
            <div class="mt-4">{{ "DOWNLOAD" | translate }}</div>
          </div>
        </div>
        <div class="col-6 text-center mt-5">
          <div (click)="singletonService.openModalSocialSharing()" class="">
            <img src="assets/img/icon/icon-actions/share.png" alt="share" />
            <div class="mt-4">{{ "SHARE" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
