import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { TranslateConfigService } from './translate-config.service';
const log = new Logger('UserService');
@Injectable({
  providedIn: 'root',
})
export class UserService {
  // API_URL:any = 'http://localhost:3000/v1'

  auth_token: any;

  // const header = new HttpHeaders()
  //   .set('Content-Type': 'application/json')
  //   .set('Authorization': `Bearer ${auth_token}`)

  constructor(
    private _http: HttpClient,
    private _translateConfigService: TranslateConfigService
  ) {}

  usersignIn(data: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/login`;
    return this._http.post(url, data).toPromise();
  }

  usersignUp(data: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/signUp`;
    return this._http.post(url, data).toPromise();
  }

  changePassword(data: any) {
    const url = `${environment.apiUrl}/wallet-server/users/change-password`;
    return this._http.post(url, data).toPromise();
  }

  updateProfile(data: any) {
    const url = `${environment.apiUrl}/wallet-server/users/me/update-profiles`;
    return this._http.patch(url, data).toPromise();
  }

  usersignUpwithData(dataInfo: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/signUp-with-data`;
    return this._http.post(url, dataInfo).toPromise();
  }

  requestMobileOtp(body: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/request-otp`;
    return this._http.post(url, body).toPromise();
  }

  verifyMobileOtp(code: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/verify-otp`;
    return this._http.post(url, code).toPromise();
  }

  requestEmailOtp(body: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/send-verification-email-code`;
    return this._http.post(url, body).toPromise();
  }

  verifyEmailOtp(code: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/verify-email-code`;
    return this._http.post(url, code).toPromise();
  }

  googleSignup(data: any): Promise<any> {
    const url = `${environment.apiUrl}/wallet-server/auth/google-fetcher/`;
    return this._http
      .post(url, data)
      .toPromise()
      .then((res) => res);
  }

  facebookSignup(data: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/facebook-fetcher`;
    return this._http.post(url, data).toPromise();
  }

  forgotPassword(data: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/send-forgot-password-email-code`;
    log.debug('forgotPassword url: %o', url);
    log.debug('forgotPassword data: %o', data);

    return this._http.post(url, data).toPromise();
  }

  forgotPasswordWithUsername(data: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/send-forgot-password-code`;
    log.debug('forgotPassword url: %o', url);
    log.debug('forgotPassword data: %o', data);

    return this._http.post(url, data).toPromise();
  }

  forgotPasswordConfirmWithEmail(data: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/verify-forgot-password-email-code`;
    log.debug('forgotPasswordConfirmWithEmail url: %o', url);
    log.debug('forgotPasswordConfirmWithEmail data: %o', data);
    return this._http.post(url, data).toPromise();
  }

  forgotPasswordConfirmWithMobilePhone(data: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/verify-forgot-password-mobile-phone-code`;
    log.debug('forgotPasswordConfirmWithMobilePhone url: %o', url);
    log.debug('forgotPasswordConfirmWithMobilePhone data: %o', data);
    return this._http.post(url, data).toPromise();
  }

  lineLogin(data: any) {
    const url = `https://access.line.me/oauth2/v2.1/authorize/consent?response_type=code&client_id=1660752493&redirect_uri=http://localhost:4200/&state=12345abcde&scope=profile+openid+email&nonce=09876xyz`;
    return this._http
      .post(
        url,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .toPromise();
  }

  listQuestion() {
    let url = `${environment.apiUrl}/platform-event/event-questions?eventCode=event-01&sortBy=sequence:asc`;

    const currentLanguage = this._translateConfigService.getCurrentLanguage()!;
    if (currentLanguage) url += `&lang=${currentLanguage}`;

    log.debug('getSurveyChecking url: %o', url);

    return this._http.get(url).toPromise();
  }

  sendSurvey(data: any) {
    const url = `${environment.apiUrl}/platform-event/event-questionnaires/`;
    return this._http.post(url, data).toPromise();
  }

  requestQrCode(code: any) {
    const url = `${environment.apiUrl}/platform-event/event-tickets/request-event-ticket`;
    return this._http.post(url, code).toPromise();
  }

  checkSurvey(code: any) {
    const url = `${environment.apiUrl}/platform-event/event-tickets/my-tickets?eventCode=event-01`;
    return this._http.get(url).toPromise();
  }

  getSurveyChecking() {
    let url = `${environment.apiUrl}/platform-event/event-questionnaires/my-questionnaires?eventCode=event-01`;
    return this._http.get(url).toPromise();
  }
}
