<ngx-loading
  [show]="submitting"
  [config]="{ fullScreenBackdrop: true }"
></ngx-loading>

<div class="modal-header border-0 pb-0 pt-4 mt-2 justify-content-center">
  <div class="fw-bold size-20" *ngIf="firstPincode.length !== 6">
    {{ "SET_PIN" | translate }}
  </div>
  <div *ngIf="firstPincode.length === 6">{{ "CONFIRM_PIN" | translate }}</div>
  <button
    type="button"
    class="btn-close btn-close-white position-absolute top-0 end-0 mt-3 me-3"
    aria-label="Close"
    (click)="modalService.dismissAll()"
  ></button>
</div>
<div class="modal-body px-4">
  <div class="d-flex flex-column align-items-center">
    <ng-container *ngIf="done">
      {{ "ALERT_SET_PIN" | translate }}
      <div class="my-4 w-100">
        <button
          type="submit"
          class="btn btn-default btn-lg w-100"
          (click)="close()"
        >
          {{ "OK" | translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!done">
      <div class="alert alert-danger my-3 w-100" role="alert" *ngIf="!!error">
        {{ error }}
      </div>
      <pincode-numpad
        (onChange)="onChange($event)"
        [clearSubject]="clearPincode"
      ></pincode-numpad>
      <div class="my-4 w-100">
        <button
          type="submit"
          class="btn btn-default btn-lg w-100 text-center btn-confirm-modal"
          (click)="submit()"
          [disabled]="currentPincode.length !== 6 || submitting"
        >
          {{ "CONFIRM" | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
