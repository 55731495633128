<div class="wrapper-100vw">
  <div class="container">
    <ngx-loading [show]="loading"></ngx-loading>

    <div class="row pt-3">
      <div class="col-3 align-self-center">
        <button
          type="button"
          class="btn text-white btn-44 back-btn"
          (click)="backnav()"
        >
          <i class="bi bi-arrow-left"></i>
        </button>
      </div>
      <div class="col align-self-center text-center px-0">
        <h6 class="title size-16">{{"MY_QR_CODE_TICKET" | translate}}</h6>
      </div>
      <div class="col-3 align-self-center"></div>
    </div>

    <ng-container *ngIf="!loading">
      <div class="section">
        <div class="container pt-3 px-2">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section">
                <div class="ticket-container">
                  <div class="ticket-header">
                    <h6 class="title size-16">BRING THE FUTURE LIVING</h6>
                  </div>
                  <div class="pt-4 pb-2">
                    <p class="size-12 text-secondary pb-2">
                      {{"SCAN_THIS_QRCODE" | translate}}
                    </p>
                    <div class="wrapper-qr-code">
                      <div>
                        <img [src]="userQrCode.qrCode" class="qr-code-img" width="250px" />
                      </div>
                    </div>
                  </div>
                  <div class="wrapper-btn-actions gap-4 mt-3 mb-4 pb-2 d-flex justify-content-center">
                    <!-- <a (click)="singletonService.downloadMediaFromUrl(qrUrl)" class="btn-action">
                      <img src="assets/img/icon/icon-actions/download.png" alt="download" />
                    </a> -->
                    <a (click)="singletonService.downloadMediaFromUrl(userQrCode.qrCode)" class="btn-action btn-purple">
                      <span class="size-14 me-2">
                        {{"DOWNLOAD_QRCODE" | translate}}
                      </span>
                      <img src="assets/img/icon/icon-actions/download.png" alt="download" />
                    </a>
                  </div>

                  <span class="section dots">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>

                  <div class="pt-5 pb-4 text-start ps-4">
                    <h6 class="title size-16 mb-1">
                      {{ userQrCode.event.location }}
                    </h6>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-calendar2-week text-secondary me-1"></i>
                      <p class="size-12 text-secondary mb-0 ms-1">
                        {{ userQrCode.event.openDate | date : "dd" }} -
                        {{ userQrCode.event.closeDate | date : "dd MMMM YYYY" }}
                      </p>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-clock text-secondary me-1"></i>
                      <p class="size-12 text-secondary mb-0 ms-1">
                        {{ userQrCode.event.openTime }} -
                        {{ userQrCode.event.closeTime }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

</div>
