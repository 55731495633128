<div class="container">
  <div class="row h-100 row-mh-100">
    <div class="col-auto align-self-center">
      <button type="button" class="btn text-white btn-44" (click)="backnav()">
        <i class="bi bi-arrow-left"></i>
      </button>
    </div>
    <div class="col-12 px-0 mx-auto mt-5 position-relative align-items-center justify-content-center d-flex">
      <div class="text-center">
        <img src="assets/img/auth/auth-img.png" alt="auth image" class="w-100 mx-auto auth-img" />
        <h1 class="text-center text-white fw-bold size-32 mb-3">LINX WALLET</h1>
        <p class="text-center text-secondary size-16 px-4">
          {{"LET_GET_START" | translate}}
        </p>
      </div>
    </div>

    <div class="col-12 mt-auto mx-auto py-5">
        <div class="row wrapper-actions-button">
            <div class="col-6 text-end d-grid">
                <a routerLink="/signup" class="btn btn-outline-main btn-lg">{{"SIGN_UP" | translate}}</a>
            </div>
            <div class="col-6 d-grid">
                <a (click)="jumpToSignIn()" class="btn btn-default btn-lg">{{"SIGN_IN" | translate}}</a>
            </div>
        </div>
    </div>
  </div>
</div>
