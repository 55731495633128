<div class="container">
  <div class="row h-100" style="min-height: 100vh;">
    <!-- <div class="col-11 col-sm-11 mx-auto">
      <div class="row">
        <header class="header">
          <div class="row">
            <div class="col px-0">
              <div class="logo-small">
                <img
                  src="assets/img/logo/0110_logo_white.png"
                  style="border-radius: unset; box-shadow: unset; width: 80px"
                  alt=""
                />
                <h5>
                  <span class="text-secondary fw-light">Finance</span><br />Wallet
                </h5>
              </div>
            </div>
            <div class="col-auto align-self-center px-0"></div>
          </div>
        </header>
      </div>
    </div> -->
    <div class="col-12 mt-auto align-self-center pb-3">
      <div class="row h-100">
        <div class="col-12 px-0 align-self-center">
          <div class="row mx-0">
            <div class="col-ld-12 position-relative thankyouimg2 text-center">
              <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="341.488"
                height="309.4"
                viewBox="0 0 341.488 309.4"
                class="bg404bg"
              >
                <defs>
                  <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stop-color="#09b2fd" />
                    <stop offset="1" stop-color="#6b00e5" />
                  </linearGradient>
                </defs>
                <path
                  id="verficationbg"
                  d="M692.351,321.27c55.785,40.634,22.728,98.486,66.116,115.7s70.616-63.289,147.384-70.248,62.673-84.023,16.027-119.57-8.366-66.884-51.151-93.738-78.652,25.674-102.343,0-69.835-30.495-100.827-13.266-48.9,40.623-48.9,81.256C621.414,273.06,662.736,291.655,692.351,321.27Z"
                  transform="translate(-618.659 -129.981)"
                  fill="url(#linear-gradient)"
                />
              </svg> -->

              <img src="assets/img/empty/maintenance.png" alt="" class="maintenance img" />
            </div>
            <div class="col-12 mx-auto">
              <p class="text-secondary text-center">
                {{"THIS_PAGE_IS_NOT_AVAILABLE" | translate}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-11 col-sm-11 mb-auto mx-auto py-4">
      <div class="row">
        <div class="col-12 d-grid">
          <a routerLink="/dashboard" class="btn btn-default btn-lg btn-50"
            >{{"PRESS.BACK_TO_HOME" | translate}}</a
          >
        </div>
      </div>
    </div>
  </div>

</div>
