import { ModalSignupUserinfoComponent } from 'src/app/@components/modal-signup-userinfo/modal-signup-userinfo.component';
// import { SignUpInfoComponent } from './../sign-up-info/sign-up-info.component';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Logger } from 'src/app/@services/logger.service';
import { FormService } from 'src/app/@services/form.service';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { UserService } from 'src/app/@services/user.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { CountdownComponent } from 'ngx-countdown';
import { TranslateService } from '@ngx-translate/core';
const log = new Logger('OtpCheckingComponent');
@Component({
  selector: 'app-otp-checking',
  templateUrl: './otp-checking.component.html',
  styleUrls: ['./otp-checking.component.scss'],
})
export class OtpCheckingComponent implements OnInit {
  @ViewChild('countdownx') countdown!: CountdownComponent;
  @ViewChild('ngxotp') ngxOtp!: NgxOtpInputComponent;
  @Input() registerType: any;
  @Input() username: any;
  @Input() password: any;
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    numericInputMode: true,
    classList: {
      inputBox: 'otp-input-custom',
    },
  };

  public loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate!: TemplateRef<any>;

  showCountdown = true;
  countdownConfig = {
    leftTime: 30,
  };

  tokenOtp: any;

  type: any = true;
  typeLogin: any;
  error: Boolean = false;
  errorText: any;

  constructor(
    public modal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    public formService: FormService,
    private modalService: NgbModal,
    private _userService: UserService,
    private authService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.loading = true;
    if (this.registerType == 'email') {
      this.type = false;
      let userName = { email: this.username };
      await this._userService.requestEmailOtp(userName);
      this.loading = false;
    } else if (this.registerType == 'mobilePhone' || 'paperWallet') {
      this.type = true;
      let userName = { mobilePhone: this.username };
      let res: any = await this._userService.requestMobileOtp(userName);
      this.loading = false;
      if (res) {
        this.tokenOtp = res.data.token;
      }
    }
  }

  handleFillEvent(value: any): void {
    log.debug('ngxotp => ', this.ngxOtp);
    this.loading = true;

    if (this.registerType == 'paperWallet') {
      let data = {
        otpToken: this.tokenOtp,
        otpCode: value,
        username: this.username,
        password: this.password,
      };
      this._userService
        .verifyMobileOtp(data)
        .then((res: any) => {
          if (res) {
            this.modal.close('success');
          }
        })
        .catch((err) => {
          log.debug('check phone => ', err);
          if (err.url.includes('verify-otp')) {
            this.error = true;
            this.errorText = this.translateService.instant('ERROR_OTP');
            setTimeout(() => {
              this.ngxOtp.clear();
              this.loading = false;
            }, 2000);
          }
        });
    }

    if (this.registerType == 'mobilePhone') {
      let data = {
        otpToken: this.tokenOtp,
        otpCode: value,
        username: this.username,
        password: this.password,
      };
      this._userService
        .verifyMobileOtp(data)
        .then(async (res: any) => {
          if (res) {
            this.tokenStorageService.setAuthToken(res);
            let loginData = await this.authService.login(
              this.username,
              this.password
            );
            log.debug('handleFillEvent => ', loginData);
            if (loginData) {
              this.modalService.dismissAll();
              const modalRef = this.modalService.open(
                ModalSignupUserinfoComponent,
                {
                  centered: true,
                  backdrop: 'static',
                  size: 'xl',
                  scrollable: true,
                }
              );
              modalRef.componentInstance.userInfo = loginData.user;
            }
          }
        })
        .catch((err) => {
          log.debug('check phone => ', err);
          if (err.url.includes('verify-otp')) {
            this.error = true;
            this.errorText = this.translateService.instant('ERROR_OTP');
            setTimeout(() => {
              this.ngxOtp.clear();
              this.loading = false;
            }, 2000);
          }
        });
    }
    if (this.registerType == 'email') {
      let data = {
        email: this.username,
        code: value,
        username: this.username,
        password: this.password,
      };
      this._userService
        .verifyEmailOtp(data)
        .then(async (res: any) => {
          if (res) {
            this.tokenStorageService.setAuthToken(res);
            let loginData = await this.authService.login(
              this.username,
              this.password
            );
            log.debug('handleFillEvent => ', loginData);
            if (loginData) {
              this.modalService.dismissAll();
              const modalRef = this.modalService.open(
                ModalSignupUserinfoComponent,
                {
                  centered: true,
                  backdrop: 'static',
                  size: 'xl',
                  scrollable: true,
                }
              );
              modalRef.componentInstance.userInfo = loginData.user;
            }
          }
        })
        .catch((err) => {
          log.debug('err otp token email =>', err);
          if (err.url.includes('verify-email-code')) {
            this.error = true;
            this.errorText = this.translateService.instant('ERROR_OTP');
            setTimeout(() => {
              this.ngxOtp.clear();
              this.loading = false;
            }, 2000);
          }
        });
    }
  }

  timeup(event: any) {
    log.debug('times up : ', event);
    if (event.status === 3) {
      this.showCountdown = false;
    }
  }

  async sendAgain() {
    log.debug('xx this.countdown => %o', this.countdown);
    if (this.registerType == 'email') {
      this.type = false;
      let userName = { email: this.username };
      await this._userService.requestEmailOtp(userName);
      this.loading = false;
    } else if (this.registerType == 'mobilePhone' || 'paperWallet') {
      this.type = true;
      let userName = { mobilePhone: this.username };
      let res: any = await this._userService.requestMobileOtp(userName);
      this.loading = false;
      if (res) {
        this.tokenOtp = res.data.token;
      }
    }
    this.countdown.restart();
    this.showCountdown = true;
  }
}
