import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthlayoutComponent } from './authlayout.component';
import { LandingComponent } from './landing/landing.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { VerifyComponent } from './verify/verify.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
// import { SplashComponent } from './splash/splash.component';
import { Thankyou2Component } from './thankyou2/thankyou2.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AuthComponent } from './auth/auth.component';
import { PaperwalletComponent } from './paperwallet/paperwallet.component';

const routes: Routes = [
  {
    path: '',
    component: AuthlayoutComponent,
    children: [
      {
        path: 'landing',
        component: LandingComponent,
      },
      {
        path: 'auth',
        component: AuthComponent,
      },
      {
        path: 'signin',
        component: SigninComponent,
      },
      {
        path: 'signup',
        component: SignupComponent,
      },
      {
        path: 'forgetpassword',
        component: ForgetpasswordComponent,
      },
      {
        path: 'resetpassword',
        component: ResetpasswordComponent,
      },
      {
        path: 'verify',
        component: VerifyComponent,
      },
      {
        path: 'thankyou',
        component: ThankyouComponent,
      },
      {
        path: 'thankyou2',
        component: Thankyou2Component,
      },
      // {
      //   path: 'termsandconditions',
      //   component: TermsandconditionsComponent,
      // },
      {
        path: 'pagenotfound',
        component: PagenotfoundComponent,
      },
      {
        path: 'paperwallet/:code',
        component: PaperwalletComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AuthlayoutRoutingModule {}
