<div *ngIf="user" class="card-namecard" [routerLink]="'/namecard/' + user.id " >
  <button *ngIf="isEdit" type="button" class="btn edit-btn" routerLink="/profilesetting">
    <i class="bi bi-pencil-fill text-white size-14"></i>
  </button>
  <div class="wrapper-card-namecard justify-content-between d-flex flex-column h-100">
    <div class="row-card d-flex justify-content-between">
      <div class="info-left">
        <div class="info-left-top text-white size-14 opac-05 text-2-line" style="max-height: 38px;">
          {{user.institution}}
        </div>
        <div class="info-left-bottom text-white size-14 opac-05 text-truncate">
          {{user.job}}
        </div>
      </div>
      <div class="info-right">
        <div class="img-namecard active-profile image-profile-preview aura-effect">
          <img [src]="
            user.profile && user.profile.picture
            ? user.profile.picture
            : 'assets/img/dashboard/avatar.png'"
            [ngClass]="
            user.profile && user.profile.picture
            ? ''
            : 'avatar-default-profile-img'"
            alt="avatar" class="avatar-profile-img">
        </div>
      </div>
    </div>
    <div class="row-card">
      <div class="namecard fw-bold size-20 text-white text-2-line line-h-normal" style="max-height: 44px;">
        {{user.profile.firstName}} {{user.profile.lastName}}
      </div>
    </div>
    <div class="row-card d-flex justify-content-between">
      <div class="info-left">
        <div class="info-left-top text-white size-14 text-truncate">
          {{user.email}}
        </div>
        <div class="info-left-bottom text-white size-14 text-truncate">
          {{user.mobilePhone}}
        </div>
      </div>
      <div class="info-right d-flex align-items-end" (click)="openQRCodeNameCardModal();$event.stopPropagation();">
        <img src="assets/img/icon/qrcode-icon.png" alt="qrcode icon">
      </div>
    </div>
  </div>
</div>

<!-- <div class="card mb-2" *ngFor="let nameCard of nameCardList">
  <div class="card-body p-0">
    <div class="row p-3" [routerLink]="'/namecard/' + nameCard.user.id ">
      <div class="col-auto">
        <div
          class="avatar avatar-46 shadow-green card rounded-10 p-1"
        >
          <div class="icons">
            <img
              [src]="nameCard.user.profile && nameCard.user.profile.picture ? nameCard.user.profile.picture : 'assets/img/categories2.png'"
              class="align-sub"
              alt="coin"
            />
          </div>
        </div>
      </div>
      <div class="col align-self-center ps-0">
        <p class="mb-0">
          {{nameCard.user.profile.firstName}} {{nameCard.user.profile.lastName}}
        </p>
        <p class="text-secondary text-muted size-12 mb-0">
          Event: {{nameCard.eventCode}}
          <br>
          Date: {{nameCard.contactDate | date: 'dd/MM/yyyy'}}
        </p>
      </div>
      <div class="col align-self-end text-end"></div>
    </div>
  </div>
</div> -->
