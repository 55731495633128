<div class="wrapper-100vw">
  <div class="wrapper-top-header mb-0 border-0">
    <div class="content-header">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-sm btn-arrow-back p-0" type="button" (click)="backnav()">
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
          <h2>
            <span class="size-18 text-white fw-bold text-capitalize">
              {{"COMPLIMENT" | translate}}
            </span>
          </h2>
          <button class="btn btn-sm btn-arrow-back p-0" type="button" (click)="openModalReview()">
            <i class="bi bi-plus-lg text-white size-16"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row-cover" *ngIf="event">
    <div class="card mb-4 overflow-hidden card-theme rounded-0 card-cover-banner">
      <div class="overlay-5"></div>
      <div class="coverimg h-100 w-100 position-relative" [style]=" 'background-image: url(' + (event.bannerImage ? event.bannerImage : 'assets/img/cover/home-cover.png') +')'"></div>

      <!-- <div
        class="coverimg h-100 w-100 position-absolute opacity-3"
        [style]=" 'background-image: url(' + (event.bannerImage ? event.bannerImage : 'assets/img/cover/home-cover.png') +')'"
      >
        <img
          [src]="(event.bannerImage ? event.bannerImage : 'assets/img/cover/home-cover.png')"
          alt=""
          style="display: none"
        />
      </div> -->
      <!--<div class="card-body">
        <h4 class="mb-1 w-100 text-white font-weight-700 text-2-line">
          {{event.name}}
        </h4>
        <p class="text-2-line">
          {{event.description}}
        </p>
      </div> -->
    </div>
  </div>

  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center">
    <div *ngIf="event">
      <h5 class="font-weight-700">{{event.name}}</h5>
      <!--<p class="text-secondary">
        {{event.description}}
      </p>-->
      <!--
        <div class="position-relative" *ngIf="event.images && event.images.length" (click)="singletonService.openGallery(event.images)">
        <div class="row mt-3">
          <div class="col-12" *ngIf="event.images[0]">
            <figure class="overflow-hidden rounded-15 text-center">
              <img
                [src]="event.images[0]"
                alt=""
                class="w-100 mx-auto"
              />
            </figure>
          </div>
          <div class="col-12" *ngIf="event.images.length >= 2">
            <div class="row">
              <div class="col-6 mb-3" *ngIf="event.images[1]">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    [src]="event.images[1]"
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
              </div>
              <div class="col-6" *ngIf="event.images[2]">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    [src]="event.images[2]"
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="event.images.length > 3"
          class="more-image-container d-flex align-items-center px-2 end-0">
          <i class="bi bi-image me-1"></i>
          <p class="size-12">+{{event.images.length - 3}}</p>
        </div>
      </div>
      -->
    </div>

    <div>
      <div *ngIf="currentUser" class="card my-3">
        <div class="card-body py-4 px-3 text-center">
          <!--
          <figure class="avatar avatar-60 rounded-15">
            <img [src]="
            currentUser.profile ? (currentUser.profile.picture ? currentUser.profile.picture: 'assets/img/dashboard/avatar.png'): 'assets/img/dashboard/avatar.png'" alt="" />
          </figure>
-->
          <h4 class="mb-3">{{"REVIEW_EVENT" | translate}}</h4>

          <span
            class="size-24"
            (click)="openModalReview()"
          >
            <i class="bi bi-star text-warning mx-1"></i>
            <i class="bi bi-star text-warning mx-1"></i>
            <i class="bi bi-star text-warning mx-1"></i>
            <i class="bi bi-star text-warning mx-1"></i>
            <i class="bi bi-star text-warning mx-1"></i>
          </span>
        </div>
      </div>

      <div>
        <card-exhibitor-review
          *ngFor="let feedback of eventReviewList"
          [feedback]="feedback">
        </card-exhibitor-review>
      </div>

    <!--
      <div class="row py-3 gx-3">

        <div class="col-auto position-relative">
          <div class="card">
            <div class="card-body p-1">
              <figure class="avatar avatar-50 rounded-15">
                <img src="assets/img/user2.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
        <div class="col">
          <a routerLink="/profile" class="mb-0 text-normal d-block text-color-theme"
            >Jessica Willson
          </a>
          <p class="mb-2">
            <span class="small">
              <i class="bi bi-star-fill text-warning"></i>
              <i class="bi bi-star-fill text-warning"></i>
              <i class="bi bi-star text-warning"></i>
              <i class="bi bi-star text-warning"></i>
              <i class="bi bi-star text-warning"></i>
            </span>
          </p>
          <p class="text-secondary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
            sollicitudin dignissim nisi, eget malesuada ligula ultricies sit amet.
            Suspendisse efficitur ex eu est placerat mattis.
          </p>
          <div class="d-flex position-relative">
            <figure class="overflow-hidden rounded-15 mx-1 w-33">
              <img src="assets/img/cover/mock-image.png" width="100%" alt="" />
            </figure>
            <figure class="overflow-hidden rounded-15 mx-1 w-33">
              <img src="assets/img/cover/mock-image.png" width="100%" alt="" />
            </figure>
            <figure class="overflow-hidden rounded-15 mx-1 w-33">
              <img src="assets/img/cover/mock-image.png" width="100%" alt="" />
            </figure>
            <div class="more-image-container d-flex align-items-center px-2">
              <i class="bi bi-image me-1"></i>
              <p class="size-12">+2</p>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>

</div>



<!-- <modal-addreviewexhibitor></modal-addreviewexhibitor> -->
