<!-- status normal -->
<div class="card custom-card mb-3 overflow-hidden text-white">
  <div class="card-body custom-card-body">
    <div class="d-flex gap-3 h-100">

      <div class="d-flex align-items-center justify-content-between gap-3 w-100">
        <div class="wrapper-quest-hunting-info">
          <div class="quest-hunting-name text-truncate mb-1 size-16 fw-bold text-white">
            {{quest.title}}
          </div>
          <div class="quest-hunting-desc text-truncate text-muted fw-normal">
            [{{quest.category ? quest.category.title : '-'}}]
            {{quest.description}}
            <!-- Get discount coupons worth a total of 30 point (Limit 500 rights) -->
          </div>
          <div class="quest-hunting-progress-bar my-3">
            <div class="quest-hunting-progress-bar-bg">
              <div class="quest-hunting-progress-bar-inner"
                [ngStyle]="{
                  'width':
                  (quest.questInfos.amountOfQuests !== 0 || quest.isCompleted)
                  ? ( '' + (quest.questInfos.amountOfCompletedQuests * 100.0 /quest.questInfos.amountOfQuests) + '%')
                  : ('0'+'%')
                }"
              ></div>
            </div>
          </div>
          <div class="quest-hunting-progress-count">



            <div class="d-flex justify-content-start align-items-center">

              <span *ngIf="quest.questInfos.date.dateType === 1" class="size-14 text-secondary">{{"OUT_OF_TIME" | translate}}</span>
              <span *ngIf="false" class="size-14 text-secondary">{{"FULLY_CLAIM" | translate}}</span>

              <i class="bi bi-check-circle size-18 lh-1"
                *ngIf="quest.questInfos.date.dateType !== 1"
                [ngClass]="
                  (quest.questInfos.amountOfCompletedQuests !== 0 || quest.isCompleted)
                  ? 'text-pink'
                  : 'text-secondary'
                "
              ></i>
              <span class="ms-2 size-14"
                *ngIf="quest.questInfos.date.dateType !== 1"
                [ngClass]="
                  (quest.questInfos.amountOfCompletedQuests !== 0 || quest.isCompleted)
                  ? 'text-white'
                  : 'text-secondary'
                "
              >
                <span *ngIf="quest.questInfos.amountOfQuests !== 0">
                  {{quest.questInfos.amountOfCompletedQuests}}/{{quest.questInfos.amountOfQuests}} {{"COMPLETE" | translate}}
                </span>
                <span *ngIf="quest.questInfos.amountOfQuests === 0">
                  <span *ngIf="quest.isCompleted">
                    {{"COMPLETE" | translate}}
                  </span>
                  <span *ngIf="!quest.isCompleted">
                    {{"INCOMPLETE" | translate}}
                  </span>
                </span>
              </span>

            </div>
          </div>
        </div>
        <div class="wrapper-quest-hunting-img d-flex flex-column justify-content-end">

          <div *ngIf="quest.questInfos.date.dateType === 1" class="date-quest-hunting size-10 mb-2 text-center text-secondary">
            <span>
              0 days left
            </span>
          </div>

          <div *ngIf="quest.questInfos.date.dateType === 0" class="date-quest-hunting size-10 mb-2 text-center text-pink">
            <span *ngIf="quest.questInfos.date.diffInDays > 0">
              {{quest.questInfos.date.diffInDays}} days left
            </span>
            <span *ngIf="quest.questInfos.date.diffInDays === 0 && quest.questInfos.date.diffInHour > 0">
              {{quest.questInfos.date.diffInHour}} hours left
            </span>
            <span *ngIf="quest.questInfos.date.diffInDays === 0 && quest.questInfos.date.diffInHour === 0 && quest.questInfos.date.diffInMinute > 0">
              {{quest.questInfos.date.diffInMinute}} minutes left
            </span>
          </div>

          <div *ngIf="quest.questInfos.date.dateType === -1" class="date-quest-hunting size-10 mb-2 text-center text-secondary">
            <span *ngIf="quest.questInfos.date.diffInDays > 0">
              {{"START_IN" | translate}} {{quest.questInfos.date.diffInDays}} {{"DAY" | translate}}
            </span>
            <span *ngIf="quest.questInfos.date.diffInDays === 0 && quest.questInfos.date.diffInHour > 0">
              {{"START_IN" | translate}} {{quest.questInfos.date.diffInHour}} {{"HOUR" | translate}}
            </span>
            <span *ngIf="quest.questInfos.date.diffInDays === 0 && quest.questInfos.date.diffInHour === 0 && quest.questInfos.date.diffInMinute > 0">
              {{"START_IN" | translate}} {{quest.questInfos.date.diffInMinute}} {{"MINUTE" | translate}}
            </span>
          </div>

          <div class="img-quest-hunting">
            <img alt="cover image"
              [src]="(quest.image ? quest.image : 'assets/img/cover/mock-image.png')"
            />
            <!-- assets/img/icon/my-download/brochure.svg -->


            <div *ngIf="!quest.isRewarded && !quest.isFullyClaimed && quest.isOutOfStock" class="status-quest-hunting status-out-of-stock">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/close-circle.png"
                  alt="close-circle icon"
                />
              </div>
              <div>{{"OUT_OF_STOCK" | translate}}</div>
            </div>

            <div *ngIf="!quest.isRewarded && quest.isFullyClaimed && !quest.isOutOfStock" class="status-quest-hunting status-fully-claimed">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/close-circle.png"
                  alt="close-circle icon"
                />
              </div>
              <div>{{"FULLY_CLAIM" | translate}}</div>
            </div>

            <div *ngIf="quest.isCompleted && !quest.isRewarded && !quest.isFullyClaimed"  class="status-quest-hunting status-claim">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/coupon/my-coupon.png"
                  alt="my coupon"
                />
              </div>
              <div>{{"CLAIM" | translate}}</div>
            </div>

            <div *ngIf="quest.isCompleted && quest.isRewarded" class="status-quest-hunting status-completed">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/check-circle.png"
                  alt="check circle icon"
                />
              </div>
              <div>{{"COMPLETE" | translate}}</div>
            </div>

            <div *ngIf="!quest.isCompleted && quest.questInfos.date.dateType === 1" class="status-quest-hunting status-out-of-time">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/close-circle.png"
                  alt="close-circle icon"
                />
              </div>
              <div>{{"OUT_OF_TIME" | translate}}</div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- end status normal -->

<!-- status claim -->
<!-- <div class="card custom-card mb-3 overflow-hidden text-white" routerLink="/quest-hunting">
  <div class="card-body custom-card-body">
    <div class="d-flex gap-3 h-100">

      <div class="d-flex align-items-center justify-content-between gap-3 w-100">
        <div class="wrapper-quest-hunting-info">
          <div class="quest-hunting-name text-truncate mb-1 size-16 fw-bold text-white">
            NameQuest
          </div>
          <div class="quest-hunting-desc text-truncate text-muted fw-normal">
            Get discount
          </div>
          <div class="quest-hunting-progress-bar my-3">
            <div class="quest-hunting-progress-bar-bg">
              <div class="quest-hunting-progress-bar-inner" style="width: 50%;"></div>
            </div>
          </div>
          <div class="quest-hunting-progress-count">
            <div class="d-flex justify-content-start align-items-center">
              <i class="bi bi-check-circle size-18 lh-1 text-pink"></i>
              <span class="ms-2 size-14 text-white">1/2 Completed</span>
            </div>
          </div>
        </div>
        <div class="wrapper-quest-hunting-img d-flex flex-column justify-content-end">
          <div class="date-quest-hunting size-14 mb-2 text-center text-secondary">
            22 Days left
          </div>
          <div class="img-quest-hunting">
            <img src="assets/img/cover/mock-image.png" alt="cover image" />
            <div class="status-quest-hunting status-claim">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/coupon/my-coupon.png"
                  alt="my coupon"
                />
              </div>
              <div>CLAIM</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->
<!-- end status claim -->

<!-- status completed -->
<!-- <div class="card custom-card mb-3 overflow-hidden text-white" routerLink="/quest-hunting">
  <div class="card-body custom-card-body">
    <div class="d-flex gap-3 h-100">

      <div class="d-flex align-items-center justify-content-between gap-3 w-100">
        <div class="wrapper-quest-hunting-info">
          <div class="quest-hunting-name text-truncate mb-1 size-16 fw-bold text-white">
            NameQuest
          </div>
          <div class="quest-hunting-desc text-truncate text-muted fw-normal">
            Get 100 point (Limit 500 rights)
          </div>
          <div class="quest-hunting-progress-bar my-3">
            <div class="quest-hunting-progress-bar-bg">
              <div class="quest-hunting-progress-bar-inner" style="width: 100%;"></div>
            </div>
          </div>
          <div class="quest-hunting-progress-count">
            <div class="d-flex justify-content-start align-items-center">
              <i class="bi bi-check-circle size-18 lh-1 text-pink"></i>
              <span class="ms-2 size-14 text-white">2/2 Completed</span>
            </div>
          </div>
        </div>
        <div class="wrapper-quest-hunting-img d-flex flex-column justify-content-end">
          <div class="date-quest-hunting size-14 mb-2 text-center text-secondary">
            22 Days left
          </div>
          <div class="img-quest-hunting">
            <img src="assets/img/cover/mock-image.png" alt="cover image" />
            <div class="status-quest-hunting status-completed">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/check-circle.png"
                  alt="check circle icon"
                />
              </div>
              <div>COMPLETED</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->
<!-- end status completed -->

<!-- status fully claimed -->
<!-- <div class="card custom-card mb-3 overflow-hidden text-white" routerLink="/quest-hunting">
  <div class="card-body custom-card-body">
    <div class="d-flex gap-3 h-100">

      <div class="d-flex align-items-center justify-content-between gap-3 w-100">
        <div class="wrapper-quest-hunting-info">
          <div class="quest-hunting-name text-truncate mb-1 size-16 fw-bold text-white">
            NameQuest
          </div>
          <div class="quest-hunting-desc text-truncate text-muted fw-normal">
            Get discount coupons worth a total of 30 point (Limit 500 rights)
          </div>
          <div class="quest-hunting-progress-bar my-3">
            <div class="quest-hunting-progress-bar-bg">
              <div class="quest-hunting-progress-bar-inner" style="width: 0%;"></div>
            </div>
          </div>
          <div class="quest-hunting-progress-count">
            <div class="d-flex justify-content-start align-items-center">
              <span class="size-14 text-secondary">Fully Claimed</span>
            </div>
          </div>
        </div>
        <div class="wrapper-quest-hunting-img d-flex flex-column justify-content-end">
          <div class="date-quest-hunting size-14 mb-2 text-center text-pink">
            2 Days left
          </div>
          <div class="img-quest-hunting">
            <img src="assets/img/cover/mock-image.png" alt="cover image" />
            <div class="status-quest-hunting status-fully-claimed">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/close-circle.png"
                  alt="close-circle icon"
                />
              </div>
              <div>Fully Claimed</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->
<!-- end status fully claimed -->

<!-- status out of time -->
<!-- <div class="card custom-card mb-3 overflow-hidden text-white" routerLink="/quest-hunting">
  <div class="card-body custom-card-body">
    <div class="d-flex gap-3 h-100">

      <div class="d-flex align-items-center justify-content-between gap-3 w-100">
        <div class="wrapper-quest-hunting-info">
          <div class="quest-hunting-name text-truncate mb-1 size-16 fw-bold text-white">
            NameQuest
          </div>
          <div class="quest-hunting-desc text-truncate text-muted fw-normal">
            Get discount coupons worth a total of 30 point (Limit 500 rights)
          </div>
          <div class="quest-hunting-progress-bar my-3">
            <div class="quest-hunting-progress-bar-bg">
              <div class="quest-hunting-progress-bar-inner" style="width: 0%;"></div>
            </div>
          </div>
          <div class="quest-hunting-progress-count">
            <div class="d-flex justify-content-start align-items-center">
              <span class="size-14 text-secondary">Out of time</span>
            </div>
          </div>
        </div>
        <div class="wrapper-quest-hunting-img d-flex flex-column justify-content-end">
          <div class="date-quest-hunting size-14 mb-2 text-center text-secondary">
            0 Days left
          </div>
          <div class="img-quest-hunting">
            <img src="assets/img/cover/mock-image.png" alt="cover image" />
            <div class="status-quest-hunting status-out-of-time">
              <div class="text-center mb-1">
                <img
                  src="assets/img/icon/close-circle.png"
                  alt="close-circle icon"
                />
              </div>
              <div>OUT OF TIME</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->
<!-- end status out-of-time -->
