/* eslint-disable */

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('DownloadComponent');
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent {
  // items:any = [
  //   {id:1,text:'price 1'},
  //   {id:2,text:'price 2'},
  //   {id:3,text:'price 3'},
  //   {id:4,text:'price 4'},
  //   {id:5,text:'price 5'},
  //   {id:6,text:'price 6'},
  //   {id:7,text:'price 7'},
  //   {id:8,text:'price 8'},
  // ]

  downloads:any = [
    // {
    //   title: 'NamePicture.png',
    //   type: 'Picture',
    //   date: '18-10-2022 18:22',
    // },
    // {
    //   title: 'NamePicture.png',
    //   type: 'Picture',
    //   date: '18-10-2022 18:22',
    // },
    // {
    //   title: 'NamePicture.png',
    //   type: 'Picture',
    //   date: '18-10-2022 18:22',
    // },
    // {
    //   title: 'NamePicture.png',
    //   type: 'Picture',
    //   date: '18-10-2022 18:22',
    // },
    // {
    //   title: 'NamePicture.png',
    //   type: 'Picture',
    //   date: '18-10-2022 18:22',
    // },
  ];

  isLoading = false

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location,
    public singletonService: SingletonService,
  ) {

  }

  // before(){

  // }

  // after(){

  // }

  backnav() {
    if(document.referrer == ''){
      this._router.navigate(['/']);
    }else{
      this.singletonService.back();
    }
    return false;
  }

  async ngOnInit() {
    await this.getBookmarkedBrochureExhibitorList()
  }

  async getBookmarkedBrochureExhibitorList(){
    this.isLoading = true

    try{
      const getBookmarkedBrochureExhibitorListRes:any = await this.backendService.getBookmarkedBrochureExhibitorList()
      log.debug("res getBookmarkedBrochureExhibitorListRes: %o",getBookmarkedBrochureExhibitorListRes)
      if(getBookmarkedBrochureExhibitorListRes){
        this.downloads = getBookmarkedBrochureExhibitorListRes.results
        log.debug("this.downloads: %o",this.downloads)

        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }

  }

  downloadBrochure(fileName:any,fileUrl:any){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
