<div class="modal-header py-3 align-items-center justify-content-between">
  <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h3 class="sign-in-header text-white size-20">
    {{"SIGNIN.FORGOT_PASSWORD2_TITLE" | translate}}
  </h3>
  <button class="btn btn-top-right-arrow" type="button"></button>
  <!-- <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
</div>
<div class="modal-body px-4">
  <div *ngIf="error" class="alert alert-danger d-flex align-items-center w-100 mt-3 mb-4 text-start" role="alert">
    <i class="bi bi-exclamation-triangle size-26"></i>
    <div class="ms-3 text-start size-14">
      {{errorText}}
    </div>
  </div>
  <div *ngIf="success" class="text-custom alert alert-success d-flex align-items-center w-100 mt-3 mb-4 text-start" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </svg>
    <div class="ms-3 text-start size-14">
      {{"FORGOT_PASSWORD2_SENDING_TEXT_1" | translate}}<br>
      {{"FORGOT_PASSWORD2_SENDING_TEXT_2" | translate}}
    </div>
  </div>
  <div *ngIf="sending" class="alert alert-info d-flex align-items-center w-100 mt-3 mb-4 text-start" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </svg>
    <div class="ms-3 text-start size-14">
      {{"FORGOT_PASSWORD2_SENDING_TEXT_1" | translate}}
    </div>
  </div>
  <div [formGroup]="forgotPasswordForm" class="row custom-row">
    <div class="text-secondary mb-4 text-center size-14">{{"SIGNIN.FORGOT_PASSWORD2_HINT" | translate}}</div>
    <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="username">{{'PHONE_OR_EMAIL' | translate}}</label>
    <div class="form-group form-floating mb-3 px-0">
      <input (keyup)="error = false" class="form-control" type="username" formControlName="username" placeholder="{{'PHONE_OR_EMAIL' | translate}}" name="username" id="username" tabindex="1">
    </div>
    <div class="signin-footer">
      <button type="button" [disabled]="sending" (click)="onSubmit()" class="btn btn-default btn-lg">{{"SIGNIN.NEXT" | translate}}</button>
    </div>
    <div class="option">
      <div class="footer-power size-12 text-secondary">
        {{"POWERED_BY" | translate}} Linxlab.io
      </div>
    </div>
  </div>

<!-- <ngx-loading
  [show]="loading"
  [config]="{
    animationType: ngxLoadingAnimationTypes.wanderingCubes,
    primaryColour: '#4b0082',
    secondaryColour: '#ffd42a',
    backdropBorderRadius: '3px'
  }"
  [template]="loadingTemplate">
</ngx-loading> -->
