/* eslint-disable */

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('CardExhibitorComponent');

@Component({
  selector: 'card-exhibitor',
  templateUrl: './card-exhibitor.component.html',
  styleUrls: ['./card-exhibitor.component.scss'],
})
export class CardExhibitorComponent {

  @Input('exhibitor') exhibitor: any;
  @Input('notemask') notemask: any;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
  ) {}


  async ngOnInit() {

  }
}
