import { ActivatedRoute } from '@angular/router';
/* eslint-disable */

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('AuthComponent');

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  returnurl:any

  constructor(
    private _router:Router,
    private _route:ActivatedRoute,
    private _location: Location,
    private singletonService: SingletonService,
  ) {
    this.returnurl = this._route.snapshot.queryParams['returnurl'];
    log.debug("this.returnurl => %o",this.returnurl);
  }

  ngOnInit() {
    log.debug("document.referrer => %o",document.referrer)
  }

  jumpToSignIn(){
    if(this.returnurl){
      this._router.navigate(['/signin'], {
        queryParams: { returnurl: this.returnurl },
      });
    }else{
      this._router.navigate(['/signin']);
    }
  }

  backnav() {
    this.singletonService.back();
  }
}
