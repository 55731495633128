<div class="wrapper-100vw">

  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{"MY_BOOKMARKS" | translate}}
            <!-- <span class="text-secondary size-14 ms-2" *ngIf="totalResults !== undefined">
              ({{ "TOTAL_RESULTS" | translate }}: {{totalResults}})
            </span> -->
          </span>
        </h2>
      </div>
    </div>
  </div>

  <!-- <div class="container" *ngIf="!isLoadingExhibitorList"> -->
  <div class="container" *ngIf="!isLoadingExhibitorList">
      <div class="exhibitor-container">

      <p class="text-secondary size-16 mb-3">
        {{"EASILY_BOOKMASK_EXHIBITOR" | translate}}
      </p>

      <!-- new ui -->
      <div *ngFor="let bookmarkedExhibitor of bookmarkedExhibitorList"  class="card custom-card mb-3 overflow-hidden text-white">
        <div class="card-body custom-card-body">
          <div class="d-flex flex-column gap-3 h-100">

            <div  class="d-flex flex-row justify-content-start gap-2">

              <div class="img-wrapper"  (click)="jumpToExhibitorDetail(bookmarkedExhibitor.eventExhibitor)">
                <img
                  [src]="(bookmarkedExhibitor.eventExhibitor.coverImage ? bookmarkedExhibitor.eventExhibitor.coverImage : 'assets/img/cover/mock-image.png')"
                  alt="cover image" />
              </div>
              <div class="bookmark-info">
                <div class="text-white size-16 fw-bold lh-sm text-2-line pe-2rem" style="max-height: 40px;">
                  {{bookmarkedExhibitor.eventExhibitor.name}}
                </div>
                <div class="text-secondary size-14 fw-normal lh-sm text-truncate mt-1">
                  {{bookmarkedExhibitor.eventExhibitor.category ? bookmarkedExhibitor.eventExhibitor.category.title : ''}}
                </div>
              </div>

              <div class="btn-bookmark" (click)="confirmUnbookmark(bookmarkedExhibitor.eventExhibitor.id)">
                <button type="button" class="btn p-0">
                  <i class="bi size-26 bi-bookmark-fill bookmark-purple"></i>
                </button>
              </div>
            </div>

            <div class="d-flex justify-content-center flex-column">
              <div class="text-white size-14 my-auto">
                <img src="assets/img/icon/note.png" alt="note icon" class="me-2" />
                {{bookmarkedExhibitor.text}}
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- end new ui -->



      <!-- old ui -->

      <!-- <card-exhibitor
        *ngFor="let bookmarkedExhibitor of bookmarkedExhibitorList"
        (click)="jumpToExhibitorDetail(bookmarkedExhibitor.eventExhibitor)"
        [exhibitor]="bookmarkedExhibitor.eventExhibitor"
        [notemask]="bookmarkedExhibitor.text"
        class="exhibitor-item"
      ></card-exhibitor> -->

      <!-- end old ui -->


      <div class="text-center" *ngIf="!bookmarkedExhibitorList.length">
        <div>
            <div class="my-5 py-3">
            <div class="card bg-transparent">
              <div class="card-body p-0">
                <div class="empty-wrapper text-center">
                  <img src="assets/img/empty/empty-bookmark.png" alt="empty bookmark">
                  <p class="text-center mt-2 fw-normal size-14 w-180px mx-auto">
                    {{"YOU_HAVENT_BOOKMARK_ANY_EXHIBITOR" | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="isLoadingExhibitorList">
      <div class="text-center my-5 py-3 empty-wrapper">
        <!-- <img src="assets/img/empty/empty-search-list.png" alt="empty search list"> -->
        <div class="spinner-border"></div>
        <p class="text-center fw-normal size-14">{{ "LOADING" | translate }}...</p>
      </div>
    </div>

    <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    ></div>
  </div>

</div>
