<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" (click)="backnav()" type="button">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            Wall Wheel Spinner
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div *ngIf="!isSpinLoading" class="section pt-3">
    <div class="container px-2">

      <div *ngFor="let wheelInfo of wheelInfos">
        <div class="row-wheel-coupon">
          <div class="row">
            <div class="col-2 col-img pe-0">
              <p class="text-center text-capitalize size-12 text-muted mb-2 text-truncate">
                {{ wheelInfo.wheel.wheelType }}
              </p>
              <div class="img-wheel-coupon">
                <img
                  src="assets/img/icon/coupon-icon.png"
                  alt="coupon image"
                />
              </div>
            </div>
            <div class="col col-content pe-0">
              <h6 class="wheel-coupon-title size-16 text-truncate mb-1">
                {{ wheelInfo.wheel.title }}
              </h6>
              <div
                class="wheel-coupon-desc size-14 text-2-line mb-2 text-muted lh-sm"
              >
                {{ wheelInfo.wheel.description }}
              </div>
              <div class="wheel-coupon-count-and-limit mt-1 size-12 text-secondary">
                {{ wheelInfo.wheel.wheelCount }}/{{ wheelInfo.wheel.wheelLimit }}
              </div>
            </div>
            <div class="col-2 col-actions ps-2">
              <button class="btn btn-default btn-sm" (click)="startSpin(wheelInfo)">{{"SPINSTART" | translate}}</button>
            </div>
          </div>
        </div>
      </div>


      <!-- <div *ngFor="let wheelInfo of wheelInfos" class="mb-2 border-bottom">
        <h3>{{wheelInfo.wheel.title}}</h3>
        <h5>{{wheelInfo.wheel.description}}</h5>

        <br>Type: {{wheelInfo.wheel.wheelType}}
        <br>Count: {{wheelInfo.wheel.wheelCount}}
        <br>Limit: {{wheelInfo.wheel.wheelLimit}}

        <button class="btn btn-default" (click)="startSpin(wheelInfo)">เริ่มหมุน</button>
      </div> -->

      <div class="my-5 py-3" *ngIf="!wheelInfos.length">
        <div class="card bg-transparent">
          <div class="card-body p-0">
            <div class="empty-wrapper text-center">
              <img src="assets/img/empty/empty-transaction.png" alt="empty transaction icon">
              <p class="text-center mt-2 fw-normal size-14">
                คุณไม่มีคูปองสำหรับหมุนวงล้อบนกำแพง
                <br/>
                (สแกน QR Code ที่บู๊ทไฮไลท์ของงานเพื่อรับสแตมป์ โดยใช้ 10 สแตมป์แลกคูปองหมุนวงล้อบนกำแพง)
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="isSpinLoading" class="text-center">
    <div class="text-center my-5 py-3 empty-wrapper">
      <div class="spinner-border"></div>
      <p class="text-center fw-normal size-14 mt-3">
        {{ "LOADING" | translate }}
      </p>
    </div>
  </div>

</div>
