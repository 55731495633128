import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {SingletonService} from '../../@services/singleton.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent {


  constructor(
    private _router: Router,
    private _location: Location,
    private singletonService: SingletonService,
  ) { }

  back() {
    this.singletonService.back();
  }
}
