import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { User } from 'src/models/user.model';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-pincode-setting',
  templateUrl: './pincode-setting.component.html',
  styleUrls: ['./pincode-setting.component.scss'],
})
export class PincodeSettingComponent {
  currentUser = this.authenticationService.currentUserSubject.value;

  error = '';

  oldPincode = '';
  changeMode = this.isPinned;
  verifyPincode = '';
  checkingVerify = false;

  currentPincode = '';
  firstPincode = '';
  secondPincode = '';
  submitting = false;

  clearPincode: Subject<void> = new Subject();

  constructor(
    private authenticationService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  get isPinned() {
    return this.currentUser.profile.isPinned;
  }

  onSetChange(pin: string) {
    if (pin.length > 1) this.error = '';
    this.currentPincode = pin;
    if (this.currentPincode.length === 6) {
      if (
        this.isPinned &&
        this.oldPincode.length === 6 &&
        this.oldPincode === this.currentPincode
      ) {
        this.error = `You can't use your old pincode`;
        this.clearPincode.next();
        return;
      }
      this.setSubmit();
    }
  }

  onVerifyPincode(pin: string) {
    if (pin.length > 1) this.error = '';
    this.verifyPincode = pin;
    if (this.verifyPincode.length === 6) {
      this.oldPincode = this.verifyPincode;
      this.verifySubmit();
    }
  }

  async verifySubmit() {
    try {
      this.checkingVerify = true;
      const payload = { pin: this.verifyPincode };
      await this.backendService.verifyPin(payload);
      this.changeMode = false;
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.checkingVerify = false;
      this.clearPincode.next();
    }
  }

  setSubmit() {
    this.error = '';
    if (this.currentPincode.length !== 6) return;
    if (this.firstPincode.length !== 6) {
      this.firstPincode = this.currentPincode;
      this.clearPincode.next();
    } else {
      this.secondPincode = this.currentPincode;
      if (this.firstPincode === this.secondPincode) {
        this.savePin(this.firstPincode);
      } else {
        this.error = 'pin ไม่ตรงกัน';
        this.currentPincode = '';
        this.firstPincode = '';
        this.secondPincode = '';
        this.clearPincode.next();
      }
    }
  }

  async savePin(pin: string) {
    try {
      this.submitting = true;
      const payload = { pin };
      const user = (await this.backendService.savePin(payload)) as User;
      this.tokenStorageService.saveUser(user);
      this.authenticationService.currentUserSubject.next(user);
      this.backnav();
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.submitting = false;
    }
  }

  backnav() {
    this.singletonService.back();
  }
}
