import { WallWheelSpinnerComponent } from './wall-wheel-spinner/wall-wheel-spinner.component';
import { MynamecardComponent } from './mynamecard/mynamecard.component';
import { DetectLinkPageComponent } from './detect-link-page/detect-link-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CouponrewardComponent } from './coupon-reward/couponreward.component';
import { ExhibitorComponent } from './exhibitor/exhibitor.component';
import { ExhibitordetailComponent } from './exhibitordetail/exhibitordetail.component';
import { ExhibitorreviewComponent } from './exhibitorreview/exhibitorreview.component';
import { FaqComponent } from './faq/faq.component';
import { HighlightroomComponent } from './highlightroom/highlightroom.component';
import { InnerlayoutComponent } from './innerlayout.component';
import { LanguagesettingComponent } from './languagesetting/languagesetting.component';
import { MyexhibitornoteComponent } from './myexhibitornote/myexhibitornote.component';
import { NewsComponent } from './news/news.component';
import { NewsdetailComponent } from './newsdetail/newsdetail.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { QrCodeScannerComponent } from './qr-code-scanner/qr-code-scanner.component';
import { ProfilesettingComponent } from './profilesetting/profilesetting.component';
import { QrTicketComponent } from './qr-ticket/qr-ticket.component';
import { ReviewusComponent } from './reviewus/reviewus.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { MycouponrewardComponent } from './mycouponreward/mycouponreward.component';
import { NamecardListComponent } from './namecard-list/namecard-list.component';
import { NamecardDetailComponent } from './namecard-detail/namecard-detail.component';
import { AddNamecardComponent } from './add-namecard/add-namecard.component';
import { PincodeSettingComponent } from './pincode-setting/pincode-setting.component';
import { WheelSpinnerComponent } from './wheel-spinner/wheel-spinner.component';
import { SettingComponent } from './setting/setting.component';
import { HelpAndSupportComponent } from './help-and-support/help-and-support.component';
import { PrivacyAndDataComponent } from './privacy-and-data/privacy-and-data.component';
import { QuestHuntingListComponent } from './quest-hunting-list/quest-hunting-list.component';
import { QuestHuntingSubComponent } from './quest-hunting-sub/quest-hunting-sub.component';
import { QuestHuntingComponent } from './quest-hunting/quest-hunting.component';
import { MyHuntingComponent } from './my-hunting/my-hunting.component';
import { CouponDetailComponent } from './coupon-detail/coupon-detail.component';
import { NftGalleryDetailComponent } from './nft-gallery-detail/nft-gallery-detail.component';
import { NftGalleryListComponent } from './nft-gallery-list/nft-gallery-list.component';
import { AuthGuard } from '../@helpers/auth.guard';
import { ReceiveComponent } from './receive/receive.component';
import { AgendaComponent } from './agenda/agenda.component';
import { NotificationComponent } from './notification/notification.component';
import { SpeakerComponent } from './speaker/speaker.component';
import { NftGalleryTarotComponent } from './nft-gallery-tarot/nft-gallery-tarot.component';
import { StampBySelfComponent } from './stamp-by-self/stamp-by-self.component';

const routes: Routes = [
  {
    path: '',
    component: InnerlayoutComponent,
    children: [
      {
        path: 'agenda',
        component: AgendaComponent,
        data: { animateState: 1 },
      },
      {
        path: 'exhibitor',
        component: ExhibitorComponent,
        data: { animateState: 1 },
      },
      {
        path: 'exhibitordetail/:exhibitorid',
        component: ExhibitordetailComponent,
        data: { animateState: 2 },
      },
      {
        path: 'highlightroom',
        component: HighlightroomComponent,
        data: { animateState: 1 },
      },
      {
        path: 'exhibitorreview/:exhibitorid',
        component: ExhibitorreviewComponent,
        data: { animateState: 1 },
      },
      {
        path: 'myexhibitornote',
        canActivate: [AuthGuard],
        component: MyexhibitornoteComponent,
        data: { animateState: 1 },
      },
      {
        path: 'qrticket',
        canActivate: [AuthGuard],
        component: QrTicketComponent,
        data: { animateState: 1 },
      },
      {
        path: 'couponreward',
        canActivate: [AuthGuard],
        component: CouponrewardComponent,
        data: { animateState: 1 },
      },
      {
        path: 'mycouponreward',
        canActivate: [AuthGuard],
        component: MycouponrewardComponent,
        data: { animateState: 2 },
      },
      {
        path: 'quest-hunting-list',
        component: QuestHuntingListComponent,
        data: { animateState: 1 },
      },
      {
        path: 'my-hunting',
        component: MyHuntingComponent,
        canActivate: [AuthGuard],
        data: { animateState: 2 },
      },
      {
        path: 'quest-hunting/:questtype/:questid',
        component: QuestHuntingComponent,
        data: { animateState: 2 },
      },
      {
        path: 'quest-hunting-sub',
        component: QuestHuntingSubComponent,
        data: { animateState: 1 },
      },
      {
        path: 'news',
        component: NewsComponent,
        data: { animateState: 1 },
      },
      {
        path: 'newsdetail/:articleid',
        component: NewsdetailComponent,
        data: { animateState: 2 },
      },
      {
        path: 'notification',
        component: NotificationComponent,
        data: { animateState: 1 },
      },
      {
        path: 'reviewus',
        component: ReviewusComponent,
        data: { animateState: 1 },
      },
      {
        path: 'language',
        component: LanguagesettingComponent,
        data: { animateState: 2 },
      },
      {
        path: 'setting',
        component: SettingComponent,
        data: { animateState: 1 },
      },
      {
        path: 'help-and-support',
        component: HelpAndSupportComponent,
        data: { animateState: 1 },
      },
      {
        path: 'privacy-and-data',
        component: PrivacyAndDataComponent,
        data: { animateState: 1 },
      },
      {
        path: 'changepassword',
        component: ChangepasswordComponent,
        data: { animateState: 2 },
      },
      {
        path: 'contactus',
        component: ContactusComponent,
        data: { animateState: 2 },
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: { animateState: 2 },
      },
      {
        path: 'privacypolicy',
        component: PrivacypolicyComponent,
        data: { animateState: 2 },
      },
      {
        path: 'termsandconditions',
        component: TermsandconditionsComponent,
        data: { animateState: 2 },
      },
      {
        path: 'qr-code-scanner',
        component: QrCodeScannerComponent,
        data: { animateState: 1 },
      },
      {
        path: 'aboutus',
        component: AboutusComponent,
        data: { animateState: 2 },
      },
      {
        path: 'profilesetting',
        component: ProfilesettingComponent,
        data: { animateState: 1 },
      },
      {
        path: 'detect-link-page/:customActionCode',
        component: DetectLinkPageComponent,
        data: { animateState: 1 },
      },
      {
        path: 'namecards',
        canActivate: [AuthGuard],
        component: NamecardListComponent,
        data: { animateState: 1 },
      },
      {
        path: 'mynamecard',
        component: MynamecardComponent,
        data: { animateState: 1 },
      },
      {
        path: 'namecard/:userId',
        component: NamecardDetailComponent,
        data: { animateState: 2 },
      },
      {
        path: 'add-namecard',
        component: AddNamecardComponent,
        data: { animateState: 2 },
      },
      {
        path: 'pincode-setting',
        component: PincodeSettingComponent,
        data: { animateState: 2 },
      },
      {
        path: 'wheel-spinner',
        canActivate: [AuthGuard],
        component: WheelSpinnerComponent,
        data: { animateState: 1 },
      },
      {
        path: 'wall-wheel-spinner/:customActionCode',
        component: WallWheelSpinnerComponent,
        data: { animateState: 1 },
      },
      {
        path: 'coupon-bundle-detail/:couponBundleId',
        component: CouponDetailComponent,
        data: { animateState: 1.5 },
      },
      {
        path: 'coupon-detail/:couponId',
        component: CouponDetailComponent,
        data: { animateState: 1.5 },
      },
      {
        path: 'nft-gallery',
        canActivate: [AuthGuard],
        component: NftGalleryListComponent,
        data: { animateState: 1.5 },
      },
      {
        path: 'nft-gallery/tarot/:id',
        component: NftGalleryTarotComponent,
        data: { animateState: 1.5 },
      },
      {
        path: 'nft-gallery/detail/:id',
        component: NftGalleryDetailComponent,
        data: { animateState: 1.5 },
      },
      {
        path: 'receive',
        component: ReceiveComponent,
        data: { animateState: 1.5 },
      },
      {
        path: 'speaker',
        component: SpeakerComponent,
        data: { animateState: 1 },
      },
      {
        path: 'stamp-by-self',
        component: StampBySelfComponent,
        data: { animateState: 1 },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class InnerRoutingModule {}
