import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
})
export class AboutusComponent {

  constructor(
    private _router:Router,
    private _location: Location,
    private singletonService: SingletonService,
  ) { }

  backnav() {
    this.singletonService.back();
  }
}
