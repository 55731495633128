import { SingletonService } from './../../@services/singleton.service';
import { environment } from 'src/environments/environment';
import { ModalAddreviewexhibitorComponent } from './../../@components/modal-addreviewexhibitor/modal-addreviewexhibitor.component';
import { ModalExhibitorBrochureListComponent } from './../../@components/modal-exhibitor-brochure-list/modal-exhibitor-brochure-list.component';
import { ModalNotemaskexhibitorComponent } from './../../@components/modal-notemaskexhibitor/modal-notemaskexhibitor.component';
/* eslint-disable */
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('ExhibitordetailComponent');
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OtpCheckingComponent } from 'src/app/@components/otp-checking/otp-checking.component';
import { ModalSocialsSharingComponent } from 'src/app/@components/modal-socials-sharing/modal-socials-sharing.component';

const SPLIT_STRING = environment.customActionCodeSplitter
import {Location} from '@angular/common';

@Component({
  selector: 'app-exhibitordetail',
  templateUrl: './exhibitordetail.component.html',
  styleUrls: ['./exhibitordetail.component.scss'],
})
export class ExhibitordetailComponent {
  exhibitorId:any

  currentUser:any
  currentUserSubscription:any

  exhibitor:any
  suggestExhibitorList:any = [];
  bookmarkedExhibitorList:any = [];

  isBookmarked = false;

  customCurrentPageUrl:any;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: NgbModal,
    public singletonService: SingletonService,
    private _location: Location,
  ) {
    this.exhibitorId = this._route.snapshot.paramMap.get('exhibitorid')!;
    this.customCurrentPageUrl = window.location.origin + `/detect-link-page/EX${SPLIT_STRING}${environment.eventCode}${SPLIT_STRING}${this.exhibitorId}`
    log.debug("this.customCurrentPageUrl => %o",this.customCurrentPageUrl)

    this.singletonService.initRequestConsent(this._route.snapshot.queryParams);
  }


  async ngOnInit() {
    this.currentUserSubscription = this._authenticationService.currentUserSubject.subscribe((_currentUser) => {
      this.currentUser = _currentUser
      log.debug("this.currentUser => %o",this.currentUser)
    })

    await this.getExhibitorInfos()
    await this.getSuggestExhibitorList()
    if(this.currentUser){
      await this.checkBookmarkedExhibitor()
    }
  }

  ngOnDestroy(): void {
    if(this.currentUserSubscription) this.currentUserSubscription.unsubscribe();
  }

  async getExhibitorInfos(){
    const getExhibitorInfosRes:any = await this.backendService.getExhibitorInfos(this.exhibitorId)
    log.debug("res getExhibitorInfosRes: %o",getExhibitorInfosRes)
    if(getExhibitorInfosRes){
      this.exhibitor = getExhibitorInfosRes
      log.debug("this.exhibitor: %o",this.exhibitor)
    }
  }

  async getSuggestExhibitorList(){
    const getSuggestExhibitorListRes:any = await this.backendService.getSuggestExhibitorList(this.exhibitorId)
    log.debug("res getSuggestExhibitorListRes: %o",getSuggestExhibitorListRes)
    if(getSuggestExhibitorListRes){
      this.suggestExhibitorList = getSuggestExhibitorListRes.results
      log.debug("this.suggestExhibitorList: %o",this.suggestExhibitorList)
    }
  }

  async checkBookmarkedExhibitor(){
    const getBookmarkedExhibitorListRes:any = await this.backendService.getBookmarkedExhibitorList(this.exhibitorId,null)
    log.debug("res getBookmarkedExhibitorListRes: %o",getBookmarkedExhibitorListRes)
    if(getBookmarkedExhibitorListRes && getBookmarkedExhibitorListRes.exhibitors){
      this.isBookmarked = true
      log.debug("this.bookmarkedExhibitorList: %o",this.bookmarkedExhibitorList)
    }else{
      this.isBookmarked = false
    }
  }



  backnav() {
    this.singletonService.back();
  }

  jumpToExhibitorDetail(exhibitor:any){
    let path = '/exhibitordetail/'+ exhibitor.id
    let href = this._router.url;
    if(href.startsWith('/exhibitordetail/')){ // same route
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]);
      });
    }else{
      this._router.navigate([path]);
    }
  }

  async doFlowBookmark(){
    if(this.isBookmarked){
      await this.unbookmark()
    }else{
      await this.openModalNotemask()
    }
  }

  async unbookmark(){
    // this.isLoading = true

    try{
      const bookmarkExhibitorRes:any = await this.backendService.unbookmarkExhibitor(this.exhibitorId)
      log.debug("res bookmarkExhibitorRes: %o",bookmarkExhibitorRes)
      if(bookmarkExhibitorRes){
        // this.exhibitor = bookmarkExhibitorRes
        // log.debug("this.exhibitor: %o",this.exhibitor)

        // this.isLoading = false
        // this.modalService.dismissAll()
        this.isBookmarked = false

      }else{
        // this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      // this.isLoading = false
    }

  }

  openModalSocialSharing() {
    let modalRef = this.modalService.open(ModalSocialsSharingComponent, {
      centered: true,
      backdrop: 'static',
      size: 'sm'
    });
  }

  openModalNotemask() {
    let modalRef = this.modalService.open(ModalNotemaskexhibitorComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      windowClass: 'modal-purple-model',
    });
    modalRef.componentInstance.exhibitorId = this.exhibitorId;
    modalRef.componentInstance.dataService.subscribe((isSuccess:any)=>{
      log.debug("ModalNotemaskexhibitorComponent isSuccess: %o",isSuccess)
      if(isSuccess){
        this.isBookmarked = true
      }
    })
  }

  openModalExhibitorBrochure(){
    let modalRef = this.modalService.open(ModalExhibitorBrochureListComponent, {
      centered: true,
      backdrop: 'static',
      fullscreen: true,
      keyboard: false,
      windowClass: 'modal-fullscreen-95',
      scrollable: true
    });
    modalRef.componentInstance.exhibitorId = this.exhibitorId;
  }

  openModalReview(){
    let modalRef = this.modalService.open(ModalAddreviewexhibitorComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.exhibitorId = this.exhibitorId;
    modalRef.componentInstance.dataService.subscribe((isSuccess:any)=>{
      log.debug("ModalAddreviewexhibitorComponent isSuccess: %o",isSuccess)
      if(isSuccess){
        // this.isBookmarked = true
        this.getExhibitorInfos()
      }
    })
  }

}
