import { environment } from 'src/environments/environment';
/* eslint-disable */

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('DetectLinkPageComponent');

@Component({
  selector: 'app-detect-link-page',
  templateUrl: './detect-link-page.component.html',
  styleUrls: ['./detect-link-page.component.scss']
})
export class DetectLinkPageComponent {

  customActionCode:any;

  currentUser:any
  currentUserSubscription:any

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.customActionCode = this._route.snapshot.paramMap.get('customActionCode')!;
    log.debug("this.customActionCode => %o",this.customActionCode)
  }

  async ngOnInit() {
    this.currentUserSubscription = this._authenticationService.currentUserSubject.subscribe((_currentUser) => {
      this.currentUser = _currentUser
      log.debug("this.currentUser => %o",this.currentUser)
    })

    await this.doCustomAction()
  }

  async doCustomAction(){
    const splitedTexts = this.customActionCode.split(`${environment.customActionCodeSplitter}`);
    const actionString = splitedTexts[0].toUpperCase( )
    let href = ''
    switch (actionString) {
      case 'UR':
        const userId = splitedTexts[1]
        // TODO: action
        href = window.location.origin+`/namecard/${userId}`
        log.debug("doCustomAction href => %o",href)
        document.location.href = href
        break;

      case 'EX':
        const eventCode = splitedTexts[1]
        const exhibitorId = splitedTexts[2]
        href = window.location.origin+`/exhibitordetail/${exhibitorId}`
        log.debug("doCustomAction href => %o",href)
        document.location.href = href
        break;
      case 'WC':
        href = window.location.origin+`/wall-wheel-spinner/${this.customActionCode}`
        log.debug("doCustomAction href => %o",href)
        document.location.href = href
        break;
      default:

        break;
    }
  }

  ngOnDestroy(): void {
    if(this.currentUserSubscription) this.currentUserSubscription.unsubscribe();
  }

}
