<div class="modal-header py-3 align-items-center justify-content-between">
  <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h3 class="header-text-normal text-white size-20">{{"THE_QR_CODE_NAMECARD" | translate}}</h3>
  <button class="btn btn-top-right-arrow" type="button"></button>
</div>
<div class="modal-body">

  <div class="text-center">
    <div class="small mb-3 mt-2 text-secondary qr-footer-text">
      <span>{{"THIS_QR_CODE_FOR_ADD_NAMECARD" | translate}}</span>
    </div>
    <div class="wrapper-qr-code mb-3">
      <div>
        <!-- <img src="{{qrUrl}}" class="qr-code-img"> -->
        <qrcode
          #parent
          [qrdata]="customCurrentPageUrl"
          [cssClass]="'center qr-code-img'"
          [colorDark]="'#000000ff'"
          [colorLight]="'#ffffffff'"
          [elementType]="'img'"
          [errorCorrectionLevel]="'M'"
          [margin]="2"
          [scale]="1"
          [width]="168"
          (qrCodeURL)="onChangeURL($event)"
        ></qrcode>
      </div>
    </div>
    <div class="wrapper-btn-actions gap-4 d-flex justify-content-center">
      <!-- <a (click)="singletonService.copyMessage(ticketId)" class="btn-action">
        <img src="assets/img/icon/icon-actions/copy.png" alt="copy" />
      </a>
      <a class="btn-action">
        <img src="assets/img/icon/icon-actions/share.png" alt="share" />
      </a> -->
      <!-- <a (click)="singletonService.downloadMediaFromUrl(qrUrl)" class="btn-action">
        <img src="assets/img/icon/icon-actions/download.png" alt="download" />
      </a> -->
      <a class="btn-action" (click)="singletonService.saveAsImage(parent,'img')">
        <img src="assets/img/icon/icon-actions/download.png" alt="download" />
      </a>
    </div>
  </div>
  <div class="option">
    <div class="footer-button">
      <button class="btn btn-default btn-lg w-100" (click)="modal.dismiss('Cross click')">
        {{"CLOSE" | translate}}
      </button>
    </div>
  </div>
</div>
<!-- <ngx-loading
  [show]="loading"
  [config]="{
    animationType: ngxLoadingAnimationTypes.wanderingCubes,
    primaryColour: '#4b0082',
    secondaryColour: '#ffd42a',
    backdropBorderRadius: '3px'
  }"
  [template]="loadingTemplate">
</ngx-loading> -->

