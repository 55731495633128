/* eslint-disable */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageType } from 'src/types/language.type';
import { Logger } from './logger.service';

const log = new Logger('TranslateConfigService'); // log.debug

@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {

  defaultLang = 'en';

  language: any;
  languages: any = ['en', 'th'];

  constructor(private translate: TranslateService) {
    this.language = localStorage.getItem('lang');
  }

  async setDefaultLanguage() {
    await this.translate.addLangs(this.languages);
    await this.translate.setDefaultLang(this.defaultLang);
    this.language = await localStorage.getItem('lang');
    if (this.languages.indexOf(this.language) === -1) {
      await this.setLanguage(this.defaultLang);
    }else{
      await this.setLanguage(this.language);
    }
  }

  async setLanguage(lang: any) {
    await localStorage.setItem('lang', lang);
    await this.translate.use(lang).toPromise();
  }

  getCurrentLanguage() {
    return localStorage.getItem('lang') || this.defaultLang;
  }
}
