import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-install-pwa',
  templateUrl: './modal-install-pwa.component.html',
  styleUrls: ['./modal-install-pwa.component.scss'],
})
export class ModalInstallPwaComponent implements OnInit {
  notShow = false;
  deferredPrompt: any = null;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {
    window.addEventListener('beforeinstallprompt', (e) => {
      const modalWindow = document.getElementsByClassName(
        'modal-install-pwa'
      )?.[0] as any;
      if (modalWindow) {
        setTimeout(() => {
          modalWindow.setAttribute('style', 'display:block !important');
        }, 350);

        let opacity = 0;
        for (opacity = 0; opacity < 1.1; opacity = opacity + 0.1) {
          setTimeout(() => {
            modalWindow.style.opacity = opacity;
          }, 1000);
        }
      }

      this.deferredPrompt = e;
    });

    if (!this.deferredPrompt) {
      this.modal.close();
    }
  }

  async onClickInstall() {
    if (this.deferredPrompt !== null) {
      this.deferredPrompt.prompt();
      const { outcome } = await this.deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        this.deferredPrompt = null;
        // document.body.classList.remove('modal-overflow-unset');
      }
      this.modal.close();
    }
  }

  onClickDontShow() {
    this.notShow = !this.notShow;
    if (this.notShow) {
      this.setLocalStorage(this.notShow);
    }
  }

  setLocalStorage(bool: boolean) {
    localStorage.setItem('notShowInstallPwa', bool.toString());
  }

  dismissModal() {
    if (!this.deferredPrompt) {
      this.setLocalStorage(true);
    }
    this.modal.dismiss('Cross click');
  }
}
