/* eslint-disable */

import { SingletonService } from 'src/app/@services/singleton.service';
import Swal from 'sweetalert2';
import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateConfigService } from './@services/translate-config.service';
import { AuthenticationService } from './@services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from './@services/logger.service';
import { User } from 'src/models/user.model';
import { BackendService } from './@services/backend.service';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { ModalInstallPwaComponent } from './@components/modal-install-pwa/modal-install-pwa.component';
import { ModalRequireNotificationPermissionComponent } from './@components/modal-require-notification-permission/modal-require-notification-permission.component';

const log = new Logger('AppComponent');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // private performance: Performance = inject(Messaging);

  constructor(
    private translateConfigService: TranslateConfigService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private backendService: BackendService,
    private singletonService: SingletonService
  ) {
    router.events.subscribe((val) => {
      // for dismiss all modal when use browser back button
      this.modalService.dismissAll();
    });
  }

  async ngOnInit() {
    log.debug('init app');
    await this.translateConfigService.setDefaultLanguage();

    this.openInstallPwaModal();
    this.alwaysScrollToTop();
    // this.requestPermission();
    try {
      await this.openRequireNotificationPermissionModal();
    } catch (error) {
      log.info(`can 't openRequireNotificationPermissionModal`);
      log.debug(error);
    }

    try {
      this.handleForgroundNotification();
    } catch (error) {
      log.info(`can 't handle forground notification`);
      log.debug(error);
    }

    try {
      this.handleUserChange();
    } catch (error) {
      log.info(`error on handleUserChange`);
      log.debug(error);
    }
  }

  openInstallPwaModal() {
    // document.body.classList.add('modal-overflow-unset');
    if (localStorage.getItem('notShowInstallPwa') !== 'true') {
      this.modalService.open(ModalInstallPwaComponent, {
        size: 'sm',
        windowClass: 'modal-install-pwa',
        backdrop: false,
      });
    }
  }

  async openRequireNotificationPermissionModal() {
    // document.body.classList.add('modal-overflow-unset');
    if (
      typeof Notification !== 'undefined' &&
      Notification.permission !== 'granted' &&
      localStorage.getItem('notShowNotiRequest') !== 'true'
    ) {
      const modalRef = this.modalService.open(
        ModalRequireNotificationPermissionComponent,
        {
          size: 'sm',
          windowClass: 'modal-noti-permission',
          backdrop: false,
          centered: true,
        }
      );
      const data = await modalRef.result;
      return data === 'granted';
    }
    return true;
  }

  alwaysScrollToTop() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) return;
      window.scrollTo(0, 0);
    });
  }

  // async requestPermission() {
  //   try {
  //     // log.debug(`requestPermission permission calling...`);
  //     const permission = await Notification.requestPermission();
  //     // log.debug(`requestPermission permission `, permission);

  //     if (permission === 'granted') {
  //       return true;
  //     } else {
  //       //TODO toast message for notification
  //       // log.debug(`show custom grant alert...`);
  //     }
  //     return false;
  //   } catch (error: any) {
  //     // log.debug(`request permission error %o`, error.message);
  //     log.error(error);
  //   }
  //   return false;
  // }

  handleUserChange() {
    this.authenticationService.currentUserSubject.subscribe(
      async (_user: User) => {
        log.info(`start handleUserChange `, _user);
        // const hasPermission = await this.requestPermission();
        let hasPermission = false;
        let supportMessaging = false;
        try {
          supportMessaging = await isSupported();
          hasPermission = (typeof Notification !== 'undefined') ? (Notification.permission === 'granted') : false;
        } catch (error) {
          log.info(`error: Notification.permission `);
          log.debug(error);
        }
        // log.debug(`hasPermission = `, hasPermission);
        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        if (hasPermission && _user && supportMessaging) {
          try {
            const messaging = getMessaging();
            const token = await getToken(messaging, {
              vapidKey: environment.fcmKey,
            });
            log.debug(`token `, token);
            // TODO check eventCode and topics
            await this.backendService.registerToken(
              token,
              'event-01',
              '0110,event-01'
            );
          } catch (error: any) {
            log.debug(`error on getToken `, error.message);
            log.debug(error);
          }
        } else {
          //TODO toast message for notification
        }
        log.info(`end handleUserChange `);
      }
    );
  }

  // handle massage when user open application
  async handleForgroundNotification() {
    log.info(`start handleForgroundNotification()`);
    try {
      const supportMessaging = await isSupported();
      log.debug(
        `handlehandleForgroundNotification supportMessaging=${supportMessaging}, `
      );
      if (supportMessaging) {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
          // TODO handle forground messaging
          // log.debug(`push message `, payload);

          const notification: any = payload.data;
          this.singletonService.toastMessage(
            notification.title,
            notification.body,
            notification
          );
        });
      }
    } catch (error: any) {
      log.info(`error handleForgroundNotification ${error.message}`);
      log.debug(error);
    }
    log.info(`end handleForgroundNotification`);
  }
}
