<div class="row h-100">
    <div class="col-11 col-sm-11 mx-auto">
        <!-- header -->
        <div class="row">
            <header class="header">
                <div class="row">
                    <div class="col">
                        <div class="logo-small">
                            <img src="assets/img/logo.png" alt="" />
                            <h5><span class="text-secondary fw-light">{{"FINANCE_WALLET_01" | translate}}</span><br />{{"FINANCE_WALLET_02" | translate}}</h5>
                        </div>
                    </div>
                    <div class="col-auto align-self-center">

                    </div>
                </div>
            </header>
        </div>
        <!-- header ends -->
    </div>
    <div class="col-12 align-self-center py-3">
        <div class="row h-100">
            <div class="col-12 px-0 align-self-center">
                <div class="row mx-0">
                    <div class="col-ld-6 position-relative thankyouimg">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="442.994" height="323.173" viewBox="0 0 442.994 323.173" class="thankyoubg mb-4">
                            <defs>
                              <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#09b2fd"/>
                                <stop offset="1" stop-color="#6b00e5"/>
                              </linearGradient>
                            </defs>
                            <path id="password-bg" d="M777.689,326.922c-33.846,30-40.769,106.154-96.153,104.615S640.766,400,563.844,372.306,494.285,294.077,528.9,247.154s5.917-85.116,48.7-111.97S700.5,179.79,765.381,166.922s61.285-31.4,87.179-48.505,80.458-13.872,92.018,21.733-24.507,55.951-16.184,90.169c3.9,16.048,6.938,35.382-4.447,51.041-16,20.622-34.073,21.193-60.29,16.47C817.288,289.476,794.138,312.342,777.689,326.922Z" transform="translate(-503.892 -108.386)" fill="url(#linear-gradient)"/>
                          </svg>

                          <div class="text">{{"SECURE" | translate}}</div>
                          <div class="circle small one"></div>
                          <div class="circle two"></div>
                          <div class="circle small three"></div>
                        <img src="assets/img/lock.png" alt="" class="slideimg">
                    </div>
                    <div class="col-11 col-md-8 col-lg-4  col-ld-6 mx-auto">
                        <h1 class="mb-4"><span class="text-secondary fw-light">{{"SUCCESSFULLY_UPDATED_01" | translate}}</span><br/>{{"SUCCESSFULLY_UPDATED_02" | translate}}</h1>
                        <p class="text-secondary">{{"YOUR_PASSWORD_HAS_BEEN_UPDATE" | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-11 col-sm-11 mt-auto mx-auto py-4">
        <div class="row ">
            <div class="col-12 d-grid">
                <a routerLink="/signin" class="btn btn-default btn-lg">{{"SIGN_IN" | translate}}</a>
            </div>
        </div>
    </div>
</div>
