import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { CouponResult } from 'src/models/event.model';

@Component({
  selector: 'app-modal-redeemcoupon',
  templateUrl: './modal-redeemcoupon.component.html',
  styleUrls: ['./modal-redeemcoupon.component.scss'],
})
export class ModalRedeemcouponComponent {
  @Input() focusItem: CouponResult;
  loading = false;
  error = '';
  success = false;

  constructor(
    public modal: NgbActiveModal,
    private _backendService: BackendService,
    private _errorService: ErrorHandlerService,
    private _router: Router
  ) {}

  goToMyCoupon() {
    this.modal.dismiss('Close');
    this._router.navigate(['/mycouponreward']);
  }

  async redeemCoupon() {
    try {
      this.loading = true;
      this.error = '';
      await this._backendService.redeemCoupon(this.focusItem.id);
      this.success = true;
    } catch (err: any) {
      const error = this._errorService.error(err.error);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  }
}
