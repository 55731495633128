<ngx-loading
  [show]="checking"
  [config]="{ fullScreenBackdrop: true }"
></ngx-loading>

<div class="wrapper-top-header">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 mx-auto px-0">
        <div class="content-header">
          <div class="ps-2 pe-0">
            <h2>
              <span class="size-28 text-white fw-bold">
                <!-- <button class="btn btn-arrow-back" type="button">
                  <i class="bi bi-chevron-left text-white size-16"></i>
                </button> -->
                Paper Wallet
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container">
  <div class="row">
    <div class="col-12 col-sm-12 mx-auto px-0">
      <div
        class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center"
      >
        <h1 class="mb-4 pb-2">
          <span class="text-title fw-600 size-32">{{
            "WELCOME_BACK" | translate
          }}</span>
          <p class="text-white size-16 fw-normal mt-1 text-secondary">
            {{ "PEASE_SCAN_OUT_THE_FORM" | translate }}
          </p>
        </h1>

        <qrcode-scanner (onDecode)="onDecodeQrCode($event)"></qrcode-scanner>
      </div>
    </div>
  </div>
</div> -->
