<div class="wrapper-content wrapper-100vw">
  <div class="wrapper-top-header mb-0">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-24 text-white fw-bold">
            <!-- <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button> -->
            <!-- My Download -->
            {{"BROCHURE" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <!-- <div class="container">
    <div>
      <div class="row mb-2 mt-3">
        <div class="col-12 px-0">
          <swiper
            class="connectionwiper"
            [slidesPerView]="'auto'"
            [spaceBetween]="0"
            [pagination]="false"
          >
            <ng-template swiperSlide>
              <div class="tag border active">
                <span class="tag-inner">
                  <span class="text-uppercase">All</span>
                </span>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="tag border">
                <span class="tag-inner">
                  <img src="assets/img/icon/my-download/gallery.svg">
                  <span class="text-uppercase">Gallery</span>
                </span>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="tag border">
                <span class="tag-inner">
                  <img src="assets/img/icon/my-download/brochure.svg">
                  <span class="text-uppercase">Brochure</span>
                </span>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="tag border">
                <span class="tag-inner">
                  <img src="assets/img/icon/my-download/name-card.svg">
                  <span class="text-uppercase">Namecard</span>
                </span>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="tag border">
                <span class="tag-inner">
                  <img src="assets/img/icon/my-download/gallery.svg">
                  <span class="text-uppercase">Gallery</span>
                </span>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="tag border">
                <span class="tag-inner">
                  <img src="assets/img/icon/my-download/brochure.svg">
                  <span class="text-uppercase">Brochure</span>
                </span>
              </div>
            </ng-template>

            <ng-template swiperSlide>
              <div class="tag border">
                <span class="tag-inner">
                  <img src="assets/img/icon/my-download/name-card.svg">
                  <span class="text-uppercase">Namecard</span>
                </span>
              </div>
            </ng-template>

          </swiper>
        </div>
      </div>
    </div>
  </div> -->

  <div class="">

    <div class="mb-4" *ngIf="downloads && downloads.length">
      <div class="card bg-transparent">
        <div class="card-body p-0">
          <ul class="list-group list-group-flush bg-none">
            <li *ngFor="let download of downloads" class="list-group-item px-4">
              <div class="row align-items-center" *ngIf="download.eventExhibitorBrochour && download.event">
                <div class="col-auto">
                  <div class="avatar avatar-img avatar-50 card rounded-15">
                    <!-- no image -->
                    <div *ngIf="!download.eventExhibitorBrochour.coverImage" class="icons icons-no-img text-white rounded-15">
                      <img class="opacity-5" src="assets/img/icon/my-download/brochure.svg">
                    </div>
                    <!-- end no image -->

                    <!-- have image -->
                    <div *ngIf="download.eventExhibitorBrochour.coverImage" class="icons icons-have-img text-white rounded-15">
                      <img [src]="download.eventExhibitorBrochour.coverImage">
                    </div>
                    <!-- end have image -->

                  </div>
                </div>
                <div class="col align-self-center ps-0">
                  <p class="size-12 mb-0 text-capitalize">
                    <!-- File Name: -->
                    {{ download.eventExhibitorBrochour.name }}
                  </p>
                  <p class="text-secondary text-muted size-12 mb-0">
                    <!-- category -->
                    <!-- Event:  -->
                    {{ download.eventExhibitorBrochour.description }}
                    <!-- {{ download.event.name }} -->
                  </p>
                  <p class="text-secondary text-muted size-12 mb-0">
                    <!-- Downloaded:  -->
                    {{ download.eventExhibitorBrochour.createdAt | date: 'dd/MM/yyyy HH:mm'}}
                  </p>
                </div>
                <div class="col-2 d-flex justify-content-end">
                  <div class="text-center" (click)="downloadBrochure(download.eventExhibitorBrochour.name,download.eventExhibitorBrochour.files[0])">
                    <i class="bi bi-download size-18"></i>
                    <p class="text-secondary size-10 mb-0 lh-sm fw-bold">{{"SAVE" | translate}}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- empty -->
    <div class="my-5 py-3" *ngIf="!downloads || !downloads.length">
      <div class="card bg-transparent">
        <div class="card-body p-0">
          <div class="empty-wrapper text-center">
            <img src="assets/img/empty/empty-download.png" alt="empty icon">
            <p class="text-center mt-2 fw-normal size-14">{{"DONT_HAVE_ANY_DOWNLOAD_NOW" | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- end empty -->

  </div>

</div>
