import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Logger } from 'src/app/@services/logger.service';
import Swal from 'sweetalert2';

const log = new Logger('ReceiveComponent');
@Component({
  selector: 'app-modal-buycoupon',
  templateUrl: './modal-buycoupon.component.html',
  styleUrls: ['./modal-buycoupon.component.scss']
})
export class ModalBuycouponComponent {

  @Input() focusItem: any;
  @Input() itemType: any;

  loading = false;
  error = '';
  success = false;

  constructor(
    public modal: NgbActiveModal,
    private _backendService: BackendService,
    private _errorService: ErrorHandlerService,
    private _router: Router
  ) {}

  goToMyCoupon() {
    this.modal.dismiss('Close');
    this._router.navigate(['/mycouponreward']);
  }

  buyItem(){
    // if(this.itemType == 'coupon'){
    //   this.buyCoupon()
    // }else{
    //   this.buyBundleCoupon()
    // }

    this.confirmTx()
  }

  async confirmTx(){
    let res:any

    try{
      if(this.itemType == 'coupon'){
        res = await this._backendService.checkLimitCoupon(this.focusItem.id)
        log.debug("checkLimitCoupon res => %o",res)
      }else{
        res = await this._backendService.checkLimitCouponBundle(this.focusItem.id);
        log.debug("checkLimitCouponBundle res => %o",res)
      }
    }catch(error){
      this._errorService.error(error,true)
    }


    if(res){
      Swal.fire({
        // icon: 'warning',
        title: 'ยืนยันการซื้อ',
        text: 'คุณต้องการซื้อ ' + this.focusItem.name + ' เป็นจำนวนเงิน ' + this.focusItem.price + ' บาท ใช่หรือไม่?',
        // text: 'Please wait a moment (2/6)',
        showDenyButton: true,
        showConfirmButton: true,
        showCancelButton: false,
        customClass: 'popup-confirm-custom popup-content-custom',
        denyButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        // didOpen: () => {
        //   Swal.showLoading()
        // }
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {


          this.modal.dismiss()
          this._router.navigate(['/receive'],{
            queryParams: {
              id: this.focusItem.id,
              amount: this.focusItem.price,
              itemType: this.itemType,
            },
          });
        }
      });
    }

  }



}
