/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalInstallPwaComponent } from 'src/app/@components/modal-install-pwa/modal-install-pwa.component';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';

import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';
const log = new Logger('QuestHuntingListComponent');

@Component({
  selector: 'app-my-hunting',
  templateUrl: './my-hunting.component.html',
  styleUrls: ['./my-hunting.component.scss']
})
export class MyHuntingComponent implements OnInit {

  questList:any = [];
  isLoadingQuestList = false

  limit=5;
  page=1;
  totalPages = 1;
  totalResults:any = undefined;

  selectedStatus = 'inProgess' // 'inProgess' / 'canClaimNow' / 'isClaimed'

  constructor(
    private _router: Router,
    private modalService: NgbModal,
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _location: Location,
    public singletonService: SingletonService,
  ) { }

  async ngOnInit() {

    await this.getMyQuestList()
  }

  async reloadData(){
    await this.resetPaginate()
    await this.getMyQuestList()
  }

  resetPaginate(){
    this.page = 1
    this.totalPages = 1;
    this.questList = []
    this.totalResults = undefined
  }

  async getMyQuestList(){
    this.isLoadingQuestList = true
    const getMyQuestListRes:any = await this.backendService.getMyQuestList(
        this.selectedStatus,
        this.limit,
        this.page,
      )
    log.debug("res getMyQuestListRes: %o",getMyQuestListRes)
    if(getMyQuestListRes){

      const results = getMyQuestListRes.results
      this.questList = [...this.questList,...results]
      log.debug("this.questList: %o",this.questList)

      this.totalPages = getMyQuestListRes.totalPages
      this.totalResults = getMyQuestListRes.totalResults

      this.isLoadingQuestList = false

    }else{
      this.isLoadingQuestList = false
    }
  }

  jumpQuestDetail(quest:any){
    let path = '/quest-hunting/'+ quest.questType + '/'+ quest._id
    let href = this._router.url;
    if(href.startsWith('/quest-hunting/')){ // same route
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]);
      });
    }else{
      this._router.navigate([path]);
    }

  }

  async onScroll() {
    log.debug("onScroll...")

    if(this.page < this.totalPages){
      this.page++;
      await this.getMyQuestList()
    }
  }

  async activateClassActive(selectedStatus:any){
    log.debug("activateClassActive selectedStatus => %o",selectedStatus)
    this.selectedStatus = selectedStatus
    await this.reloadData()
  }

  backnav() {
    this.singletonService.back();
  }

  openInstallPwaModal() {
    this.modalService.open(ModalInstallPwaComponent, {
      size: 'sm',
      windowClass: 'modal-install-pwa',
      backdrop: false
    });
  }

}
