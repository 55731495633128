<div class="modal-header d-flex border-0 p-4 pb-0">
  <h3 class="size-20 text-white">Bookmark</h3>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="">
    <div>
      <textarea
        [(ngModel)]="comment"
        class="form-control"
        id="text"
        name="text"
        maxlength="60"
        placeholder="Write your note about this exhibitor, only you can see this."
        rows="7"
      ></textarea>
      <div class="text-end size-14 text-secondary">{{comment.length}}/60</div>
    </div>
  </div>
  <div class="row mb-0 mt-3 wrapper-btn-footer-custom">
    <div class="col-12">
      <button
        [disabled]="isLoading || !comment.length"
        (click)="saveNotemask()"
        class="btn btn-default btn-lg w-100"
        >{{"DONE" | translate}}</button
      >
    </div>
  </div>
</div>



