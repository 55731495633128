<div class="wrapper-100vw">
  <div class="wrapper-top-header mt-0 pb-3 mb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center px-0">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="backnav()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center px-1">
          <div class="input-group">
            <span class="input-group-text">
              <img src="assets/img/icon/search.png" alt="search icon">
            </span>
            <input
                type="text"
                class="form-control rounded-10"
                value=""
                placeholder="{{'SEARCH'| translate}}"
                (keyup)="searchWithDelay($event)"
              />
          </div>
        </div>
        <!-- <div class="col-auto align-self-center px-0">
          <button class="btn text-white btn-sm pe-0 filter-btn">
            <i class="bi bi-sliders size-22"></i>
          </button>
        </div> -->
      </div>
    </div>
  </div>

  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="title size-16">
        {{ "QUEST_HUNTING.QUEST_HUNTING" | translate }}
      </h6>
      <div>
        <button type="button" class="btn" routerLink="/my-hunting">
          <img
              src="assets/img/icon/history.png"
              alt="my hunting icon"
            />
        </button>
      </div>
    </div>
    <div class="row mb-2 mt-2" *ngIf="!isLoadingQuestList">
      <div class="col-12 px-0">
        <!-- swiper categories -->
        <swiper
          class="connectionwiper"
          [slidesPerView]="'auto'"
          [spaceBetween]="0"
          [pagination]="false"
        >
        <ng-template swiperSlide>
          <div class="tag border" (click)="setSelectedAll()" [ngClass]="isSelectedAll ? 'active' : ''">
            <span class="tag-inner justify-content-center">
              <span class="text-uppercase">{{ "ALL" | translate }}</span>
            </span>
          </div>
        </ng-template>

        <ng-template swiperSlide  *ngFor="let category of categoryList">
          <div class="tag border" (click)="activateClassActive(category);" [ngClass]="(category && category.active ? 'active' : '')">
            <span class="tag-inner justify-content-center">
              <span class="text-uppercase">{{category.title}}</span>
            </span>
          </div>
        </ng-template>

        </swiper>
      </div>
    </div>

    <div class="quest-hunting-container" *ngIf="!isLoadingDelayedSearch">

      <app-card-quest-hunting
        *ngFor="let quest of questList"
        (click)="jumpQuestDetail(quest)"
        [quest]="quest"
      ></app-card-quest-hunting>

      <div class="text-center">
        <div *ngIf="!questList.length && !isLoadingQuestList">
          <div class="my-5 py-3">
            <div class="card bg-transparent">
              <div class="card-body p-0">
                <div class="empty-wrapper text-center">
                  <img src="assets/img/empty/empty-search-list.png" alt="empty search list">
                  <p class="text-center mt-2 fw-normal size-14">
                    {{"WE_CANT_FIND_ANY_MATCH" | translate}} <br>
                    {{"PLEASE_TRY_OTHER_WORDS" | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="isLoadingDelayedSearch || isLoadingQuestList">
      <div class="text-center my-5 py-3 empty-wrapper">
        <!-- <img src="assets/img/empty/empty-search-list.png" alt="empty search list"> -->
        <div class="spinner-border"></div>
        <p class="text-center fw-normal size-14">{{ "LOADING" | translate }}...</p>
      </div>
    </div>

    <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    ></div>

  </div>

</div>
