import { NumberDirective } from './numbers-only.directive';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    NumberDirective,
   ],
  imports: [],
  exports: [
    NumberDirective,
  ]
})
export class CoreDirectivesModule {}
