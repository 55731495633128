/* eslint-disable */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserRoleType } from 'src/types/user-role.type';
import { TokenStorageService } from './token-storage.service';

import { Logger } from './logger.service';
const log = new Logger('AuthenticationService');
import { SignUpDataType } from 'src/models/auth.model';
import { UserWithToken } from 'src/models/authToken.model';
import { User } from 'src/models/user.model';
const prefix = environment.localStoragePrefix;

import {
  getMessaging,
  deleteToken,
  onMessage,
  isSupported,
} from '@angular/fire/messaging';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // private _currentUser: User | null = null;

  public currentUser: Observable<any>;
  public currentUserSubject: BehaviorSubject<any>;

  constructor(
    private _http: HttpClient,
    private _tokenStorageService: TokenStorageService
  ) {
    // this._currentUser = JSON.parse(
    //   localStorage.getItem('currentUser')!
    // ) as User;

    // ============= start clone 0110 ====================
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('0110-user_user')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
    // ============= end clone 0110 ====================
  }

  // public get currentUser() {
  //   return this._currentUser;
  // }

  // login(email: string, password: string) {
  //   const fakeUser: User = {
  //     id: '4fus645fki',
  //     email: 'ikhalas.work@gmail.com',
  //     firstName: 'Ikhalas',
  //     lastName: 'Mannoon',
  //     avatar: '',
  //     role: UserRoleType.User,
  //   };

  //   this._currentUser = fakeUser;
  //   localStorage.setItem('currentUser', JSON.stringify(fakeUser));
  // }

  // logout() {
  //   localStorage.removeItem('currentUser');
  //   this._currentUser = null;
  // }

  login(username: string, password: string): Promise<any> {
    const urlEndpoint = `${environment.apiUrl}/wallet-server/auth/login`;
    // log.debug(`request to ${urlEndpoint} with body ${email}, ${password}`);
    log.debug(`request to ${urlEndpoint} with body ${username}, ${password}`);

    return new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.apiUrl}/wallet-server/auth/google-fetcher`;
        const res: any = await this._http
          .post(urlEndpoint, { username, password })
          .toPromise();

        log.debug(`login res %o`, res);

        await this.doAfterLogin(res);

        resolve(res);
      } catch (error) {
        log.debug('error : %o', error);
        reject(error);
      }
    });
  }

  async doAfterLogin(res: any) {
    await this._tokenStorageService.setAuthToken(res);
    const token = await JSON.parse(
      localStorage.getItem('0110-user_accessToken')!
    );
    const user = await JSON.parse(localStorage.getItem('0110-user_user')!);
    await this.currentUserSubject.next(user);
  }

  async refreshTokenPromise(refreshToken: any) {
    const url = `${environment.apiUrl}/wallet-server/auth/refresh-tokens`;
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          refreshToken: refreshToken,
        };
        log.debug('refreshTokenPromise url,data : %o,%o', url, data);
        let tokens: any = await this._http.post(url, data).toPromise();
        log.debug('refreshTokenPromise tokens %o', tokens);
        if (tokens) {
          // await this.updateTokens(tokens)
          this._tokenStorageService.saveAccessToken(tokens.access);
          this._tokenStorageService.saveRefreshToken(tokens.refresh);

          resolve(tokens);
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async logout() {
    log.debug('prepare to logout...');

    // remove user from local storage to log user out
    localStorage.removeItem(`${prefix}_user`);
    localStorage.removeItem(`${prefix}_refreshToken`);
    localStorage.removeItem(`${prefix}_accessToken`);
    localStorage.removeItem('notShowNotiRequest');
    localStorage.removeItem('notShowInstallPwa');

    // notify
    this.currentUserSubject.next(null!);
    log.debug('success to logout...');
    // delete firebase token
    try {
      const supportMessaging = await isSupported();
      if (supportMessaging) {
        const messaging = getMessaging();
        const _deletedBoolean = deleteToken(messaging);
      }
    } catch (error: any) {
      log.info(`error login ${error.message}`);
      log.debug(error);
    }
  }

  googleFetcher(data: { idToken: string }): Promise<UserWithToken> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.apiUrl}/wallet-server/auth/google-fetcher`;
        const response = await this._http
          .post<UserWithToken>(url, data)
          .toPromise();
        resolve(response);
      } catch (error) {
        log.debug(`googleFetcher error %o`, error);
        reject(error);
      }
    });
  }

  facebookFetcher(data: { accessToken: string }): Promise<UserWithToken> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.apiUrl}/wallet-server/auth/facebook-fetcher`;
        const response = await this._http
          .post<UserWithToken>(url, data)
          .toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  signUpwithData(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.apiUrl}/wallet-server/auth/signUp-with-data`;
        const response = await this._http.post<any>(url, data).toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  signUpwithPaperWallet(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.apiUrl}/wallet-server/auth/signUp-with-paper-wallet`;
        const response = await this._http.post<any>(url, data).toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  checkSurveyStatus(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${environment.apiUrl}/platform-event/event-tickets/my-tickets?eventCode=event-01`;
        const response = await this._http.get(url).toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  isLoggedIn() {
    const currentUser = this.currentUserSubject.value;
    if (!currentUser) return false;
    return true;
  }

  checkPaperWalletCode(code: string) {
    const url = `${environment.apiUrl}/wallet-server/auth/paper-qrcode/check/${code}`;
    return this._http.get(url).toPromise();
  }

  changePasswordPaperWallet(payload: any, code: string) {
    const url = `${environment.apiUrl}/wallet-server/auth/paper-qrcode/change-password/${code}`;
    return this._http.post(url, payload).toPromise();
  }
}
