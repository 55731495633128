import { SingletonService } from './../../@services/singleton.service';
import { data } from 'autoprefixer';
import { ModalGalleryComponent } from './../modal-gallery/modal-gallery.component';
/* eslint-disable */

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'card-exhibitor-review',
  templateUrl: './card-exhibitor-review.component.html',
  styleUrls: ['./card-exhibitor-review.component.scss']
})
export class CardExhibitorReviewComponent {
  @Input('feedback') feedback: any;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private modalService: NgbModal,
    public singletonService: SingletonService
  ) {}


  async ngOnInit() {

  }

}
