/* eslint-disable */
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { FormService } from 'src/app/@services/form.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { UserService } from 'src/app/@services/user.service';
import { ConfirmPasswordValidator } from 'src/validators/confirm-password.validator';
import { PasswordStrengthValidator } from 'src/validators/password-strength.validators';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';
import { Logger } from 'src/app/@services/logger.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';

const log = new Logger('ChangepasswordComponent');

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
})
export class ChangepasswordComponent {
  passwordForm!: FormGroup;
  loading = false;
  allowChange = false;

  isShowOldPassword: boolean = false;
  isShowPassword: boolean = false;
  isShowComfirmPassword: boolean = false;

  error = '';
  success = false;

  constructor(
    private fb: FormBuilder,
    public formService: FormService,
    private _tokenStorageService: TokenStorageService,
    private userService: UserService,
    public translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private _router:Router,
    private _location: Location,
    private singletonService: SingletonService,
    private _errorHandlerService: ErrorHandlerService,
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            // PasswordStrengthValidator,
          ],
        ],
        confirmNewPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmPasswordValidator(
          'newPassword',
          'confirmNewPassword'
        ),
      }
    );

    const currentUser = this._authenticationService.currentUserSubject.value;
    const registerType = currentUser.registerType!;
    this.allowChange = ['email', 'mobilePhone'].includes(registerType);
    if (!this.allowChange) {
      this.passwordForm.disable();
    }
  }

  showOldPassword() {
    this.isShowOldPassword = !this.isShowOldPassword;
  }

  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  showConfirmPassword() {
    this.isShowComfirmPassword = !this.isShowComfirmPassword;
  }

  async onSubmit() {
    this.loading = true;
    this.success = false;
    this.error = '';

    if (this.passwordForm.invalid) {
      this.error = this.translateService.instant('ERROR_MSG.ERROR_REGISTER');
      return;
    }

    try {
      const formValue = this.passwordForm.value;
      const response = (await this.userService.changePassword(
        formValue
      )) as any;


      await this._authenticationService.doAfterLogin(response)
      // this._tokenStorageService.setAuthToken(response);
      // const user = JSON.parse(localStorage.getItem('0110-user_user')!);
      // this._authenticationService.currentUserSubject.next(user);

      this.success = true;
      this.passwordForm.reset();
    } catch (error: any) {
      const _error = this._errorHandlerService.error(error).message;
      log.debug("_error => %o",_error)
      this.error = _error
    } finally {
      this.loading = false;
    }
  }

  backnav() {
    this.singletonService.back();
  }
}
