import { SingletonService } from 'src/app/@services/singleton.service';
/* eslint-disable */
import { Chart, ChartConfiguration } from 'chart.js';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAssetsDetailComponent } from 'src/app/@components/modal-assets-detail/modal-assets-detail.component';
import { ModalReceivePointComponent } from 'src/app/@components/modal-receive-point/modal-receive-point.component';
import { ModalTransferSendComponent } from 'src/app/@components/modal-transfer-send/modal-transfer-send.component';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { environment } from 'src/environments/environment';
import { PointResult, PointType } from 'src/models/point.model';
import {
  TransectionResult,
  TransectionType,
} from 'src/models/tokenTransection.model';
import { User } from 'src/models/user.model';

type IncomeAndExpense = {
  income: number;
  expense: number;
};

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {
  loading = true;
  currentUser: User;
  allToken: PointType;
  defaultToken: PointResult;
  incomeAndExpense: IncomeAndExpense;
  // allTransections: TransectionResult[];
  allTransections: any[];
  error: '';
  lineChart: ChartConfiguration;
  transectionsPage = 1;
  transectionsLoadMore = false;
  setupChartDone = false;

  transectionPageInfo: any;

  acticeTab = 'ASSETS';
  selectedAsset = 'TOKENS';

  nftPage = 1;
  allNfts: any[];
  nftPageInfo: any;
  nftLoadMore = false;

  constructor(
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    private modalService: NgbModal,
    private _authenticationService: AuthenticationService,
    private singletonService: SingletonService,
  ) {}

  async ngOnInit() {
    await this.setupProperty();
    this.setupChart();
  }

  async setupProperty() {
    try {
      this.loading = true;
      this.currentUser = this._authenticationService.currentUserSubject.value;
      await this.getAllAvailableToken();
      const defaultToken = this.allToken.results.find((result) => {
        return result.token.isDefault;
      });
      this.defaultToken = defaultToken || this.allToken.results?.[0] || null;

      await this.getTotalIncomeAndExpense();
      const nfts = await this.getAllNfts(this.nftPage);
      this.allNfts = nfts?.results || [];

      const transection = await this.getAllTokenTransactions(
        this.transectionsPage
      );
      this.allTransections = transection?.results || [];
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  }

  setupChart() {
    if (this.incomeAndExpense != undefined) {
      const { expense, income } = this.incomeAndExpense;

      const chartData = !expense && !income ? [0, 9999] : [expense, income];
      const tooltipsEnabled = !!expense || !!income;

      this.lineChart = new Chart('lineChart', {
        type: 'doughnut',
        data: {
          labels: ['expense', 'income'],
          datasets: [
            {
              label: 'Total points balance',
              data: chartData,
              borderColor: ['#1f1e41', '#1f1e41'],
              backgroundColor: ['#FF6AE0', '#8383FF'],
              borderWidth: 2,
            },
          ],
        },
        options: {
          cutoutPercentage: 92,
          responsive: true,
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
          tooltips: {
            enabled: tooltipsEnabled,
          },
          animation: {
            onComplete: () => {
              this.setupChartDone = true;
            },
          },
        },
      });
    }
  }

  async getAllAvailableToken() {
    try {
      const query = {
        eventCode: environment.eventCode,
        isOnlyAvailable: false,
      };
      this.allToken = (await this.backendService.getAllPoint(
        query
      )) as PointType;
    } catch (err) {
      throw err;
    }
  }

  async getTotalIncomeAndExpense() {
    try {
      this.incomeAndExpense =
        (await this.backendService.getTotalIncomeAndExpenseByTokenId(
          this.defaultToken.token.id
        )) as IncomeAndExpense;
    } catch (err) {
      throw err;
    }
  }

  async getAllNfts(page: number) {
    try {
      const nft = (await this.backendService.getAllNfts({
        eventCode: environment.eventCode,
        limit: 5,
        page,
        sortBy: 'createdAt:desc',
      })) as any;
      this.nftPageInfo = nft;
      return nft;
    } catch (err) {
      throw err;
    }
  }

  async onNftsScrollEnd() {
    if (this.acticeTab !== 'ASSETS' || this.selectedAsset !== 'NFTS') return;
    this.nftLoadMore = true;
    if (this.allNfts.length < this.nftPageInfo.totalResults) {
      this.nftPage++;
      const nft = await this.getAllNfts(this.nftPage);
      this.allNfts = [...this.allNfts, ...nft?.results];
    }
    this.nftLoadMore = false;
  }

  async getAllTokenTransactions(page: number) {
    try {
      const transection = (await this.backendService.getAllPointTransactions({
        eventCode: environment.eventCode,
        limit: 10,
        page,
        type: 'token',
      })) as TransectionType;
      this.transectionPageInfo = transection;
      return transection;
    } catch (err) {
      throw err;
    }
  }

  async onTransactionsScrollEnd() {
    if (this.acticeTab !== 'HISTORY') return;
    this.transectionsLoadMore = true;
    if (this.allTransections.length < this.transectionPageInfo.totalResults) {
      this.transectionsPage++;
      const transection = await this.getAllTokenTransactions(
        this.transectionsPage
      );
      this.allTransections = [...this.allTransections, ...transection?.results];
    }
    this.transectionsLoadMore = false;
  }

  onSelectAsset(e: any) {
    this.selectedAsset = e.target.value;
  }

  openSendModal() {
    this.singletonService.showNotAvaliabledFeatureSwalAlert()

    // if (!this.defaultToken) return;
    // const modalRef = this.modalService.open(ModalTransferSendComponent, {
    //   centered: true,
    // });
    // modalRef.componentInstance.token = this.defaultToken.token;
    // modalRef.componentInstance.balance = this.defaultToken.balance;
    // modalRef.result.then(
    //   (data) => {
    //     if (data === 'success') {
    //       this.setupProperty();
    //       this.setupChart();
    //     }
    //   },
    //   (_) => {}
    // );
  }

  openReceiveModal() {
    this.singletonService.showNotAvaliabledFeatureSwalAlert()

    // this.modalService.open(ModalReceivePointComponent, {
    //   centered: true,
    //   fullscreen: true,
    //   keyboard: false,
    //   windowClass: 'modal-fullscreen-95',
    //   backdrop: false,
    //   scrollable: true,
    // });
  }

  openAssetsDetailModal(token: PointResult) {
    const modalRef = this.modalService.open(ModalAssetsDetailComponent, {
      centered: true,
      fullscreen: true,
      keyboard: false,
      windowClass: 'modal-fullscreen-95',
      backdrop: false,
      scrollable: true,
    });
    modalRef.componentInstance.token = token;
  }

  setActiveTab(tab: string) {
    this.acticeTab = tab;
  }
}
