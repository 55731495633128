import {
  Component,
  // ElementRef,
  // HostListener,
  // Renderer2,
  // ViewChild,
} from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { authSlideInAnimation } from '../@helpers/route-animations';

@Component({
  selector: 'app-walletlayout',
  templateUrl: './walletlayout.component.html',
  styleUrls: ['./walletlayout.component.scss'],
  animations: [authSlideInAnimation],
})
export class WalletlayoutComponent {
  constructor(private contexts: ChildrenOutletContexts) {}

  // @ViewChild('HeaderEl', { read: ElementRef, static: false })
  // headerView: ElementRef;
  // @ViewChild('mainPage', { read: ElementRef, static: false })
  // mainPageView: ElementRef;
  // @ViewChild('FooterEl', { read: ElementRef, static: false })
  // footerView: ElementRef;
  // constructor(private renderer: Renderer2) {}
  // ngAfterViewInit() {
  //   this.renderer.setStyle(
  //     this.mainPageView.nativeElement,
  //     'padding-top',
  //     this.headerView.nativeElement.offsetHeight + 10 + 'px'
  //   );
  //   this.renderer.setStyle(
  //     this.mainPageView.nativeElement,
  //     'padding-bottom',
  //     this.headerView.nativeElement.offsetHeight + 10 + 'px'
  //   );
  //   this.renderer.setStyle(
  //     this.mainPageView.nativeElement,
  //     'min-height',
  //     window.outerHeight + 'px'
  //   );
  // }
  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   let header = document.getElementsByTagName('headermenu')[0];
  //   let main = document.getElementsByTagName('html')[0];
  //   if (main.scrollTop > 15) {
  //     header.classList.add('active');
  //   } else {
  //     header.classList.remove('active');
  //   }
  // }

  getRouteAnimationState() {
    const data = this.contexts.getContext('primary')?.route?.snapshot?.data;
    return data?.animateState;
  }
}
