import { Component } from '@angular/core';

@Component({
  selector: 'app-add-namecard',
  templateUrl: './add-namecard.component.html',
  styleUrls: ['./add-namecard.component.scss']
})
export class AddNamecardComponent {

}
