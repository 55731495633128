<div class="container px-2">
  <ul class="nav nav-pills nav-justified">
    <li class="nav-item">
      <a class="nav-link home-menu" routerLink="/dashboard" routerLinkActive="active">
        <span>
          <span class="icon-footer-item" style="background: url('assets/img/dashboard/home.png');"></span>
        </span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link wallet-menu" routerLink="/wallet" routerLinkActive="active">
        <span>
          <span class="icon-footer-item" style="background: url('assets/img/dashboard/wallet.png');width: 30px;height: 30px;"></span>
        </span>
      </a>
    </li>
    <li class="nav-item centerbutton">
      <button
        type="button"
        class="nav-link centerbtn"
        [class.active]="isVisited == true"
        (click)="checkVisited()"
        data-bs-toggle="modal"
        data-bs-target="#menumodal"
        id="centermenubtn"
      >
        <span class="theme-radial-gradient">
          <i class="bi bi-grid size-22"></i>
        </span>
      </button>
    </li>
    <li class="nav-item">
      <a class="nav-link download-menu" routerLink="/download" routerLinkActive="active">
        <span>
          <span class="icon-footer-item" style="background: url('assets/img/dashboard/download.png');width: 26px;height: 26px;"></span>
        </span>
      </a>
    </li>
    <li class="nav-item">
      <!-- <authwrapper
        class="col-auto text-center"
        (onClick)="navigate('/qrticket')"
      >
        <a class="nav-link"> -->
        <a class="nav-link profile-menu" routerLink="/profile" routerLinkActive="active">
          <span>
            <span class="icon-footer-item" style="background: url('assets/img/dashboard/profile.png');"></span>
            <!-- <i class="nav-icon bi bi-person"></i> -->
            <!-- <span class="nav-text">Profile </span> -->
          </span>
        </a>
      <!-- </authwrapper> -->
    </li>
  </ul>
</div>
