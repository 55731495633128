import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from '../@services/token-storage.service';
import { AuthenticationService } from '../@services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private _authenticationService: AuthenticationService,
    private _tokenStorageService: TokenStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isApiShortenLinkUrl = request.url.startsWith(environment.apiShortenLinkUrl);
    const accessToken = this._tokenStorageService.getAccessToken();

    if (isApiUrl && accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    if (isApiShortenLinkUrl && accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return next.handle(request);
  }

  // const currentUser = this._authenticationService.currentUser;
  // if (currentUser) {
  //   const isLoggedIn = currentUser;
  //   const isApiUrl = request.url.startsWith(environment.apiUrl);
  //   const accessToken = this._tokenStorageService.getAccessToken();
  //   // log.debug('isAccessTokenExpired',isAccessTokenExpired)
  //   if (isLoggedIn && isApiUrl && accessToken) {
  //     request = request.clone({
  //       setHeaders: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });
  //   }
  // }
  // return next.handle(request);
  // }
}
