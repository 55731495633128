import { ErrorHandlerService } from './../../@services/error-handler.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { UserService } from 'src/app/@services/user.service';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';


const log = new Logger('MynamecardComponent');

@Component({
  selector: 'app-mynamecard',
  templateUrl: './mynamecard.component.html',
  styleUrls: ['./mynamecard.component.scss']
})
export class MynamecardComponent {

  currentUser:any

  constructor(
    private _tokenStorageService: TokenStorageService,
    private userService: UserService,
    public translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private backendService: BackendService,
    private _router:Router,
    private errorHandlerService:ErrorHandlerService,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  async ngOnInit() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    if(!this.currentUser) this.errorHandlerService.goSignIn()
  }

  backnav() {
    this.singletonService.back();
  }
}
