/* eslint-disable */

import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { FormService } from 'src/app/@services/form.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('ModalExhibitorBrochureListComponent');

@Component({
  selector: 'modal-exhibitor-brochure-list',
  templateUrl: './modal-exhibitor-brochure-list.component.html',
  styleUrls: ['./modal-exhibitor-brochure-list.component.scss']
})
export class ModalExhibitorBrochureListComponent {

  @Input("exhibitorId") exhibitorId:any;

  comment:any = '';

  isLoading = false;

  brochureList:any = [];

  downloadedTotalResults:any;

  constructor(
    public modal: NgbActiveModal,
    public formService: FormService,
    private modalService: NgbModal,
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {

  }

  async ngOnInit() {
    await this.getBookmarkedBrochureExhibitorList()
    await this.getBrochureList()
  }


  async getBookmarkedBrochureExhibitorList(){
    this.isLoading = true

    try{
      const getBookmarkedBrochureExhibitorListRes:any = await this.backendService.getBookmarkedBrochureExhibitorList()
      log.debug("res getBookmarkedBrochureExhibitorListRes: %o",getBookmarkedBrochureExhibitorListRes)
      if(getBookmarkedBrochureExhibitorListRes){

        this.downloadedTotalResults = getBookmarkedBrochureExhibitorListRes.totalResults
        log.debug(" this.downloadedTotalResults : %o", this.downloadedTotalResults )

        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }

  }

  async getBrochureList(){
    this.isLoading = true

    try{
      const brochureExhibitorListRes:any = await this.backendService.getBrochureExhibitorList(this.exhibitorId)
      log.debug("res brochureExhibitorListRes: %o",brochureExhibitorListRes)
      if(brochureExhibitorListRes){
        this.brochureList = brochureExhibitorListRes.results
        log.debug("this.brochureList: %o",this.brochureList)

        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }

  }

  async bookmarkBrochureExhibitor(brochureId:any){
    this.isLoading = true

    try{
      const brochureExhibitorListRes:any = await this.backendService.bookmarkBrochureExhibitor(brochureId)
      log.debug("res brochureExhibitorListRes: %o",brochureExhibitorListRes)
      if(brochureExhibitorListRes){
        // this.brochureList = brochureExhibitorListRes.results
        log.debug("this.brochureList: %o",this.brochureList)

        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }
  }

  async doDownloadProcess(brochureName:any,brochureUrl:any,brochureId:any){
    await this.downloadBrochure(brochureName,brochureUrl)
    await this.bookmarkBrochureExhibitor(brochureId)
    await this.getBookmarkedBrochureExhibitorList()
    // await this.jumpToDownload()
  }

  downloadBrochure(fileName:any,fileUrl:any){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  jumpToDownload(){
    this._router.navigate(['/download']);
    this.modalService.dismissAll()
  }
}
