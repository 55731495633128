<div class="wrapper-100vw">
  <div class="row pt-3">
    <div class="col-auto align-self-center">
      <!-- <button type="button" class="btn text-white btn-44" (click)="backnav()">
          <i class="bi bi-arrow-left"></i>
        </button> -->
    </div>
    <div class="col align-self-center text-center px-0">
      <h6 class="title size-16">{{"NAME_CARD" | translate}}</h6>
    </div>
    <div class="col-auto align-self-center">
      <!-- <button class="btn text-white btn-44">
          <i class="bi bi-sliders size-22"></i>
        </button> -->
    </div>
  </div>

  <div class="row mb-5 mt-3 pb-5">
    <div class="col-12">
      <div class="text-center">
        <img src="assets/img/categories2.png" class="align-sub" alt="coin" />
      </div>

      <div class="card mt-5" style="border-radius: 10px !important">
        <div class="card-body p-0">
          <div class="text-center p-3" routerLink="">
            <b>{{"ADD_NAME_CARD" | translate}}</b>
          </div>
        </div>
      </div>
      <div class="card mt-3" style="border-radius: 10px !important">
        <div class="card-body p-0">
          <div class="text-center p-3" routerLink="/namecards">
            <b>{{"BACK_TO_LIST" | translate}}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

