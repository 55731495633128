<div class="wrapper-100vw">

  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            <!-- {{"SETTING" | translate}} -->
            {{"SPEAKERS" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="container text-center">
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\non_arkara.jpg" alt="รูปภาพที่ 2">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Non Arkara</p>
          <p class="size-14 text-center text-secondary">Senior Expert in Smart City Promotion
            Digital Economy Promotion Agency (depa)</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\joseph_hong.jpg" alt="รูปภาพที่ 2">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Joseph Hong</p>
          <p class="size-14 text-center text-secondary">Managing Director Bosch Thailand & Laos PDR</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\warat_panyatiyakul.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Warat Panyatiyakul</p>
          <p class="size-14 text-center text-secondary">Business Developer & Counseling Psychologist Me Hug</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\max_rye_.jpg" alt="รูปภาพที่ 4">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Max Rye</p>
          <p class="size-14 text-center text-secondary">Co-Founder and Chief Strategist Turtle Treev</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\piyorot_vana.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Piyorot Piyachan</p>
          <p class="size-14 text-center text-secondary">Founder and CEO Vana Farm</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\karl.png" alt="รูปภาพที่ 4">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Karl Von Luckwald</p>
          <p class="size-14 text-center text-secondary">Thailand Head Smart Air Filter</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\sam_michael.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Sam Michael</p>
          <p class="size-14 text-center text-secondary">Vice President Sales and Operations AtmosAir Asia</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\anthony_chow.jpg" alt="รูปภาพที่ 4">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Anthony Chow</p>
          <p class="size-14 text-center text-secondary">CEO & Co-founder igloocompany</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\om.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Om Kaosa-Ard</p>
          <p class="size-14 text-center text-secondary">Head of Investment and Venture Capital InnoPower</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\niramol.jpg" alt="รูปภาพที่ 4">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Niramol Direkmahamongkol</p>
          <p class="size-14 text-center text-secondary">Senior Director LIV-24</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\shotiwan.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Shotiwan Wattanalarp</p>
          <p class="size-14 text-center text-secondary">CEO Maxion</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\zenith.jpg" alt="รูปภาพที่ 4">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Zenith Ajhanwong</p>
          <p class="size-14 text-center text-secondary">CEO Shin-A</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\varin.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Dr.Varin Khera</p>
          <p class="size-14 text-center text-secondary">Co-Founder and CEO Cloudsec Asia</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\thanapant.jpg" alt="รูปภาพที่ 4">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">AM. Thanapant Raicharoen</p>
          <p class="size-14 text-center text-secondary">Ph.D. Commissioner NBTC</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\pacharawan.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Pacharawan Aksornsawas</p>
          <p class="size-14 text-center text-secondary">Innovation Counsellor NIA</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\john.jpg" alt="รูปภาพที่ 4">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">John Rattanaveroj</p>
          <p class="size-14 text-center text-secondary">President of D Tech Association</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\ekapak.png" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Ekapak Nirapathpongporn</p>
          <p class="size-14 text-center text-secondary">Co-founder and CEO Fraction</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\chonchayong.jpg" alt="รูปภาพที่ 4">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Chonchayong Trairatkeyoon</p>
          <p class="size-14 text-center text-secondary">Founder & CEO IncredibleBio</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\jakkarin.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Jakkarin Chatrungrueangchai</p>
          <p class="size-14 text-center text-secondary">Owner Farmday</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="\assets\img\speaker\montha.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Montha Kaihirun</p>
          <p class="size-14 text-center text-secondary">Innovation Counsellor Manager NIA</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/nithee.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">คุณนิธี สีแพร</p>
          <p class="size-14 text-center text-secondary">รองผู้ว่า ด้านดิจิทัล วิจัย และพัฒนาการท่องเที่ยวแห่งประเทศไทย (ททท.)</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/krishnayot.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">คุณกฤษณยศ บูรณะสัมฤทธิ</p>
          <p class="size-14 text-center text-secondary">รองผู้ประธานกรรมการบริหาร บริษัท คลาวด์เซค เอเชีย จำกัด</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/pongpan.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">คุณพงพันธ์ ด่านพิษณุพันธ์</p>
          <p class="size-14 text-center text-secondary">Co-Founder บริษัท ไอดิโอ เทค จำกัด</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/worakit.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">คุณวรกิตติ์ วาณิชยรรยง</p>
          <p class="size-14 text-center text-secondary">ประธานเจ้าหน้าที่บริหาร บริษัท ฮาโก้ อิเลคทริค (ประเทศไทย) จำกัด</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/yuthaporn_chitakasem.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Yuthaporn Chitakasem</p>
          <p class="size-14 text-center text-secondary">CEO and Managing Director Loxley Evolution Technology Co., Ltd.</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/keita.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Dr. Keita Ono</p>
          <p class="size-14 text-center text-secondary">Head of Big Win Fun Innospace</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/krit_kunplin.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Krit Kunplin</p>
          <p class="size-14 text-center text-secondary">CTO DazzBott TECH</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/kwanchai.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Kwanchai Mongkolkittaveepol</p>
          <p class="size-14 text-center text-secondary">CEO Nextate technology</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/teerawat_chanvichitkul.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Teerawat Chanvichitkul</p>
          <p class="size-14 text-center text-secondary">Vice President: Head Of Marketing Jaymart Mobile</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/passana_kongklieng.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Passana Kongklieng</p>
          <p class="size-14 text-center text-secondary">Marketing Executive Yindii</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/varitthorn.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Varitthorn Mungkornthongsakul</p>
          <p class="size-14 text-center text-secondary">CEO and Co-Founder Oho!</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/henry.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Henry Lane</p>
          <p class="size-14 text-center text-secondary">Owner Corvecto</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/anish_kapoor.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Anish Kapoor</p>
          <p class="size-14 text-center text-secondary">CTO Metabase</p>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="image-column mb-5">
          <img class="image" src="https://0110techland.co/assets/images/speakers/dr_ekarin_vasanasong.jpg" alt="รูปภาพที่ 3">
          <p class="speaker-name size-18 text-center pt-3 fw-bold">Dr. Ekarin Vasanasong</p>
          <p class="size-14 text-center text-secondary">CEO Botlink Company Limited</p>
        </div>
      </div>

    </div>
  </div>

</div>


