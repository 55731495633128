import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';

export interface Item {
  text: string;
  fillStyle: string;
  id: any;
}
export enum TextAlignment {
  INNER = 'inner',
  OUTER = 'outer',
  CENTER = 'center',
}

export enum TextOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  CURVED = 'curved',
}
@Component({
  selector: 'app-wheel-core',
  templateUrl: './wheel-core.component.html',
  styleUrls: ['./wheel-core.component.scss'],
})
export class WheelCoreComponent implements OnInit, AfterViewInit {
  constructor() {}

  @Input() height!: number;
  @Input() idToLandOn: any;
  @Input() width!: number;
  @Input() items!: Item[];
  @Input() spinDuration!: number;
  @Input() spinAmount!: number;
  @Input() innerRadius!: number;
  @Input() pointerStrokeColor!: string;
  @Input() pointerFillColor!: string;
  @Input() disableSpinOnClick!: boolean;
  @Input() textOrientation!: TextOrientation;
  @Input() textAlignment!: TextAlignment;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSpinStart: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSpinComplete: EventEmitter<any> = new EventEmitter();

  wheel: any;
  completedSpin = false;
  isSpinning = false;
  audio: any;

  reset() {
    this.wheel.stopAnimation(false);
    this.wheel.rotationAngle = 0;
    this.wheel.ctx.clearRect(
      0,
      0,
      this.wheel.ctx.canvas.width,
      this.wheel.ctx.canvas.height
    );
    this.isSpinning = false;
    this.completedSpin = false;
    this.ngAfterViewInit();
  }

  ngOnInit(): void {}

  spin() {
    if (this.completedSpin || this.isSpinning) { return; }
    this.isSpinning = true;
    this.onSpinStart.emit(null);

    const segmentToLandOn = this.wheel.segments
      .filter((x: any) => !!x)
      .find(({ id }: any) => this.idToLandOn === id);
    const segmentTheta = segmentToLandOn.endAngle - segmentToLandOn.startAngle;
    this.wheel.animation.stopAngle =
      segmentToLandOn.endAngle - segmentTheta / 4;
    this.wheel.startAnimation();
    setTimeout(() => {
      this.completedSpin = true;
      this.onSpinComplete.emit(null);
    }, this.spinDuration * 1000);
  }

  ngAfterViewInit() {
    const segments = this.items;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.wheel = new Winwheel({
      numSegments: segments.length,
      segments,
      innerRadius: this.innerRadius || 0,
      // outerRadius: this.height / 2 - 20,
      // centerY: this.height / 2 + 20,
      // textOrientation: this.textOrientation,
      // textAlignment: this.textAlignment,
      drawMode: 'image',
      drawText: false, // show text
      textFontFamily: 'Kanit, sans-serif',
      textFillStyle: 'black',
      // textFontSize: 2,
      // textLineWidth: 2,
      // textMargin: 10,
      responsive   : true,
      // scaleFactor: 1.1,
      pins :  {
        number : segments.length,
        outerRadius: 1
      },
      animation: {
        type: 'spinToStop', // Type of animation.
        // easing: 'Power4.easeOut',
        duration: this.spinDuration, // How long the animation is to take in seconds.
        spins: this.spinAmount, // The number of complete 360 degree rotations the wheel is to do.
        callbackSound: () => {
          this.playSound();
        }, // Specify function to call when sound is to be triggered.
        soundTrigger: 'pin', // Pins trigger the sound for this animation.
      },
    });

    const loadedImg = new Image();
    loadedImg.onload = () => {
      this.wheel.wheelImage = loadedImg; // Make wheelImage equal the loaded image object.
      this.wheel.draw(); // Also call draw function to render the wheel.
    };
    loadedImg.src = './assets/img/wheels/wheel.png';
    this.wheel.drawWheelImage();

    this.audio = new Audio('./assets/sounds/tick.mp3');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    TweenMax.ticker.addEventListener('tick', this.drawPointer.bind(this));
  }

  async playSound() {
    // Stop and rewind the sound (stops it if already playing).
    await this.audio.pause();
    this.audio.currentTime = 0;

    // Play the sound.
    await this.audio.play();
  }

  ngOnDestroy() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    TweenMax.ticker.removeEventListener('tick');
  }

  drawPointer() {
    //const c = this.wheel.ctx;
    // Create pointer.
    /*const img = new Image;
    img.onload = () => {
      c.drawImage(img, this.width / 2 - 20, 20, 40, 53);
    };
    img.src = './assets/img/wheels/needle.png';*/
    /*
    if (c) {
      c.save();
      c.lineWidth = 4;
      c.strokeStyle = this.pointerStrokeColor;
      c.fillStyle = this.pointerFillColor;
      c.beginPath();
      c.moveTo(this.width / 2 - 20, 2);
      c.lineTo(this.width / 2 + 20, 2);
      c.lineTo(this.width / 2, 40);
      c.lineTo(this.width / 2 - 20, 2);
      c.stroke();
      c.fill();
      c.restore();
    }*/
  }
}
