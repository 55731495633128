<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div>
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
          </div>
          <h2>
            <span class="size-18 text-white fw-bold text-capitalize">
              {{"NAMECARD_DETAIL" | translate}}
            </span>
          </h2>
          <div>
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" [routerLink]="['/qr-code-scanner']">
              <img src="assets/img/icon/scanner-white-20x20.png" alt="scanner" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">

    <app-card-namecard [user]="user"></app-card-namecard>

    <div class="row mb-5 mt-3 pb-5">
      <div class="col-12" class="text-center wrapper-btn-actions" *ngIf="user">
        <!-- <div *ngIf="user.profile">
          <img [src]="user.profile.picture ? user.profile.picture : 'assets/img/categories2.png'" class="align-sub" alt="coin" />
          <h3>{{user.profile.firstName}} {{user.profile.lastName}}</h3>
        </div> -->

        <div *ngIf="!isAddedContact">
          <button type="button" class="text-center btn btn-default"
            [disabled]="isLoading" (click)="addContact()">
            <b>{{ "ADD_NAME_CARD" | translate }}</b>
          </button>
        </div>

        <div *ngIf="isAddedContact" >
          <button type="button" class="text-center btn btn-outline-danger"
            [disabled]="isLoading" (click)="removeContact()">
            <b>{{ "REMOVE_FROM_LIST" | translate }}</b>
          </button>
        </div>

        <div>
          <button type="button" class="text-center btn btn-outline-secondary" [routerLink]="'/namecards'">
            <b>{{ "BACK_TO_LIST" | translate }}</b>
          </button>
        </div>

      </div>
    </div>

  </div>

</div>
