/* eslint-disable */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'staticfooter',
  templateUrl: './staticfooter.component.html',
  styleUrls: ['./staticfooter.component.scss'],
})
export class StaticfooterComponent implements OnInit {
  public isVisited = false;

  constructor(
    private _router: Router,
  ) {}

  ngOnInit(): void {
    const myModalEl = document.getElementsByClassName('menumodal')[0];
    const myModalBtn = document.getElementsByClassName('centerbtn')[0];
    myModalEl.addEventListener('hidden.bs.modal', function (event: any) {
      myModalBtn.classList.remove('active');
    });
  }

  checkVisited() {
    const myModalBtn = document.getElementsByClassName('centerbtn')[0];
    myModalBtn.classList.add('active');
  }

  navigate(to: string) {
    this._router.navigate([to]);
  }
}
