import { BackendService } from 'src/app/@services/backend.service';
/* eslint-disable */

import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { Logger } from 'src/app/@services/logger.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { UserService } from 'src/app/@services/user.service';
import { CardNamecardComponent } from 'src/app/@components/card-namecard/card-namecard.component';
import { Router } from '@angular/router';

const log = new Logger('NamecardListComponent');
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-namecard-list',
  templateUrl: './namecard-list.component.html',
  styleUrls: ['./namecard-list.component.scss'],
})
export class NamecardListComponent {

  isLoading:any = false
  nameCardList:any = []

  limit=5;
  page=1;
  totalPages = 1;
  totalResults:any = undefined;

  constructor(
    private _tokenStorageService: TokenStorageService,
    private userService: UserService,
    public translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private backendService: BackendService,
    private _router:Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  async ngOnInit() {
    log.debug("namecardList!")
    await this.getContactList()
  }

  resetPaginate(){
    this.page = 1
    this.totalPages = 1;
    this.nameCardList = []
    this.totalResults = undefined
  }

  async getContactList(){
    this.isLoading = true
    const currentUser = this._authenticationService.currentUserSubject.value;
    log.debug("getContactList currentUser: %o",currentUser)

    try{
      const getContactListRes:any = await this.backendService.getContactList(
        this.limit,
        this.page
      )
      log.debug("res getContactListRes: %o",getContactListRes)
      if(getContactListRes){
        const results = getContactListRes.results

        // this.nameCardList = getContactListRes.results
        this.nameCardList = [...this.nameCardList,...results]

        this.totalPages = getContactListRes.totalPages
        this.totalResults = getContactListRes.totalResults

        log.debug("this.nameCardList: %o",this.nameCardList)

        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }

  }

  onScroll() {
    log.debug("onScroll...")

    if(this.page < this.totalPages){
      this.page++;
      this.getContactList()
    }
  }

  backnav() {
    this._router.navigate(['/']);
  }
}
