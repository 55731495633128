import { RouterExtService } from './@services/router-ext.service';
import { ErrorInterceptor } from './@helpers/error.interceptor';
import { QuestionnaireComponent } from './@components/questionnaire/questionnaire.component';
import { ForgotPasswordConfirmComponent } from './@components/forgot-password-confirm/forgot-password-confirm.component';
import { ForgotPasswordComponent } from './@components/forgot-password/forgot-password.component';
import { QrCodeModalComponent } from './@components/qr-code-modal/qr-code-modal.component';
import { OtpCheckingComponent } from './@components/otp-checking/otp-checking.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SwiperModule } from 'swiper/angular';
import { ChartsModule } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NouisliderModule } from 'ng2-nouislider';
import { FullCalendarModule } from '@fullcalendar/angular';
import { Daterangepicker } from 'ng2-daterangepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
} from 'ngx-mask';
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { JwtInterceptor } from 'src/app/@helpers/jwt.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NumeralModule } from 'ngx-numeral';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AuthlayoutComponent } from './authlayout/authlayout.component';
import { StaticfooterComponent } from './@components/partials/staticfooter/staticfooter.component';
import { HeadermenuComponent } from './@components/partials/headermenu/headermenu.component';
import { SidebarComponent } from './@components/partials/sidebar/sidebar.component';
import { WalletlayoutComponent } from './walletlayout/walletlayout.component';
import { HomeComponent } from './walletlayout/home/home.component';
import { PointComponent } from './walletlayout/point/point.component';
import { PointhistoryComponent } from './walletlayout/pointhistory/pointhistory.component';
import { DownloadComponent } from './walletlayout/download/download.component';
import { ExhibitorComponent } from './innerlayout/exhibitor/exhibitor.component';
import { InnerlayoutComponent } from './innerlayout/innerlayout.component';
import { ExhibitordetailComponent } from './innerlayout/exhibitordetail/exhibitordetail.component';
import { LandingComponent } from './authlayout/landing/landing.component';
import { SigninComponent } from './authlayout/signin/signin.component';
import { SignupComponent } from './authlayout/signup/signup.component';
import { ForgetpasswordComponent } from './authlayout/forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './authlayout/resetpassword/resetpassword.component';
import { VerifyComponent } from './authlayout/verify/verify.component';
import { ThankyouComponent } from './authlayout/thankyou/thankyou.component';
import { HighlightroomComponent } from './innerlayout/highlightroom/highlightroom.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagenotfoundComponent } from './authlayout/pagenotfound/pagenotfound.component';
import { ExhibitorreviewComponent } from './innerlayout/exhibitorreview/exhibitorreview.component';
import { ModalAddreviewexhibitorComponent } from './@components/modal-addreviewexhibitor/modal-addreviewexhibitor.component';
import { ModalNotemaskexhibitorComponent } from './@components/modal-notemaskexhibitor/modal-notemaskexhibitor.component';
import { MyexhibitornoteComponent } from './innerlayout/myexhibitornote/myexhibitornote.component';
import { ModalMoremenuComponent } from './@components/modal-moremenu/modal-moremenu.component';
import { FooterinfoComponent } from './@components/partials/footerinfo/footerinfo.component';
import { AuthWrapperComponent } from './@helpers/auth-wrapper.component';
import { ModalGosigninComponent } from './@components/modal-gosignin/modal-gosignin.component';
import { CardExhibitorComponent } from './@components/card-exhibitor/card-exhibitor.component';
import { CardPointbalanceComponent } from './@components/card-pointbalance/card-pointbalance.component';
import { CountdownModule } from 'ngx-countdown';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalSignupUserinfoComponent } from './@components/modal-signup-userinfo/modal-signup-userinfo.component';
import { QrTicketComponent } from './innerlayout/qr-ticket/qr-ticket.component';
import { CouponrewardComponent } from './innerlayout/coupon-reward/couponreward.component';
import { ModalNewrewardComponent } from './@components/modal-newreward/modal-newreward.component';
import { NewsComponent } from './innerlayout/news/news.component';
import { NewsdetailComponent } from './innerlayout/newsdetail/newsdetail.component';
import { ReviewusComponent } from './innerlayout/reviewus/reviewus.component';
import { LanguagesettingComponent } from './innerlayout/languagesetting/languagesetting.component';
import { ChangepasswordComponent } from './innerlayout/changepassword/changepassword.component';
import { ContactusComponent } from './innerlayout/contactus/contactus.component';
import { FaqComponent } from './innerlayout/faq/faq.component';
import { TermsandconditionsComponent } from './innerlayout/termsandconditions/termsandconditions.component';
import { AboutusComponent } from './innerlayout/aboutus/aboutus.component';
import { PrivacypolicyComponent } from './innerlayout/privacypolicy/privacypolicy.component';
import { ProfileComponent } from './walletlayout/profile/profile.component';
import { QrCodeScannerComponent } from './innerlayout/qr-code-scanner/qr-code-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ProfilesettingComponent } from './innerlayout/profilesetting/profilesetting.component';
import { ModalTransferSendComponent } from './@components/modal-transfer-send/modal-transfer-send.component';
import { MycouponrewardComponent } from './innerlayout/mycouponreward/mycouponreward.component';
import { CardCouponComponent } from './@components/card-coupon/card-coupon.component';
import { ModalRedeemcouponComponent } from './@components/modal-redeemcoupon/modal-redeemcoupon.component';
import { CardMycouponComponent } from './@components/card-mycoupon/card-mycoupon.component';
import { CardExhibitorReviewComponent } from './@components/card-exhibitor-review/card-exhibitor-review.component';
import { ModalExhibitorBrochureListComponent } from './@components/modal-exhibitor-brochure-list/modal-exhibitor-brochure-list.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ModalSetupPincodeComponent } from './@components/modal-setup-pincode/modal-setup-pincode.component';
import { PincodeNumpadComponent } from './@components/pincode-numpad/pincode-numpad.component';
import { ModalVerifyPincodeComponent } from './@components/modal-verify-pincode/modal-verify-pincode.component';
import { ModalReceivePointComponent } from './@components/modal-receive-point/modal-receive-point.component';
import { QrcodeScannerComponent } from './@components/qrcode-scanner/qrcode-scanner.component';
import { DetectLinkPageComponent } from './innerlayout/detect-link-page/detect-link-page.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NamecardListComponent } from './innerlayout/namecard-list/namecard-list.component';
import { NamecardDetailComponent } from './innerlayout/namecard-detail/namecard-detail.component';
import { AddNamecardComponent } from './innerlayout/add-namecard/add-namecard.component';
import { DashboardComponent } from './walletlayout/dashboard/dashboard.component';
import { WalletComponent } from './walletlayout/wallet/wallet.component';
import { PincodeSettingComponent } from './innerlayout/pincode-setting/pincode-setting.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ModalGalleryComponent } from './@components/modal-gallery/modal-gallery.component';
import { ModalAssetsDetailComponent } from './@components/modal-assets-detail/modal-assets-detail.component';
import { AuthComponent } from './authlayout/auth/auth.component';
import { ModalSocialsSharingComponent } from './@components/modal-socials-sharing/modal-socials-sharing.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxWheelModule } from 'ngx-wheel';
import { WheelSpinnerComponent } from './innerlayout/wheel-spinner/wheel-spinner.component';
import { SettingComponent } from './innerlayout/setting/setting.component';
import { HelpAndSupportComponent } from './innerlayout/help-and-support/help-and-support.component';
import { PrivacyAndDataComponent } from './innerlayout/privacy-and-data/privacy-and-data.component';
import { CardNamecardComponent } from './@components/card-namecard/card-namecard.component';
import { ModalQrcodeNamecardComponent } from './@components/modal-qrcode-namecard/modal-qrcode-namecard.component';
import { MynamecardComponent } from './innerlayout/mynamecard/mynamecard.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ModalMynamecardComponent } from './@components/modal-mynamecard/modal-mynamecard.component';
import { ModalInstallPwaComponent } from './@components/modal-install-pwa/modal-install-pwa.component';
import { QuestHuntingSubComponent } from './innerlayout/quest-hunting-sub/quest-hunting-sub.component';
import { QuestHuntingComponent } from './innerlayout/quest-hunting/quest-hunting.component';
import { QuestHuntingListComponent } from './innerlayout/quest-hunting-list/quest-hunting-list.component';
import { MyHuntingComponent } from './innerlayout/my-hunting/my-hunting.component';
import { CardQuestHuntingComponent } from './@components/card-quest-hunting/card-quest-hunting.component';
import { ModalQrcodeRewardComponent } from './@components/modal-qrcode-reward/modal-qrcode-reward.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
} from '@angular/fire/analytics';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import {
  provideRemoteConfig,
  getRemoteConfig,
} from '@angular/fire/remote-config';
import { CouponDetailComponent } from './innerlayout/coupon-detail/coupon-detail.component';
import { NftGalleryListComponent } from './innerlayout/nft-gallery-list/nft-gallery-list.component';
import { NftGalleryDetailComponent } from './innerlayout/nft-gallery-detail/nft-gallery-detail.component';
import { WheelCoreComponent } from './@components/wheel-core/wheel-core.component';
import { ConfettiComponent } from './@components/confetti/confetti.component';
import { ReceiveComponent } from './innerlayout/receive/receive.component';
import { AgendaComponent } from './innerlayout/agenda/agenda.component';
import { NotificationComponent } from './innerlayout/notification/notification.component';
import { ModalRequireNotificationPermissionComponent } from './@components/modal-require-notification-permission/modal-require-notification-permission.component';
import { WallWheelSpinnerComponent } from './innerlayout/wall-wheel-spinner/wall-wheel-spinner.component';
import { SpeakerComponent } from './innerlayout/speaker/speaker.component';
import { CoreDirectivesModule } from './directives/directive.module';
import { NftGalleryTarotComponent } from './innerlayout/nft-gallery-tarot/nft-gallery-tarot.component';
import { ModalBuycouponComponent } from './@components/modal-buycoupon/modal-buycoupon.component';
import { PaperwalletComponent } from './authlayout/paperwallet/paperwallet.component';
import { ModalSetNewPasswordComponent } from './@components/modal-set-new-password/modal-set-new-password.component';
import { StampBySelfComponent } from './innerlayout/stamp-by-self/stamp-by-self.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AuthlayoutComponent,
    WalletlayoutComponent,
    InnerlayoutComponent,
    HomeComponent,
    PointComponent,
    PointhistoryComponent,
    DownloadComponent,
    ExhibitorComponent,
    ExhibitordetailComponent,
    HighlightroomComponent,
    ExhibitorreviewComponent,
    MyexhibitornoteComponent,
    LandingComponent,
    SigninComponent,
    SignupComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    VerifyComponent,
    ThankyouComponent,
    PagenotfoundComponent,
    ProfileComponent,
    HeadermenuComponent,
    SidebarComponent,
    StaticfooterComponent,
    FooterinfoComponent,
    ModalAddreviewexhibitorComponent,
    ModalNotemaskexhibitorComponent,
    ModalMoremenuComponent,
    AuthWrapperComponent,
    ModalGosigninComponent,
    CardExhibitorComponent,
    CardPointbalanceComponent,
    OtpCheckingComponent,
    ModalSignupUserinfoComponent,
    QrCodeModalComponent,
    ForgotPasswordComponent,
    ForgotPasswordConfirmComponent,
    QuestionnaireComponent,
    QrTicketComponent,
    CouponrewardComponent,
    ModalNewrewardComponent,
    NewsComponent,
    NewsdetailComponent,
    ReviewusComponent,
    LanguagesettingComponent,
    ChangepasswordComponent,
    ContactusComponent,
    FaqComponent,
    TermsandconditionsComponent,
    AboutusComponent,
    PrivacypolicyComponent,
    QrCodeScannerComponent,
    ProfilesettingComponent,
    ModalTransferSendComponent,
    MycouponrewardComponent,
    CardCouponComponent,
    ModalRedeemcouponComponent,
    CardMycouponComponent,
    CardExhibitorReviewComponent,
    ModalExhibitorBrochureListComponent,
    ModalSetupPincodeComponent,
    PincodeNumpadComponent,
    ModalVerifyPincodeComponent,
    ModalReceivePointComponent,
    QrcodeScannerComponent,
    DetectLinkPageComponent,
    NamecardListComponent,
    NamecardDetailComponent,
    AddNamecardComponent,
    DashboardComponent,
    WalletComponent,
    PincodeSettingComponent,
    ModalGalleryComponent,
    ModalAssetsDetailComponent,
    AuthComponent,
    ModalSocialsSharingComponent,
    WheelSpinnerComponent,
    SettingComponent,
    HelpAndSupportComponent,
    PrivacyAndDataComponent,
    CardNamecardComponent,
    ModalQrcodeNamecardComponent,
    MynamecardComponent,
    ModalMynamecardComponent,
    ModalInstallPwaComponent,
    QuestHuntingSubComponent,
    QuestHuntingComponent,
    QuestHuntingListComponent,
    MyHuntingComponent,
    CardQuestHuntingComponent,
    ModalQrcodeRewardComponent,
    CouponDetailComponent,
    NftGalleryListComponent,
    NftGalleryDetailComponent,
    WheelCoreComponent,
    ConfettiComponent,
    ReceiveComponent,
    AgendaComponent,
    NotificationComponent,
    ModalRequireNotificationPermissionComponent,
    WallWheelSpinnerComponent,
    SpeakerComponent,
    NftGalleryTarotComponent,
    ModalBuycouponComponent,
    PaperwalletComponent,
    ModalSetNewPasswordComponent,
    StampBySelfComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    ChartsModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgCircleProgressModule.forRoot(),
    NumeralModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      primaryColour: '#4b0082',
      secondaryColour: '#ffd42a',
      backdropBorderRadius: '3px',
    }),
    NgbModule,
    NouisliderModule,
    FullCalendarModule,
    Daterangepicker,
    MatTabsModule,
    HttpClientModule,
    SocialLoginModule,
    CountdownModule,
    NgxOtpInputModule,
    NgxIntlTelInputModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ZXingScannerModule,
    QRCodeModule,
    NgImageSliderModule,
    NgxWheelModule,
    CoreDirectivesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
    // provideMessaging(() => getMessaging()),
    AngularFireMessagingModule,
  ],
  providers: [
    NgbActiveModal,
    provideEnvironmentNgxMask(),
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookId),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    ScreenTrackingService,
    RouterExtService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
