import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventToken } from 'src/models/eventToken.model';
import { ModalTransferSendComponent } from '../modal-transfer-send/modal-transfer-send.component';
import { ModalReceivePointComponent } from '../modal-receive-point/modal-receive-point.component';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'card-pointbalance',
  templateUrl: './card-pointbalance.component.html',
  styleUrls: ['./card-pointbalance.component.scss'],
})
export class CardPointbalanceComponent {
  @Input('token') token: EventToken | null;
  @Input('balance') balance: number;
  @Output() reloadCallback = new EventEmitter<void>();

  constructor(
    private modalService: NgbModal,
    private singletonService: SingletonService,
  ) {}

  openSendModal() {
    this.singletonService.showNotAvaliabledFeatureSwalAlert()

    // const modalRef = this.modalService.open(ModalTransferSendComponent, {
    //   centered: true,
    // });
    // modalRef.componentInstance.token = this.token;
    // modalRef.componentInstance.balance = this.balance;
    // modalRef.result.then(
    //   (data) => {
    //     data === 'success' && this.reloadCallback.emit();
    //   },
    //   (reason) => {}
    // );
  }

  openReceiveModal() {
    this.singletonService.showNotAvaliabledFeatureSwalAlert()

    // const modalRef = this.modalService.open(ModalReceivePointComponent, {
    //   centered: true,
    //   fullscreen: true,
    //   keyboard: false,
    //   windowClass: 'modal-fullscreen-95',
    //   backdrop: false,
    //   scrollable: true,
    // });
  }
}
