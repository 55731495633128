<div class="modal-header py-3 align-items-center justify-content-between">
  <button class="btn btn-arrow-back" type="button" (click)="dismissModal()">
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h3 class="text-white size-20">{{ "SIGNIN.CREATE_ACCOUNT" | translate }}</h3>
  <button class="btn btn-top-right-arrow" type="button"></button>
  <!-- <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button> -->
</div>
<div class="modal-body px-3 px-sm-4" #modalbody>
  <div class="alert alert-danger mt-2 mb-4" role="alert" *ngIf="!!error">
    {{ error }}
  </div>

  <ng-container *ngIf="!!paperWalletCode">
    <form class="mt-3" [formGroup]="passwordForm">
      <label
        class="form-control-label text-uppercase mb-2 size-14 text-label"
        for="password"
        >{{ "PASSWORD" | translate }}</label
      >
      <div
        class="form-group form-floating mb-3 px-0 input-group input-group-merge"
      >
        <input
          class="form-control button-inside"
          [type]="isShow ? 'text' : 'password'"
          formControlName="password"
          placeholder="{{ 'NEW_PASSWORD' | translate }}"
          tabindex="2"
          name="password"
          value=""
          id="password"
          [class.is-invalid]="
            formService.isControlInvalid(passwordForm, 'password')
          "
        />
        <div class="input-group-append">
          <span class="input-group-text cursor-pointer icon-input"
            ><i
              class="font-small-4 bi"
              [ngClass]="{
                'bi-eye-slash': !isShow,
                'bi-eye': isShow
              }"
              (click)="showPassword()"
            ></i
          ></span>
        </div>
        <div
          class="invalid-feedback px-3"
          *ngIf="
            formService.controlHasError(passwordForm, 'minlength', 'password')
          "
        >
          {{ "SIGNIN.PASSWORD_VALIDATE" | translate }}
        </div>
        <div
          class="invalid-feedback px-3 pb-3"
          *ngIf="
            formService.controlHasError(
              passwordForm,
              'passwordStrength',
              'password'
            )
          "
        >
          {{ "SIGNIN.PASSWORD_VALIDATE_STRICT" | translate }}
        </div>
      </div>

      <label
        class="form-control-label text-uppercase mb-2 size-14 text-label"
        for="confirmPassword"
        >{{ "CONFIRM_PASSWORD" | translate }}</label
      >
      <div
        class="form-group form-floating px-0 mb-3 input-group input-group-merge"
      >
        <input
          class="form-control button-inside"
          [type]="isShowRecomfirm ? 'text' : 'password'"
          formControlName="confirmPassword"
          placeholder="{{ 'CONFIRM_NEW_PASSWORD' | translate }}"
          tabindex="2"
          name="confirmPassword"
          value=""
          id="confirmPassword"
          [class.is-invalid]="
            formService.isControlInvalid(passwordForm, 'confirmPassword')
          "
        />
        <div class="input-group-append">
          <span class="input-group-text cursor-pointer icon-input"
            ><i
              class="font-small-4 bi"
              [ngClass]="{
                'bi-eye-slash': !isShowRecomfirm,
                'bi-eye': isShowRecomfirm
              }"
              (click)="showConfirm()"
            ></i
          ></span>
        </div>
        <div
          class="invalid-feedback px-3 pb-3"
          *ngIf="
            formService.controlHasError(
              passwordForm,
              'confirmPasswordValidator',
              'confirmPassword'
            )
          "
        >
          {{ "SIGNIN.PASSWORD_MATCH" | translate }}
        </div>
      </div>
    </form>

    <div class="my-4">
      <hr />
    </div>
  </ng-container>

  <form [formGroup]="infoForm">
    <div class="file-container my-4" *ngIf="!paperWalletCode">
      <label for="files-input" *ngIf="!avatarPreview">
        <div class="dropzone">
          <div class="upload-icon">
            <i class="bi bi-cloud-upload size-40 text-secondary"></i>
            <span class="size-14 text-secondary fw-600 text-uppercase">{{
              "UPLOAD" | translate
            }}</span>
          </div>
          <input
            #inputRef
            id="files-input"
            type="file"
            class="file-input"
            (change)="onInputFileChange($event)"
          />
        </div>
      </label>

      <div
        class="preview-image-container active-profile image-profile-preview aura-effect"
        *ngIf="!!avatarPreview"
      >
        <img
          [src]="avatarPreview"
          alt="avatarPreview"
          width="100"
          class="avatar-profile-img"
        />
        <button (click)="removeAvatar()" type="button" class="btn">
          <i class="bi bi-x size-20 text-white"></i>
        </button>
      </div>
    </div>

    <label
      class="form-control-label text-uppercase mb-2 size-14 text-label"
      for="firstName"
      >{{ "FIRSTNAME" | translate }}</label
    >
    <div class="form-group form-floating mb-3">
      <input
        class="form-control"
        [class.is-invalid]="
          infoForm.get('firstName')?.invalid &&
          infoForm.get('firstName')?.touched
        "
        type="text"
        formControlName="firstName"
        placeholder="{{ 'FIRSTNAME' | translate }}"
        name="firstName"
      />
      <div
        class="w-100"
        *ngIf="
          (infoForm.get('firstName')?.invalid &&
            infoForm.get('firstName')?.touched) ||
          infoForm.get('firstName')?.dirty
        "
      >
        <small
          *ngIf="infoForm.get('firstName')?.errors?.required"
          class="text-danger d-block w-100 px-3 pb-3"
          >{{ "FIRSTNAME" | translate }} {{ "IS_REQUIRED" | translate }}</small
        >
      </div>
    </div>

    <label
      class="form-control-label text-uppercase mb-2 size-14 text-label"
      for="lastName"
      >{{ "LASTNAME" | translate }}</label
    >
    <div class="form-group form-floating mb-3">
      <input
        class="form-control"
        [class.is-invalid]="
          infoForm.get('lastName')?.invalid && infoForm.get('lastName')?.touched
        "
        type="text"
        formControlName="lastName"
        placeholder="{{ 'LASTNAME' | translate }}"
        name="Lastname"
      />
      <div
        class="w-100"
        *ngIf="
          (infoForm.get('lastName')?.invalid &&
            infoForm.get('lastName')?.touched) ||
          infoForm.get('lastName')?.dirty
        "
      >
        <small
          *ngIf="infoForm.get('lastName')?.errors?.required"
          class="text-danger d-block w-100 px-3 pb-3"
          >{{ "LASTNAME" | translate }} {{ "IS_REQUIRED" | translate }}</small
        >
      </div>
    </div>

    <label
      class="form-control-label text-uppercase mb-2 size-14 text-label"
      for="mobilePhone"
      >{{ "MOBILE_PHONE_NUMBER" | translate }}</label
    >
    <div class="form-group form-floating mb-3 input-group input-group-merge">
      <div class="input-group-prepend d-flex align-items-end">
        <span class="input-group-text" id="basic-addon1">0</span>
      </div>
      <input
        class="form-control px-2 pb-2"
        [class.is-invalid]="
          infoForm.get('mobilePhone')?.invalid &&
          infoForm.get('mobilePhone')?.touched
        "
        type="text"
        formControlName="mobilePhone"
        placeholder="{{ 'MOBILE_PHONE_NUMBER' | translate }}"
        name="mobilePhone"
        mask="99 999 9999"
      />
      <div
        class="w-100"
        *ngIf="
          (infoForm.get('mobilePhone')?.invalid &&
            infoForm.get('mobilePhone')?.touched) ||
          infoForm.get('mobilePhone')?.dirty
        "
      >
        <small
          *ngIf="infoForm.get('mobilePhone')?.errors?.required"
          class="text-danger d-block w-100 px-3 pb-3"
          >{{ "MOBILE_PHONE_NUMBER" | translate }}
          {{ "IS_REQUIRED" | translate }}</small
        >
      </div>
    </div>

    <label
      class="form-control-label text-uppercase mb-2 size-14 text-label"
      for="email"
      >{{ "EMAIL" | translate }}</label
    >
    <div class="form-group form-floating mb-3">
      <input
        class="form-control"
        [class.is-invalid]="
          infoForm.get('email')?.invalid && infoForm.get('email')?.touched
        "
        type="email"
        formControlName="email"
        placeholder="{{ 'EMAIL' | translate }}"
        name="email"
      />
      <div
        class="w-100"
        *ngIf="
          (infoForm.get('email')?.invalid && infoForm.get('email')?.touched) ||
          infoForm.get('email')?.dirty
        "
      >
        <small
          *ngIf="infoForm.get('email')?.errors?.required"
          class="text-danger d-block w-100 px-3 pb-3"
          >{{ "EMAIL" | translate }} {{ "IS_REQUIRED" | translate }}</small
        >
        <small
          *ngIf="infoForm.get('email')?.errors?.email"
          class="text-danger d-block w-100 px-3 pb-3"
          >{{ "PLEASE_PROVIDE_A_VALID_EMAIL_ADDRESS" | translate }}</small
        >
      </div>
    </div>

    <div class="form-check mt-4 pt-2">
      <input
        class="form-check-input"
        type="checkbox"
        formControlName="isPrivilege"
        name="isPrivilege"
        id="isPrivilege"
      />
      <label
        class="form-check-label ms-2 text-secondary size-14"
        for="isPrivilege"
      >
        {{ "SIGNIN.POLICY_ONE" | translate }}
      </label>
    </div>

    <div class="form-check mt-3">
      <input
        [class.is-invalid]="
          infoForm.get('isAgreement')?.invalid &&
          infoForm.get('isAgreement')?.touched
        "
        class="form-check-input"
        type="checkbox"
        formControlName="isAgreement"
        name="isAgreement"
        id="isAgreement"
      />
      <label
        class="form-check-label ms-2 text-secondary size-14"
        for="isAgreement"
      >
        {{ "SIGNIN.POLICY_TWO_ONE" | translate }}
        <button
          type="button"
          class="btn btn-link align-baseline agree-policy p-0 text-white size-14"
          (click)="openAgreement()"
        >
          {{ "SIGNIN.POLICY_TWO_TWO" | translate }}
        </button>
        {{ "SIGNIN.POLICY_TWO_THREE" | translate }}
        <button
          type="button"
          class="btn btn-link align-baseline agree-policy p-0 text-white size-14"
          (click)="openPolicy()"
        >
          {{ "SIGNIN.POLICY_TWO_FOUR" | translate }}
        </button>
      </label>
    </div>

    <div
      class="ps-4 ms-1"
      *ngIf="
        (infoForm.get('isAgreement')?.invalid &&
          infoForm.get('isAgreement')?.touched) ||
        infoForm.get('isAgreement')?.dirty
      "
    >
      <small
        *ngIf="infoForm.get('isAgreement')?.errors?.required"
        class="text-danger"
        >{{ "AGREEMENT" | translate }} {{ "IS_REQUIRED" | translate }}</small
      >
    </div>

    <div class="form-check mt-3" *ngIf="!paperWalletCode">
      <input
        [class.is-invalid]="
          infoForm.get('isPress')?.invalid && infoForm.get('isPress')?.touched
        "
        class="form-check-input"
        type="checkbox"
        formControlName="isPress"
        name="isPress"
        id="isPress"
      />
      <label class="form-check-label ms-2 text-secondary size-14" for="isPress">
        {{ "SIGNIN.POLICY_THREE" | translate }}
      </label>
    </div>

    <div class="mt-4 mb-4 pt-2 text-center">
      <button
        *ngIf="!paperWalletCode"
        type="submit"
        class="btn btn-default btn-lg w-100"
        (click)="onSubmitNormalSignUp()"
        [disabled]="isLoading || infoForm.invalid"
      >
        {{ "SIGNIN.SIGNUP" | translate }}
      </button>
      <button
        *ngIf="!!paperWalletCode"
        type="submit"
        class="btn btn-default btn-lg w-100"
        (click)="onSubmitWithPaperWalletCode()"
        [disabled]="
          isLoading ||
          infoForm.invalid ||
          formService.controlHasError(
            passwordForm,
            'confirmPasswordValidator',
            'confirmPassword'
          )
        "
      >
        {{ "SIGNIN.SIGNUP" | translate }}
      </button>
    </div>
  </form>
</div>
