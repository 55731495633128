import { Component } from '@angular/core';
import { Router } from '@angular/router';

import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {

  constructor(
    private _router:Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) { }

  backnav() {
    this.singletonService.back();
  }
}
