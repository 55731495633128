<div class="row h-100">
    <div class="col-11 col-sm-11 mx-auto">
        <!-- header -->
        <div class="row">
            <header class="header">
                <div class="row">
                    <div class="col">
                        <div class="logo-small">
                            <img src="assets/img/logo.png" alt="" />
                            <h5><span class="text-secondary fw-light">{{"FINANCE_WALLET_01" | translate}}</span><br />{{"FINANCE_WALLET_02" | translate}}</h5>
                        </div>
                    </div>
                    <div class="col-auto align-self-center">
                        <a routerLink="/signin">{{"SIGN_IN" | translate}}</a>
                    </div>
                </div>
            </header>
        </div>
        <!-- header ends -->
    </div>
    <div class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
        <h1 class="mb-3"><span class="text-secondary fw-light">{{"SIGNIN.FORGOT_PASSWORD" | translate}}</span></h1>
        <p class="text-secondary mb-4">{{"PROVIDE_EMAIL_TO_CHANGE_PASSWORD" | translate}}</p>
        <div class="form-group form-floating mb-3 is-valid">
            <input type="text" class="form-control" value="maxartkiller" id="email" placeholder="Username">
            <label class="form-control-label" for="email">{{"USERNAME" | translate}}</label>
        </div>
    </div>
    <div class="col-11 col-sm-11 mt-auto mx-auto py-4">
        <div class="row ">
            <div class="col-12 d-grid">
                <a routerLink="/resetpassword" class="btn btn-default btn-lg">{{"RESET_PASSWORD" | translate}}</a>
            </div>
        </div>
    </div>
</div>
