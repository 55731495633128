<main #mainPage>
  <div
    class="main-container container px-0"
    style="min-height: 100vh"
    #mainContainer
  >
    <div [@routerTransition]="getRouteAnimationState()">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <app-footerinfo #FooterEl class="container-fluid d-block"></app-footerinfo> -->
</main>
