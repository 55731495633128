<div class="wrapper-100vw">
  <div class="wrapper-top-header mt-0 pb-3 mb-0">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="back()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-0">
          <h6 class="title size-16">
            {{ galleryImage ? galleryImage.name : "" }}
          </h6>
        </div>
        <div class="col-auto align-self-center"></div>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="text-center">
    <div class="text-center my-5 py-3 empty-wrapper">
      <div class="spinner-border"></div>
      <p class="text-center fw-normal size-14 mt-3">
        {{ "LOADING" | translate }}
      </p>
    </div>
  </div>

  <ng-container *ngIf="!isLoading">
    <img
      [src]="galleryImage.imageUrl"
      alt=""
      class="w-100 coverimg"
      *ngIf="galleryImage.fileType === GalleryFileType.IMAGE"
    />

    <video
      class="w-100 coverimg"
      controls
      *ngIf="galleryImage.fileType === GalleryFileType.VIDEO"
    >
      <source [src]="galleryImage.videoUrl + '#t=0.1'" type="video/mp4" />
      {{ "YOUR_BROWSER_DOES_NOT_SUPPORT" | translate }}
    </video>

    <div class="container mt-4">
      <div class="row">
        <div class="col-12">
          <h6>{{ "INFORMATION" | translate }}</h6>
          <h5 class="mt-2">{{ galleryImage.name }}</h5>
          <div
            class="badge bg-theme me-2 mt-3"
            *ngFor="let tag of galleryImage.tags"
          >
            {{ tag }}
          </div>
        </div>
        <div class="col-6 text-center mt-5">
          <div
            (click)="
              singletonService.downloadMediaFromUrl(
                galleryImage.getUrl(),
                galleryImage.getNameWithSuffix()
              )
            "
            class=""
          >
            <img
              src="assets/img/icon/icon-actions/download.png"
              alt="download"
            />
            <div class="mt-4">{{ "DOWNLOAD" | translate }}</div>
          </div>
        </div>
        <div class="col-6 text-center mt-5">
          <div (click)="singletonService.openModalSocialSharing()" class="">
            <img src="assets/img/icon/icon-actions/share.png" alt="share" />
            <div class="mt-4">{{ "SHARE" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
