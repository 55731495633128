import { Component, EventEmitter, Output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { Subscription } from 'rxjs';
import { QrCodeReader } from 'src/app/@services/qr-code-reader.service';

@Component({
  selector: 'qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss'],
})
export class QrcodeScannerComponent {
  @Output('onDecode') onDecode = new EventEmitter<string>();

  availableDevices: MediaDeviceInfo[] | undefined;
  currentDevice: MediaDeviceInfo | undefined;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices = false;
  subscription: Subscription;

  constructor(private qrReader: QrCodeReader) {}

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.onDecode.emit(resultString);
  }

  onDeviceSelectChange(event: any) {
    if (this.availableDevices) {
      const selected = event.target.value;
      const device = this.availableDevices.find((x) => x.deviceId === selected);
      this.currentDevice = device || undefined;
    }
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    this.subscription = this.qrReader
      .decode(file)
      .subscribe((decodedString) => {
        this.onCodeResult(decodedString);
      });
  }
}
