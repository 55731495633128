/* eslint-disable */

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { PointResult } from 'src/models/point.model';
import {
  TransectionResult,
  TransectionType,
} from 'src/models/tokenTransection.model';
import { User } from 'src/models/user.model';
import { ModalTransferSendComponent } from '../modal-transfer-send/modal-transfer-send.component';
import { ModalReceivePointComponent } from '../modal-receive-point/modal-receive-point.component';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-modal-assets-detail',
  templateUrl: './modal-assets-detail.component.html',
  styleUrls: ['./modal-assets-detail.component.scss'],
})
export class ModalAssetsDetailComponent implements OnInit {
  @Input('token') token: PointResult;
  loading = true;
  error = '';
  // transections: TransectionResult[];
  transections: any[];
  transectionsPage = 1;
  transectionsLoadMore = false;
  currentUser: User;

  constructor(
    public modal: NgbActiveModal,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private singletonService: SingletonService,
  ) {}

  async ngOnInit() {
    this.currentUser = this.authenticationService.currentUserSubject.value;
    await this.getTransactions();
  }

  async getTransactions() {
    try {
      this.loading = true;
      const transection = await this.fetchAllTokenTransactions(1);
      this.transections = transection?.results || [];
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
      // if (error.code === 401) {
      //   this.errorService.goSignIn();
      // }
    } finally {
      this.loading = false;
    }
  }

  async fetchAllTokenTransactions(page: number) {
    try {
      const transection = (await this.backendService.getAllPointTransactions({
        token: this.token.token.id,
        limit: 10,
        page,
      })) as TransectionType;
      return transection;
    } catch (err) {
      throw err;
    }
  }

  async onTransactionsScrollEnd() {
    this.transectionsPage++;
    this.transectionsLoadMore = true;
    const transection = await this.fetchAllTokenTransactions(
      this.transectionsPage
    );
    this.transections = [...this.transections, ...transection?.results];
    this.transectionsLoadMore = false;
  }

  openSendModal() {
    this.singletonService.showNotAvaliabledFeatureSwalAlert()

    // if (!this.token) return;
    // const modalRef = this.modalService.open(ModalTransferSendComponent, {
    //   centered: true,
    // });
    // modalRef.componentInstance.token = this.token.token;
    // modalRef.componentInstance.balance = this.token.balance;
    // modalRef.result.then(
    //   (data) => {
    //     data === 'success' && this.getTransactions();
    //   },
    //   (_) => {}
    // );
  }

  openReceiveModal() {
    this.singletonService.showNotAvaliabledFeatureSwalAlert()

    // this.modalService.open(ModalReceivePointComponent, {
    //   centered: true,
    //   fullscreen: true,
    //   keyboard: false,
    //   windowClass: 'modal-fullscreen-95',
    //   backdrop: false,
    //   scrollable: true,
    // });
  }
}
