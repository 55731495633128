<div class="wrapper-100vw">
  <ngx-loading [show]="balanceLoading"> </ngx-loading>

  <div class="alert alert-danger m-3" role="alert" *ngIf="!!error">
    {{ error }}
  </div>

  <ng-container *ngIf="!balanceLoading && !error">
    <div class="alert alert-info m-3" role="alert" *ngIf="!point.totalResults">
      {{"DONT_HAVE_ANY_POINT_IN_EVENT" | translate}}
    </div>

    <div class="py-5 px-3" *ngIf="point.totalResults">
      <!-- <card-pointbalance  [token]="null" [balance]="0"></card-pointbalance> -->
      <mat-tab-group dynamicHeight (selectedTabChange)="tabChanged($event)">
        <ng-container *ngFor="let point of point.results">
          <mat-tab>
            <ng-template mat-tab-label>
              <p class="size-18 font-weight-700">{{ point.token.name }}</p>
            </ng-template>

            <div class="mt-3">
              <card-pointbalance
                [token]="point.token"
                [balance]="point.balance"
                (reloadCallback)="reloadCallback()"
              ></card-pointbalance>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>

      <div class="row mt-4 mb-1">
        <div class="col">
          <h6 class="title size-16">{{"HISTORY" | translate}}</h6>
        </div>
        <div class="col-auto align-self-center">
          <a
            *ngIf="point?.results?.[activeTab]?.token?.id"
            [routerLink]="'/point/history/' + point.results[activeTab].token.id"
            class="small"
            >{{"VIEW_ALL" | translate}}</a
          >
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="card">
            <div class="card-body p-0">
              <div
                *ngIf="historyLoading; then loadingBlock; else contentBlock"
              ></div>
              <ng-template #loadingBlock>
                <div class="py-5 text-center text-muted">{{"LOADING" | translate}}...</div>
              </ng-template>

              <ng-template #contentBlock>
                <div
                  class="p-5 text-center text-muted"
                  *ngIf="!transection.results.length"
                >
                {{"NO_CONTENT" | translate}}...
                </div>

                <ul class="list-group list-group-flush bg-none">
                  <li
                    *ngFor="let transection of transection.results"
                    class="list-group-item"
                  >
                    <div class="row">
                      <div class="col-auto">
                        <div
                          *ngIf="currentUser.id === transection.to.id"
                          class="avatar avatar-46 shadow-green card rounded-10 p-1"
                        >
                          <div class="icons">
                            <i
                              class="bi bi-arrow-down-left-square text-success size-26"
                            ></i>
                          </div>
                        </div>
                        <div
                          *ngIf="currentUser.id === transection.from.id"
                          class="avatar avatar-46 shadow-danger card rounded-10 p-1"
                        >
                          <div class="icons">
                            <i
                              class="bi bi-arrow-up-right-square text-danger size-26"
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div class="col align-self-center ps-0">
                        <h6 class="mb-0">
                          {{ currentUser.id === transection.to.id ? "+" : "-" }}
                          {{ transection.amount }}
                          <img
                            src="assets/img/icon/coin.png"
                            class="align-sub"
                            alt="coin"
                          />
                        </h6>
                        <p class="text-secondary text-muted size-12 mb-0">
                          {{ transection.status }}
                        </p>
                      </div>
                      <div class="col align-self-end text-end">
                        <p class="text-secondary text-muted size-12 mb-0">
                          {{ transection.transactionDate | date : "dd/MM/yy" }}
                        </p>
                        <p class="text-secondary text-muted size-12 mb-0">
                          {{ transection.transactionDate | date : "HH:mm" }}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
