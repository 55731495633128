export enum GalleryFileType {
  IMAGE = 'image',
  VIDEO = 'video'
}

export interface GalleryImageData {
  _id: string;
  name?: string;
  subject?: string;
  fileType?: GalleryFileType | string;
  imageUrl?: string;
  videoUrl?: string;
  tags?: string[];
}

export class GalleryImage implements GalleryImageData {

  _id: string;
  id: string;
  name?: string;
  subject?: string;
  fileType?: GalleryFileType;
  imageUrl?: string;
  videoUrl?: string;
  tags?: string[];

  constructor(data: GalleryImageData);
  constructor(_id: string, name: string, subject: string, fileType: string, imageUrl: string, videoUrl: string, tags: string[]);
  constructor(
    dataOrId: GalleryImageData | string,
    name?: string,
    subject?: string,
    fileType?: string,
    imageUrl?: string,
    videoUrl?: string,
    tags?: string[]
  ) {
    if (typeof dataOrId === 'object') {
      this._id = dataOrId._id;
      this.name = dataOrId.name;
      this.subject = dataOrId.subject;
      this.fileType = dataOrId.fileType as GalleryFileType;
      this.imageUrl = dataOrId.imageUrl;
      this.videoUrl = dataOrId.videoUrl;
      this.tags = dataOrId.tags;
    } else {
      this._id = dataOrId;
      this.name = name;
      this.subject = subject;
      this.fileType = fileType as GalleryFileType;
      this.imageUrl = imageUrl;
      this.videoUrl = videoUrl;
      this.tags = tags;
    }
    this.id = this._id;
  }

  getShrinkUrl(width = 600, height = 400): string {
    const suffix = `?with${width}&height=${height}`;
    return this.imageUrl + suffix;
  }

  getUrl(): string {
    if (this.fileType === GalleryFileType.VIDEO) {
      return this.videoUrl ? this.videoUrl : '';
    } else {
      return this.imageUrl ? this.imageUrl : '';
    }
  }

  getNameWithSuffix(){
    if (this.fileType === GalleryFileType.VIDEO) {
      return this.name + '.mp4';
    } else {
      return this.name;
    }
  }

}
