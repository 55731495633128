import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SingletonService } from '../../@services/singleton.service';
import {
  GalleryFileType,
  GalleryImage,
  GalleryImageData,
} from '../../../models/galleryImage.model';
import { BackendService } from '../../@services/backend.service';
import { Logger } from '../../@services/logger.service';
const log = new Logger('NftGalleryListComponent');
@Component({
  selector: 'app-nft-gallery-list',
  templateUrl: './nft-gallery-list.component.html',
  styleUrls: ['./nft-gallery-list.component.scss'],
})
export class NftGalleryListComponent implements OnInit {
  limit = 10;
  page = 1;
  totalPages = 1;
  totalResults = 0;
  activeTab = 'MAIN';

  constructor(
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
    private _backendService: BackendService
  ) {}

  isLoading = true;
  galleryImages: GalleryImage[] = [];
  tarots: any = [];

  protected readonly GalleryImage = GalleryImage;

  protected readonly GalleryFileType = GalleryFileType;

  async ngOnInit() {
    this.isLoading = true;
    await this.fetchGallery();
    await this.fetchTarot();
    this.isLoading = false;
  }

  async fetchGallery() {
    const result: any = await this._backendService.getUserGalleryImages(
      undefined,
      this.limit,
      this.page
    );
    this.totalPages = result.totalPages;
    this.totalResults = result.totalResults;
    const _galleryImages = result.results.map(
      (item: GalleryImageData) => new GalleryImage(item)
    );
    this.galleryImages.push(..._galleryImages);
  }

  async fetchTarot() {
    try {
      const _result: any =
        await this._backendService.getUserTarotGalleryImages();
      this.tarots = _result.results;
    } catch (e) {
      log.debug(e);
    }
  }

  async jumpToDetail(galleryImage: GalleryImage) {
    await this._router.navigate([`nft-gallery/detail/${galleryImage.id}`]);
  }

  async back() {
    await this._router.navigate(['/']);
  }

  async onScroll() {
    log.debug('on scroll');
    if (this.page < this.totalPages) {
      log.debug('fetch more data');
      this.page++;
      await this.fetchGallery();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onImageLoaded() {}

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  async goToTarot(id: any) {
    await this._router.navigate([`nft-gallery/tarot/${id}`]);
  }
}
