<div class="wrapper-top-header">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 mx-auto px-0">
        <!-- header -->
        <div class="content-header">
          <div class="ps-2 pe-0">
            <h2>
              <span class="size-28 text-white fw-bold">
                <button
                  class="btn btn-arrow-back"
                  type="button"
                  (click)="home()"
                >
                  <i class="bi bi-chevron-left text-white size-16"></i>
                </button>
                {{ "SIGNIN.SIGNUP" | translate }}
              </span>
            </h2>
          </div>
        </div>
        <!-- header ends -->
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div
      class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center"
    >
      <div *ngIf="isInAppBrowser">
        <div class="wrapper-is-support-browser size-16">
          {{ "IN_APP_BROWSER_NOTICE" | translate }}
          <!-- <div class="wrapper-go-to-0110-website"> -->
            <!-- <button
              (click)="openInSystemBrowser()"
              class="btn btn-outline-main size-16 fw-bold"
            >
              {{ "OPEN_IN_SYSTEM_BROWSER" | translate }}
            </button> -->
          <!-- </div> -->
        </div>
      </div>

      <h1 class="mb-4 pb-2">
        <span class="text-title fw-600 size-32">{{
          "WELCOME" | translate
        }}</span>
        <p class="text-white size-16 fw-normal mt-1 text-secondary">
          {{ "PLEASE_FILL_OUT_THE_FORM" | translate }}
        </p>
      </h1>
      <div class="alert alert-danger mb-4" role="alert" *ngIf="!!error">
        {{ errorText + "." }}
        <span class="ml-1" *ngIf="loginLink">{{
          "REDIRECT_TO_SIGN_IN" | translate
        }}</span>
        <button
          *ngIf="loginLink"
          (click)="goToSignIn()"
          class="btn btn-link redirect-link"
        >
          {{ "CLICK_HERE" | translate }}
        </button>
      </div>
      <!-- <div class="form-floating is-valid mb-3">
                  <input type="text" class="form-control" value="info@maxartkiller.com"
                      placeholder="Email or Phone" id="emailphone">
                  <label for="emailphone">Email or Phone</label>
              </div>
              <div class="form-floating is-valid mb-3">
                  <input type="password" class="form-control" value="asdasdasdsd" placeholder="Password"
                      id="password">
                  <label for="password">Password</label>
              </div>
              <div class="form-floating is-invalid mb-3">
                  <input type="password" class="form-control" placeholder="Confirm Password" id="confirmpassword">
                  <label for="confirmpassword">Confirm Password</label>
                  <button type="button" class="btn btn-link text-danger tooltip-btn" data-bs-toggle="tooltip"
                      data-bs-placement="left" title="Enter valid Password" id="passworderror">
                      <i class="bi bi-info-circle"></i>
                  </button>
              </div>
              <p class="mb-3"><span class="text-muted">By clicking on Sign up button, you are agree to the our </span> <a
                      routerLink="/termsandconditions">Terms and Conditions</a></p> -->

      <div [formGroup]="signUpForm" class="row">
        <div class="col-12">
          <label
            class="form-control-label text-uppercase mb-2 size-14 text-label"
            for="username"
            >{{ "PHONE_OR_EMAIL" | translate }}</label
          >
          <div class="form-group form-floating mb-3">
            <label class="tooltip-btn tooltip-btn-left" for="username">
              <img src="assets/img/auth/username.png" alt="username icon" />
            </label>
            <input
              (keyup)="error = false"
              class="form-control"
              type="email"
              formControlName="username"
              placeholder="{{ 'PHONE_OR_EMAIL' | translate }}"
              name="username"
              id="username"
              tabindex="1"
            />
          </div>

          <label
            class="form-control-label text-uppercase mb-2 size-14 text-label"
            for="password"
            >{{ "PASSWORD" | translate }}</label
          >
          <div
            class="form-group form-floating mb-3 input-group input-group-merge"
          >
            <label class="tooltip-btn tooltip-btn-left" for="password">
              <img src="assets/img/auth/key.png" alt="username icon" />
            </label>
            <input
              (keyup)="error = false"
              class="form-control button-inside px-0"
              [type]="isShow ? 'text' : 'password'"
              formControlName="password"
              placeholder="{{ 'PASSWORD' | translate }}"
              tabindex="2"
              name="password"
              value=""
              id="password"
              [class.is-invalid]="
                formService.isControlInvalid(signUpForm, 'password')
              "
            />
            <div class="input-group-append">
              <span class="input-group-text cursor-pointer icon-input"
                ><i
                  class="bi"
                  [ngClass]="{
                    'bi-eye-slash': !isShow,
                    'bi-eye': isShow
                  }"
                  (click)="showPassword()"
                >
                  <!-- {{isShow}} -->
                </i></span
              >
            </div>
            <div
              class="invalid-feedback px-3"
              *ngIf="
                formService.controlHasError(signUpForm, 'minlength', 'password')
              "
            >
              {{ "SIGNIN.PASSWORD_VALIDATE" | translate }}
            </div>
            <div
              class="invalid-feedback px-3 pb-3"
              *ngIf="
                formService.controlHasError(
                  signUpForm,
                  'passwordStrength',
                  'password'
                )
              "
            >
              {{ "SIGNIN.PASSWORD_VALIDATE_STRICT" | translate }}
            </div>
          </div>

          <label
            class="form-control-label text-uppercase mb-2 size-14 text-label"
            for="confirmPassword"
            >{{ "CONFIRM_PASSWORD" | translate }}</label
          >
          <div
            class="form-group form-floating mb-3 input-group input-group-merge"
          >
            <label class="tooltip-btn tooltip-btn-left" for="confirmPassword">
              <img src="assets/img/auth/key.png" alt="username icon" />
            </label>
            <input
              (keyup)="error = false"
              class="form-control button-inside px-0"
              [type]="isShowRecomfirm ? 'text' : 'password'"
              formControlName="confirmPassword"
              placeholder="{{ 'CONFIRM_PASSWORD' | translate }}"
              tabindex="2"
              name="confirmPassword"
              value=""
              id="confirmPassword"
              [class.is-invalid]="
                formService.isControlInvalid(signUpForm, 'confirmPassword')
              "
            />
            <div class="input-group-append">
              <span class="input-group-text cursor-pointer icon-input"
                ><i
                  class="bi"
                  [ngClass]="{
                    'bi-eye-slash': !isShowRecomfirm,
                    'bi-eye': isShowRecomfirm
                  }"
                  (click)="showConfirm()"
                >
                  <!-- {{isShowRecomfirm}} -->
                </i></span
              >
            </div>
            <div
              class="invalid-feedback px-3 pb-3"
              *ngIf="
                formService.controlHasError(
                  signUpForm,
                  'confirmPasswordValidator',
                  'confirmPassword'
                )
              "
            >
              {{ "SIGNIN.PASSWORD_MATCH" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="py-4">
        <a (click)="onSubmit()" class="btn btn-default btn-lg">
          {{ "SIGNIN.NEXT" | translate }}
        </a>
      </div>

      <div class="px-2">
        <div class="separator">
          <h5>
            <span>{{ "OR_CONTINUE_WITH" | translate }}</span>
          </h5>
        </div>
        <div class="signin-choice" [ngClass]="isInAppBrowser?'signin-choice-blur':''">
          <div *ngIf="isInAppBrowser" class="text-signin-choice-blur size-14 text-center d-flex align-items-center justify-content-center">
            {{ "ONLY_SYSTEM_BROWSER" | translate }}
          </div>
          <div class="signin-icon-wrapper">
            <div class="google-button" id="google-button"></div>
            <img
              class="google-signin-icon"
              src="assets/img/auth/social/google.png"
              alt="google"
            />
          </div>

          <div class="signin-icon-wrapper" (click)="signInWithFacebook()">
            <img
              class="facebook-signin-icon"
              src="assets/img/auth/social/facebook.png"
              alt="google"
            />
          </div>

          <!-- <div class="signin-icon-wrapper">
            <img
              class="line-signin-icon"
              src="assets/img/auth/social/line.png"
              alt="line"
            />
          </div>

          <div class="signin-icon-wrapper">
            <img
              class="apple-signin-icon"
              src="assets/img/auth/social/apple.png"
              alt="apple"
            />
          </div> -->
        </div>
      </div>

      <!-- <div class="text-center text-secondary size-14">
        {{ "DONT_HAVE_AN_ACCOUNT" | translate }}  <a routerLink="/signin" class="text-white text-docoration">{{ "SIGN_IN" | translate }}</a>
      </div> -->
    </div>

    <ngx-loading
      [show]="loading"
      [config]="{
        animationType: ngxLoadingAnimationTypes.wanderingCubes,
        primaryColour: '#4b0082',
        secondaryColour: '#ffd42a',
        backdropBorderRadius: '3px'
      }"
    ></ngx-loading>
  </div>
</div>
