<div class="modal-header p-4">
  <h3 class="header-text-normal size-20">
    {{"SEND_POINT" | translate}}
    <div class="long-text size-14 text-secondary mt-2">
      {{"TOTAL" | translate}}
      <img
        [src]="token.logo || 'assets/img/dashboard/coin-53x53.png'"
        width="22"
        height="22"
        alt="coin"
        class="mx-1"
      />
      {{ balance | numeral : "0,0.00" }}
    </div>
  </h3>
  <button
    type="button"
    class="btn-close btn-close-white mb-auto"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>

<div class="modal-body p-4">
  <div class="alert alert-danger" role="alert" *ngIf="!!error">
    {{ error }}
  </div>

  <div
    *ngIf="
      !selectedTransferType;
      then selectedTypeContent;
      else transferContent
    "
  ></div>
  <ng-template #selectedTypeContent>
    <div class="mb-3">
      <small class="size-14">{{"SELECT_METHOD" | translate}}</small>
    </div>
    <div class="option-container">
      <div
        class="option-card d-flex align-items-center justify-content-between"
        (click)="onSelectedTransferType(transferType.ticketId)"
      >
        <div class="inner-option-card text-start text-white mx-auto d-flex align-items-center">
          <div class="box-img">
            <img src="assets/img/icon/qr-code-white.png" alt="qr img" />
          </div>
          <span class="ms-2 ps-1 size-16">QR Code</span>

        </div><div>
            <i class="bi bi-chevron-right size-14 text-secondary m-3"></i>
          </div>
      </div>

      <div
        class="option-card d-flex align-items-center justify-content-between"
        (click)="onSelectedTransferType(transferType.email)"
      >
        <div class="inner-option-card text-start text-white mx-auto d-flex align-items-center">
          <div class="box-img">
            <img src="assets/img/icon/email-white.png" alt="mail img" />
          </div>
          <span class="ms-2 ps-1 size-16">{{"EMAIL" | translate}}</span>
        </div><div>
            <i class="bi bi-chevron-right size-14 text-secondary m-3"></i>
          </div>
      </div>

      <div
      class="option-card d-flex align-items-center justify-content-between"
      (click)="onSelectedTransferType(transferType.mobilePhone)"
    >
      <div class="inner-option-card text-start text-white mx-auto d-flex align-items-center">
        <div class="box-img">
          <img src="assets/img/icon/phone-white.png" alt="phone img" />
        </div>
        <span class="ms-2 ps-1 size-16">{{"MOBILE_PHONE" | translate}}</span>
      </div>
      <div>
        <i class="bi bi-chevron-right size-14 text-secondary m-3"></i>
      </div>
    </div>

    </div>
  </ng-template>

  <ng-template #transferContent>
    <ng-container [ngSwitch]="selectedTransferType">
      <!-- scan qr case  -->
      <ng-container *ngSwitchCase="transferType.ticketId">
        <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
          <div class="d-inline" (click)="onClearTransferType()">
            <button class="btn btn-arrow-back ps-0 pe-1" type="button">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            <small>{{"CHANGE_METHOD" | translate}}</small>
          </div>

          <small class="text-secondary size-12">{{"TRANSFER" | translate}} via QR Code</small>
        </div>
        <ng-container *ngIf="!toValue">
          <qrcode-scanner (onDecode)="onDecodeQrCode($event)"></qrcode-scanner>
        </ng-container>

        <ng-container *ngIf="!!toValue">
          <div [formGroup]="sendForm" class="row">
            <div class="col-12">
              <div class="text-secondary my-3">{{"TRANSFER_TO" | translate}} {{ toValue }}</div>
              <div
                class="form-group form-floating mb-3 input-group input-group-merge"
              >
                <input
                  (keyup)="error = ''"
                  class="form-control button-inside px-0"
                  formControlName="amount"
                  placeholder="amount"
                  tabindex="2"
                  name="amount"
                  id="amount"
                  mask="999999999999"
                />
                <label class="form-control-label" for="password">{{"AMOUNT" | translate}}</label>
              </div>
              <div *ngIf="pointBalanceError">{{"NOT_ENOUGH_POINT" | translate}}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- mobilePhone case -->
      <ng-container *ngSwitchCase="transferType.mobilePhone">
        <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
          <div class="d-inline" (click)="onClearTransferType()">
            <button class="btn btn-arrow-back ps-0 pe-1" type="button">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            <small>{{"CHANGE_METHOD" | translate}}</small>
          </div>

          <small class="text-secondary size-12">{{"TRANSFER" | translate}} via {{"MOBILE_PHONE" | translate}}</small>
        </div>

        <div [formGroup]="sendForm" class="row">
          <div class="col-12">
            <div
              class="form-group form-floating mb-3 input-group input-group-merge"
            >
              <input
                (keyup)="error = ''"
                class="form-control button-inside px-0"
                formControlName="to"
                placeholder="to"
                tabindex="2"
                name="to"
                id="to"
                mask="999 999 9999"
              />
              <label class="form-control-label pl-3" for="to"
                >To ({{"MOBILE_PHONE" | translate}})</label
              >
            </div>
            <div
              class="form-group form-floating mb-3 input-group input-group-merge"
            >
              <input
                (keyup)="error = ''"
                class="form-control button-inside px-0"
                formControlName="amount"
                placeholder="amount"
                tabindex="2"
                name="amount"
                id="amount"
                mask="999999999999"
              />
              <label class="form-control-label" for="amount">{{"AMOUNT" | translate}}</label>
            </div>
            <div *ngIf="pointBalanceError">{{"NOT_ENOUGH_POINT" | translate}}</div>
          </div>
        </div>
      </ng-container>

      <!-- email case -->
      <ng-container *ngSwitchCase="transferType.email">
        <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
          <div class="d-inline" (click)="onClearTransferType()">
            <button class="btn btn-arrow-back ps-0 pe-1" type="button">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            <small>{{"CHANGE_METHOD" | translate}}</small>
          </div>

          <small class="text-secondary size-12">{{"TRANSFER" | translate}} via {{"EMAIL" | translate}}</small>
        </div>

        <div [formGroup]="sendForm" class="row">
          <div class="col-12">
            <div
              class="form-group form-floating mb-3 input-group input-group-merge"
            >
              <input
                (keyup)="error = ''"
                class="form-control button-inside px-0"
                formControlName="to"
                placeholder="to"
                tabindex="2"
                name="to"
                id="to"
              />
              <label class="form-control-label pl-3" for="to">To ({{"EMAIL" | translate}})</label>
            </div>
            <div
              class="form-group form-floating mb-3 input-group input-group-merge"
            >
              <input
                (keyup)="error = ''"
                class="form-control button-inside px-0"
                formControlName="amount"
                placeholder="amount"
                tabindex="2"
                name="amount"
                id="amount"
                mask="999999999999"
              />
              <label class="form-control-label" for="amount">{{"AMOUNT" | translate}}</label>
            </div>
            <div *ngIf="pointBalanceError">{{"NOT_ENOUGH_POINT" | translate}}</div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="mt-0 mb-0">
      <div class="col-11 col-sm-11 mt-auto mx-auto pt-4 pb-3">
        <div class="row">
          <div class="col-12 d-grid px-0">
            <button
              type="submit"
              class="btn btn-default btn-lg"
              (click)="onSubmit()"
              [disabled]="
                submitting || !sendForm.value.amount || !sendForm.value.to
              "
            >
              {{"CONFIRM" | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
