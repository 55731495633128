/* eslint-disable */
import { BackendService } from 'src/app/@services/backend.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('ExhibitorComponent');
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-exhibitor',
  templateUrl: './exhibitor.component.html',
  styleUrls: ['./exhibitor.component.scss'],
})
export class ExhibitorComponent {

  keyword:any;
  selectedCategoryList:any = [];

  categoryList:any = [];
  exhibitorList:any = [];
  isSelectedAll = true

  delayTimer:any;
  isLoadingDelayedSearch = false
  isLoadingExhibitorList = false
  isLoadingSuggestExhibitorList = false

  limit=6;
  page=1;
  totalPages = 1;
  totalResults:any = undefined;

  suggestExhibitorList:any = []

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _location: Location,
    private singletonService: SingletonService,
  ) {}

  async ngOnInit() {
    await this.searchWithDelay()
    await this.getExhibitorCategoryList()
    await this.getSuggestExhibitorList()
  }

  async getExhibitorCategoryList(){
    this.isLoadingExhibitorList = true
    const getExhibitorCategoryListRes:any = await this.backendService.getExhibitorCategoryList()
    log.debug("res getExhibitorCategoryListRes: %o",getExhibitorCategoryListRes)
    if(getExhibitorCategoryListRes){
      this.categoryList = getExhibitorCategoryListRes.results
      log.debug("this.categoryList: %o",this.categoryList)
    }
  }

  resetPaginate(){
    this.page = 1
    this.totalPages = 1;
    this.exhibitorList = []
    this.totalResults = undefined
  }

  async activateClassActive(category:any){
    await this.resetPaginate()
    log.debug("activateClassActive category => %o",category)
    category.active = !category.active;
    if(this.categoryList && (this.categoryList.length == this.categoryList.filter((it:any) => !it.active ).length )){
      this.isSelectedAll = true
    }else if(this.categoryList && (this.categoryList.length == this.categoryList.filter((it:any) => it.active ).length )){
      this.isSelectedAll = true
    }else{
      this.isSelectedAll = false
    }

    log.debug("activateClassActive this.selectedCategoryList => %o",this.selectedCategoryList)

    if(category.active){
      this.selectedCategoryList.push(category)
    }else{
      this.selectedCategoryList = this.selectedCategoryList.filter((it:any) => it.id != category.id )
    }

    this.doSearch(this.keyword)
  }

  async setSelectedAll(){
    await this.resetPaginate()
    this.isSelectedAll = !this.isSelectedAll
    if(!this.isSelectedAll){
      this.categoryList.map( (it:any) => it.active = false)
      this.selectedCategoryList = []
    }else{
      this.categoryList.map( (it:any) => it.active = true)
      this.selectedCategoryList = this.categoryList
    }

    this.doSearch(this.keyword)
  }

  async searchWithDelay(event:any = undefined){
    await this.resetPaginate()
    log.debug("searchWithDelay...")

    var text = event ? event.target.value : undefined;
    const self = this
    clearTimeout(self.delayTimer);
    self.isLoadingDelayedSearch = true
    self.delayTimer = setTimeout(async function() {
      try{
        await self.doSearch(text)
        self.isLoadingDelayedSearch = false
      }catch(err){
        self.isLoadingDelayedSearch = false
      }
    }, 1000);
  }


  async doSearch(
    text:any = undefined
  ) {
    this.isLoadingExhibitorList = true
    log.debug("doSearch...")
    this.keyword = text

    log.debug("doSearch this.selectedCategoryList => %o",this.selectedCategoryList)
    let selectedCategories:any = []
    if(this.selectedCategoryList.length){
      selectedCategories = this.selectedCategoryList.map((it:any) => it.id) // TODO: multiple
    }else{
      selectedCategories = undefined // TODO: multiple
    }
    log.debug("doSearch selectedCategories => %o",selectedCategories)

    let res:any = await this.backendService.getExhibitorList(
      text,
      selectedCategories,
      this.limit,
      this.page,
    )
    log.debug("doSearch res => %o",res)
    if(res){
      const results = res.results
      this.exhibitorList = [...this.exhibitorList,...results]
      log.debug("this.exhibitorList: %o",this.exhibitorList)

      this.totalPages = res.totalPages
      this.totalResults = res.totalResults

      this.isLoadingExhibitorList = false
    }else{
      this.isLoadingExhibitorList = false
    }
  }

  onScroll() {
    log.debug("onScroll...")

    if(this.page < this.totalPages){
      this.page++;
      this.doSearch(this.keyword)
    }
  }


  backnav() {
    this.singletonService.back();
  }

  async getSuggestExhibitorList(){
    this.isLoadingSuggestExhibitorList = true
    let res:any = await this.backendService.getExhibitorList(
      null,
      null,
      null,
      null,
      true, //isSuggested
    )
    log.debug("doSearch res => %o",res)
    if(res){
      const results = res.results
      this.suggestExhibitorList = [...this.suggestExhibitorList,...results]
      log.debug("this.suggestExhibitorList: %o",this.suggestExhibitorList)

      // this.totalPages = res.totalPages
      // this.totalResults = res.totalResults

      this.isLoadingSuggestExhibitorList = false
    }else{
      this.isLoadingSuggestExhibitorList = false
    }
  }

  jumpToExhibitorDetail(exhibitor:any){
    let path = '/exhibitordetail/'+ exhibitor.id
    let href = this._router.url;
    if(href.startsWith('/exhibitordetail/')){ // same route
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]);
      });
    }else{
      this._router.navigate([path]);
    }

  }
}
