import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-privacy-and-data',
  templateUrl: './privacy-and-data.component.html',
  styleUrls: ['./privacy-and-data.component.scss']
})
export class PrivacyAndDataComponent implements OnInit {

  constructor(
    private _router:Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) { }

  ngOnInit() {
  }

  backnav() {
    this.singletonService.back();
  }

}
