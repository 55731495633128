<div class="modal-header d-flex border-0 p-3 pb-0 align-items-start w-100">
  <p
    class="d-flex flex-column pt-2 justify-content-center align-items-center text-center ps-4 w-100d-flex flex-column pt-2 justify-content-center align-items-center text-center ps-4 w-100"
  >
    <img
      src="assets/img/icon/notification.png"
      class="img-pwa-install img-noti"
    />
    <span class="size-14 text-white mt-3 fw-bold size-20 lh-sm"
      >Get notification from
      <span class="size-16 fw-bold me-1 text-logo">LINX</span>
      <span class="size-16 text-white fw-normal">WALLET</span>
    </span>
  </p>

  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.close('Cross click')"
  ></button>
</div>
<div class="modal-body pt-0 modal-body-notfi-custom pb-4">
  <ng-container *ngIf="notificationStatus === 'denied'">
    <p class="text-center size-14 text-muted mb-4">
      Please enable notifications in your browser settings to receive
      notifications.
    </p>
    <button
      type="button"
      class="btn btn-outline-main btn-sm"
      (click)="onClickDismiss()"
    >
      No Thanks
    </button>
  </ng-container>

  <ng-container *ngIf="notificationStatus === 'default'">
    <div class="d-flex flex-column gap-3 mt-2">
      <button
        type="button"
        class="btn btn-default btn-sm"
        (click)="onClickAllow()"
      >
        ALLOW
      </button>
      <button
        type="button"
        class="btn btn-outline-main btn-sm"
        (click)="onClickDismiss()"
      >
        No Thanks
      </button>
    </div>
  </ng-container>
  <div class="d-flex flex-row mt-3 align-items-center justify-content-center">
    <div (click)="onClickDontShow()">
      <i *ngIf="!notShow" class="bi bi-square size-14 text-checkbox"></i>
      <i *ngIf="notShow" class="bi bi-check-square size-14 text-checkbox"></i>
    </div>
    <div class="size-12 text-muted ms-2">
      {{ "DONT_SHOW_AGAIN" | translate }}
    </div>
  </div>
</div>
