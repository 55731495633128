import { async } from '@angular/core/testing';
import { BackendService } from 'src/app/@services/backend.service';
import { environment } from 'src/environments/environment';
import { RouterExtService } from './router-ext.service';
import { Router, ActivatedRoute } from '@angular/router';
/* eslint-disable */

import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalGalleryComponent } from '../@components/modal-gallery/modal-gallery.component';
import Swal from 'sweetalert2';
import { ModalSocialsSharingComponent } from '../@components/modal-socials-sharing/modal-socials-sharing.component';
import { Location, isPlatformBrowser } from '@angular/common';
import { Logger } from './logger.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { ErrorHandlerService } from './error-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

const log = new Logger('AuthComponent');

@Injectable({
  providedIn: 'root',
})
export class SingletonService {
  constructor(
    private modalService: NgbModal,
    private _location: Location,
    private _router: Router,
    private _route: ActivatedRoute,
    private readonly injector: Injector,
    private translateService: TranslateService,
    private backendService: BackendService,
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  private get errorHandlerService() {
    return this.injector.get(ErrorHandlerService);
  }

  back(isBackWithReturnUrl: any = false) {
    log.debug('window.location.href => %o', window.location.href);
    log.debug('document.referrer => %o', document.referrer);

    const returnurl = this._route.snapshot.queryParams['returnurl'];
    log.debug('returnurl => %o', returnurl);

    if (isBackWithReturnUrl && returnurl) {
      this._router.navigate([returnurl]);
    } else {
      if (document.referrer) {
        log.debug('111111111 %o');
        this._location.back();
      } else {
        log.debug('222222222 %o');
        this._router.navigate(['/']);
      }
    }
  }

  openExternalLink(url: any) {
    setTimeout(() => {
      window.open(url, '_blank');
    });
  }

  showNotAvaliabledFeatureSwalAlert() {
    Swal.fire({
      title: this.translateService.instant('NOT_YET_FEATURE'),
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonColor: '#4B0082',
      confirmButtonText: this.translateService.instant('OK'),
      customClass: 'popup-success-custom',
    });
  }

  successAlert(text: any = undefined, title: any = 'Success') {
    if (!text) {
      Swal.fire({
        title: title,
        icon: 'success',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonColor: '#4B0082',
        confirmButtonText: this.translateService.instant('OK'),
        customClass: 'popup-success-custom',
      });
    } else {
      Swal.fire({
        title: title,
        text: text,
        icon: 'success',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonColor: '#4B0082',
        confirmButtonText: this.translateService.instant('OK'),
        customClass: 'popup-success-custom',
      });
    }
  }

  showIsConsentSwalModal(logParams: any) {
    Swal.fire({
      icon: 'warning',
      title: 'คำขอความยินยอม',
      text: 'ในการเข้าร่วมกิจกรรมนี้จำเป็นต้องผ่านการเก็บข้อมูลการเข้าร่วมกิจกรรมของคุณเพื่อนำไปใช้ในเชิงพาณิชย์ คุณจะยินยอมหรือไม่?',
      // text: 'Please wait a moment (2/6)',
      showDenyButton: true,
      showConfirmButton: true,
      showCancelButton: false,
      customClass: 'popup-process-custom popup-content-custom',
      denyButtonText: 'ไม่ตกลง',
      confirmButtonText: 'ตกลง',
      // didOpen: () => {
      //   Swal.showLoading()
      // }
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          await this.processingSwal('กำลังบันทึกความยินยอม', 'กรุณารอสักครู่');
          const res = await this.backendService.confirmConsent(logParams);
          log.debug('res => %o', res);
          if (res) {
            await this.successAlert(
              undefined,
              'ความยินยอมถูกบันทึกแล้ว ขอให้สนุกกับกิจกรรม!'
            );
          }
        } catch (err) {
          this.errorHandlerService.error(err, true);
        }
      }
    });
  }

  showErrorSwalAlert(message: any) {
    Swal.close();
    Swal.fire({
      icon: 'error',
      title: this.translateService.instant('ERROR'),
      text: message,
      showDenyButton: false,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: this.translateService.instant('CLOSE'),
      customClass: 'popup-error-custom popup-content-custom',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
      }
    });
  }

  initRequestConsent(queryParams: any) {
    const isConsentParams = queryParams['isConsent'];
    const logParams = queryParams['log'];
    log.debug('isConsentParams => %o', isConsentParams);
    log.debug('logParams => %o', logParams);

    const currentUser = this.authenticationService.currentUserSubject.value;
    if (isConsentParams == 'true' && logParams) {
      if (currentUser) {
        this.showIsConsentSwalModal(logParams);
      } else {
        log.debug('currentUser not found go to signin with url...');
        this.errorHandlerService.goSignIn(true);
      }
    }
  }

  isWhitelistUrl(_url: any) {
    log.debug('isWhitelistUrl _url => %o', _url);

    const whitelistUrls = environment.whitelistUrls;
    log.debug('isWhitelistUrl whitelistUrls => %o', whitelistUrls);

    const isWhitelistUrl = whitelistUrls.filter(
      (whitelistUrl) => _url.indexOf(whitelistUrl) > -1
    );
    log.debug(`isWhitelistUrl: %o`, isWhitelistUrl);

    return isWhitelistUrl.length ? true : false;
  }

  processingSwal(title = 'Processing', text = 'Please wait a moment...') {
    Swal.fire({
      icon: 'success',
      title: title,
      text: text,
      // text: 'Please wait a moment (2/6)',
      showConfirmButton: false,
      showDenyButton: false,
      showCancelButton: false,
      customClass: 'popup-process-custom popup-content-custom',
      didOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
      }
    });
  }

  handleNavigateNotification(notification: any) {
    const refModel = notification ? notification?.data?.refModel : undefined;
    const refId = notification ? notification?.data?.refId : undefined;
    switch (refModel) {
      case 'coupon': {
        this._router.navigate(['/mycouponreward']);
        break;
      }
      case 'token': {
        this._router.navigate(['/wallet']);
        break;
      }
      case 'photo': {
        this._router.navigate([`'/nft-gallery/detail/${refId}'`]);
        break;
      }
      case 'nft': {
        break;
      }
      default: {
        this._router.navigate(['/notification']);
        break;
      }
    }
  }

  toastMessage(title: any, body: any, notification: any = undefined) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      showCloseButton: true,
      customClass: 'toast-notifocation-main',
      backdrop: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
        toast.addEventListener('click', () => {
          Swal.close();
          this.handleNavigateNotification(notification);
        });
      },
    });

    Toast.fire({
      // icon: 'success',
      title: title,
      // text: body,
      // html: body + '<div class="text-end mt-2"><a href="" class="btn btn-default btn-sm">อ่านต่อ</a></div>',
      html: body,
      // imageUrl: 'assets/img/logo/logo-0110.png',
      imageUrl: 'assets/img/icon/notification.png',
    });
  }

  openGallery(images: any) {
    const modalRef = this.modalService.open(ModalGalleryComponent, {
      centered: true,
    });
    modalRef.componentInstance.images = images;
  }

  openModalSocialSharing() {
    let modalRef = this.modalService.open(ModalSocialsSharingComponent, {
      centered: true,
      backdrop: 'static',
      size: 'sm',
    });
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    alert('Copied: ' + val);
  }

  // async downloadMediaFromUrl(url: string, fileName?: string) {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  downloadMediaFromUrl(url: string, fileName?: string) {
    // if (isVideo(url)) {
    //   this.downloadImageFromUrl(
    //     'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
    //     fileName
    //   );
    // } else {
    //   this.downloadImageFromUrl(
    //     'https://as2.ftcdn.net/v2/jpg/05/88/62/91/1000_F_588629190_ErMz1NnyP2zyaCJckkKgpWeScsFbFRVd.jpg',
    //     fileName
    //   );
    // }
    // console.log("download " + fileName);
    this.httpClient.get(url, { responseType: 'blob' }).subscribe(
      (blob: Blob) => {
        const imageUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = fileName || 'untitled'; // + '.mp4'; // TODO : Fix this
        link.click();
        URL.revokeObjectURL(imageUrl);
      },
      (error) => {
        // this.showErrorSwalAlert(
        //   this.translateService.instant('DOWNLOAD_ERROR')
        // );
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        log.error(error);
      }
    );
  }

  saveAsImage(parent: any, elementType: any = 'img') {
    let parentElement = null;

    if (elementType === 'canvas') {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector('canvas')
        .toDataURL('image/png');
    } else if (elementType === 'img' || elementType === 'url') {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector('img').src;
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.");
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement);
      // saves as image
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // name of the file
      link.download = 'Qrcode';
      link.click();
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(';base64,');
    // hold the content type
    const imageType = parts[0].split(':')[1];
    // decode base64 string
    const decodedData = window.atob(parts[1]);
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

  // @ts-ignore
  async forceDownload(url, fileName) {
    const pom = document.createElement('a');
    pom.setAttribute('href', await this.toDataURL(url));
    pom.setAttribute('download', fileName);
    pom.style.display = 'none';
    document.body.appendChild(pom);
    pom.click();
    document.body.removeChild(pom);
  }

  async toDataURL(url: string) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }

  isInAppฺBrowser() {
    const ua = navigator.userAgent || navigator.vendor || (window as any).opera;
    const rules = [
      'WebView',
      '(iPhone|iPod|iPad)(?!.*Safari/)',
      'Android.*(wv)',
    ];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    if (!ua.match(regex)) return false;
    return true;
  }

  isPlatformBrowser() {
    // true : the platform is a browser
    // fasle : if the platform is not a browser (e.g., in-app browser)
    return isPlatformBrowser(this.platformId);
  }
}

function isVideo(url: string) {
  return /\.(mp4|mov)$/.test(url);
}

function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
