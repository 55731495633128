<div class="modal-header pb-3">
  <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h1 class="mb-0">
    <div class="size-20 fw-bold text-white">{{ token.token.name }}</div>
  </h1>
  <div>
    <!-- <button class="btn btn-top-right-arrow" type="button">
    <i class="bi bi-arrow-up-right-square text-white size-16"></i>
  </button> -->
  </div>
</div>
<div class="modal-body px-0">
  <div class="text-center mt-3 mb-2">
    <img
      [src]="token.token.logo || 'assets/img/dashboard/coin-53x53.png'"
      alt="coin-53x53"
      width="53"
      height="53"
    />
  </div>
  <div class="text-center text-secondary size-16 mb-2">
    {{ "CURRENT_BALANCE" | translate }}
  </div>
  <h1 class="text-white text-center size-40 pb-3 px-3 px-md-5">
    {{ token.balance | numeral : "0,0" }}
    <span class="text-white size-24">{{ token.token.symbol }}</span>
  </h1>

  <div class="mt-2 mb-5 px-5">
    <div class="wrapper-actions d-flex justify-content-around gap-3">
      <a class="box-action" (click)="openSendModal()">
        <div class="box-img d-flex align-item-center">
          <img src="assets/img/dashboard/send.png" alt="send img" />
          <p class="ms-2 text-red size-16 mt-0">{{ "SEND" | translate }}</p>
        </div>
      </a>
      <a class="box-action" (click)="openReceiveModal()">
        <div class="box-img d-flex align-item-center">
          <img src="assets/img/dashboard/recive.png" alt="recive img" />
          <p class="ms-2 text-green size-16 mt-0">
            {{ "RECEIVE" | translate }}
          </p>
        </div>
      </a>
    </div>
  </div>

  <div
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="500"
    (scrolled)="onTransactionsScrollEnd()"
  >
    <ng-container *ngFor="let transection of transections">
      <div class="row-assets d-flex align-items-center px-3 px-md-5">
        <div class="d-flex flex-column justify-content-center w-100">
          <h4
            class="size-16 text-white mb-1 d-flex align-item-center justify-content-between"
          >
            <span>{{ transection?.ref?.name }}</span>
            <span
              >{{ currentUser.id === transection.to ? "+" : "-" }}
              {{ transection.amount | numeral : "0,0" }}
              <small>{{ transection?.ref?.symbol }}</small></span
            >
          </h4>
          <p class="size-12 mb-0 text-date">
            {{ transection.transactionDate | date : "dd MMMM yy HH:mm" }}
          </p>
          <p class="size-14 mb-0 text-secondary">
            {{ transection.status }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
