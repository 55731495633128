import { Component, Input, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPasswordValidator } from 'src/validators/confirm-password.validator';

import Swal from 'sweetalert2';
const log = new Logger('ModalSetNewPasswordComponent');

import { Logger } from 'src/app/@services/logger.service';
import { UserService } from 'src/app/@services/user.service';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { FormService } from 'src/app/@services/form.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-set-new-password',
  templateUrl: './modal-set-new-password.component.html',
  styleUrls: ['./modal-set-new-password.component.scss'],
})
export class ModalSetNewPasswordComponent {
  @Input() code: string;

  forgotPasswordForm: any;
  sending = false;
  error: boolean = false;
  errorText: any;
  isShow: boolean = false;
  isShowRecomfirm: boolean = false;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    public userService: UserService,
    public formService: FormService,
    private translateService: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            // PasswordStrengthValidator
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmPasswordValidator('password', 'confirmPassword'),
      }
    );
  }

  dismiss() {
    this.modalService.dismissAll();
  }

  async onSubmit() {
    const formValue = this.forgotPasswordForm.value;
    if (this.forgotPasswordForm.invalid) {
      // log.debug('error send form',this.formData.invalid)
      this.error = true;
      this.errorText = this.translateService.instant(
        'ERROR_MSG.ERROR_FORGOT_PASSWORD'
      );
      return;
    }

    try {
      this.sending = true;
      const payload = {
        password: formValue.password,
        confirmPassword: formValue.confirmPassword,
      };

      const result = await this.authenticationService.changePasswordPaperWallet(
        payload,
        this.code
      );

      if (result) {
        Swal.fire({
          title: this.translateService.instant('SUCCESS'),
          icon: 'success',
          text: this.translateService.instant('YOUR_PASSWORD_HAS_BEEN_CHANGED'),
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonColor: '#4B0082',
          confirmButtonText: this.translateService.instant('OK'),
          customClass: 'popup-success-custom',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/signin']);
          }
        });
      }
    } catch (error) {
      // this.errorHandlerService.error(error, true);
      const _error = this.errorHandlerService.error(error).message;
      log.debug('_error => %o', _error);
      this.errorText = _error;
    } finally {
      this.sending = false;
    }
  }

  showPassword() {
    this.isShow = !this.isShow;
    log.debug(this.isShow);
  }

  showConfirm() {
    this.isShowRecomfirm = !this.isShowRecomfirm;
  }
}
