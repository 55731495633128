<div class="card">
  <div class="card-body py-4 px-3">
    <!-- balance text -->

    <div class="row my-3 text-center">
      <div class="col-12 position-relative">
        <div class="extend-icon position-absolute">
          <div
            class="extend-icon-container p-1 d-flex justify-content-center align-items-center"
          >
            <!-- <i class="bi bi-app text-secondary"></i> -->
            <img src="assets/img/icon/scanner.png" alt="scanner" />
          </div>
        </div>
        <h1 class="fw-light mb-2">
          {{ balance | numeral : "0,0.00" }}
        </h1>
        <p class="text-secondary">{{ "POINT_PALANCE" | translate }}</p>
      </div>
    </div>

    <!-- end balance text -->

    <!-- inner cards -->
    <div class="row">
      <div class="col-6">
        <div class="card rounded-15">
          <div class="card-body button-body">
            <div class="row">
              <div class="col-auto">
                <div
                  class="avatar avatar-36 shadow-success rounded-10 btn-action-left"
                >
                  <div class="icons text-white">
                    <i class="bi bi-arrow-down-left-square size-26"></i>
                  </div>
                </div>
              </div>
              <div class="col align-self-center px-0">
                <p class="size-12 text-secondary mb-0">{{"RECEIVE" | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card rounded-15" (click)="openSendModal()">
          <div class="card-body button-body">
            <div class="row">
              <div class="col-auto">
                <div
                  class="avatar avatar-36 shadow-danger rounded-10 btn-action-right"
                >
                  <div class="icons text-white">
                    <i class="bi bi-arrow-up-right-square size-26"></i>
                  </div>
                </div>
              </div>
              <div class="col align-self-center px-0">
                <p class="size-12 text-secondary mb-0">{{"SEND" | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end inner cards -->
  </div>
</div>

<style>
  .button-body {
    padding: 0.65rem !important;
  }
  .balance-text {
    font-size: 35px;
  }
  .extend-icon-container {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }
  .extend-icon {
    right: 5%;
    top: 20%;
    font-size: 13px;
  }
</style>
