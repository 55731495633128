<div class="wrapper-100vw">
  <div class="wrapper-top-header" #topHeader>
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" (click)="backnav()" type="button">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{ "PROFILE" | translate }}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2">
    <div class="alert alert-success mt-4" role="alert" *ngIf="success">
      {{"UPDATED_PROFILE" | translate}}
    </div>
    <div class="alert alert-danger mt-4" role="alert" *ngIf="!!error">
      {{ error }}
    </div>
  </div>

  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center">
    <form [formGroup]="profileForm" class="mt-4">

      <div class="file-container py-4">
        <label for="files-input" *ngIf="!avatarPreview">
          <div class="dropzone">
            <div class="upload-icon mt-0">
              <i class="bi bi-cloud-upload size-40 text-secondary lh-sm"></i>
              <span class="size-14 text-secondary fw-600 text-uppercase">{{"UPLOAD" | translate}}</span>
            </div>
            <input
              #inputRef
              id="files-input"
              type="file"
              class="file-input"
              (change)="onInputFileChange($event)"
            />
          </div>
        </label>

        <div class="preview-image-container active-profile image-profile-preview aura-effect" *ngIf="!!avatarPreview">
          <img [src]="avatarPreview" alt="avatarPreview" class="avatar-profile-img" />
          <button (click)="removeAvatar()" type="button" class="btn"><i class="bi bi-x size-20 text-white"></i></button>
        </div>
      </div>


      <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="firstName">{{
        "FIRSTNAME" | translate
      }}</label>
      <div class="form-group form-floating mb-3">
        <input
          class="form-control"
          [class.is-invalid]="
            profileForm.get('firstName')?.invalid &&
            profileForm.get('firstName')?.touched
          "
          type="text"
          formControlName="firstName"
          placeholder="{{ 'FIRSTNAME' | translate }}"
          name="firstName"
        />
        <div
          class="w-100"
          *ngIf="
            (profileForm.get('firstName')?.invalid &&
              profileForm.get('firstName')?.touched) ||
            profileForm.get('firstName')?.dirty
          "
        >
          <small
            *ngIf="profileForm.get('firstName')?.errors?.required"
            class="text-danger d-block w-100 px-3 pb-3"
            >{{ "FIRSTNAME" | translate }} {{ "IS_REQUIRED" | translate }}</small
          >
        </div>
      </div>

      <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="lastName">{{
        "LASTNAME" | translate
      }}</label>
      <div class="form-group form-floating mb-3">
        <input
          class="form-control"
          [class.is-invalid]="
            profileForm.get('lastName')?.invalid &&
            profileForm.get('lastName')?.touched
          "
          type="text"
          formControlName="lastName"
          placeholder="{{ 'LASTNAME' | translate }}"
          name="Lastname"
        />
        <div
          class="w-100"
          *ngIf="
            (profileForm.get('lastName')?.invalid &&
              profileForm.get('lastName')?.touched) ||
            profileForm.get('lastName')?.dirty
          "
        >
          <small
            *ngIf="profileForm.get('lastName')?.errors?.required"
            class="text-danger d-block w-100 px-3 pb-3"
            >{{ "LASTNAME" | translate }} {{ "IS_REQUIRED" | translate }}</small
          >
        </div>
      </div>


      <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="mobilePhone">{{
        "PHONE" | translate
      }}</label>
      <div class="form-group form-floating mb-3 input-group input-group-merge">
        <div class="input-group-prepend d-flex align-items-end">
          <span class="input-group-text" id="basic-addon1">0</span>
        </div>
        <input
          class="form-control px-2 pb-2"
          [class.is-invalid]="
            profileForm.get('mobilePhone')?.invalid &&
            profileForm.get('mobilePhone')?.touched
          "
          [class.disable-input]="currentUser.registerType === 'mobilePhone'"
          type="text"
          formControlName="mobilePhone"
          placeholder="{{ 'MOBILE_PHONE_NUMBER' | translate }}"
          name="mobilePhone"
          mask="99 999 9999"
          [readonly]="currentUser.registerType === 'mobilePhone'"
        />
        <div
          class="w-100"
          *ngIf="
            (profileForm.get('mobilePhone')?.invalid &&
              profileForm.get('mobilePhone')?.touched) ||
            profileForm.get('mobilePhone')?.dirty
          "
        >
          <small
            *ngIf="profileForm.get('mobilePhone')?.errors?.required"
            class="text-danger d-block w-100 px-3 py-3"
            >{{ "MOBILE_PHONE_NUMBER" | translate }}
            {{ "IS_REQUIRED" | translate }}</small
          >
        </div>
      </div>

      <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="email">{{
        "EMAIL" | translate
      }}</label>
      <div class="form-group form-floating mb-3">
        <input
          class="form-control"
          [class.is-invalid]="
            profileForm.get('email')?.invalid && profileForm.get('email')?.touched
          "
          [class.disable-input]="currentUser.registerType === 'email'"
          type="email"
          formControlName="email"
          placeholder="{{ 'EMAIL' | translate }}"
          name="email"
          [readOnly]="currentUser.registerType === 'email'"
        />
        <div
          class="w-100"
          *ngIf="
            (profileForm.get('email')?.invalid &&
              profileForm.get('email')?.touched) ||
            profileForm.get('email')?.dirty
          "
        >
          <small
            *ngIf="profileForm.get('email')?.errors?.required"
            class="text-danger d-block w-100 px-3 pb-3"
            >{{ "EMAIL" | translate }} {{ "IS_REQUIRED" | translate }}</small
          >
          <small
            *ngIf="profileForm.get('email')?.errors?.pattern"
            class="text-danger d-block w-100 px-3 pb-3"
            >{{ "PLEASE_PROVIDE_A_VALID_EMAIL_ADDRESS" | translate }}</small
          >
        </div>
      </div>

      <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="job">{{
        "JOB" | translate
      }}</label>
      <div class="form-group form-floating mb-3">
        <input
          class="form-control"
          [class.is-invalid]="
            profileForm.get('job')?.invalid &&
            profileForm.get('job')?.touched
          "
          type="text"
          formControlName="job"
          placeholder="{{ 'JOB' | translate }}"
          name="job"
        />
        <div
          class="w-100"
          *ngIf="
            (profileForm.get('job')?.invalid &&
              profileForm.get('job')?.touched) ||
            profileForm.get('job')?.dirty
          "
        >
          <small
            *ngIf="profileForm.get('job')?.errors?.required"
            class="text-danger d-block w-100 px-3 pb-3"
            >{{ "JOB" | translate }} {{ "IS_REQUIRED" | translate }}</small
          >
        </div>
      </div>

      <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="institution">{{
        "INSTITUTION" | translate
      }}</label>
      <div class="form-group form-floating mb-3">
        <input
          class="form-control"
          [class.is-invalid]="
            profileForm.get('institution')?.invalid &&
            profileForm.get('institution')?.touched
          "
          type="text"
          formControlName="institution"
          placeholder="{{ 'INSTITUTION' | translate }}"
          name="institution"
        />
        <div
          class="w-100"
          *ngIf="
            (profileForm.get('institution')?.invalid &&
              profileForm.get('institution')?.touched) ||
            profileForm.get('institution')?.dirty
          "
        >
          <small
            *ngIf="profileForm.get('institution')?.errors?.required"
            class="text-danger d-block w-100 px-3 pb-3"
            >{{ "INSTITUTION" | translate }} {{ "IS_REQUIRED" | translate }}</small
          >
        </div>
      </div>

      <div class="mt-4 pb-5 pt-3 text-center">
        <button
          type="submit"
          class="btn btn-default btn-lg w-100"
          [disabled]="loading"
          (click)="onSubmit()"
        >
        {{"SAVE" | translate}}
        </button>
      </div>
    </form>
  </div>

</div>
