import { Component } from '@angular/core';
import { Router } from '@angular/router';

import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
})
export class ContactusComponent {

  constructor(
    private _router:Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) { }

  backnav() {
    this.singletonService.back();
    return false;
  }


}
