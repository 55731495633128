import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import party from "party-js";

@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss']
})
export class ConfettiComponent {

  run(source:any){
    party.confetti(source,{
      count: party.variation.range(50, 60),
      size: party.variation.range(0.6, 1.4),
    });
  }

}
