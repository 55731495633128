
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserWithToken } from 'src/models/authToken.model';
import { Token } from 'src/models/token.model';
import { User } from 'src/models/user.model';

const prefix = environment.localStoragePrefix;

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor() {}

  setAuthToken(authToken: UserWithToken) {
    this.saveAccessToken(authToken.tokens.access);
    this.saveRefreshToken(authToken.tokens.refresh);
    this.saveUser(authToken.user);
    return true;
  }

  saveAccessToken(accessToken: Token) {
    localStorage.setItem(`${prefix}_accessToken`, JSON.stringify(accessToken));
  }

  saveRefreshToken(refreshToken: Token) {
    localStorage.setItem(
      `${prefix}_refreshToken`,
      JSON.stringify(refreshToken)
    );
  }

  getAccessTokenObj(): Token {
    const token = localStorage.getItem(`${prefix}_accessToken`);
    return JSON.parse(token!) as Token;
  }

  getRefreshTokenObj(): Token {
    const token = localStorage.getItem(`${prefix}_refreshToken`);
    return JSON.parse(token!) as Token;
  }

  getAccessToken(): any {
    const tokenObj: Token = this.getAccessTokenObj();
    return tokenObj ? tokenObj.token : undefined;
  }

  getAccessTokenExpire(): any {
    const tokenObj: Token = this.getAccessTokenObj();
    return tokenObj ? tokenObj.expires : undefined;
  }

  getRefreshToken(): any {
    const tokenObj: Token = this.getRefreshTokenObj();
    return tokenObj ? tokenObj.token : undefined;
  }

  saveUser(user: User) {
    localStorage.setItem(`${prefix}_user`, JSON.stringify(user));
  }

  getUser(): User {
    const user = localStorage.getItem(`${prefix}_user`);
    return JSON.parse(user!) as User;
  }
}
