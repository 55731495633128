<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: true }"
></ngx-loading>

<ng-container *ngIf="!loading">
  <!-- <div class="modal-header p-4 mb-0">
    <h4>
      <span class="text-white fw-bold size-20">{{"MY_QR_CODE_TICKET" | translate}}</span>
      <p class="text-secondary mb-0 mt-2 size-14 fw-normal">{{"SCAN_THIS_QRCODE" | translate}}</p>
    </h4>
    <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
  </div> -->
  <div class="modal-header pb-3">
    <button
      class="btn btn-arrow-back"
      type="button"
      (click)="modal.dismiss('Cross click')"
    >
      <i class="bi bi-chevron-left text-white size-16"></i>
    </button>
    <h1 class="mb-0">
      <div class="size-20 fw-bold text-white">{{"RECEIVE" | translate}}</div>
    </h1>
    <button class="btn btn-top-right-arrow" type="button"></button>
  </div>
  <div class="modal-body p-4 text-center">
    <div class="wrapper-select-token d-flex align-items-center justify-content-between">
      <div class="size-14">
        Select Token
      </div>
      <div>
        <select class="form-select">
          <option *ngFor="let exchangeToken of exchangeTokens" (click)="selectToken(exchangeToken)">
            <!-- <img [src]="exchangeToken.eventToken.logo" width="24" alt=""> -->
            {{exchangeToken.eventToken.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="text-center text-secondary size-16 my-3">
      Amount
    </div>
    <div class="input-amount-receive">
      <input type="text" class="form-control input-border-bottom" [(ngModel)]="amount" placeholder="10" numbersOnly>
      <button (click)="selectAmount('')" type="button" class="btn btn-clear-value">
        <i class="bi bi-x-circle-fill text-white size-14"></i>
      </button>
    </div>
    <div class="wrapper-select-amount mt-4 mb-3">
      <div class="size-14 text-start mb-2">Select Amount</div>
      <div class="row d-flex justify-content-start">
        <div class="col-3 px-1 my-1">
          <button type="btn" class="btn" (click)="selectAmount(10)">10</button>
        </div>
        <div class="col-3 px-1 my-1">
          <button type="btn" class="btn" (click)="selectAmount(100)">100</button>
        </div>
        <div class="col-3 px-1 my-1">
          <button type="btn" class="btn" (click)="selectAmount(200)">200</button>
        </div>
        <div class="col-3 px-1 my-1">
          <button type="btn" class="btn" (click)="selectAmount(300)">300</button>
        </div>
        <div class="col-3 px-1 my-1">
          <button type="btn" class="btn" (click)="selectAmount(400)">400</button>
        </div>
        <div class="col-3 px-1 my-1">
          <button type="btn" class="btn" (click)="selectAmount(500)">500</button>
        </div>
        <div class="col-3 px-1 my-1">
          <button type="btn" class="btn" (click)="selectAmount(1000)">1,000</button>
        </div>
        <div class="col-3 px-1 my-1">
          <button type="btn" class="btn" (click)="selectAmount(2000)">2,000</button>
        </div>
        <!-- <button type="btn" class="btn" (click)="selectAmount(3000)">3,000</button> -->
        <!-- <button type="btn" class="btn" (click)="selectAmount(0)">Enter Amount</button> -->
      </div>
    </div>
    <div class="wrapper-footer-action-fixed">
      <div class="footer-action-fixed-inner">
        <p class="mb-2 text-secondary size-14">
          Press confirm to generate a QR code
        </p>
        <button [disabled]="!selectedExchangeToken || !amount || amount === '' " type="button" class="btn btn-default size-16 py-2" (click)="confirmTx()">Confirm</button>
      </div>
    </div>
    <!-- <div class="wrapper-qr-code">
      <div>
        <img [src]="userQrCode.qrCode" alt="QR Code" class="receive-qrcode qr-code-img" />
      </div>
    </div> -->
    <!-- <div class="wrapper-btn-actions gap-4 mt-3 mb-2 d-flex justify-content-center">
      <a (click)="singletonService.downloadMediaFromUrl(userQrCode.qrCode)" class="btn-action btn-purple">
        <span class="size-14 me-2">
          {{"DOWNLOAD_QRCODE" | translate}}
        </span>
        <img src="assets/img/icon/icon-actions/download.png" alt="download" />
      </a>
    </div> -->
  </div>
</ng-container>
