<!-- <div class="col-11 col-sm-11 mt-auto mx-auto py-4">
  <div class="row">
    <div class="col-12 d-grid">
      <button
        type="submit"
        class="btn btn-default btn-lg"
        (click)="signOut()"
      >
        Sign Out
      </button>
    </div>
  </div>
</div> -->

<!-- Add pushcontent or fullmenu instead overlay -->

<div class="profile-wrapper wrapper-100vw">
  <div class="content-container">
    <!-- if logged in -->
    <div class="row-top-profile row border-bottom mx-0" *ngIf="currentUser">
      <div class="col-auto">
        <!-- <figure class="avatar avatar-70 rounded-circle p-1 bg-white"> -->
        <figure
          class="active-profile image-profile-preview aura-effect mb-0"
          routerLink="/profilesetting"
        >
          <img
            [src]="
              currentUser.profile && currentUser.profile.picture
                ? currentUser.profile.picture
                : 'assets/img/dashboard/avatar.png'
            "
            [ngClass]="
              currentUser.profile && currentUser.profile.picture
                ? ''
                : 'avatar-default-profile-img'
            "
            alt=""
            class="avatar-profile-img"
          />
        </figure>
      </div>
      <div class="col px-0 align-self-center col-profile-name">
        <h5 class="mb-1 text-light size-18" *ngIf="currentUser">
          {{
            currentUser
              ? currentUser.profile.firstName +
                " " +
                currentUser.profile.lastName
              : currentUser[currentUser.registerType]
              ? currentUser[currentUser.registerType]
              : currentUser.id
          }}
        </h5>
        <!-- <p class="text-light size-14 text-uppercase">
          <span class="me-2">{{ "MY_POINT" | translate }}</span>
          <span class="me-2"><b>0</b></span>
          <img
            src="assets/img/icon/coin.png"
            alt="coin"
          />
        </p> -->
        <div class="d-flex">
          <p class="mt-2 me-2">
            <button
              type="button"
              class="btn btn-outline-light text-capitalize btn-sm lh-sm rounded-10 px-3"
              routerLink="/profilesetting"
            >
              {{ "EDIT_PROFILE" | translate }}
            </button>
          </p>

          <p class="mt-2">
            <button
              style="min-width: 140px"
              type="button"
              class="btn btn-outline-light text-capitalize btn-sm lh-sm rounded-10 px-3"
              (click)="copyInfomation()"
            >
              {{
                copied
                  ? ("COPIED" | translate)
                  : ("COPY_INFOMATION" | translate)
              }}
            </button>
          </p>
        </div>
      </div>
      <!-- <div class="col-auto align-self-center pe-4">
        <i class="bi bi-chevron-right text-light"></i>
      </div> -->
    </div>
    <!-- end logged in -->

    <!-- if not log in -->
    <div *ngIf="!currentUser">
      <div class="wrapper-info-blur">
        <div class="wrapper-info-blur-inner">
          <div class="pt-2">
            <div class="text-secondary size-12">
              {{ "PLEASE_SIGN_IN_OR_SIGN_UP" | translate }}
              {{ "TO_ACCESS_YOUR_ACCOUNT" | translate }}
            </div>
          </div>
          <div class="wrapper-actions-button">
            <a routerLink="/signup" class="btn btn-outline-main size-16">{{
              "SIGNIN.SIGNUP" | translate
            }}</a>
            <a routerLink="/signin" class="btn btn-default size-16">{{
              "SIGNIN.SIGNIN" | translate
            }}</a>
          </div>
        </div>
      </div>
      <!-- end not log in -->
    </div>

    <div class="menu-container text-light">
      <!-- my namecard -->
      <div class="row-menu-container mb-3">
        <div class="row mx-0 menu-row" routerLink="/mynamecard">
          <div class="col-max-w-100px col-2 align-self-center text-center">
            <!-- <i class="bi bi-ui-checks"></i> -->
            <span
              class="icon-profile-head icon-profile-item"
              style="
                background: url('assets/img/icon/menu-mobile/mynamecard.png');
              "
            ></span>
          </div>
          <div class="col align-self-center px-0">
            <p>{{ "MY_NAMECARD" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <i class="bi bi-chevron-right"></i>
          </div>
        </div>
      </div>
      <!-- end my namecard -->

      <!-- setting -->
      <!-- <div class="row-menu-container sub-menu-container row-menu-container-group" [class.active]="isSettingCollapsed"> -->
      <div class="row-menu-container row-menu-container-group">
        <div
          class="row mx-0 menu-row main-menu-divider noti-menu"
          routerLink="/notification"
        >
          <div
            class="col-max-w-100px col-2 align-self-center text-center position-relative"
          >
            <div class="wrapper-badge-noti">
              <span
                class="icon-profile-head icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/notification.png');
                "
              ></span>
              <span *ngIf="!!notiBadge" class="badge-count-noti">{{
                notiBadge
              }}</span>
            </div>
          </div>
          <div class="col align-self-center px-0">
            <p class="text-white">{{ "NOTIFICATION" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <div class="bi bi-chevron-right collapse-icon text-white"></div>
          </div>
        </div>
        <div
          class="row mx-0 menu-row main-menu-divider settings-menu"
          routerLink="/setting"
        >
          <div class="col-max-w-100px col-2 align-self-center text-center">
            <span
              class="icon-profile-head icon-profile-item"
              style="
                background: url('assets/img/icon/menu-mobile/settings.png');
              "
            ></span>
          </div>
          <div class="col align-self-center px-0">
            <p class="text-white">{{ "SETTING" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <div class="bi bi-chevron-right collapse-icon text-white"></div>
          </div>
        </div>
        <!-- <div class="collapse" id="settingCollapse">
          <div class="row menu-row mx-0" routerLink="/language">
            <div class="col-max-w-100px col-2 align-self-center text-center">
              <span
                class="icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/language-active.png');
                "
              ></span>
            </div>
            <div class="col align-self-center px-0">
              <p>{{ "LANGUAGE" | translate }}</p>
            </div>
            <div class="col-2 align-self-center text-center">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
          <div class="row menu-row mx-0" routerLink="/changepassword">
            <div class="col-max-w-100px col-2 align-self-center text-center">
              <span
                class="icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/key-active.png');
                "
              ></span>
            </div>
            <div class="col align-self-center px-0">
              <p>{{ "PASSWORD" | translate }}</p>
            </div>
            <div class="col-2 align-self-center text-center">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
          <div class="row menu-row mx-0" routerLink="/pincode-setting">
            <div class="col-max-w-100px col-2 align-self-center text-center">
              <span
                class="icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/key-active.png');
                "
              ></span>
            </div>
            <div class="col align-self-center px-0">
              <p>{{ "PINCODE" | translate }}</p>
            </div>
            <div class="col-2 align-self-center text-center">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div> -->
      </div>
      <!-- end setting -->

      <!-- support -->
      <!-- <div class="row-menu-container sub-menu-container row-menu-container-group" [class.active]="isSupportCollapsed"> -->
      <div class="row-menu-container row-menu-container-group">
        <div
          class="row mx-0 menu-row main-menu-divider services-menu"
          routerLink="/help-and-support"
        >
          <div class="col-max-w-100px col-2 align-self-center text-center">
            <!-- <i class="bi bi-headset"></i> -->
            <span
              class="icon-profile-head icon-profile-item"
              style="
                background: url('assets/img/icon/menu-mobile/help-and-support.png');
              "
            ></span>
          </div>
          <div class="col align-self-center px-0">
            <p class="text-white">{{ "HELP_SUPPORT" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <div class="bi bi-chevron-right collapse-icon text-white"></div>
          </div>
        </div>
        <!-- <div class="collapse" id="supportCollapse">
          <div class="row menu-row mx-0" routerLink="/contactus">
            <div class="col-max-w-100px col-2 align-self-center text-center">
              <span
                class="icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/contact-active.png');
                "
              ></span>
            </div>
            <div class="col align-self-center px-0">
              <p>{{ "CONTACT_US" | translate }}</p>
            </div>
            <div class="col-2 align-self-center text-center">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
          <div class="row menu-row mx-0" routerLink="/faq">
            <div class="col-max-w-100px col-2 align-self-center text-center">
              <span
                class="icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/faq-active.png');
                "
              ></span>
            </div>
            <div class="col align-self-center px-0">
              <p>{{ "FAQ" | translate }}</p>
            </div>
            <div class="col-2 align-self-center text-center">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div> -->
        <!-- end support -->
      </div>
      <!-- end support -->

      <!-- other -->
      <!-- <div class="row-menu-container sub-menu-container row-menu-container-group" [class.active]="isOtherCollapsed"> -->
      <div class="row-menu-container row-menu-container-group">
        <div
          class="row mx-0 menu-row main-menu-divider others-menu"
          routerLink="/privacy-and-data"
        >
          <div class="col-max-w-100px col-2 align-self-center text-center">
            <span
              class="icon-profile-head icon-profile-item"
              style="
                background: url('assets/img/icon/menu-mobile/privacy-and-data.png');
              "
            ></span>
          </div>
          <div class="col align-self-center px-0">
            <p class="text-white">{{ "PRIVACY_DATA" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <div class="bi bi-chevron-right collapse-icon text-white"></div>
          </div>
        </div>
        <!-- <div class="collapse" id="otherCollapse">
          <div class="row menu-row mx-0" routerLink="/privacypolicy">
            <div class="col-max-w-100px col-2 align-self-center text-center">
              <span
                class="icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/policy-active.png');
                "
              ></span>
            </div>
            <div class="col align-self-center px-0">
              <p>{{ "PRIVACY_POLICY" | translate }}</p>
            </div>
            <div class="col-2 align-self-center text-center">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
          <div class="row menu-row mx-0" routerLink="/termsandconditions">
            <div class="col-max-w-100px col-2 align-self-center text-center">
              <span
                class="icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/condition-active.png');
                "
              ></span>
            </div>
            <div class="col align-self-center px-0">
              <p>{{ "TERMS_AND_CONDITIONS" | translate }}</p>
            </div>
            <div class="col-2 align-self-center text-center">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
          <div class="row menu-row mx-0" routerLink="/aboutus">
            <div class="col-max-w-100px col-2 align-self-center text-center">
              <span
                class="icon-profile-item"
                style="
                  background: url('assets/img/icon/menu-mobile/about-us-active.png');
                "
              ></span>
            </div>
            <div class="col align-self-center px-0">
              <p>{{ "ABOUT_US" | translate }}</p>
            </div>
            <div class="col-2 align-self-center text-center">
              <i class="bi bi-chevron-right"></i>
            </div>
          </div>
        </div> -->
      </div>
      <!-- end other -->

      <!-- review us -->
      <div class="row-menu-container mt-3">
        <div class="row mx-0 menu-row" routerLink="/reviewus">
          <div class="col-max-w-100px col-2 align-self-center text-center">
            <!-- <i class="bi bi-ui-checks"></i> -->
            <span
              class="icon-profile-head icon-profile-item"
              style="background: url('assets/img/icon/menu-mobile/ratings.png')"
            ></span>
          </div>
          <div class="col align-self-center px-0">
            <p>{{ "COMPLIMENT" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <i class="bi bi-chevron-right"></i>
          </div>
        </div>
      </div>
      <!-- end review us -->

      <!-- review us -->
      <div
        class="row-menu-container mt-3"
        *ngIf="
          currentUser &&
          currentUser.role &&
          (isRoleStaff || currentUser.role === 'admin')
        "
      >
        <div class="row mx-0 menu-row" (click)="getDashboardToken()">
          <div class="col-max-w-100px col-2 align-self-center text-center">
            <!-- <i class="bi bi-ui-checks"></i> -->
            <span
              class="icon-profile-head icon-profile-item"
              style="
                background: url('assets/img/icon/menu-mobile/language.png');
              "
            ></span>
          </div>
          <div class="col align-self-center px-0">
            <p>{{ "DASHBOARD" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <i class="bi bi-chevron-right"></i>
          </div>
        </div>
      </div>
      <!-- end review us -->

      <!-- sign out -->
      <div class="row-menu-container mt-5">
        <div *ngIf="currentUser" class="row mx-0 menu-row" (click)="signOut()">
          <div class="col-max-w-100px col-2 align-self-center text-center">
            <!-- <i class="bi bi-box-arrow-right"></i> -->
            <span
              class="icon-profile-head icon-profile-item"
              style="background: url('assets/img/icon/menu-mobile/login.png')"
            ></span>
          </div>
          <div class="col align-self-center px-0">
            <p>{{ "SIGN_OUT" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <i class="bi bi-chevron-right"></i>
          </div>
        </div>
      </div>
      <!-- end sign out -->

      <!-- sign in -->
      <div class="row-menu-container mt-5">
        <div
          *ngIf="!currentUser"
          class="row mx-0 menu-row"
          routerLink="/signin"
        >
          <div class="col-max-w-100px col-2 align-self-center text-center">
            <!-- <i class="bi bi-box-arrow-left"></i> -->
            <span
              class="icon-profile-head icon-profile-item"
              style="background: url('assets/img/icon/menu-mobile/login.png')"
            ></span>
          </div>
          <div class="col align-self-center px-0">
            <p>{{ "SIGN_IN" | translate }}</p>
          </div>
          <div class="col-2 align-self-center text-center ps-3">
            <i class="bi bi-chevron-right"></i>
          </div>
        </div>
      </div>
      <!-- end sign in -->
      <div style="padding-top: 10px; text-align: center; font-size: 14px">
        Version {{ appVersion }} by Linxlab.io
      </div>
    </div>
  </div>
</div>

<!-- user information -->
