<div class="px-3 wrapper-100vw">
  <div class="row pt-3">
    <div class="col-auto align-self-center">
      <button type="button" class="btn text-white btn-44" (click)="backnav()">
        <i class="bi bi-arrow-left"></i>
      </button>
    </div>
    <div class="col align-self-center text-center px-0">
      <h6 class="title size-16">{{"HISTORY" | translate}}</h6>
    </div>
    <div class="col-auto align-self-center">
      <button class="btn text-white btn-44">
        <i class="bi bi-sliders size-22"></i>
      </button>
    </div>
  </div>

  <div class="row mb-5 mt-3 pb-5">
    <div class="col-12">
      <div class="card">
        <div class="card-body p-0">
          <div
            *ngIf="historyLoading; then loadingBlock; else contentBlock"
          ></div>
          <ng-template #loadingBlock>
            <div class="py-5 text-center text-muted">{{"LOADING" | translate}}...</div>
          </ng-template>

          <ng-template #contentBlock>
            <div
              class="p-5 text-center text-muted"
              *ngIf="!transections.length"
            >
            {{"NO_CONTENT" | translate}}...
            </div>

            <div
              infinite-scroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="500"
              (scrolled)="onScrollEnd()"
            >
              <ul class="list-group list-group-flush bg-none">
                <li
                  *ngFor="let transection of transections"
                  class="list-group-item"
                >
                  <div class="row">
                    <div class="col-auto">
                      <div
                        *ngIf="currentUser.id === transection.to.id"
                        class="avatar avatar-46 shadow-green card rounded-10 p-1"
                      >
                        <div class="icons">
                          <i
                            class="bi bi-arrow-down-left-square text-success size-26"
                          ></i>
                        </div>
                      </div>
                      <div
                        *ngIf="currentUser.id === transection.from.id"
                        class="avatar avatar-46 shadow-danger card rounded-10 p-1"
                      >
                        <div class="icons">
                          <i
                            class="bi bi-arrow-up-right-square text-danger size-26"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="col align-self-center ps-0">
                      <p class="mb-0">
                        {{ currentUser.id === transection.to.id ? "+" : "-" }}
                        {{ transection.amount }}
                        <img
                          src="assets/img/icon/coin.png"
                          class="align-sub"
                          alt="coin"
                        />
                      </p>
                      <p class="text-secondary text-muted size-12 mb-0">
                        {{ transection.status }}
                      </p>
                    </div>
                    <div class="col align-self-end text-end">
                      <p class="text-secondary text-muted size-12 mb-0">
                        {{ transection.transactionDate | date : "dd/MM/yy" }}
                      </p>
                      <p class="text-secondary text-muted size-12 mb-0">
                        {{ transection.transactionDate | date : "HH:mm" }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="historyLoadMore">{{"LOADING" | translate}}...</div>
    </div>
  </div>
</div>
