<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" (click)="backnav()" type="button">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{"SCAN_QR_WITH" | translate}} {{"CAMERA" | translate}} / {{"IMAGE" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="section pt-3">
    <div class="container px-2">
      <!-- <h3 class="text-secondary fw-light">
        {{"SCAN_QR_WITH" | translate}
      </h3>
      <h3 class="mb-3">{{"CAMERA" | translate} / {{"IMAGE" | translate}</h3> -->
      <div id="qr-scanner-with-camera">
        <zxing-scanner [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
        [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
        (camerasFound)="onCamerasFound($event)"></zxing-scanner>
        <select class="form-select select-device form-select-sm" (change)="onDeviceSelectChange($event)">
          <option value="" [selected]="!currentDevice">{{"NO_DEVICE_SELECTED" | translate}}</option>
          <option *ngFor="let device of availableDevices" [value]="device.deviceId"
            [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{device.label}}</option>
        </select>
        <div class="select-img">
          <label for="select-img">
            <i class="bi bi-card-image"></i>
          </label>
          <input type="file" accept="image/*" id="select-img" hidden (change)="onFileChange($event)">
        </div>
      </div>
      <div class="mt-3 text-center" *ngIf="qrResultString">
        <h3 class="text-secondary fw-light mb-1 size-20">
          {{"RESULT" | translate}}:
        </h3>
        <h3 class="size-24">
          {{qrResultString}}
        </h3>
      </div>
    </div>
  </div>

</div>

