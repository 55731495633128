<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{"SETTING" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center">

    <label class="text-uppercase mb-2 size-14 text-label">{{"DISPLAY" | translate}}</label>
    <ul class="card mb-3">
      <li routerLink="/language">
        <label class="text-white size-16">{{"LANGUAGE" | translate}}</label>
        <label class="text-secondary">
          <span class="size-14 me-2">{{currentLanguageDisplay}}</span>
          <i class="bi bi-chevron-right size-16"></i>
        </label>
      </li>
      <!-- <li>
        <label class="text-white size-16">Dark Mode</label>
        <label class="text-secondary"></label>
      </li> -->
    </ul>

    <label class="text-uppercase mb-2 size-14 text-label">{{"SYSTEM" | translate}}</label>
    <ul class="card mb-3">
      <li routerLink="/changepassword">
        <label class="text-white size-16">{{"PASSWORD" | translate}}</label>
        <label class="text-secondary">
          <i class="bi bi-chevron-right size-16"></i>
        </label>
      </li>
      <li routerLink="/pincode-setting">
        <label class="text-white size-16">{{"PIN" | translate}}</label>
        <label class="text-secondary">
          <i class="bi bi-chevron-right size-16"></i>
        </label>
      </li>
      <li>
        <label class="text-white size-16">{{"REMIND_TRANSACTION" | translate}}</label>
        <label class="text-secondary">
          <div class="form-check form-switch">
            <input class="form-check-input ms-0" type="checkbox" role="switch" id="remindAddingTransaction" checked>
            <!-- <label class="form-check-label" for="remindAddingTransaction">Checked switch checkbox input</label> -->
          </div>
        </label>
      </li>
    </ul>

    <ul class="card mt-5">
      <li (click)="openConfirmDeleteID()">
        <label class="text-white size-16">{{"DELETE_ACCOUNT" | translate}}</label>
        <!-- <div class="card-body button-body">
          <div class="row">
            <div class="col-auto">
              <div
                class="avatar avatar-36 shadow-success rounded-10 btn-action-left"
              >
                <div class="icons text-white">
                  <i class="bi bi-arrow-down-left-square size-26"></i>
                </div>
              </div>
            </div>
            <div class="col align-self-center px-0">
            </div>
          </div>
        </div> -->

        <label class="text-secondary">
          <i class="bi bi-chevron-right size-16"></i>
        </label>
      </li>
    </ul>

  </div>

</div>
