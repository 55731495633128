<ng-container *ngIf="coupon">
  <div class="card card-container mb-3">
    <div class="v-label px-2 text-light">
      {{ coupon?.category?.title | uppercase }}
    </div>
    <div class="w-100">
      <div
        class="d-flex p-3 card-description-row justify-content-between gap-2"
      >
        <div class="">
          <h6 class="title size-16 mb-2">{{ coupon?.name }}</h6>
          <p class="text-secondary size-14">
            {{ coupon?.description }}
          </p>
        </div>
        <div class="">
          <div class="wrapper-coupon-img">
            <ng-container *ngIf="coupon.image">
              <img [src]="coupon.image" class="card-image" />
            </ng-container>
            <ng-container *ngIf="!coupon.image">
              <img
                src="assets/img/dashboard/services/coupon-reward.png"
                class="card-image empty-coupon-img"
                alt="coupon reward"
              />
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row p-3 gap-3">
        <div class="col align-self-center">
          <div class="d-flex flex-row" style="margin-left: -3px">
            <ng-container *ngFor="let condition of coupon.redeemConditions">
              <ng-container [ngSwitch]="condition.type">
                <h6 class="title mb-1 me-2" *ngSwitchCase="'nft'">
                  <img
                    [src]="
                      condition?.ref?.logo || 'assets/img/icon/nft-icon.png'
                    "
                    width="20"
                    height="20"
                    alt="nft"
                    class="me-1"
                  />
                  {{ condition?.amount | numeral : "0,0" }}
                  {{ condition?.ref?.name }}
                </h6>

                <h6 class="title mb-1 me-2" *ngSwitchCase="'token'">
                  <img
                    [src]="
                      condition.ref.logo ||
                      'assets/img/dashboard/coin-53x53.png'
                    "
                    width="24"
                    height="24"
                    alt="coin"
                    class="me-1"
                  />
                  {{ condition.amount | numeral : "0,0" }}
                  {{ condition.ref.name }}
                </h6>
                <h6 *ngSwitchDefault>เกิดข้อผิดพลาด</h6>
              </ng-container>
            </ng-container>
          </div>
          <p
            class="d-flex align-items-center justify-content-start text-secondary size-12"
          >
            <i class="bi bi-calendar4 size-14 text-secondary me-2"></i>
            {{ "GET_UNTILL" | translate }}
            {{ coupon?.endDate | date : "dd/MM/yy" }}
          </p>
        </div>
        <div class="col align-self-center text-end">
          <button
            class="btn btn-default text-capitalize fw-bold btn-use-coupon"
            (click)="buttonClick.emit()"
            [disabled]="buttonDisabled"
          >
            {{ "USE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
