<!-- <div class="row pt-3">
  <div class="col-3 align-self-center">
    <button
      type="button"
      class="btn text-white btn-44 back-btn"
      (click)="backnav()"
    >
      <i class="bi bi-arrow-left"></i>
    </button>
  </div>
  <div class="col align-self-center text-center px-0">
    <h6 class="title size-16">นโยบายความเป็นส่วนตัว</h6>
  </div>
  <div class="col-3 align-self-center"></div>
</div> -->
<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button
              class="btn btn-sm btn-arrow-back ps-0"
              type="button"
              (click)="backnav()"
            >
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{ "PRIVACY_POLICY" | translate }}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div
    class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center"
  >
    <div class="pb-4">
      <h6 class="title size-15 mb-2">{{ "PRIVACY_POLICY" | translate }}</h6>
      <p class="text-secondary mb-3">
        We are committed to protecting your privacy. This Privacy Policy
        explains how we collect, use, and disclose your personal information in
        connection with our event registration site.
      </p>

      <ol class="text-secondary">
        <li class="mb-3">
          <b>Information We Collect</b> We may collect personal information from
          you such as your name, email address, mobile number, and interests. We
          may also collect information about your device, browser, and IP
          address.
        </li>
        <li class="mb-3">
          <b>Use of Information</b> We may use your personal information to
          communicate with you about the event and related information, to
          improve our services, and to personalize your experience on our site.
        </li>
        <li class="mb-3">
          <b>Disclosure of Information</b> We may share your personal
          information with our event partners and service providers to
          facilitate the event and related services. We may also disclose your
          personal information to comply with applicable laws and regulations or
          to protect our rights and property.
        </li>
        <li class="mb-3">
          <b>Security</b> We take reasonable measures to protect your personal
          information from unauthorized access, use, or disclosure. However, no
          method of transmission over the internet or method of electronic
          storage is 100% secure.
        </li>
        <li class="mb-3">
          <b>Cookies</b> We may use cookies to collect information about your
          use of our site and to personalize your experience. You can choose to
          disable cookies in your browser settings.
        </li>
        <li class="mb-3">
          <b>Third-Party Sites</b> Our site may contain links to third-party
          sites. We are not responsible for the privacy practices or content of
          these sites.
        </li>
        <li class="mb-3">
          <b>Children’s Privacy</b> Our site is not intended for children under
          the age of 13. We do not knowingly collect personal information from
          children under the age of 13. If you are a parent or guardian and
          believe that your child has provided us with personal information,
          please contact us immediately.
        </li>
        <li class="mb-3">
          <b>Changes to this Policy</b> We may update this Privacy Policy from
          time to time. We will notify you of any changes by posting the new
          Privacy Policy on this page. We encourage you to review this Privacy
          Policy periodically for any changes.
        </li>
      </ol>

      <p class="text-secondary">
        If you have any questions or concerns about our Terms and Conditions or
        Privacy Policy, please contact us at <b>linxcoperate@gmail.com</b>
      </p>
    </div>
  </div>
</div>
