<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <div class="d-flex justify-content-between">
          <div>
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" routerLink="/">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
          </div>
          <h2>
            <span class="size-18 text-white fw-bold text-capitalize">
              {{ "MY_NAMECARD" | translate }}
            </span>
          </h2>
          <div>
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" [routerLink]="['/qr-code-scanner']">
              <i class="bi bi-sliders size-22"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mb-5 mt-3 pb-5">
      <div class="col-12">
        <div>
          <app-card-namecard [user]="currentUser" [isEdit]="true"></app-card-namecard>
        </div>
      </div>
    </div>
  </div>
</div>
