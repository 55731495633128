<div class="modal-header p-4 mb-0">
  <h4>
    <span class="text-white fw-bold size-20">{{
      "COUPON_REWARD" | translate
    }}</span>
  </h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>

<div class="modal-body p-0">
  <div class="section">
    <div class="container p-0">
      <div class="row justify-content-center mx-0">
        <div class="col-12 text-center px-0">
          <div class="section">
            <div class="ticket-container">
              <!-- <div class="ticket-header">
                <h6 class="title size-16">BRING THE FUTURE LIVING</h6>
              </div> -->
              <div class="pt-3 pb-2">
                <p class="size-12 text-secondary pb-2">
                  {{ "SCAN_THIS_QRCODE" | translate }}
                </p>
                <div class="wrapper-qr-code">
                  <div>
                    <img
                      [src]="focusCoupon.qrCode"
                      class="qr-code-img"
                      width="250px"
                    />
                  </div>
                </div>
              </div>
              <div
                class="wrapper-btn-actions gap-3 mt-3 mb-4 pb-2 d-flex justify-content-center flex-column align-items-center"
              >
                <a
                  (click)="
                    singletonService.downloadMediaFromUrl(focusCoupon.qrCode)
                  "
                  class="btn-action btn-purple"
                >
                  <span class="size-14 me-2">
                    {{ "DOWNLOAD" | translate }} QR-Code
                  </span>
                  <img
                    src="assets/img/icon/icon-actions/download.png"
                    alt="download"
                    width="18"
                  />
                </a>

                <ng-container *ngIf="!!focusCoupon?.coupon?.actions?.length">
                  <ng-container *ngFor="let action of focusCoupon?.coupon?.actions">
                      <a
                        class="btn-action btn-default"
                        (click)="handleNavigareAction(action?.redirectUrl)"
                      >
                        <b class="size-14 text-white fw-normal me-2 text-capitalize">
                          {{ action?.title?.[translateConfigService.getCurrentLanguage()!] }}
                        </b>
                        <img
                          src="assets/img/icon/scanner-white-20x20.png"
                          alt="download"
                          width="24"
                        />
                      </a>
                  </ng-container>
                </ng-container>
              </div>

              <span class="section dots">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </span>

              <div class="pt-5 pb-4 text-start ps-4 pe-4">
                <div class="text-center">
                  <img
                    [src]="focusCoupon.coupon.image"
                    alt=""
                    class="card-image"
                  />
                </div>

                <div class="text-start ps-0 mt-4">
                  <h6 class="title size-18 mb-1">
                    {{ focusCoupon.coupon.name }}
                  </h6>
                  <p class="text-muted size-14">
                    {{ focusCoupon.coupon.description }}
                  </p>
                  <div class="d-flex align-items-center">
                    <i class="bi bi-clock text-secondary me-1"></i>
                    <p class="size-12 text-secondary mb-0 ms-1">
                      {{ "GET_UNTILL" | translate }}
                      {{ focusCoupon.coupon.endDate | date : "dd/MM/yy" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class=" wrapper-btn-actions gap-3 pt-0 mb-4 pb-2 d-flex justify-content-center flex-column align-items-center" *ngIf="focusCoupon.coupon.isExternal">
    <button
      class="btn-action btn-default"
      (click)="onClickMarkCouponAsUsed()"
      [disabled]="submiting"
    >
      <b class="size-14 text-white fw-normal text-capitalize">
        {{ "MARK_AS_USED" | translate }}
      </b>
    </button>
  </div>
</div>

<ngx-loading
  [show]="submiting"
  [config]="{ fullScreenBackdrop: true }"
></ngx-loading>
