/* eslint-disable */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'card-coupon',
  templateUrl: './card-coupon.component.html',
  styleUrls: ['./card-coupon.component.scss'],
})
export class CardCouponComponent {
  @Input() coupon: any;
  @Input() buttonText: string;
  @Output('buttonClick') buttonClick = new EventEmitter<void>();
}
