import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss'],
})
export class TermsandconditionsComponent {

  constructor(
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {


  }

  backnav() {
    this.singletonService.back();
  }
}
