import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
/* eslint-disable */
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { UserService } from 'src/app/@services/user.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SingletonService } from 'src/app/@services/singleton.service';

const log = new Logger('NamecardDetailComponent');

@Component({
  selector: 'app-namecard-detail',
  templateUrl: './namecard-detail.component.html',
  styleUrls: ['./namecard-detail.component.scss'],
})
export class NamecardDetailComponent {

  userId:any
  isLoading:any = false
  user:any

  isAddedContact:any = false

  constructor(
    private _tokenStorageService: TokenStorageService,
    private userService: UserService,
    public translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private backendService: BackendService,
    private _route: ActivatedRoute,
    private _router: Router,
    private modalService: NgbModal,
    public singletonService: SingletonService,
  ) {
    this.modalService.dismissAll()
    this.userId = this._route.snapshot.paramMap.get('userId')!;
  }

  async ngOnInit() {
    log.debug("this.userId : %o",this.userId)

    await this.getUserById()
    await this.checkContact()
  }

  async getUserById(){
    this.isLoading = true

    try{
      const getUserByIdRes:any = await this.backendService.getUserById(this.userId)
      log.debug("res getUserByIdRes: %o",getUserByIdRes)
      if(getUserByIdRes){
        this.user = getUserByIdRes
        log.debug("this.user: %o",this.user)

        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }
  }


  async checkContact(){
    this.isLoading = true

    try{
      const checkContactRes:any = await this.backendService.checkContact(this.userId)
      log.debug("res checkContactRes: %o",checkContactRes)
      if(checkContactRes){
        this.isAddedContact = checkContactRes.result
        log.debug("this.isAddedContact: %o",this.isAddedContact)

        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }
  }

  async removeContact(){
    if(confirm("Are you sure to remove?")) {
      this.isLoading = true

      try{
        const removeContactRes:any = await this.backendService.removeContact(this.userId)
        log.debug("res removeContactRes: %o",removeContactRes)
        if(removeContactRes){
          this.isAddedContact = (removeContactRes.contacts.length && removeContactRes.contacts.find((it:any)=> this.userId == it.user)) ? true : false
          log.debug("this.isAddedContact: %o",this.isAddedContact)

          this.isLoading = false
        }else{
          this.isLoading = false
        }
      }catch(error){
        log.debug("error: %o",error)
        this.isLoading = false
      }
    }
  }

  async addContact(){
    this.isLoading = true
    try{
      const addContactRes:any = await this.backendService.addContact(this.userId,environment.eventCode)
      log.debug("res addContactRes: %o",addContactRes)
      if(addContactRes){
        this.isAddedContact = (addContactRes.contacts.length && addContactRes.contacts.find((it:any)=> this.userId == it.user)) ? true : false
        log.debug("this.isAddedContact: %o",this.isAddedContact)

        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }
  }

  backnav() {
    this.singletonService.back();
  }
}
