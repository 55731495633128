<div
  class="modal fade menumodal modal-fullscreen--250"
  id="menumodal"
  tabindex="-1"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div
    class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable modal-fullscreen"
  >
    <div class="modal-content shadow modal-moremenu-content">
      <div class="modal-header pb-3">
        <button
          class="btn btn-arrow-back"
          type="button"
          data-bs-dismiss="modal"
        >
          <i class="bi bi-chevron-left text-white size-20"></i>
        </button>
        <h1 class="mb-0">
          <div class="size-20 fw-bold text-white">Quick actions</div>
        </h1>
        <button
          class="btn btn-top-right-arrow"
          type="button"
          [routerLink]="['/qr-code-scanner']"
          data-bs-dismiss="modal"
        >
          <img src="assets/img/icon/scanner-white-20x20.png" alt="scanner" />
        </button>
      </div>
      <div class="modal-body">
        <div
          class="text-center"
          *ngIf="!loading && userQrCode?.qrCode && currentUser"
        >
          <p class="small text-secondary mb-4">
            {{ "SCAN_THIS_QRCODE" | translate }}
          </p>
          <div class="wrapper-qr-code mb-3">
            <div>
              <img [src]="userQrCode.qrCode" alt="" class="qr-code-img" />
            </div>
          </div>
          <div
            class="wrapper-btn-actions mb-5 gap-4 d-flex justify-content-center"
          >
            <!-- <a (click)="singletonService.copyMessage(userQrCode.id)" class="btn-action">
              <img src="assets/img/icon/icon-actions/copy.png" alt="copy" />
            </a> -->
            <!-- <a class="btn-action">
              <img src="assets/img/icon/icon-actions/share.png" alt="share" />
            </a> -->
            <a
              (click)="singletonService.downloadMediaFromUrl(userQrCode.qrCode)"
              class="btn-action"
            >
              <img
                src="assets/img/icon/icon-actions/download.png"
                alt="download"
              />
            </a>
          </div>
        </div>

        <div
          class="text-center mt-2 mb-4"
          *ngIf="!loading && (!userQrCode?.qrCode || !currentUser)"
        >
          <div class="wrapper-info-blur">
            <div class="wrapper-info-blur-inner">
              <div class="pt-2">
                <div class="text-white size-16">Please sign in or sign up</div>
                <div class="text-white size-20">to access your account!</div>
              </div>
              <div class="wrapper-actions-button">
                <a
                  (click)="jumpToRoute('/signup')"
                  data-bs-dismiss="modal"
                  class="btn btn-outline-main size-16"
                  >{{ "SIGNIN.SIGNUP" | translate }}</a
                >
                <a
                  (click)="jumpToRoute('/signin')"
                  data-bs-dismiss="modal"
                  class="btn btn-default size-16"
                  >{{ "SIGNIN.SIGNIN" | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row-events-ticket row justify-content-around">
          <div class="col-auto text-center mb-4">
            <a
              (click)="openModalMyNamecard()"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-danger">
                <img
                  src="assets/img/icon/qr-code-ticket/my-name-card.png"
                  alt="my namecard"
                />
              </div>
            </a>
            <p class="size-12 text-white">{{ "MY_NAMECARD" | translate }}</p>
          </div>

          <!-- <div class="col-auto text-center mb-4">
            <a
              routerLink="/addmoney"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-warning">
                <img
                  src="assets/img/icon/qr-code-ticket/mypuzzl.png"
                  alt="my puzzle"
                />
              </div>
            </a>
            <p class="size-12 text-white">{{ "MY_PUZZLE" | translate }}</p>
          </div> -->
          <div class="col-auto text-center mb-4">
            <a
              routerLink="/myexhibitornote"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-success">
                <img
                  src="assets/img/icon/qr-code-ticket/my-notemark-exhibitor.png"
                  alt="my Bookmarks"
                />
              </div>
            </a>
            <p class="size-12 text-white">{{ "MY_BOOKMARKS" | translate }}</p>
          </div>

          <div class="col-auto text-center mb-4">
            <a
              routerLink="/mycouponreward"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-info">
                <!-- <i class="bi bi-tv size-24"></i> -->
                <div class="wrapper-inner-icon">
                  <img
                    src="assets/img/icon/qr-code-ticket/my-reward.png"
                    alt="my reward"
                  />
                  <span *ngIf="!!unusedBadge" class="badge-count-noti">{{
                    unusedBadge
                  }}</span>
                </div>
              </div>
            </a>
            <p class="size-12 text-white">{{ "MY_REWARD" | translate }}</p>
          </div>
        </div>
        <div class="row-events-ticket row justify-content-around">
          <!-- <div class="col-auto text-center mb-4">
            <a
              routerLink="/reviewus"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-secondary">
                <img
                  src="assets/img/icon/qr-code-ticket/compliment.png"
                  alt="comment feedback"
                />
              </div>
            </a>
            <p class="size-12 text-white">Compliment</p>
          </div> -->

          <!-- <div class="col-auto text-center mb-4">
            <a
              routerLink="/sendmoney"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-purple">
                <img
                  src="assets/img/icon/qr-code-ticket/mybooking-highlight-room.png"
                  alt="my booking highlight room"
                />
              </div>
            </a>
            <p class="size-12 text-white">My Booking<br />Highlight Room</p>
          </div>

          <div class="col-auto text-center mb-4">
            <a
              routerLink="/myexhibitornote"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-success">
                <img
                  src="assets/img/icon/qr-code-ticket/my-notemark-exhibitor.png"
                  alt="my notemask exhibitor"
                />
              </div>
            </a>
            <p class="size-12 text-white">My Notemark<br />Exhibitor</p>
          </div> -->
        </div>
        <!-- <div class="row-events-ticket row justify-content-around">
          <div class="col-auto text-center mb-4">
            <a
              routerLink="/qr-code-scanner"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-primary">
                <i class="bi bi-camera size-24"></i>
              </div>
            </a>
            <p class="size-12 text-white">QR Code Scanner</p>
          </div> -->

        <!-- <div class="col-auto text-center mb-4">
            <a
              routerLink="/addmoney"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-warning">
                <i class="bi bi-wallet2 size-24"></i>
              </div>
            </a>
            <p class="size-10 text-secondary">My Puzzle</p>
          </div>

          <div class="col-auto text-center mb-4">
            <a
              routerLink="/couponreward"
              class="avatar avatar-70 p-1 rounded-15 box-event-action mb-2"
              data-bs-dismiss="modal"
            >
              <div class="icons text-info">
                <i class="bi bi-tv size-24"></i>
              </div>
            </a>
            <p class="size-10 text-secondary">My Reward</p>
          </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
