<!-- <div class="modal-header">
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div> -->
<div class="modal-header py-3 align-items-center justify-content-between">
  <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h3 class="text-white size-20">
    {{"GALLERY" | translate}}
  </h3>
  <button class="btn btn-top-right-arrow" type="button"></button>
</div>
<div class="modal-body">
  <ng-image-slider
    [images]="sliderImages"
    [imagePopup]="true"
    [infinite]="true"
    [autoSlide]="true"
    [animationSpeed]="0.1"
    [imageSize]="{space: 4}"
    #nav>
</ng-image-slider>
</div>
