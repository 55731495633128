<div class="modal-header d-flex border-0 p-3 pb-0 align-items-start">
  <h3>
    <img src="assets/img/logo/logo-0110.png" class="img-pwa-install me-2" />
    <span class="size-16 text-white">{{
      "INSTALL_PWA_TITLE" | translate
    }}</span>
  </h3>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="dismissModal()"
  ></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="deferredPrompt">
    <div class="d-flex justify-content-between gap-2">
      <p class="text-muted m-0 size-12">
        {{ "INSTALL_PWA_DESCRIPTION" | translate }}
      </p>
      <button
        type="button"
        class="btn btn-default text-uppercase btn-sm btn-pwa-install"
        (click)="onClickInstall()"
      >
        {{ "INSTALL" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!deferredPrompt">
    <p class="text-muted m-0 size-12">
      {{ "PWA_NOT_SUPPORT" | translate }}
    </p>
  </ng-container>

  <div class="d-flex flex-row mt-3 align-items-center">
    <div (click)="onClickDontShow()">
      <i *ngIf="!notShow" class="bi bi-square size-14 text-checkbox"></i>
      <i *ngIf="notShow" class="bi bi-check-square size-14 text-checkbox"></i>
    </div>
    <div class="size-12 text-muted ms-2">
      {{ "DONT_SHOW_AGAIN" | translate }}
    </div>
  </div>
</div>
