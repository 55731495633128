/* eslint-disable */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { FormService } from 'src/app/@services/form.service';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('ModalNotemaskexhibitorComponent');

@Component({
  selector: 'modal-notemaskexhibitor',
  templateUrl: './modal-notemaskexhibitor.component.html',
  styleUrls: ['./modal-notemaskexhibitor.component.scss'],
})
export class ModalNotemaskexhibitorComponent {
  @Input("exhibitorId") exhibitorId:any;
  @Output() dataService = new EventEmitter();

  comment:any = '';

  isLoading = false;

  constructor(
    public modal: NgbActiveModal,
    public formService: FormService,
    private modalService: NgbModal,
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {

  }

  async ngOnInit() {

  }

  async saveNotemask(){
    this.isLoading = true

    try{
      const bookmarkExhibitorRes:any = await this.backendService.bookmarkExhibitor(this.exhibitorId,this.comment)
      log.debug("res bookmarkExhibitorRes: %o",bookmarkExhibitorRes)
      if(bookmarkExhibitorRes){
        // this.exhibitor = bookmarkExhibitorRes
        // log.debug("this.exhibitor: %o",this.exhibitor)

        this.isLoading = false
        this.dataService.emit(true)
        this.modalService.dismissAll()
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }

  }

}
