import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { SingletonService } from 'src/app/@services/singleton.service';
import { UserService } from 'src/app/@services/user.service';
import { environment } from 'src/environments/environment';
import { UserQrCode } from 'src/models/user.model';
import {Location} from '@angular/common';

@Component({
  selector: 'app-qr-ticket',
  templateUrl: './qr-ticket.component.html',
  styleUrls: ['./qr-ticket.component.scss'],
})
export class QrTicketComponent {
  loading = true;
  error = '';
  userQrCode: UserQrCode;

  constructor(
    private userService: UserService,
    private errorService: ErrorHandlerService,
    private _router: Router,
    public singletonService: SingletonService,
    private _location: Location,
  ) {}

  ngOnInit() {
    this.getQrCode();
  }

  async getQrCode() {
    try {
      this.loading = true;
      this.userQrCode = (await this.userService.requestQrCode({
        eventCode: environment.eventCode,
      })) as UserQrCode;
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  }

  backnav() {
    this.singletonService.back();
  }
}
