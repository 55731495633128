/* eslint-disable */

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';
import Swal from 'sweetalert2';

const log = new Logger('ExhibitordetailComponent');
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-myexhibitornote',
  templateUrl: './myexhibitornote.component.html',
  styleUrls: ['./myexhibitornote.component.scss'],
})
export class MyexhibitornoteComponent {

  isLoadingExhibitorList = false;
  bookmarkedExhibitorList:any = [];

  limit=6;
  page=1;
  totalPages = 1;
  totalResults:any = undefined;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  async ngOnInit() {
    await this.reloadData()
  }

  async reloadData(){
    await this.resetPaginate()
    await this.getBookmarkedExhibitorList()
  }

  resetPaginate(){
    this.page = 1
    this.totalPages = 1;
    this.bookmarkedExhibitorList = []
    this.totalResults = undefined
  }

  async getBookmarkedExhibitorList(){
    this.isLoadingExhibitorList = true
    const getBookmarkedExhibitorListRes:any = await this.backendService.getBookmarkedExhibitorList()
    log.debug("res getBookmarkedExhibitorListRes: %o",getBookmarkedExhibitorListRes)
    if(getBookmarkedExhibitorListRes){
      let results = getBookmarkedExhibitorListRes.exhibitors || []
      log.debug("results: %o",results)

      // if(results.length) results = results.map((it:any) => it.eventExhibitor )

      // log.debug("x results: %o",results)

      this.bookmarkedExhibitorList = [...this.bookmarkedExhibitorList,...results]

      log.debug("x this.bookmarkedExhibitorList: %o",this.bookmarkedExhibitorList)

      this.isLoadingExhibitorList = false

    }else{
      this.isLoadingExhibitorList = false
    }
  }

  jumpToExhibitorDetail(exhibitor:any){
    let path = '/exhibitordetail/'+ exhibitor.id
    let href = this._router.url;
    if(href.startsWith('/exhibitordetail/')){ // same route
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]);
      });
    }else{
      this._router.navigate([path]);
    }
  }

  confirmUnbookmark(exhibitorId:any){
    Swal.fire({
      title: "Do you want to remove this bookmark?",
      text: 'This will also remove your personal note about this exhibitor and can not be recovered.',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Remove',
      denyButtonText: `Cancel`,
      customClass: "popup-confirm-custom popup-content-custom"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.unbookmark(exhibitorId);
      }

    })
  }

  async unbookmark(exhibitorId:any){
    // this.isLoading = true

    try{
      const bookmarkExhibitorRes:any = await this.backendService.unbookmarkExhibitor(exhibitorId)
      log.debug("res bookmarkExhibitorRes: %o",bookmarkExhibitorRes)
      if(bookmarkExhibitorRes){
        // this.exhibitor = bookmarkExhibitorRes
        // log.debug("this.exhibitor: %o",this.exhibitor)

        // this.isLoading = false
        // this.modalService.dismissAll()

        await this.reloadData()

      }else{
        // this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      // this.isLoading = false
    }

  }

  onScroll() {
    log.debug("onScroll...")

    if(this.page < this.totalPages){
      this.page++;
      this.getBookmarkedExhibitorList()
    }
  }

  backnav() {
    this.singletonService.back();
  }
}
