import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalNewrewardComponent } from 'src/app/@components/modal-newreward/modal-newreward.component';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import { environment } from 'src/environments/environment';
import {
  MyRedeemedCouponResult,
  MyRedeemedCouponType,
} from 'src/models/event.model';
import { PointResult, PointType } from 'src/models/point.model';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-mycouponreward',
  templateUrl: './mycouponreward.component.html',
  styleUrls: ['./mycouponreward.component.scss'],
})
export class MycouponrewardComponent implements OnInit {
  balanceLoading = true;
  point: PointType;
  defaultToken: PointResult;

  categoryLoading = false;
  categories = ['Valid', 'Used', 'Expired'];
  activeCategory: string = 'Valid';

  loading = true;
  coupons: MyRedeemedCouponResult[] = [];
  page = 1;
  isUsed = false;
  isExpired = false;

  error = '';
  loadingMore = false;
  nftRemainAmount = 0;

  constructor(
    private _modalService: NgbModal,
    private _backendService: BackendService,
    private _errorService: ErrorHandlerService,
    private _translateConfigService: TranslateConfigService,
    public singletonService: SingletonService
  ) {}

  ngOnInit() {
    this.mount();
  }

  async mount() {
    this.balanceLoading = true;
    await this.getAvailablePoint();
    await this.getAvailableNfts();
    this.balanceLoading = false;
    await this.getAvailableCoupons();
  }

  async getAvailablePoint() {
    try {
      const query = { eventCode: environment.eventCode, isOnlyAvailable: true };
      this.point = (await this._backendService.getAllPoint(query)) as PointType;
      const defaultToken = this.point.results.find((result) => {
        return result.token.isDefault;
      });
      this.defaultToken = defaultToken || this.point.results?.[0] || null;
    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
    }
  }

  async getAvailableCoupons() {
    this.loading = true;
    const couponPayload = await this.getMyCoupons();
    this.filterCoupon(couponPayload?.results || []);
    this.loading = false;
  }

  async getMyCoupons() {
    try {
      const queryParams = {
        eventCode: environment.eventCode,
        limit: 30,
        page: this.page,
        lang: this._translateConfigService.getCurrentLanguage(),
        isUsed: this.isUsed.toString(),
        isExpired: this.isExpired.toString(),
      };
      const couponPayload = (await this._backendService.getMyRedeemedCoupons(
        queryParams
      )) as MyRedeemedCouponType;
      return couponPayload;
    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
      return {} as MyRedeemedCouponType;
    }
  }

  async getAvailableNfts() {
    try {
      const nft = (await this._backendService.getAllNfts({
        eventCode: environment.eventCode,
      })) as any;
      this.nftRemainAmount = nft?.infos?.remainAmount || 0;
      return nft;
    } catch (err) {
      throw err;
    }
  }

  async handleActiveCategory(categoryId: string) {
    this.loading = true;
    this.activeCategory = categoryId;
    this.page = 1;

    switch (this.activeCategory) {
      case 'Expired': {
        this.isUsed = false;
        this.isExpired = true;
        break;
      }
      case 'Used': {
        this.isUsed = true;
        this.isExpired = false;
        break;
      }
      default: {
        this.isUsed = false;
        this.isExpired = false;
      }
    }
    const couponPayload = await this.getMyCoupons();
    this.filterCoupon(couponPayload?.results || []);
    this.loading = false;
  }

  filterCoupon(coupons: MyRedeemedCouponResult[]) {
    this.coupons = coupons.filter((coupon) => !!coupon);
  }

  async onScrollEnd() {
    this.page++;
    this.loadingMore = true;
    const couponPayload = await this.getMyCoupons();
    const _coupons = [...this.coupons, ...couponPayload?.results];
    this.filterCoupon(_coupons);
    this.loadingMore = false;
  }

  openRewardModal(coupon: any) {
    const modalRef = this._modalService.open(ModalNewrewardComponent, {
      centered: true,
      size: 'sm',
      scrollable: true,
      windowClass: 'modal-newreward',
    });
    modalRef.componentInstance.focusCoupon = coupon;

    modalRef.result.then(
      (data) => {},
      async (reason) => {
        if (reason === 'mark as used') {
          this.page = 1;
          await this.mount();
        }
      }
    );
  }

  backnav() {
    this.singletonService.back(true);
  }
}
