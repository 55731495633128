<div class="wrapper-top-header">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 mx-auto px-0">
        <!-- header -->
        <!-- </div> -->
        <div class="content-header">
          <div class="ps-2 pe-0">
            <h2>
              <span class="size-28 text-white fw-bold">
                <button
                  class="btn btn-arrow-back"
                  type="button"
                  (click)="home()"
                >
                  <i class="bi bi-chevron-left text-white size-16"></i>
                </button>
                {{ "SIGNIN.SIGNIN" | translate }}
              </span>
            </h2>
          </div>
        </div>
        <!-- header ends -->
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 col-sm-12 mx-auto px-0">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div
          *ngIf="isInAppBrowser"
          class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center"
        >
          <div class="wrapper-is-support-browser size-16">
            {{ "IN_APP_BROWSER_NOTICE" | translate }}
            <!-- <div class="wrapper-go-to-0110-website"> -->
            <!-- <button
                (click)="openInSystemBrowser()"
                class="btn btn-outline-main size-16 fw-bold"
              >
                {{ "OPEN_IN_SYSTEM_BROWSER" | translate }}
              </button> -->
            <!-- </div> -->
          </div>
        </div>

        <div
          class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center"
        >
          <h1 class="mb-4 pb-2">
            <span class="text-title fw-600 size-32">{{
              "WELCOME_BACK" | translate
            }}</span>
            <p class="text-white size-16 fw-normal mt-1 text-secondary">
              {{ "PEASE_FILL_OUT_THE_FORM" | translate }}
            </p>
          </h1>

          <div class="alert alert-danger my-4" role="alert" *ngIf="!!error">
            {{ error }}
          </div>

          <label
            class="form-control-label text-uppercase mb-2 size-14 text-label"
            for="emailOrPhone"
            >{{ "PHONE_OR_EMAIL" | translate }}</label
          >
          <div
            class="form-group form-floating mb-3"
            [class.is-invalid]="!!formInvalid.emailOrPhone"
          >
            <label class="tooltip-btn tooltip-btn-left" for="emailOrPhone">
              <img src="assets/img/auth/username.png" alt="username icon" />
            </label>
            <input
              id="emailOrPhone"
              type="text"
              formControlName="emailOrPhone"
              class="form-control"
              placeholder="{{ 'PHONE_OR_EMAIL' | translate }}"
            />
            <button
              *ngIf="!!formInvalid.emailOrPhone"
              type="button"
              class="text-danger tooltip-btn"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              [title]="formInvalid.emailOrPhone"
              id="passworderror"
            >
              <i class="bi bi-info-circle"></i>
            </button>
          </div>

          <label
            class="form-control-label text-uppercase mb-2 size-14 text-label"
            for="password"
            >{{ "PASSWORD" | translate }}</label
          >
          <div
            class="form-group form-floating mb-3 input-group input-group-merge"
            [class.is-invalid]="!!formInvalid.password"
          >
            <label class="tooltip-btn tooltip-btn-left" for="emailOrPhone">
              <img src="assets/img/auth/key.png" alt="username icon" />
            </label>
            <input
              formControlName="password"
              [type]="passwordTextType ? 'text' : 'password'"
              class="form-control"
              id="password"
              placeholder="{{ 'PASSWORD' | translate }}"
            />
            <div class="input-group-append">
              <span class="input-group-text cursor-pointer icon-input"
                ><i
                  class="bi"
                  [ngClass]="{
                    'bi-eye-slash': !passwordTextType,
                    'bi-eye': passwordTextType
                  }"
                  (click)="togglePasswordTextType()"
                >
                  <!-- {{passwordTextType}} -->
                </i></span
              >
            </div>

            <button
              *ngIf="!!formInvalid.password"
              type="button"
              class="text-danger tooltip-btn"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              [title]="formInvalid.password"
              id="passworderror"
              style="right: 35px"
            >
              <i class="bi bi-info-circle"></i>
            </button>
          </div>
          <!-- {{ formInvalid.password }} -->
        </div>

        <div
          class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 pb-4 mt-4"
        >
          <div class="row">
            <div class="col-12 mb-3">
              <a
                (click)="openForgotPasswordModal()"
                class="d-block text-end text-secondary size-12"
              >
                {{ "SIGNIN.FORGOT_PASSWORD" | translate }}
              </a>
            </div>
            <div class="col-12 text-center">
              <button type="submit" class="btn btn-default btn-lg">
                {{ "SIGNIN.SIGNIN" | translate }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 mt-4"
        >
          <div class="separator">
            <!-- <h5>{{ "SIGNIN.OR" | translate }}</h5> -->
            <h5>
              <span>{{ "OR_CONTINUE_WITH" | translate }}</span>
            </h5>
          </div>
          <div
            class="signin-choice"
            [ngClass]="isInAppBrowser ? 'signin-choice-blur' : ''"
          >
            <div
              *ngIf="isInAppBrowser"
              class="text-signin-choice-blur size-14 text-center d-flex align-items-center justify-content-center"
            >
              {{ "ONLY_SYSTEM_BROWSER" | translate }}
            </div>
            <div class="signin-icon-wrapper">
              <div class="google-button" id="google-button"></div>
              <img
                class="google-signin-icon"
                src="assets/img/auth/social/google.png"
                alt="google"
              />
            </div>

            <div class="signin-icon-wrapper" (click)="signInWithFacebook()">
              <img
                class="facebook-signin-icon"
                src="assets/img/auth/social/facebook.png"
                alt="google"
              />
            </div>

            <!-- <a class="signin-icon-wrapper" routerLink="/paperwallet">
              <img
                class="qr-signin-icon"
                src="assets/img/icon/qr-code-blue.png"
                alt="google"
              />
            </a> -->

            <!-- <div class="signin-icon-wrapper">
              <img
                class="line-signin-icon"
                src="assets/img/auth/social/line.png"
                alt="line"
              />
            </div>

            <div class="signin-icon-wrapper">
              <img
                class="apple-signin-icon"
                src="assets/img/auth/social/apple.png"
                alt="apple"
              />
            </div> -->
          </div>
        </div>

        <div class="text-center text-secondary size-14 pb-4">
          {{ "DONT_HAVE_AN_ACCOUNT" | translate }}
          <a routerLink="/signup" class="text-white text-docoration">{{
            "SIGN_UP" | translate
          }}</a>
        </div>
      </form>
    </div>
  </div>
</div>
