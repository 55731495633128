import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'pincode-numpad',
  templateUrl: './pincode-numpad.component.html',
  styleUrls: ['./pincode-numpad.component.scss'],
})
export class PincodeNumpadComponent {
  position = 1;

  @Input() clearSubject: Subject<void>;
  @Output('onChange') onChange = new EventEmitter<string>();

  @ViewChild('first') first: ElementRef;
  @ViewChild('second') second: ElementRef;
  @ViewChild('third') third: ElementRef;
  @ViewChild('fourth') fourth: ElementRef;
  @ViewChild('fifth') fifth: ElementRef;
  @ViewChild('sixth') sixth: ElementRef;

  ngOnInit() {
    this.clearSubject.subscribe(() => {
      this.clearAll();
    });
  }

  enter(n: number) {
    if (this.position < 7) {
      if (this.position === 1) {
        this.first.nativeElement.value = n;
      }
      if (this.position === 2) {
        this.second.nativeElement.value = n;
      }
      if (this.position === 3) {
        this.third.nativeElement.value = n;
      }
      if (this.position === 4) {
        this.fourth.nativeElement.value = n;
      }
      if (this.position === 5) {
        this.fifth.nativeElement.value = n;
      }
      if (this.position === 6) {
        this.sixth.nativeElement.value = n;
      }
      this.position++;
      this.setValue();
    }
  }

  back() {
    if (this.position > 1) {
      this.position--;
      if (this.position === 1) {
        this.first.nativeElement.value = '';
      }
      if (this.position === 2) {
        this.second.nativeElement.value = '';
      }
      if (this.position === 3) {
        this.third.nativeElement.value = '';
      }
      if (this.position === 4) {
        this.fourth.nativeElement.value = '';
      }
      if (this.position === 5) {
        this.fifth.nativeElement.value = '';
      }
      if (this.position === 6) {
        this.sixth.nativeElement.value = '';
      }
      this.setValue();
    }
  }

  setValue() {
    const currentPincode =
      this.first.nativeElement.value +
      this.second.nativeElement.value +
      this.third.nativeElement.value +
      this.fourth.nativeElement.value +
      this.fifth.nativeElement.value +
      this.sixth.nativeElement.value;
    this.onChange.emit(currentPincode);
  }

  clearAll() {
    this.first.nativeElement.value = '';
    this.second.nativeElement.value = '';
    this.third.nativeElement.value = '';
    this.fourth.nativeElement.value = '';
    this.fifth.nativeElement.value = '';
    this.sixth.nativeElement.value = '';
    this.setValue();
    this.position = 1;
  }
}
