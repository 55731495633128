<div class="d-flex flex-column align-items-center">
  <div>
    <table class="mt-3 mb-4">
      <tr>
        <td>
          <input
            type="password"
            #first
            maxlength="1"
            required
            readonly
            placeholder=""
            size="1"
          />
        </td>
        <td>
          <input
            type="password"
            #second
            maxlength="1"
            required
            readonly
            placeholder=""
            size="1"
          />
        </td>
        <td>
          <input
            type="password"
            #third
            maxlength="1"
            required
            readonly
            placeholder=""
            size="1"
          />
        </td>
        <td>
          <input
            type="password"
            #fourth
            maxlength="1"
            required
            readonly
            placeholder=""
            size="1"
          />
        </td>
        <td>
          <input
            type="password"
            #fifth
            maxlength="1"
            required
            readonly
            placeholder=""
            size="1"
          />
        </td>
        <td>
          <input
            type="password"
            #sixth
            maxlength="1"
            required
            readonly
            placeholder=""
            size="1"
          />
        </td>
      </tr>
    </table>
  </div>
  <div>
    <table>
      <tr>
        <td (click)="enter(1)">
          <div>1</div>
        </td>
        <td (click)="enter(2)"><div>2</div></td>
        <td (click)="enter(3)"><div>3</div></td>
      </tr>
      <tr>
        <td (click)="enter(4)"><div>4</div></td>
        <td (click)="enter(5)"><div>5</div></td>
        <td (click)="enter(6)"><div>6</div></td>
      </tr>
      <tr>
        <td (click)="enter(7)"><div>7</div></td>
        <td (click)="enter(8)"><div>8</div></td>
        <td (click)="enter(9)"><div>9</div></td>
      </tr>
      <tr>
        <td></td>
        <td (click)="enter(0)"><div>0</div></td>
        <td>
          <div (click)="back()">{{"DELETE" | translate}}</div>
        </td>
      </tr>
    </table>
  </div>
</div>
