import { SingletonService } from './../../@services/singleton.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from 'src/app/@services/logger.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/@services/authentication.service';
/* eslint-disable */

const log = new Logger('ModalQrcodeNamecardComponent');

const SPLIT_STRING = environment.customActionCodeSplitter

@Component({
  selector: 'app-modal-qrcode-namecard',
  templateUrl: './modal-qrcode-namecard.component.html',
  styleUrls: ['./modal-qrcode-namecard.component.scss']
})
export class ModalQrcodeNamecardComponent implements OnInit {
  @Input('user') user: any;

  qrCodeDownloadLink:any;

  customCurrentPageUrl:any;
  constructor(
    public singletonService:SingletonService,
    public modal: NgbActiveModal,
    private _authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    // const currentUser = this._authenticationService.currentUserSubject.value;
    // const profile = currentUser.profile;

    this.customCurrentPageUrl = window.location.origin + `/detect-link-page/UR${SPLIT_STRING}${this.user.id}`
    log.debug("this.customCurrentPageUrl => %o",this.customCurrentPageUrl)

  }

  onChangeURL(url: any) {
    log.debug("url => %o",url)
    this.qrCodeDownloadLink = url;

  }

}
