<div class="row h-100">
    <div class="col-11 col-sm-11 mx-auto">
        <!-- header -->
        <div class="row">
            <header class="header">
                <div class="row">
                    <div class="col">
                        <div class="logo-small">
                            <img src="assets/img/logo.png" alt="" />
                            <h5><span class="text-secondary fw-light">{{"FINANCE_WALLET_01" | translate}}</span><br />{{"FINANCE_WALLET_02" | translate}}</h5>
                        </div>
                    </div>
                    <div class="col-auto align-self-center">
                        <a routerLink="/signin">{{"SIGN_IN" | translate}}</a>
                    </div>
                </div>
            </header>
        </div>
        <!-- header ends -->
    </div>
    <div class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
        <h1 class="mb-3"><span class="text-secondary fw-light">{{"VERIFY_YOUR_EMAIL_ID_01" | translate}}</span><br/>{{"VERIFY_YOUR_EMAIL_ID_02" | translate}}</h1>
        <p class="text-secondary mb-4">{{"WE_HAVE_SEND_OTP_TO_EMAIL" | translate}}</p>

        <div class="form-group form-floating mb-3 is-valid">
            <input type="text" class="form-control" value="maxartkiller" id="OTP" placeholder="Enter OTP">
            <label class="form-control-label" for="OTP">{{"ENTER_OPTION" | translate}}</label>
        </div>


    </div>
    <div class="col-11 col-sm-11 mt-auto mx-auto py-4">
        <div class="row ">
            <div class="col-auto text-center mx-auto">
                <span class="progressstimer">
                    <img src="assets/img/progress.png" alt="">
                    <span class="timer" id="timer">3:00</span>
                </span>
                <br />
                <p class="mb-3"><span class="text-muted">{{"DIDNT_RECEIVED_YET" | translate}}</span> <a>{{"RESEND_OTP" | translate}}</a>
                </p>
            </div>
            <div class="col-12 d-grid">
                <a routerLink="/thankyou2" class="btn btn-default btn-lg">{{"VERIFY_ACCOUNT" | translate}}</a>
            </div>
        </div>
    </div>
</div>
