
export class WheelReward {
  id: string;
  nameTh: string;
  nameEn: string;
  imageUrl: string;

  constructor(id: string, nameTh: string, nameEn: string, imageUrl?: string) {
    this.id = id;
    this.nameTh = nameTh;
    this.nameEn = nameEn;
    if (imageUrl) { this.imageUrl = imageUrl; }
  }

  getName(_lang= 'th') {
    switch (_lang) {
      case 'th' :
        return this.nameTh;
        break;
      case 'en' :
        return this.nameEn;
        break;
      default :
        return this.nameTh;
        break;
    }
  }
}
