import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from 'src/app/@services/logger.service';
import { ModalQrcodeNamecardComponent } from '../modal-qrcode-namecard/modal-qrcode-namecard.component';

/* eslint-disable */

const log = new Logger('CardNamecardComponent');

@Component({
  selector: 'app-card-namecard',
  templateUrl: './card-namecard.component.html',
  styleUrls: ['./card-namecard.component.scss']
})
export class CardNamecardComponent implements OnInit {
  @Input("user") user:any = undefined;
  @Input("isEdit") isEdit:any = false;

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    log.debug("user => %o",this.user)
  }

  openQRCodeNameCardModal() {
    const modalRef = this.modalService.open(ModalQrcodeNamecardComponent, {
      centered: true,
    });
    modalRef.componentInstance.user = this.user;
  }

}
