import { SingletonService } from './singleton.service';
/* eslint-disable */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalGosigninComponent } from '../@components/modal-gosignin/modal-gosignin.component';
import { Router } from '@angular/router';
import { Logger } from './logger.service';
import { TranslateConfigService } from './translate-config.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

const log = new Logger('ErrorHandlerService');

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private _modalService: NgbModal,
    private _location: Location,
    private _router: Router,
    private _translateConfigService: TranslateConfigService,
    private singletonService: SingletonService,
    public translateService: TranslateService,
  ) {}

  error = (error: any, isShowWithSwal: any = false) => {
    log.debug('error => %o', error);
    if (typeof error === 'string') {
      log.debug('typeof error string');
      return {
        code: 0,
        message: error,
      };
    } else if (error && ((error.error && error.error.code == 1111) || error.code == 1111)) {
      log.debug('1111');
      const currentLanguage =
        this._translateConfigService.getCurrentLanguage();
      const code = error.code || error.error.code;
      const message = error.message[currentLanguage] || error.error.message[currentLanguage];
      if (isShowWithSwal) {
        this.singletonService.showErrorSwalAlert(message);
      }
      const _error = {
        code: code,
        message: message,
      };
      log.debug('1111 _error : %o',_error);
      return _error
    } else if (error && ((error.error && error.error.code == 1112) || error.code == 1112)) {
      log.debug('1112');
      const currentLanguage =
        this._translateConfigService.getCurrentLanguage();
      const code = error.code || error.error.code;
      const redirectUrl = error.redirectUrl || error.error.redirectUrl;
      const message = error.message[currentLanguage] || error.error.message[currentLanguage];
      if (isShowWithSwal) {
        this.singletonService.showErrorSwalAlert(message);
      }
      const _error = {
        code: code,
        message: message,
        redirectUrl: redirectUrl,
      };
      log.debug('1112 _error : %o',_error);
      return _error
    } else if (error instanceof Error) {
      log.debug('Error');
      return {
        code: 0,
        message: error.message,
      };
    } else if (error instanceof HttpErrorResponse) {

      // --------- example HttpErrorResponse
      // {
      //   error: None,
      //   headers: [B4],
      //   message: [Filtered],
      //   name: HttpErrorResponse,
      //   ok: False,
      //   status: 504,
      //   statusText: Gateway Timeout,
      //   url: [Filtered]
      // }

      let code = error.status
      let message = error.statusText

      if(error && error instanceof Object){
        code = error.error ? error.error.code : 'unknown-code';
        message = error.error ? error.error.message : 'unknown-error';
      }
      if (isShowWithSwal) {
        this.singletonService.showErrorSwalAlert(message);
      }
      return { code, message };
    }

    return {
      code: 0,
      message: 'unknown error',
    };
  };

  goSignIn(isBackToReturnUrlAfterAuth:any = false) {
    const returnurl = this._router.url
    log.debug('returnurl : %o',returnurl);

    this._modalService.dismissAll();

    if(isBackToReturnUrlAfterAuth){
      this._router.navigate(['/auth'], {
        queryParams: { returnurl: returnurl },
      });
    }else{
      this._router.navigate(['/auth']);
    }
  }
}
