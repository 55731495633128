/* eslint-disable */

import { ForgotPasswordConfirmComponent } from './../forgot-password-confirm/forgot-password-confirm.component';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'src/app/@services/logger.service';
import { UserService } from 'src/app/@services/user.service';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { FormService } from 'src/app/@services/form.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
const log = new Logger('ForgotPasswordComponent');

// import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  // loading:any = false;
  forgotPasswordForm:any;
  success:Boolean = false;
  sending:Boolean = false;

  // public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  // public loadingTemplate!: TemplateRef<any>;
  error:Boolean = false
  errorText:any

  constructor(
    private modalService : NgbModal,
    private fb :FormBuilder,
    public modal: NgbActiveModal,
    public userService: UserService,
    private _authentication: AuthenticationService,
    public formService: FormService,
    private authService: SocialAuthService,
    private _storageService: TokenStorageService,
    private _userService: UserService,
    private translateService: TranslateService,
    private _errorHandlerService: ErrorHandlerService,
    ) {
  }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      username: ['',[Validators.required]],
    },
    {
      // validator: ConfirmPasswordValidator("password", "confirmPassword")
    });
  }

  dismiss(){
    this.modalService.dismissAll()
  }

  async onSubmit() {
    this.sending = true
    this.error = false

    // this.loading = true;
    const formValue = this.forgotPasswordForm.value
    log.debug('onSubmit formValue',formValue);

    let data = {
      username: formValue.username,
    }

    await this.userService.forgotPasswordWithUsername(data).then((res:any) => {
      // this.loading = false;
      log.debug('forgotPassword res',res);
      if(res && res.registerType != 'not-allowed'){
        setTimeout(() => {
          this.sending = false
          this.success = true;
          this.modalService.dismissAll()
          const modalRef = this.modalService.open(ForgotPasswordConfirmComponent, {centered:true,backdrop:'static'})
          modalRef.componentInstance.username = formValue.username
          modalRef.componentInstance.registerType = res.registerType
          modalRef.componentInstance.otp = res.otp
        }, 5000);
      }else if(res && res.registerType == 'not-allowed'){
        this.sending = false
        this.error = true;
        this.errorText = this.translateService.instant('ERROR_MSG.ERROR_FORGOT_PASSWORD_REGISTER_TYPE_NOT_ALLOW')
      }
    },(err) => {
      this.sending = false
      // this.loading = false;
      log.debug('err =>',err)
      this.error = true;

      const _error = this._errorHandlerService.error(err).message;
      log.debug("_error => %o",_error)
      this.errorText = _error

    })


  }

}
