import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../@helpers/auth.guard';
import { DownloadComponent } from './download/download.component';
import { HomeComponent } from './home/home.component';
import { PointComponent } from './point/point.component';
import { PointhistoryComponent } from './pointhistory/pointhistory.component';
import { ProfileComponent } from './profile/profile.component';
import { WalletlayoutComponent } from './walletlayout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WalletComponent } from './wallet/wallet.component';

const routes: Routes = [
  {
    path: '',
    component: WalletlayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: { animateState: 1 },
      },
      {
        path: 'home',
        component: HomeComponent,
        data: { animateState: 1 },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { animateState: 1 },
      },
      {
        path: 'wallet',
        canActivate: [AuthGuard],
        component: WalletComponent,
        data: { animateState: 1.5 },
      },
      {
        path: 'point',
        component: PointComponent,
        data: { animateState: 2 },
      },
      {
        path: 'point/history/:tokenId',
        component: PointhistoryComponent,
        canActivate: [AuthGuard],
        data: { animateState: 2.5 },
      },
      {
        path: 'download',
        canActivate: [AuthGuard],
        component: DownloadComponent,
        data: { animateState: 3 },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: { animateState: 4 },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class WalletRoutingModule {}
