import { Component } from '@angular/core';
import { Router } from '@angular/router';

import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';
@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.scss'],
})
export class PrivacypolicyComponent {
  constructor(
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  backnav() {
    this.singletonService.back();
  }
}
