import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Logger } from 'src/app/@services/logger.service';
import { SingletonService } from 'src/app/@services/singleton.service';
import Swal from 'sweetalert2';
import packageJson from '../../../../package.json';

const log = new Logger('ProfileComponent');

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  settingCollapse: any;
  supportCollapse: any;
  otherCollapse: any;

  isSettingCollapsed = false;
  isSupportCollapsed = false;
  isOtherCollapsed = false;

  isRoleStaff = false

  currentUser: any;
  currentUserSubscription: any;

  notiBadge = 0;
  copied = false;

  dashboardUrl = environment.dashboardUrl;
  public appVersion: string = packageJson.version;
  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private backendService: BackendService,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
    public singletonService: SingletonService
  ) {}

  ngOnInit(): void {
    this.currentUserSubscription =
      this._authenticationService.currentUserSubject.subscribe(
        (_currentUser) => {
          this.currentUser = _currentUser;
          log.debug('this.currentUser => %o', this.currentUser);
        }
      );

    if (this.currentUser) {
      this.getNotificationBadge();
      this.checkIsRoleStaff();
    }
  }



  ngOnDestroy(): void {
    if (this.currentUserSubscription)
      this.currentUserSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    const settingCollapseElement = document.getElementById('settingCollapse');
    if (settingCollapseElement) {
      this.settingCollapse = new bootstrap.Collapse(settingCollapseElement, {
        toggle: false,
      });
    }

    const supportCollapseElement = document.getElementById('supportCollapse');
    if (supportCollapseElement) {
      this.supportCollapse = new bootstrap.Collapse(supportCollapseElement, {
        toggle: false,
      });
    }

    const otherCollapseElement = document.getElementById('otherCollapse');
    if (supportCollapseElement) {
      this.otherCollapse = new bootstrap.Collapse(otherCollapseElement, {
        toggle: false,
      });
    }
  }

  async checkIsRoleStaff(){
    try {
      const res =
        (await this.backendService.checkIsRoleStaff(environment.eventCode)) as any;
      if(res && res.role == 'staff'){
        log.debug('res checkIsRoleStaff => %o', res);
        // isRoleStaff
        this.isRoleStaff = true
      }
    } catch (err) {
      const error = this.errorHandlerService.error(err, true);
    }
  }

  async getNotificationBadge() {
    try {
      const notification =
        (await this.backendService.getNotificationBadge()) as any;
      this.notiBadge = notification?.count || 0;
    } catch (err) {
      const error = this.errorHandlerService.error(err, true);
    }
  }

  copyInfomation() {
    if (!this.copied) {
      const { profile, email, id } = this.currentUser;
      const val = `${profile.firstName} ${profile.lastName}, ${email}, ${id}`;
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 3000);
    }
  }

  toggleSettingCollapse() {
    this.isSettingCollapsed = !this.isSettingCollapsed;
    this.settingCollapse.toggle();
  }

  toggleSupportCollapse() {
    this.isSupportCollapsed = !this.isSupportCollapsed;
    this.supportCollapse.toggle();
  }

  toggleOtherCollapse() {
    this.isOtherCollapsed = !this.isOtherCollapsed;
    this.otherCollapse.toggle();
  }

  async getDashboardToken(){
    try {
      const res = (await this.backendService.getDashboardToken()) as any; // TODO: CALL API
      log.debug('getDashboardToken res => %o', res);
      if(res){
        const dashboardToken = res.token
        this.singletonService.openExternalLink(this.dashboardUrl + '/login-with-token/' + dashboardToken)
      }
    } catch (err) {
      const error = this.errorHandlerService.error(err, true);
    }
  }

  signOut() {
    Swal.fire({
      title: this.translateService.instant('SIGNING_OUT'),
      text: this.translateService.instant('SIGNING_OUT_TEXT'),
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('SIGN_OUT'),
      cancelButtonText: this.translateService.instant('CANCEL'),
      customClass: 'popup-confirm-custom popup-content-custom',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this._authenticationService.logout();
        this._router.navigate(['/']);
      }
    });
  }
}
