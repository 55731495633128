<div class="modal-header">
  <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h3 class="sign-in-header text-white size-18">
    {{"SIGNIN.FORGOT_PASSWORD_CONFIRM" | translate}}
  </h3>
  <button class="btn btn-top-right-arrow" type="button"></button>
  <!-- <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
</div>
<div class="modal-body px-4">
  <div *ngIf="error" class="alert alert-danger d-flex align-items-center w-100 mt-3 mb-4" role="alert">
    <i class="bi bi-exclamation-triangle size-26"></i>
    <div class="ms-3 size-14 text-start">
      {{errorText}}
    </div>
  </div>
  <div [formGroup]="forgotPasswordForm" class="row custom-row">
    <div class="mt-3 mb-4 text-secondary size-14 text-center">{{"SIGNIN.FORGOT_PASSWORD_CONFIRM_HINT" | translate}}</div>

    <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="code">{{"VERIFICATION_CODE" | translate}}</label>
    <div class="form-group form-floating mb-3 px-0">
      <input (keyup)="error = false" class="form-control" type="text" formControlName="code" placeholder="{{'VERIFICATION_CODE' | translate}}" name="code" id="code" tabindex="1">
    </div>

    <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="password">{{'PASSWORD' | translate}}</label>
    <div class="form-group form-floating mb-3 px-0 input-group input-group-merge">
        <input (keyup)="error = false" class="form-control button-inside" [type]="isShow ? 'text' : 'password'" formControlName="password" placeholder="{{'NEW_PASSWORD' | translate}}" tabindex="2" name="password" value="" id="password"
        [class.is-invalid]="formService.isControlInvalid(forgotPasswordForm,'password')">
        <div class="input-group-append">
          <span class="input-group-text cursor-pointer icon-input"
            ><i
              class="font-small-4 bi"
              [ngClass]="{
                'bi-eye-slash': !isShow,
                'bi-eye': isShow
              }"
              (click)="showPassword()"
            ></i
          ></span>
        </div>
        <div class="invalid-feedback px-3" *ngIf="formService.controlHasError(forgotPasswordForm,'minlength', 'password')">{{"SIGNIN.PASSWORD_VALIDATE" | translate}}</div>
        <div class="invalid-feedback px-3 pb-3" *ngIf="formService.controlHasError(forgotPasswordForm,'passwordStrength', 'password')">{{"SIGNIN.PASSWORD_VALIDATE_STRICT" | translate}}</div>
    </div>

    <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="confirmPassword">{{'CONFIRM_PASSWORD' | translate}}</label>
    <div class="form-group form-floating px-0 mb-3 input-group input-group-merge">
        <input (keyup)="error = false" class="form-control button-inside" [type]="isShowRecomfirm ? 'text' : 'password'" formControlName="confirmPassword" placeholder="{{'CONFIRM_NEW_PASSWORD' | translate}}" tabindex="2" name="confirmPassword" value="" id="confirmPassword" [class.is-invalid]="formService.isControlInvalid(forgotPasswordForm,'confirmPassword')">
        <div class="input-group-append">
            <span class="input-group-text cursor-pointer icon-input"
              ><i
                class="font-small-4 bi"
                [ngClass]="{
                  'bi-eye-slash': !isShowRecomfirm,
                  'bi-eye': isShowRecomfirm
                }"
                (click)="showConfirm()"
              ></i
            ></span>
        </div>
        <div class="invalid-feedback px-3 pb-3" *ngIf="formService.controlHasError(forgotPasswordForm,'confirmPasswordValidator', 'confirmPassword')">{{"SIGNIN.PASSWORD_MATCH" | translate}}</div>
    </div>

  <div class="signin-footer mt-3">
    <button type="button" (click)="onSubmit()" class="btn btn-default btn-lg">{{"SIGNIN.NEXT" | translate}}</button>
  </div>
  <div class="option mb-4">
    <div class="footer-power text-secondary size-14">
      {{"POWERED_BY" | translate}} Linxlab.io
    </div>
  </div>
</div>
