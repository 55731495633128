<div class="wrapper-100vw">
  <div class="wrapper-top-header mt-0 pb-3 mb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="back()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-0">
          <h6 class="title size-16">{{ "NFT_GALLERY" | translate }}</h6>
        </div>
        <div class="col-auto align-self-center"></div>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading" class="text-center">
    <div class="text-center my-5 py-3 empty-wrapper">
      <div class="spinner-border"></div>
      <p class="text-center fw-normal size-14 mt-3">
        {{ "LOADING" | translate }}
      </p>
    </div>
  </div>

  <ul class="nav nav-tabs mt-4" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="history-tab"
        data-bs-toggle="tab"
        data-bs-target="#history-tab-pane"
        type="button"
        role="tab"
        aria-controls="history-tab-pane"
        aria-selected="true"
        (click)="setActiveTab('MAIN')"
      >
        MAIN
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="tarot-tab"
        data-bs-toggle="tab"
        data-bs-target="#tarot-tab-pane"
        type="button"
        role="tab"
        aria-controls="tarot-tab-pane"
        (click)="setActiveTab('TAROT')"
      >
        NFT
      </button>
    </li>
  </ul>
  <div
    class="tab-pane fade show active"
    id="history-tab-pane"
    role="tabpanel"
    aria-labelledby="history-tab"
    tabindex="0"
  >
    <div class="text-center">
      <div *ngIf="!isLoading && !galleryImages.length && activeTab === 'MAIN'">
        <div class="my-5 py-3">
          <div class="card bg-transparent">
            <div class="card-body p-0">
              <div class="empty-wrapper text-center">
                <img src="assets/img/empty/empty-img.png" alt="empty image" />
                <p class="text-center mt-4 fw-normal size-14">
                  {{ "NO_RESULT" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!isLoading && totalResults > 0 && activeTab === 'MAIN'"
      class="container mt-3"
    >
      <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollDisabled]="activeTab !== 'MAIN'"
        (scrolled)="onScroll()"
      >
        <div class="row mb-3">
          <div class="col align-self-center">
            <h6 class="title">{{ "FOUND_RESULT" | translate }}</h6>
            <p class="text-secondary small">
              {{ totalResults }} {{ "PHOTO" | translate }}
            </p>
          </div>
          <div class="col-auto">
            <nav>
              <div class="nav nav-tabs border-0" id="nav-tab" role="tablist">
                <button
                  class="btn btn-44 btn-outline-light active ms-2 text-normalcase"
                  id="nav-lists-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-lists"
                  type="button"
                  role="tab"
                  aria-controls="nav-lists"
                  aria-selected="false"
                >
                  <i class="bi bi-list"></i>
                </button>
                <button
                  class="btn btn-44 btn-outline-light ms-2 text-normalcase"
                  id="nav-thumbnails-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-thumbnails"
                  type="button"
                  role="tab"
                  aria-controls="nav-thumbnails"
                  aria-selected="true"
                >
                  <i class="bi bi-grid"></i>
                </button>
              </div>
            </nav>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade"
                id="nav-thumbnails"
                role="tabpanel"
                aria-labelledby="nav-thumbnails-tab"
              >
                <!-- product medsize box  -->
                <div class="row mb-2">
                  <div
                    (click)="jumpToDetail(galleryImage)"
                    class="col-6 col-md-4 col-lg-3 mb-3"
                    *ngFor="let galleryImage of galleryImages"
                  >
                    <figure class="avatar w-100 rounded-15 position-relative">
                      <img
                        [src]="galleryImage.imageUrl"
                        alt=""
                        class="w-100 coverimg"
                        (load)="onImageLoaded()"
                        *ngIf="galleryImage.fileType === GalleryFileType.IMAGE"
                      />
                      <video
                        [src]="galleryImage.videoUrl + '#t=0.1'"
                        alt=""
                        class="w-100 coverimg"
                        (load)="onImageLoaded()"
                        *ngIf="galleryImage.fileType === GalleryFileType.VIDEO"
                      >
                        <source
                          [src]="galleryImage.videoUrl + '#t=0.1'"
                          type="video/mp4"
                        />
                      </video>
                      <div class="type-container">
                        <i
                          [ngClass]="
                            galleryImage.fileType === GalleryFileType.IMAGE
                              ? 'bi bi-image'
                              : 'bi bi-film'
                          "
                        ></i>
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade show active"
                id="nav-lists"
                role="tabpanel"
                aria-labelledby="nav-lists-tab"
              >
                <!-- product medsize list  -->
                <div class="row mb-2">
                  <div
                    (click)="jumpToDetail(galleryImage)"
                    class="col-12 col-md-6 mb-3"
                    *ngFor="let galleryImage of galleryImages"
                  >
                    <figure class="avatar w-100 rounded-15 position-relative">
                      <img
                        [src]="galleryImage.imageUrl"
                        alt=""
                        class="w-100 coverimg"
                        (load)="onImageLoaded()"
                        *ngIf="galleryImage.fileType === GalleryFileType.IMAGE"
                      />
                      <video
                        [src]="galleryImage.videoUrl + '#t=0.1'"
                        alt=""
                        class="w-100 coverimg"
                        (load)="onImageLoaded()"
                        *ngIf="galleryImage.fileType === GalleryFileType.VIDEO"
                      >
                        <source
                          [src]="galleryImage.videoUrl + '#t=0.1'"
                          type="video/mp4"
                        />
                      </video>
                      <div class="type-container">
                        <i
                          [ngClass]="
                            galleryImage.fileType === GalleryFileType.IMAGE
                              ? 'bi bi-image'
                              : 'bi bi-film'
                          "
                        ></i>
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="tab-pane fade"
    id="tarot-tab-pane"
    role="tabpanel"
    aria-labelledby="tarot-tab"
    tabindex="0"
  >
    <div class="container mt-3">
      <div class="row mb-2" *ngIf="!isLoading && tarots.length > 0">
        <div
          (click)="jumpToDetail(tarot)"
          class="col-12 col-md-6 mb-3"
          *ngFor="let tarot of tarots"
        >
          <figure class="avatar w-100 rounded-15 position-relative">
            <img
              [src]="tarot.nft.logo"
              alt=""
              class="w-100 coverimg"
              (click)="goToTarot(tarot.id)"
              (load)="onImageLoaded()"
            />
            <div class="type-container">
              <i class="bi bi-image"></i>
            </div>
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>
