import { Router } from '@angular/router';
/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';

import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject,Subscription } from 'rxjs';
import { Logger } from 'src/app/@services/logger.service';
import { QrCodeReader } from 'src/app/@services/qr-code-reader.service';
const log = new Logger('QrCodeScannerComponent');
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.scss']
})
export class QrCodeScannerComponent implements OnInit, OnDestroy {

  scannerTypeSelected: any // Camera/Image
  ticketId: any = ''

  availableDevices: MediaDeviceInfo[] | undefined;
  currentDevice: MediaDeviceInfo | undefined;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean = false;
  hasPermission: boolean = false;

  qrResultString: string = "";

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  action:any;

  subscription: Subscription;


  constructor(
    // private modalService: NgbModal
    private qrReader: QrCodeReader,
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  ngOnInit(): void {
    log.debug("this.action : ", this.action);
  }

  selectScanner(type: any) {
    this.scannerTypeSelected = type // Camera/Image
    const self: any = this
    if (this.scannerTypeSelected == 'Camera') {
      setTimeout(() => {
        log.debug("self.action : ", self.action);
        if (self.action && !self.isStart) self.action.start()
      }, 1000);
    } else {
      setTimeout(() => {
        log.debug("self.action : ", self.action);
        if (self.action && self.isStart) self.action.stop()
      }, 1000);
    }
  }

  public onEvent(e: any): void {
    log.debug(e);
    if (e && e.length) {
      this.ticketId = e[0].value
      if (this.scannerTypeSelected == 'Camera') {
        this.action.pause()
      }
    }
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    log.debug(`camera found`);
    log.debug(devices)
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    log.debug(`qrcode %s`, this.qrResultString);
    this.ticketId = this.qrResultString;

    this.navigateHandler()
  }

  async navigateHandler(){
    log.debug(`navigateHandler called...`);
    const isWhitelistUrl = await this.singletonService.isWhitelistUrl(this.qrResultString)
    log.debug(`isWhitelistUrl... %o`,isWhitelistUrl);

    if(isWhitelistUrl){
      document.location.href = this.qrResultString
    }else{
      log.debug(`this.qrResultString: %o`,this.qrResultString);
      log.debug(`window.location.origin: %o`,window.location.origin);
      log.debug(`startsWith(window.location.origin) -> false`);
    }
  }

  isValidHttpUrl(stringUrl:any) {
    let url;

    try {
      url = new URL(stringUrl);
    } catch (_) {
      return false;
    }

    return true;
  }

  onDeviceSelectChange(event:any) {
    if (this.availableDevices) {
      const selected = event.target.value;
      const device = this.availableDevices.find(x => x.deviceId === selected);
      this.currentDevice = device || undefined;
    }

  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

  onFileChange(event:any) {
    const file = event.target.files[0];
    this.subscription = this.qrReader.decode(file)
      .subscribe((decodedString) => {
        log.debug(decodedString)
        this.qrResultString = decodedString
        this.navigateHandler()
      });
  }


  backnav() {
    this.singletonService.back();
  }

}
