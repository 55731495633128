/* eslint-disable */

import { Component, Input, OnInit } from '@angular/core';
import { Logger } from 'src/app/@services/logger.service';
const log = new Logger('CardQuestHuntingComponent');

@Component({
  selector: 'app-card-quest-hunting',
  templateUrl: './card-quest-hunting.component.html',
  styleUrls: ['./card-quest-hunting.component.scss']
})
export class CardQuestHuntingComponent implements OnInit {

  @Input("quest") quest:any

  constructor(

  ) { }

  ngOnInit() {

  }

}
