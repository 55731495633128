import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('ModalRequireNotificationPermissionComponent');

@Component({
  selector: 'app-modal-require-notification-permission',
  templateUrl: './modal-require-notification-permission.component.html',
  styleUrls: ['./modal-require-notification-permission.component.scss'],
})
export class ModalRequireNotificationPermissionComponent {
  // notificationStatus = Notification.permission;
  notificationStatus:string;
  notShow = false;

  constructor(public modal: NgbActiveModal) {
    try {
      log.info(`ModalRequireNotificationPermissionComponent constructor`)
      this.notificationStatus = (typeof Notification !== 'undefined') ? Notification.permission : 'undefined';
    } catch (error) {
      log.info(`error: ModalRequireNotificationPermissionComponent contruct`);
      log.debug(error);
    }
  }

  onClickAllow() {
    this.requestPermission();
  }

  onClickDismiss() {
    this.modal.close('dismiss');
  }

  onClickDontShow() {
    this.notShow = !this.notShow;
    if (this.notShow) {
      localStorage.setItem('notShowNotiRequest', this.notShow.toString());
    }
  }

  async requestPermission() {
    log.info(`start requestPermission`);
    try {
      const permission = (typeof Notification !== 'undefined') ? await Notification.requestPermission() : false;
      if (permission === 'granted') {
        this.modal.close('granted');
        // document.body.classList.remove('modal-overflow-unset');
      }
      this.modal.close('denied');
      return false;
    } catch (error: any) {
      log.info(`error requestPermission ${error.message}`);
      log.debug(error);
    }
    log.info(`end requestPermission`);
    return false;
  }
}
