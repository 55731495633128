import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-gosignin',
  templateUrl: './modal-gosignin.component.html',
  styleUrls: ['./modal-gosignin.component.scss'],
})
export class ModalGosigninComponent {
  constructor(private _router: Router, private _modalService: NgbModal) {}

  signIn() {
    this._modalService.dismissAll();
    this._router.navigate(['/signin'], {
      // queryParams: { returnurl: this._router.url },
    });
  }
}
