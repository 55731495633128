<div class="wrapper-100vw">
  <div class="wrapper-top-header border-0 mt-0 mb-0 pb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="backnav()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-1">
          <h6 class="title size-18" *ngIf="!loading">{{ item?.name }}</h6>
        </div>
        <div class="col-auto align-self-center">
          <button
            class="btn text-white btn-sm pe-0 filter-btn"
            (click)="singletonService.openModalSocialSharing()"
          >
            <img
              src="assets/img/icon/coupon/share.png"
              class="align-sub"
              alt="my coupon"
            />
            <!-- share icon -->
          </button>
        </div>
      </div>
    </div>
  </div>

  <ngx-loading
    [show]="loading"
    [config]="{ fullScreenBackdrop: true }"
  ></ngx-loading>

  <ng-container *ngIf="!loading">
    <div class="my-point-container">
      <div class="my-point px-4 text-light">
        <div class="size-14 fw-bold w-100">
          <div class="d-flex justify-content-between align-items-center">
            <b class="size-12 text-capitalize">{{ "MY_POINT" | translate }}</b>
            <span>
              {{ point.balance | numeral : "0,0.00" }}
              <img
                [src]="
                point.token.logo || 'assets/img/dashboard/coin-53x53.png'
                "
                width="20"
                height="20"
                class="align-sub ms-1 rounded-circle"
                alt="coin"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="my-point my-nft px-4 text-light">
        <div class="size-14 fw-bold w-100">
          <div class="d-flex justify-content-between align-items-center">
            <b class="size-12">{{ "MY_NFT" | translate }}</b>
            <span>
              {{ nftRemainAmount | numeral : "0,0" }}
              <img
                src="assets/img/icon/nft-icon.png"
                width="20"
                height="20"
                class="align-sub ms-1 rounded-circle"
                alt="nft"
              />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="item">
      <div class="col-12 px-0 coupon-cover-img">
        <img
          [src]="
            item?.bannerImage ||
            item.image ||
            'assets/img/cover/home-cover.png'
          "
        />
      </div>
      <div class="card-body p-0">
        <div class="wrapper-coupon-intro p-3 border-bottom">
          <div class="row mb-4">
            <div *ngIf="item.category" class="col size-14 text-secondary text-capitalize">
              {{ item.category.title }}
            </div>
            <div class="col size-14 text-secondary text-end">
              <div *ngIf="isNoLimit; then noLimit; else limit"></div>

              <ng-template #noLimit>{{ "UNLIMITED" | translate }}</ng-template>

              <ng-template #limit>
                <ng-container
                  *ngIf="translateConfigService.getCurrentLanguage() === 'th'"
                >
                  เหลืออยู่
                  <span class="text-white">{{ item.remainAmount }}</span>
                </ng-container>

                <ng-container
                  *ngIf="translateConfigService.getCurrentLanguage() === 'en'"
                >
                  <span class="text-white">{{ item.remainAmount }}</span> Left
                </ng-container>
              </ng-template>
            </div>
          </div>
          <div class="d-flex flex-row flex-wrap mb-1 align-items-center gap-2">
            <div *ngIf="item.isRedeemAble && !item.isBuyAble" class="size-20 me-2 fw-bold lh-sm">
              {{ "REDEEM" | translate }}
            </div>
            <div *ngIf="item.isBuyAble && !item.isRedeemAble" class="w-100 size-20 me-2 fw-bold lh-sm d-flex justify-content-between align-items-center">
              <span>
                {{ "BUY" | translate }}
              </span>
              <p class="size-20 text-pink">
                ฿ {{item.price}}
              </p>
            </div>
            <div *ngIf="item.isBuyAble && item.isRedeemAble" class="w-100 size-20 me-2 fw-bold lh-sm d-flex justify-content-between align-items-center">
              <span>{{ "REDEEM" | translate }} / {{ "BUY" | translate }}</span>
              <p class="size-20 text-pink">
                ฿ {{item.price}}
              </p>
            </div>
            <ng-container *ngFor="let condition of item.redeemConditions">
              <h6 *ngIf="condition.type === 'token'">
                <img
                  [src]="
                    condition.ref.logo || 'assets/img/dashboard/coin-53x53.png'
                  "
                  width="20"
                  height="20"
                  alt="coin"
                  class="align-middle"
                />
                <span class="align-middle fw-normal size-16">
                  {{ condition.amount | numeral : "0,0" }}
                  {{ condition.ref.name }}
                </span>
              </h6>
            </ng-container>
          </div>
          <p class="size-16 text-secondary mb-3">
            {{ item?.shortDescription }}
          </p>
          <p *ngIf="item.quotaPerUser" class="size-16 text-secondary mb-3">
            Quota:
            {{ item.quotaPerUser }} item/user
          </p>
          <p
            class="d-flex align-items-center justify-content-start text-secondary size-14"
          >
            <i class="bi bi-calendar4 size-16 text-secondary me-2"></i>
            {{ "GET_UNTILL" | translate }}
            {{ item.endDate | date : "dd/MM/yyyy" }}
          </p>
        </div>

        <div class="wrapper-coupon-detail px-3 py-3 border-bottom mb-4">
          <div class="accordion coupon-detail-accordion" id="accordionDetail">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {{ "DETAIL" | translate }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionDetail"
              >
                <div class="accordion-body px-0 pb-0 pt-2">
                  <div class="detail-coupon-detail size-14 text-secondary">
                    {{ item?.description }}
                  </div>
                  <div class="condition-coupon-detail mt-3">
                    <h4 class="fw-bold size-16 text-white">
                      {{ "CONDITION" | translate }}
                    </h4>
                    <div
                      class="mt-2"
                      *ngIf="item?.condition"
                      [innerHtml]="item.condition"
                    ></div>
                  </div>

                  <div class="condition-coupon-detail mt-3" *ngIf="item.eventCoupons && item.eventCoupons.length">
                    <h4 class="fw-bold size-16 text-white">
                      Coupon List
                    </h4>
                    <div class="mt-2">
                      <ul>
                        <li class="size-14" *ngFor="let _eventCoupon of item.eventCoupons">
                          [x{{_eventCoupon.amount}}]
                          {{_eventCoupon.eventCoupon.name}}
                          <br>
                          <span class="size-14">
                            {{ "GET_UNTILL" | translate }}
                            {{ _eventCoupon.eventCoupon.endDate | date : "dd/MM/yyyy" }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrapper-footer-action-fixed">
          <div class="footer-action-fixed-inner">

            <button
              *ngIf="!isNoLimit && !item.remainAmount && item.isRedeemAble === 'coupon'"
              type="button"
              class="btn btn-dark w-100 text-capitalize"
              disabled
            >
              {{ "FULLY_REDEEMED" | translate }}
            </button>

            <button
              *ngIf="(isNoLimit || item.remainAmount) && item.isRedeemAble && itemType === 'coupon'"
              type="button"
              class="btn btn-default text-capitalize"
              (click)="openRedeemModal()"
            >
              {{ "REDEEM" | translate }}
            </button>

            <button
              *ngIf="(isNoLimit || item.remainAmount) && item.isBuyAble"
              type="button"
              class="btn btn-default text-capitalize force-mt-4"
              (click)="openBuyModal()"
            >
              {{ "BUY" | translate }}
            </button>

<!--
            <div *ngIf="isNoLimit; then noLimitButton; else limitButton"></div>

            <ng-template #noLimitButton>
              <button
                class="btn btn-default text-capitalize"
                (click)="openRedeemModal()"
              >
                {{ "REDEEM" | translate }} x
              </button></ng-template
            >

            <ng-template #limitButton>
              <ng-container
                *ngIf="!coupon.remainAmount; then emptyBlock; else buttonBlock"
              ></ng-container>
              <ng-template #emptyBlock>
                <button
                  type="button"
                  class="btn btn-dark w-100 text-capitalize"
                  disabled
                >
                  {{ "FULLY_REDEEMED" | translate }}
                </button>
              </ng-template>

              <ng-template #buttonBlock>
                <button
                  class="btn btn-default text-capitalize"
                  (click)="openRedeemModal()"
                >
                  {{ "REDEEM" | translate }} y
                </button>
              </ng-template>
            </ng-template> -->

          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!item">
      <div class="text-center">
        <div class="my-5 py-3">
          <div class="card bg-transparent">
            <div class="card-body p-0">
              <div class="empty-wrapper text-center">
                <img
                  src="assets/img/empty/empty-brochure.png"
                  alt="empty brochure"
                />
                <p class="text-center mt-2 fw-normal size-14">
                  {{ "NO_COUPON" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
