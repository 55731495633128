import { SingletonService } from 'src/app/@services/singleton.service';
/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalReceivePointComponent } from 'src/app/@components/modal-receive-point/modal-receive-point.component';
import { ModalTransferSendComponent } from 'src/app/@components/modal-transfer-send/modal-transfer-send.component';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Logger } from 'src/app/@services/logger.service';
import { environment } from 'src/environments/environment';
import { PointResult, PointType } from 'src/models/point.model';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/core';

const log = new Logger('DashboardComponent');

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  tokenLoading = true;
  currentUser: any;
  currentUserSubscription: any;
  defaultToken: PointResult;
  error: '';
  myCouponRewardBadge = 0;
  inAppWheelBadge = 0;
  notiBadge = 0;

  articleList: any = [];

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    private modalService: NgbModal,
    private singletonService: SingletonService
  ) {
    this.singletonService.initRequestConsent(this._route.snapshot.queryParams);
  }

  async ngOnInit() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    this.currentUserSubscription =
      this._authenticationService.currentUserSubject.subscribe(
        async (_currentUser) => {
          this.currentUser = _currentUser;
          log.debug('this.currentUser => %o', this.currentUser);
        }
      );

    this.getNotificationBadge();
    await this.reloadData();
  }

  async reloadData() {
    await this.getDefaultToken();
    await this.getArticleList();
    await this.getUnusedCouponBadge()

    try {
      await this.getInAppWheelBadge();
    } catch (error) {
      log.info(`error getInAppWheelBadge`);
      log.debug(error);
    }
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription)
      this.currentUserSubscription.unsubscribe();
  }

  async getUnusedCouponBadge() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    if (this.currentUser) {
      try {
        const coupons =
          (await this.backendService.getUnusedCouponBadge()) as any;
        this.myCouponRewardBadge = coupons?.totalResults || 0;
      } catch (err) {
        this.errorService.error(err, true);
      }
    }
  }

  async getNotificationBadge() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    if (this.currentUser) {
      try {
        const notification =
          (await this.backendService.getNotificationBadge()) as any;
        this.notiBadge = notification?.count || 0;
      } catch (err) {
        this.errorService.error(err, true);
      }
    }
  }



  async getInAppWheelBadge() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    if (this.currentUser) {
      try {
        const badge = (await this.backendService.getInAppWheelBadge()) as any;
        this.inAppWheelBadge = badge?.remains || 0;
      } catch (err) {
        const error = this.errorService.error(err);
        this.error = error.message;
      }
    }
  }

  async getDefaultToken() {
    this.currentUser = this._authenticationService.currentUserSubject.value;
    if (this.currentUser) {
      try {
        this.tokenLoading = true;
        const query = {
          eventCode: environment.eventCode,
          isOnlyAvailable: false,
        };
        const allToken = (await this.backendService.getAllPoint(
          query
        )) as PointType;
        const defaultToken = allToken.results.find((result) => {
          return result.token.isDefault;
        });
        this.defaultToken = defaultToken || allToken.results?.[0] || null;
      } catch (err) {
        const error = this.errorService.error(err);
        this.error = error.message;
        // if (error.code === 401) {
        //   this.errorService.goSignIn();
        // }
      } finally {
        this.tokenLoading = false;
      }
    }
  }

  openSendModal() {
    this.singletonService.showNotAvaliabledFeatureSwalAlert()

    // if (!this.defaultToken) return;
    // const modalRef = this.modalService.open(ModalTransferSendComponent, {
    //   centered: true,
    // });
    // modalRef.componentInstance.token = this.defaultToken.token;
    // modalRef.componentInstance.balance = this.defaultToken.balance;
    // modalRef.result.then(
    //   (data) => {
    //     data === 'success' && this.getDefaultToken();
    //   },
    //   (reason) => {}
    // );
  }

  openReceiveModal() {
    this.singletonService.showNotAvaliabledFeatureSwalAlert()

    // this.modalService.open(ModalReceivePointComponent, {
    //   centered: true,
    //   fullscreen: true,
    //   keyboard: false,
    //   windowClass: 'modal-fullscreen-95',
    //   backdrop: false,
    //   scrollable: true,
    // });
  }

  jumpToNewsDetail() {
    log.debug('jumpToNewsDetail...');
    this._router.navigate(['/newsdetail/fjok34']);
  }

  jumpToProfileSetting() {
    if (this.currentUser) {
      this._router.navigate(['/profilesetting']);
    } else {
      this.errorService.goSignIn();
    }
  }

  async getArticleList() {
    let res: any = await this.backendService.getArticleList(
      undefined, // keyword
      undefined, // categories
      5, // limit
      1 // page
    );
    log.debug('doSearch res => %o', res);
    if (res) {
      const results = res.results;
      this.articleList = results;
      log.debug('this.articleList: %o', this.articleList);
    }
  }
}
