<div class="wrapper-100vw">
  <div class="wrapper-top-header border-0 mt-0 mb-0 pb-3">
    <div class="container">
      <div class="row pt-3">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            (click)="backnav()"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-1">
          <h6 class="title size-18">{{ "COUPON_REWARD" | translate }}</h6>
        </div>
        <div class="col-auto align-self-center">
          <button
            class="btn text-white btn-sm pe-0 filter-btn position-relative"
            routerLink="/mycouponreward"
          >
            <img
              src="assets/img/icon/coupon/my-coupon.png"
              class="align-sub"
              alt="my coupon"
            />
            <span *ngIf="!!unusedBadge" class="badge-count-noti">{{
              unusedBadge
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <ngx-loading
    [show]="loading"
    [config]="loadingConfig"
    [template]="loadingTemplate"
  >
  </ngx-loading>

  <ng-container *ngIf="!loading">
    <div class="my-point-container">
      <div class="my-point px-4 text-light">
        <div class="size-14 fw-bold w-100">
          <div class="d-flex justify-content-between align-items-center">
            <b class="size-12 text-capitalize">{{ "MY_POINT" | translate }}</b>
            <span>
              {{ point.results[0].balance | numeral : "0,0.00" }}
              <img
                [src]="
                  defaultToken.token.logo || 'assets/img/dashboard/coin-53x53.png'
                "
                width="20"
                height="20"
                class="align-sub ms-1 rounded-circle"
                alt="coin"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="my-point my-nft px-4 text-light">
        <div class="size-14 fw-bold w-100">
          <div class="d-flex justify-content-between align-items-center">
            <b class="size-12">{{ "MY_NFT" | translate }}</b>
            <span>
              {{ nftRemainAmount | numeral : "0,0" }}
              <img
                src="assets/img/icon/nft-icon.png"
                width="20"
                height="20"
                class="align-sub ms-1 rounded-circle"
                alt="nft"
              />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-suggestion-exhibitor border-bottom mb-4 pb-2 mt-3">
      <div class="container">
        <div class="mt-2 mb-1">
          <h6 class="title size-16">{{"COUPON_BUNDLE" | translate}}</h6>
          <h6 class="text-secondary mt-1 size-14">{{"SUGGESTION_FOR_YOU" | translate}}</h6>
        </div>
        <div class="row my-3">
          <div class="col-12">

            <swiper
              class="swiper-parent swiper-parent-title-not-over"
              [slidesPerView]="'auto'"
              [spaceBetween]="15"
              [pagination]="true"
            >

              <ng-template swiperSlide *ngFor="let bundle of bundles">
                <div class="slide-wrapper" (click)="openCouponDetail(bundle.id,true)">
                  <figure class="overflow-hidden rounded-15 text-center">
                    <img
                      [src]="(bundle.image ? bundle.image : 'assets/img/cover/mock-image.png')"
                      alt=""
                      class="w-100 mx-auto"
                    />
                  </figure>

                  <div class="news-content">
                    <h6 class="size-16 text-white text-truncate">
                      {{bundle.name}}
                    </h6>
                    <div class="">
                      <div class="size-12 text-secondary text-truncate lh-sm">
                        <!-- {{bundle.description}} -->
                          {{bundle.eventCoupons ? bundle.eventCoupons.length : 0}} coupons included
                      </div>
                      <div class="d-flex justify-content-between align-items-center size-12 text-secondary">
                        <div>
                          {{ "GET_UNTILL" | translate }}
                          {{ bundle.endDate| date: 'dd/MM/yyyy'}}
                        </div>
                        <div class="wrapper-price" *ngIf="bundle.isBuyAble">
                          <span class="span-price size-18 text-pink fw-bold">
                            ฿ {{bundle.price}}
                            <!-- <span class="size-14">{{ "BAHT" | translate}}</span> -->
                          </span>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </ng-template>

              <div *ngIf="isLoadingBundleList">
                <div class="text-center my-5 py-3 empty-wrapper">
                  <!-- <img src="assets/img/empty/empty-search-list.png" alt="empty search list"> -->
                  <div class="spinner-border"></div>
                  <p class="text-center fw-normal size-14">{{ "LOADING" | translate }}...</p>
                </div>
              </div>

            </swiper>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mb-2 mt-3">
        <div class="col-12 px-0">
          <swiper
            class="connectionwiper"
            [slidesPerView]="'auto'"
            [spaceBetween]="0"
            [pagination]="false"
          >
            <ng-template swiperSlide>
              <div
                class="tag border active"
                [ngClass]="{ active: !activeCategory }"
                (click)="handleActiveCategory(null)"
              >
                <span class="tag-inner">
                  <span class="text-uppercase">{{ "ALL" | translate }}</span>
                </span>
              </div>
            </ng-template>

            <ng-container *ngFor="let category of categories.results">
              <ng-template swiperSlide>
                <div
                  class="tag border"
                  [ngClass]="{ active: activeCategory === category.id }"
                  (click)="handleActiveCategory(category.id)"
                >
                  <span class="tag-inner">
                    <span class="text-uppercase">{{ category.title }}</span>
                  </span>
                </div>
              </ng-template>
            </ng-container>
          </swiper>
        </div>
      </div>

      <div *ngIf="categoryLoading; then loadingBlock; else contentBlock"></div>
      <ng-template #loadingBlock> {{ "LOADING" | translate }}... </ng-template>

      <ng-template #contentBlock>
        <div
          infinite-scroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onScrollEnd()"
        >
          <div *ngIf="!coupons.length">
            <div class="text-center">
              <div class="my-5 py-3">
                <div class="card bg-transparent">
                  <div class="card-body p-0">
                    <div class="empty-wrapper text-center">
                      <img
                        src="assets/img/empty/empty-brochure.png"
                        alt="empty brochure"
                      />
                      <p class="text-center mt-2 fw-normal size-14">
                        {{ "NO_COUPON" | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngFor="let coupon of coupons">
            <card-coupon
              [coupon]="coupon"
              [buttonText]="'redeem'"
              (buttonClick)="openCouponDetail(coupon.id)"
            ></card-coupon>
          </ng-container>
        </div>
        <div *ngIf="loadingMore">{{ "LOADING" | translate }}...</div>
      </ng-template>
    </div>
  </ng-container>
</div>
