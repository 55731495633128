<div class="modal-header d-flex border-0 p-4 pb-0 align-items-start">
  <h3 class="size-20 text-white">
    {{"MY_NAMECARD" | translate}}
    <p class="text-secondary size-14 mt-2 fw-normal">{{"OPEN_THE_SCAN_TO_CHANGE_CONTACT" | translate}}</p>
  </h3>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <app-card-namecard [user]="currentUser"></app-card-namecard>
</div>



