import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { Router } from '@angular/router';
/* eslint-disable */

import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { FormService } from 'src/app/@services/form.service';
import { Logger } from 'src/app/@services/logger.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { UserService } from 'src/app/@services/user.service';
import { environment } from 'src/environments/environment';
import { ViewportScroller } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
const log = new Logger('ProfilesettingComponent');
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-profilesetting',
  templateUrl: './profilesetting.component.html',
  styleUrls: ['./profilesetting.component.scss'],
})
export class ProfilesettingComponent {
  @ViewChild('topHeader') topHeader?: ElementRef;

  profileForm!: FormGroup;
  loading = false;
  currentUser: any = null;
  avatarPreview = '';
  error = '';
  success = false;

  customCurrentPageUrl: any;

  constructor(
    private fb: FormBuilder,
    public formService: FormService,
    private _tokenStorageService: TokenStorageService,
    private userService: UserService,
    public translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private viewportScroller: ViewportScroller,
    public _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
    public _errorHandlerService: ErrorHandlerService,
  ) // private modalService: NgbModal,
  {
    // this.modalService.dismissAll()
  }

  ngOnInit(): void {
    const currentUser = this._authenticationService.currentUserSubject.value;
    const profile = currentUser.profile;

    this.currentUser = currentUser;

    this.profileForm = this.fb.group({
      picture: [null],
      firstName: [profile?.firstName || '', Validators.required],
      lastName: [profile?.lastName || '', Validators.required],
      mobilePhone: [
        currentUser?.mobilePhone?.substring(1) || '',
        Validators.required,
      ],
      email: [
        currentUser?.email || '',
        [
          Validators.required,
          Validators.email,
          // Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      job: [profile?.job || '', Validators.required],
      institution: [profile?.institution || '', Validators.required],
    });

    if (profile && profile.picture) this.avatarPreview = profile.picture;
  }

  async onInputFileChange(e: any) {
    const files = e.target.files;
    const file = Array.from(files)[0] as File;
    this.avatarPreview = await this.fileToBase64(file);
    this.profileForm.patchValue({ picture: file });
  }

  fileToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  removeAvatar() {
    this.profileForm.patchValue({ picture: null });
    this.avatarPreview = '';
  }

  async onSubmit() {
    this.loading = true;
    this.success = false;
    this.error = '';
    this.scrollToTop();

    if (this.profileForm.invalid) {
      this.error = this.translateService.instant(
        'ERROR_MSG.ERROR_COMPLETE_INFORMATION'
      );
      this.loading = false;
      return;
    }

    try {
      const formValue = this.profileForm.value;

      const formData: any = new FormData();
      if (this.profileForm.value && this.profileForm.value.picture)
        formData.append('picture', this.profileForm.value.picture);
      if (this.profileForm.value && this.profileForm.value.firstName)
        formData.append('firstName', this.profileForm.value.firstName);
      if (this.profileForm.value && this.profileForm.value.lastName)
        formData.append('lastName', this.profileForm.value.lastName);
      if (this.profileForm.value && this.profileForm.value.mobilePhone)
        formData.append(
          'mobilePhone',
          `0${this.profileForm.value.mobilePhone}`
        );
      if (this.profileForm.value && this.profileForm.value.email)
        formData.append('email', this.profileForm.value.email);
      if (this.profileForm.value && this.profileForm.value.job)
        formData.append('job', this.profileForm.value.job);
      if (this.profileForm.value && this.profileForm.value.institution)
        formData.append('institution', this.profileForm.value.institution);

      const response = (await this.userService.updateProfile(formData)) as any;
      log.debug(`updateProfile response %o`,response);

      await this._authenticationService.doAfterLogin(response)
      // this._tokenStorageService.setAuthToken(response);
      // const user = JSON.parse(localStorage.getItem('0110-user_user')!);
      // this._authenticationService.currentUserSubject.next(user);

      this.success = true;
    } catch (error: any) {
      const _error = this._errorHandlerService.error(error).message;
      log.debug("_error => %o",_error)
      this.error = _error
    } finally {
      this.loading = false;
    }
  }

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.topHeader!.nativeElement.scrollTop = 0;
  }

  backnav() {
    this.singletonService.back();
  }

  jumpToMyNameCard(href: any) {
    document.location.href = href;
  }
}
