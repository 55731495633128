/* eslint-disable */

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddreviewexhibitorComponent } from 'src/app/@components/modal-addreviewexhibitor/modal-addreviewexhibitor.component';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';

import { Logger } from 'src/app/@services/logger.service';

const log = new Logger('ExhibitordetailComponent');
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-exhibitorreview',
  templateUrl: './exhibitorreview.component.html',
  styleUrls: ['./exhibitorreview.component.scss'],
})
export class ExhibitorreviewComponent {
  exhibitorId:any

  currentUser:any
  currentUserSubscription:any

  exhibitorFeedbackList:any = [];

  isLoading = false;

  limit=5;
  page=1;
  totalPages = 1;
  totalResults:any = undefined;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: NgbModal,
    private _location: Location,
    public singletonService: SingletonService,
  ) {
    this.exhibitorId = this._route.snapshot.paramMap.get('exhibitorid')!;

  }

  async ngOnInit() {
    this.currentUserSubscription = this._authenticationService.currentUserSubject.subscribe((_currentUser) => {
      this.currentUser = _currentUser
      log.debug("this.currentUser => %o",this.currentUser)
    })

    await this.getExhibitorFeedbacks()
  }

  ngOnDestroy(): void {
    if(this.currentUserSubscription) this.currentUserSubscription.unsubscribe();
  }

  async getExhibitorFeedbacks(){
    this.exhibitorFeedbackList = [];
    this.isLoading = true
    const getExhibitorFeedbacksRes:any = await this.backendService.getExhibitorFeedbacks(
      this.exhibitorId,
      this.limit,
      this.page,
    )
    log.debug("res getExhibitorFeedbacksRes: %o",getExhibitorFeedbacksRes)
    if(getExhibitorFeedbacksRes){
      const results = getExhibitorFeedbacksRes.results
      this.exhibitorFeedbackList = [...this.exhibitorFeedbackList,...results]
      log.debug("this.exhibitorFeedbackList: %o",this.exhibitorFeedbackList)

      this.totalPages = getExhibitorFeedbacksRes.totalPages
      this.totalResults = getExhibitorFeedbacksRes.totalResults

      this.isLoading = false
    }else{
      this.isLoading = false
    }
  }

  resetPaginate(){
    this.page = 1
    this.totalPages = 1;
    this.exhibitorFeedbackList = []
    this.totalResults = undefined
  }

  onScroll() {
    log.debug("onScroll...")

    if(this.page < this.totalPages){
      this.page++;
      this.getExhibitorFeedbacks()
    }
  }

  backnav() {
    this.singletonService.back();
  }


  openModalReview(){
    let modalRef = this.modalService.open(ModalAddreviewexhibitorComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.exhibitorId = this.exhibitorId;
    modalRef.componentInstance.dataService.subscribe((isSuccess:any)=>{
      log.debug("ModalAddreviewexhibitorComponent isSuccess: %o",isSuccess)
      if(isSuccess){
        // this.isBookmarked = true
        this.getExhibitorFeedbacks()
      }
    })
  }
}
