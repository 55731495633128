import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { EventToken } from 'src/models/eventToken.model';
import { ModalVerifyPincodeComponent } from '../modal-verify-pincode/modal-verify-pincode.component';
import { TransferTokenType } from 'src/types/transfer-token.type';

@Component({
  selector: 'modal-transfer-send',
  templateUrl: './modal-transfer-send.component.html',
  styleUrls: ['./modal-transfer-send.component.scss'],
})
export class ModalTransferSendComponent implements OnInit {
  @Input('token') token: EventToken;
  @Input('balance') balance: number;

  transferType = TransferTokenType;
  selectedTransferType: TransferTokenType | null = null;

  sendForm: FormGroup;
  error = '';
  pointBalanceError = false;
  submitting = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.sendForm = this.fb.group({
      to: ['', Validators.required],
      amount: ['', [Validators.required, Validators.max(this.balance)]],
    });
  }

  onDecodeQrCode(userId: string) {
    this.sendForm.patchValue({ to: userId });
  }

  onSelectedTransferType(type: TransferTokenType) {
    this.selectedTransferType = type;
  }

  onClearTransferType() {
    this.selectedTransferType = null;
    this.sendForm.reset();
  }

  get toValue() {
    return this.sendForm.value.to;
  }

  async onSubmit() {
    try {
      const verifyRef = this.modalService.open(ModalVerifyPincodeComponent, {
        centered: true,
        backdrop: 'static',
      });
      const result = await verifyRef.result;
      if (!result) return;

      this.submitting = true;
      this.pointBalanceError = false;

      const { value } = this.sendForm;
      if (parseInt(value.amount) > this.balance) {
        this.pointBalanceError = true;
      }

      const payload = {
        to: value.to,
        type: this.selectedTransferType!,
        token: this.token.id,
        amount: parseInt(value.amount),
      };

      await this.backendService.transferToken(payload);
      this.modal.close('success');
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.submitting = false;
    }
  }
}
