<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{"HELP_SUPPORT" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center">

    <label class="text-uppercase mb-2 size-14 text-label">{{"HELP_SUPPORT" | translate}}</label>
    <ul class="card mb-3">
      <li routerLink="/contactus">
        <label class="text-white size-16">{{"NAVBAR.CONTACT" | translate}}</label>
        <label class="text-secondary">
          <i class="bi bi-chevron-right size-16"></i>
        </label>
      </li>
      <li routerLink="/faq">
        <label class="text-white size-16">{{"Q_&_A" | translate}}</label>
        <label class="text-secondary">
          <i class="bi bi-chevron-right size-16"></i>
        </label>
      </li>
    </ul>

  </div>

</div>
