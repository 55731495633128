<!-- <div class="row pt-3">
  <div class="col-3 align-self-center">
    <button
      type="button"
      class="btn text-white btn-44 back-btn"
      (click)="backnav()"
    >
      <i class="bi bi-arrow-left"></i>
    </button>
  </div>
  <div class="col align-self-center text-center px-0">
    <h6 class="title size-16">{{"PASSWORD" | translate}}</h6>
  </div>
  <div class="col-3 align-self-center"></div>
</div> -->

<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            <!-- {{"PASSWORD" | translate}} -->
            {{ 'PASSWORD' | translate }}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="mt-4">
      <h6 class="title size-16 mb-2">{{"SET_PASSWORD" | translate}}</h6>
      <p class="text-muted">
        {{"USE_LOWERCASE_UPPERCASE" | translate}}
      </p>
    </div>

    <div class="alert alert-success mt-4" role="alert" *ngIf="success">
      {{"SIGNIN.FORGOT_PASSWORD3_TITLE" | translate}}
    </div>
    <div class="alert alert-danger mt-4" role="alert" *ngIf="!!error">
      {{ error }}
    </div>
    <div class="alert alert-warning mt-4" role="alert" *ngIf="!allowChange">
      {{"CANT_USE_CHANGE_PASSWORD" | translate}}
    </div>

    <div [formGroup]="passwordForm" class="row mt-3">
      <div class="col-12">
        <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="oldPassword">{{
          "OLD_PASSWORD" | translate
        }}</label>
        <div class="form-group form-floating mb-3 input-group input-group-merge">
          <label class="tooltip-btn tooltip-btn-left" for="oldPassword">
            <img
            src="assets/img/auth/key.png"
            alt="username icon"
          />
          </label>
          <input
            (keyup)="error = ''"
            class="form-control button-inside px-0"
            [type]="isShowOldPassword ? 'text' : 'password'"
            formControlName="oldPassword"
            placeholder="{{ 'OLD_PASSWORD' | translate }}"
            tabindex="2"
            name="oldPassword"
            value=""
            id="oldPassword"
            [class.is-invalid]="
              formService.isControlInvalid(passwordForm, 'oldPassword')
            "
          />
          <div class="input-group-append">
            <span class="input-group-text cursor-pointer icon-input"
              ><i
                class="bi"
                [ngClass]="{
                  'bi-eye-slash': !isShowOldPassword,
                  'bi-eye': isShowOldPassword
                }"
                (click)="showOldPassword()"
              >
              </i
            ></span>
          </div>
        </div>

        <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="newPassword">{{
          "NEW_PASSWORD" | translate
        }}</label>
        <div class="form-group form-floating mb-3 input-group input-group-merge">
          <label class="tooltip-btn tooltip-btn-left" for="newPassword">
            <img
            src="assets/img/auth/key.png"
            alt="username icon"
          />
          </label>
          <input
            (keyup)="error = ''"
            class="form-control button-inside px-0"
            [type]="isShowPassword ? 'text' : 'password'"
            formControlName="newPassword"
            placeholder="{{ 'NEW_PASSWORD' | translate }}"
            tabindex="2"
            name="newPassword"
            value=""
            id="newPassword"
            [class.is-invalid]="
              formService.isControlInvalid(passwordForm, 'newPassword')
            "
          />
          <div class="input-group-append">
            <span class="input-group-text cursor-pointer icon-input"
              ><i
                class="bi"
                [ngClass]="{
                  'bi-eye-slash': !isShowPassword,
                  'bi-eye': isShowPassword
                }"
                (click)="showPassword()"
              >
              </i
            ></span>
          </div>
          <div
            class="invalid-feedback px-3 pb-3"
            *ngIf="
              formService.controlHasError(passwordForm, 'minlength', 'newPassword')
            "
          >
            {{ "SIGNIN.PASSWORD_VALIDATE" | translate }}
          </div>
          <div
            class="invalid-feedback px-3 pb-3"
            *ngIf="
              formService.controlHasError(
                passwordForm,
                'passwordStrength',
                'newPassword'
              )
            "
          >
            {{ "SIGNIN.PASSWORD_VALIDATE_STRICT" | translate }}
          </div>
        </div>

        <label class="form-control-label text-uppercase mb-2 size-14 text-label" for="confirmNewPassword">{{
          "CONFIRM_NEW_PASSWORD" | translate
        }}</label>
        <div class="form-group form-floating mb-3 input-group input-group-merge">
          <label class="tooltip-btn tooltip-btn-left" for="confirmNewPassword">
            <img
            src="assets/img/auth/key.png"
            alt="username icon"
          />
          </label>
          <input
            (keyup)="error = ''"
            class="form-control button-inside px-0"
            [type]="isShowComfirmPassword ? 'text' : 'password'"
            formControlName="confirmNewPassword"
            placeholder="{{ 'CONFIRM_NEW_PASSWORD' | translate }}"
            tabindex="2"
            name="confirmNewPassword"
            value=""
            id="confirmNewPassword"
            [class.is-invalid]="
              formService.isControlInvalid(passwordForm, 'confirmNewPassword')
            "
          />
          <div class="input-group-append">
            <span class="input-group-text cursor-pointer icon-input"
              ><i
                class="bi"
                [ngClass]="{
                  'bi-eye-slash': !isShowComfirmPassword,
                  'bi-eye': isShowComfirmPassword
                }"
                (click)="showConfirmPassword()"
              >
                <!-- {{isShowRecomfirm}} -->
              </i></span
            >
          </div>
          <div
            class="invalid-feedback px-3 pb-3"
            *ngIf="
              formService.controlHasError(
                passwordForm,
                'confirmPasswordValidator',
                'confirmNewPassword'
              )
            "
          >
            {{ "SIGNIN.PASSWORD_MATCH" | translate }}
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 mt-auto mx-auto py-4">
        <button
          (click)="onSubmit()"
          class="btn btn-default btn-lg"
          [disabled]="!allowChange || loading || passwordForm.invalid"
        >
          {{ "OK" | translate }}
        </button>
      </div>
    </div>

  </div>
</div>


