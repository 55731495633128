<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: true }"
></ngx-loading>

<div style="min-height: 100vh">
  <div class="wrapper-100vw">
    <div class="wrapper-top-header">
      <div class="content-header">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <h2>
              <span class="size-18 text-white fw-bold text-capitalize">
                <button
                  class="btn btn-sm btn-arrow-back ps-0"
                  type="button"
                  (click)="backnav()"
                >
                  <i class="bi bi-chevron-left text-white size-16"></i>
                </button>
                {{ "NOTIFICATION" | translate }}
              </span>
            </h2>
            <div
              class="d-flex align-items-center justify-content-center gap-3"
              (click)="toggleSelectModeEnable()"
            >
              <div *ngIf="selectModeEnable; then enable; else disable"></div>
              <ng-template #enable>
                <button type="button" class="btn p-0">
                  <i class="bi bi-x-lg size-18 text-white d-flex"></i>
                </button>
              </ng-template>

              <ng-template #disable>
                <button type="button" class="btn p-0">
                  <img
                    src="assets/img/icon/icon-actions/trash.png"
                    alt="delete icon"
                  />
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-swiper-filter">
      <swiper
        class="connectionwiper"
        [slidesPerView]="'auto'"
        [spaceBetween]="0"
        [pagination]="false"
      >
        <ng-template swiperSlide>
          <div
            class="tag border"
            [class.active]="activeTab === 'ALL'"
            (click)="handleChangeTab('ALL')"
          >
            <span class="tag-inner justify-content-center">
              <span class="text-uppercase">{{ "ALL" | translate }}</span>
            </span>
          </div>
        </ng-template>

        <ng-template swiperSlide>
          <div
            class="tag border"
            [class.active]="activeTab === 'UNREAD'"
            (click)="handleChangeTab('UNREAD')"
          >
            <span class="tag-inner justify-content-center">
              <span class="text-uppercase">{{ "UNREAD" | translate }}</span>
            </span>
          </div>
        </ng-template>
      </swiper>
    </div>

    <div class="wrapper-row-noti container mt-3" *ngIf="!loading">
      <div
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="500"
        (scrolled)="onScrollEnd()"
      >
        <div class="my-5 py-3" *ngIf="!notifications || !notifications.length">
          <div class="card bg-transparent">
            <div class="card-body p-0">
              <div class="empty-wrapper text-center">
                <img
                  src="assets/img/empty/empty-notifications.png"
                  alt="empty icon"
                />
                <p class="text-center mt-2 fw-normal size-14">
                  {{ "DONT_HAVE_ANY_NOTIFICATION_NOW" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngFor="let payload of notifications">
          <div
            class="row-noti"
            (click)="handleClickNotification(payload)"
            [@deleteAnimation]
            [@.disabled]="!payload.selected"
          >
            <div class="row">
              <div class="col-2 col-img pe-0">
                <div class="img-noti">
                  <ng-container [ngSwitch]="payload.data.refModel">
                    <img
                      src="assets/img/icon/coupon-icon.png"
                      alt="coupon image"
                      *ngSwitchCase="'coupon'"
                    />
                    <img
                      src="assets/img/icon/token-icon.png"
                      alt="token image"
                      *ngSwitchCase="'token'"
                    />
                    <img
                      src="assets/img/icon/nft-icon.png"
                      alt="nft image"
                      *ngSwitchCase="'nft'"
                    />
                    <img
                      src="assets/img/icon/photo-icon.png"
                      alt="nft image"
                      *ngSwitchCase="'photo'"
                    />
                    <img
                      src="assets/img/icon/notification.png"
                      alt="notification image"
                      *ngSwitchDefault
                    />
                  </ng-container>
                </div>
              </div>
              <div class="col col-content pe-0">
                <h6 class="noti-title size-16 text-truncate mb-1">
                  {{ payload?.notification?.title || "" }}
                </h6>
                <div
                  class="noti-desc size-14 text-2-line mb-2 text-muted lh-sm"
                >
                  {{ payload?.notification?.body || "" }}
                </div>
                <div class="noti-date mt-1 size-14 color-pink">
                  {{ payload.timeAgo }}
                </div>
              </div>
              <div class="col-2 col-actions ps-0">
                <div *ngIf="selectModeEnable; then enable; else disable"></div>
                <ng-template #enable>
                  <div
                    *ngIf="payload.selected; then selected; else notSelected"
                  ></div>
                  <ng-template #selected>
                    <i class="bi bi-check-circle size-18 color-pink"></i>
                  </ng-template>
                  <ng-template #notSelected>
                    <i class="bi bi-check-circle size-18 color-grey"></i>
                  </ng-template>
                </ng-template>

                <ng-template #disable>
                  <i
                    *ngIf="!payload?.isReaded"
                    class="bi bi-circle-fill size-16 color-pink"
                  ></i>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="loadingMore">{{ "LOADING" | translate }}...</div>

      <!-- <div class="row-noti">
      <div class="row">
        <div class="col-2 col-img pe-0">
          <div class="img-noti">
            <img src="assets/img/icon/notification.png" alt="notification image">
          </div>
        </div>
        <div class="col col-content pe-0">
          <h6 class="noti-title size-16 text-truncate mb-1">Subject</h6>
          <div class="noti-desc size-14 text-2-line mb-2 text-muted lh-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis
          </div>
          <div class="noti-date mt-1 size-14 color-pink">
            11 hours ago
          </div>
        </div>
        <div class="col-2 col-actions ps-0">
          <i class="bi bi-circle-fill size-18 color-pink"></i>
          <i class="bi bi-check-circle size-18 color-pink"></i>
          <i class="bi bi-check-circle size-18 color-grey"></i>
        </div>
      </div>
    </div>
 -->
    </div>

    <div class="wrapper-footer-action-fixed" *ngIf="selectModeEnable">
      <div class="footer-action-fixed-inner" style="position: fixed; bottom: 0">
        <button
          type="button"
          (click)="handleDeleteNotification()"
          class="btn btn-default size-16 py-2"
        >
          {{"DELETE" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
