<div class="wrapper-100vw">

  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{"QUEST_HUNTING.QUEST_NAME" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="wrapper-intro-sub-quest-hunting container border-bottom">
    <h6 class="quest-hunting-name size-16 text-white fw-bold">{{"QUEST_HUNTING.QUEST_NAME" | translate}}</h6>
    <div class="quest-hunting-desc text-muted fw-normal my-3 size-14">
      Detail Quest name QLorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum auctor urna fermentum commodo risus mattis pellentesque. Dictumst eget.
    </div>
    <div class="quest-hunting-progress-bar my-3">
      <div class="quest-hunting-progress-bar-bg">
        <div class="quest-hunting-progress-bar-inner" style="width: 50%;"></div>
      </div>
    </div>
    <div class="quest-hunting-progress-count my-3">
      <div class="d-flex justify-content-start align-items-center">
        <i class="bi bi-check-circle size-18 lh-1 text-white"></i>
        <span class="ms-2 size-14 text-white">2/4 {{"COMPLETE" | translate}}</span>
      </div>
    </div>
  </div>

  <div class="wrapper-list-quest-hunting container mt-3 pt-1">

    <!-- demo expanded = true -->
    <!-- <div class="card custom-card mb-3">
      <div class="wrapper-quest-hunting-info card-body">
        <div class="quest-hunting-name w-100">
          <span class="size-16 fw-bold text-white">
            Sub Quest Name
          </span>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 py-1">
          <div class="quest-hunting-progress-bar-bg">
            <div class="quest-hunting-progress-bar-inner" style="width: 100%;"></div>
          </div>
        </div>
        <div class="detail-sub-quest-hunting">
          <div class="accordion quest-hunting-accordion" id="detail-sub-quest-hunting-0">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="bi bi-check-circle size-18 lh-1 text-pink"></i>
                    <span class="ms-2 size-14 text-white">Completed</span>
                  </div>
                </button>
              </h2>
              <div id="collapseZero" class="accordion-collapse collapse show" data-bs-parent="#detail-sub-quest-hunting-0">
                <div class="accordion-body px-0 pb-0">
                  <div class="detail-quest-hunting size-14 text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum auctor urna fermentum commodo risus mattis pellentesque. Dictumst eget.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="card custom-card mb-3">
      <div class="wrapper-quest-hunting-info card-body">
        <div class="quest-hunting-name w-100">
          <span class="size-16 fw-bold text-white">
            {{"QUEST_HUNTING.SUB_QUEST_NAME" | translate}}
          </span>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 py-1">
          <div class="quest-hunting-progress-bar-bg">
            <div class="quest-hunting-progress-bar-inner" style="width: 100%;"></div>
          </div>
        </div>
        <div class="detail-sub-quest-hunting">
          <div class="accordion quest-hunting-accordion" id="detail-sub-quest-hunting-1">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="bi bi-check-circle size-18 lh-1 text-pink"></i>
                    <span class="ms-2 size-14 text-white">{{"COMPLETE" | translate}}</span>
                  </div>
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#detail-sub-quest-hunting-1">
                <div class="accordion-body px-0 pb-0">
                  <div class="detail-quest-hunting size-14 text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum auctor urna fermentum commodo risus mattis pellentesque. Dictumst eget.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card mb-3">
      <div class="wrapper-quest-hunting-info card-body">
        <div class="quest-hunting-name w-100">
          <span class="size-16 fw-bold text-white">
            {{"QUEST_HUNTING.SUB_QUEST_NAME" | translate}}
          </span>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 py-1">
          <div class="quest-hunting-progress-bar-bg">
            <div class="quest-hunting-progress-bar-inner" style="width: 0%;"></div>
          </div>
        </div>
        <div class="detail-sub-quest-hunting">
          <div class="accordion quest-hunting-accordion" id="detail-sub-quest-hunting-2">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="bi bi-check-circle size-18 lh-1 text-secondary"></i>
                    <span class="ms-2 size-14 text-secondary">{{"UNCOMPLETE" | translate}}</span>
                  </div>
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#detail-sub-quest-hunting-2">
                <div class="accordion-body px-0 pb-0">
                  <div class="detail-quest-hunting size-14 text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum auctor urna fermentum commodo risus mattis pellentesque. Dictumst eget.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card mb-3">
      <div class="wrapper-quest-hunting-info card-body">
        <div class="quest-hunting-name w-100">
          <span class="size-16 fw-bold text-white">
            {{"QUEST_HUNTING.SUB_QUEST_NAME" | translate}}
          </span>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 py-1">
          <div class="quest-hunting-progress-bar-bg">
            <div class="quest-hunting-progress-bar-inner" style="width: 0%;"></div>
          </div>
        </div>
        <div class="detail-sub-quest-hunting">
          <div class="accordion quest-hunting-accordion" id="detail-sub-quest-hunting-3">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="bi bi-check-circle size-18 lh-1 text-secondary"></i>
                    <span class="ms-2 size-14 text-secondary">{{"UNCOMPLETE" | translate}}</span>
                  </div>
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#detail-sub-quest-hunting-3">
                <div class="accordion-body px-0 pb-0">
                  <div class="detail-quest-hunting size-14 text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum auctor urna fermentum commodo risus mattis pellentesque. Dictumst eget.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card custom-card mb-3">
      <div class="wrapper-quest-hunting-info card-body">
        <div class="quest-hunting-name w-100">
          <span class="size-16 fw-bold text-white">
            {{"QUEST_HUNTING.SUB_QUEST_NAME" | translate}}
          </span>
        </div>
        <div class="quest-hunting-progress-bar max-w70per mt-2 mb-2 py-1">
          <div class="quest-hunting-progress-bar-bg">
            <div class="quest-hunting-progress-bar-inner" style="width: 100%;"></div>
          </div>
        </div>
        <div class="detail-sub-quest-hunting">
          <div class="accordion quest-hunting-accordion" id="detail-sub-quest-hunting-4">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false">
                  <div class="d-flex justify-content-start align-items-center">
                    <i class="bi bi-check-circle size-18 lh-1 text-pink"></i>
                    <span class="ms-2 size-14 text-white">{{"COMPLETE" | translate}}</span>
                  </div>
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#detail-sub-quest-hunting-4">
                <div class="accordion-body px-0 pb-0">
                  <div class="detail-quest-hunting size-14 text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum auctor urna fermentum commodo risus mattis pellentesque. Dictumst eget.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="wrapper-quest-hunting-reward container">
    <h6 class="text-white size-18 fw-bold mt-4 mb-2 pb-1">{{"REWARD" | translate}}</h6>
    <p class="size-14 text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis fermentum auctor urna fermentum commodo risus mattis pellentesque. Dictumst eget.</p>

    <div class="row my-3">
      <div class="col-12">

        <swiper
          class="swiper-reward-quest"
          [slidesPerView]="'auto'"
          [spaceBetween]="12"
          [pagination]="false"
        >

          <ng-template swiperSlide>
            <div class="slide-wrapper d-flex flex-column">
              <figure class="overflow-hidden rounded-15 text-center">
                <img
                  src="assets/img/cover/mock-image.png"
                  alt=""
                  class="w-100 mx-auto"
                />
              </figure>
              <div class="text-content-reward">
                <h6 class="size-16 text-white text-truncate text-center">
                  {{"NAME_REWARD" | translate}}
                </h6>
              </div>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="slide-wrapper d-flex flex-column">
              <figure class="overflow-hidden rounded-15 text-center">
                <img
                  src="assets/img/cover/mock-image.png"
                  alt=""
                  class="w-100 mx-auto"
                />
              </figure>
              <div class="text-content-reward">
                <h6 class="size-16 text-white text-truncate text-center">
                  {{"NAME_REWARD" | translate}}
                </h6>
              </div>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="slide-wrapper d-flex flex-column">
              <figure class="overflow-hidden rounded-15 text-center">
                <img
                  src="assets/img/cover/mock-image.png"
                  alt=""
                  class="w-100 mx-auto"
                />
              </figure>
              <div class="text-content-reward">
                <h6 class="size-16 text-white text-truncate text-center">
                  {{"NAME_REWARD" | translate}}
                </h6>
              </div>
            </div>
          </ng-template>

        </swiper>
      </div>
    </div>

    <div class="wrapper-reward-btn-actions gap-3 d-flex flex-column pb-5">
      <button class="btn btn-default" type="button" disabled>
        {{"CLAIM_REWARD" | translate}}
      </button>
    </div>

  </div>

</div>
