/* eslint-disable */

import { Router } from '@angular/router';
import {
  Component,
  Inject,
  NgZone,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { FormService } from 'src/app/@services/form.service';
import { Logger } from 'src/app/@services/logger.service';
import { UserService } from 'src/app/@services/user.service';
import { ConfirmPasswordValidator } from 'src/validators/confirm-password.validator';
import { PasswordStrengthValidator } from 'src/validators/password-strength.validators';
import { OtpCheckingComponent } from 'src/app/@components/otp-checking/otp-checking.component';

import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { ModalSignupUserinfoComponent } from 'src/app/@components/modal-signup-userinfo/modal-signup-userinfo.component';
import { QuestionnaireComponent } from 'src/app/@components/questionnaire/questionnaire.component';
import { QrCodeModalComponent } from 'src/app/@components/qr-code-modal/qr-code-modal.component';
import { UserWithToken } from 'src/models/authToken.model';
import { environment } from 'src/environments/environment';

const log = new Logger('SignupComponent');
import { isPlatformBrowser } from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

type GoogleAuthUser = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signUpForm!: UntypedFormGroup;

  isShow: boolean = false;
  isShowRecomfirm: boolean = false;

  error: any = false;
  errorText: any;
  loginLink: Boolean = false;

  public loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate!: TemplateRef<any>;

  loginForm: UntypedFormGroup;
  submitting = false;
  returnUrl: string;
  formInvalid = {
    emailOrPhone: '',
    password: '',
  };

  isSupportBrowser: boolean = false;
  isInAppBrowser: boolean;

  public passwordTextType: boolean = false;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    public formService: FormService,
    public translateService: TranslateService,
    private userService: UserService,
    private router: Router,
    private _authenticationService: AuthenticationService,
    private _errorHandlerService: ErrorHandlerService,
    private _socialAuthService: SocialAuthService,

    private _modalService: NgbModal,
    private _ngZone: NgZone,

    private singletonService: SingletonService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  backnav() {
    this.singletonService.back();
  }

  home() {
    this.router.navigate(['/']);
  }

  ngOnInit(): void {
    this.tooltipSetup();

    this.checkPlatformBrowser();
    var tooltiptriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    tooltiptriggerList.map(function (e) {
      return new bootstrap.Tooltip(e);
    });

    this.signUpForm = this.fb.group(
      {
        username: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            // PasswordStrengthValidator,
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmPasswordValidator('password', 'confirmPassword'),
      }
    );
  }

  ngAfterViewInit() {
    // this.googleSetup();
    try {
      this.googleSetup();
    } catch (error) {
      log.info(`error: googleSetup()`);
      log.debug(error);
    }
  }

  checkPlatformBrowser() {
    this.isInAppBrowser = this.singletonService.isInAppฺBrowser();
    this.isSupportBrowser = this.singletonService.isPlatformBrowser();
  }

  openInSystemBrowser() {
    window.open(this.router.url, '_system');
  }

  showPassword() {
    this.isShow = !this.isShow;
    log.debug(this.isShow);
  }

  showConfirm() {
    this.isShowRecomfirm = !this.isShowRecomfirm;
  }

  async onSubmit() {
    const formValue = this.signUpForm.value;
    if (this.signUpForm.invalid) {
      this.error = true;
      let errText = this.translateService.instant('ERROR_MSG.ERROR_REGISTER');
      this.errorText = errText;
      return;
    }
    let data = {
      username: formValue.username,
      password: formValue.password,
      confirmPassword: formValue.confirmPassword,
    };

    setTimeout(async () => {
      this.loading = true;
      await this.userService.usersignUp(data).then(
        (res: any) => {
          this.loading = false;
          log.debug('datas', res);
          if (res) {
            if (res.registerType === 'mobilePhone') {
              this.modalService.dismissAll();
              const modalRef = this.modalService.open(OtpCheckingComponent, {
                centered: true,
                backdrop: 'static',
              });
              modalRef.componentInstance.registerType = res.registerType;
              modalRef.componentInstance.username = res.username;
              modalRef.componentInstance.password = formValue.password;
            } else if (res.registerType === 'email') {
              this.modalService.dismissAll();
              const modalRef = this.modalService.open(OtpCheckingComponent, {
                centered: true,
                backdrop: 'static',
              });
              modalRef.componentInstance.registerType = res.registerType;
              modalRef.componentInstance.username = res.username;
              modalRef.componentInstance.password = formValue.password;
            } else {
              // do nothing
            }
          }
        },
        (err) => {
          this.loading = false;
          log.debug('err =>', err);
          this.error = true;

          const __error: any = this._errorHandlerService.error(err);
          const _error = __error.message;
          const hasRedirectUrl = __error.redirectUrl;
          log.debug('_error => %o', _error);
          this.errorText = _error;

          if (hasRedirectUrl) {
            this.loginLink = true;
          }
        }
      );
    }, 500);
  }

  goToSignIn() {
    const formValue = this.signUpForm.value;
    this.modalService.dismissAll();
    this.router.navigate(['/signin'], {
      queryParams: {
        transferEmail: formValue.username,
      },
    });
  }

  tooltipSetup() {
    const tooltiptriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltiptriggerList.map(function (e: any) {
      return new bootstrap.Tooltip(e);
    });
  }

  googleSetup() {
    log.debug('--- googleSetup');
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: environment.googleClientID,
      callback: this.signInWithGoogle.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });

    // @ts-ignore
    google.accounts.id.renderButton(document.getElementById('google-button'), {
      theme: 'outline',
      size: 'large',
      shape: 'circle',
      type: 'icon',
    });
  }

  async signInWithGoogle(user: GoogleAuthUser) {
    //   {
    //     "clientId": "912232120931-0hjmaebm41apo2lq6jv8a5hdlu0cnr6b.apps.googleusercontent.com",
    //     "client_id": "912232120931-0hjmaebm41apo2lq6jv8a5hdlu0cnr6b.apps.googleusercontent.com",
    //     "credential": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk4NmVlOWEzYjc1MjBiNDk0ZGY1NGZlMzJlM2U1YzRjYTY4NWM4OWQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NzkzOTc0MTgsImF1ZCI6IjkxMjIzMjEyMDkzMS0waGptYWVibTQxYXBvMmxxNmp2OGE1aGRsdTBjbnI2Yi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTU0NjM0MDY5MjUyMDExNDg0NyIsImVtYWlsIjoiaWtoYWxhcy53b3JrQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhenAiOiI5MTIyMzIxMjA5MzEtMGhqbWFlYm00MWFwbzJscTZqdjhhNWhkbHUwY25yNmIuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJuYW1lIjoiSWtoYWxhcyBNYW5ub29uIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FHTm15eFpHa1RtY2lxZGZ2dlhYOTRDNmdFQjJWcTlJTEw5MXg2UkJ2Uk5aPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IklraGFsYXMiLCJmYW1pbHlfbmFtZSI6Ik1hbm5vb24iLCJpYXQiOjE2NzkzOTc3MTgsImV4cCI6MTY3OTQwMTMxOCwianRpIjoiNDk0OTY4MGIwM2FkNDA2YWMwMWQ3YTEzMWE2ODI5OGUxYjVlZTcxOSJ9.mj4xpYUhIddTyLuYhaKQdFT2VY42P9I0mXL7cXicrJPtlT7vMBwgVvPbzy0s281EmKRBcK4jnWFgo12ZPTqgtN9SP4zr-qExebdTCDd_dRuqjRf1_2gtMr4PzKsv3DtWeuHbHVW-JutxtwvndDlyQMwnQy02eCfuK3XLih2tmsNU2dRawpQBCxKL1igLZEz6ZTn378R2dI2NpunFpzoXdwU_05ZT1BDETyg1QTI0tHFmYo57ogLSgswAz2Mm8qDyQSMtEpj9DSSXIdYRikPdbk1x9bRFNzEjWm45agAJTAUc5jmUaDiUp0Ej5VYRGblothYPj4DMzqJBEChbLvrHBA",
    //     "select_by": "btn"
    // }
    log.debug('--- signInWithGoogle');
    try {
      this.error = '';
      this.submitting = true;
      const bodyPayload = {
        idToken: user.credential,
      };
      const response = await this._authenticationService.googleFetcher(
        bodyPayload
      );
      log.debug('--- googleFetcher :%o', response);
      await this.socailAuthenticationSetup(response);
    } catch (error: any) {
      // const _error = this._errorHandlerService.error(error).message;
      log.debug('_error => %o', error);
      this._errorHandlerService.error(error, true);
      // this.error = _error;
    } finally {
      this.submitting = false;
    }
  }

  async signInWithFacebook() {
    try {
      this.error = '';
      this.submitting = true;
      const user = await this._socialAuthService.signIn(
        FacebookLoginProvider.PROVIDER_ID
      );
      //   {
      //     "id": "3022994027834245",
      //     "name": "Ikhalas Mannoon",
      //     "photoUrl": "https://graph.facebook.com/3022994027834245/picture?type=normal",
      //     "firstName": "Ikhalas",
      //     "lastName": "Mannoon",
      //     "authToken": "EAACvcKZC39EoBAIQWf35IviX5LiZAZBN3nHalDLUdhvTd87jGZALRkHJQ3DHLu7ogGMLUt1tXuBGknNYzrTd6tmpSsBQIZCjYpakmeutUUSPllD4CncGVtbt5lbSYcQdwoy1BHfMOoaYrQhJDhQpVeeNbXZCvD4N3iBqvMOoS8leHW5XMlsMZCdXXsP73x8FYkFuNp9DUXBflpLQf5IWSn1",
      //     "response": {
      //         "name": "Ikhalas Mannoon",
      //         "picture": {
      //             "data": {
      //                 "height": 50,
      //                 "is_silhouette": false,
      //                 "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3022994027834245&height=50&width=50&ext=1681981697&hash=AeRqDgkkI4-l3wuA_wg",
      //                 "width": 50
      //             }
      //         },
      //         "first_name": "Ikhalas",
      //         "last_name": "Mannoon",
      //         "id": "3022994027834245"
      //     },
      //     "provider": "FACEBOOK"
      // }

      const bodyPayload = {
        accessToken: user.authToken,
      };
      const response = await this._authenticationService.facebookFetcher(
        bodyPayload
      );
      this.socailAuthenticationSetup(response);
    } catch (error: any) {
      const _error = this._errorHandlerService.error(error).message;
      log.debug('_error => %o', _error);
      this.error = _error;
    } finally {
      this.submitting = false;
    }
  }

  async socailAuthenticationSetup(userWithToken: UserWithToken) {
    try {
      log.debug('socailAuthenticationSetup userWithToken => %o', userWithToken);
      await this._authenticationService.doAfterLogin(userWithToken);
      const {
        user: { profile },
      } = userWithToken;

      const servayStatus =
        await this._authenticationService.checkSurveyStatus();

      if (!profile.isAgreement || !profile.isPrivacyPolicy) {
        this._ngZone.run(() => {
          const modalRef = this._modalService.open(
            ModalSignupUserinfoComponent,
            {
              centered: true,
              size: 'xl',
              scrollable: true,
            }
          );
          modalRef.componentInstance.userInfo = userWithToken.user;
        });
        return;
      }

      this._modalService.dismissAll();
      if (!servayStatus.results.length) {
        log.debug('xx GO TO SURVAY MODAL');
        this._ngZone.run(() => {
          this.modalService.open(QuestionnaireComponent, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
            scrollable: true,
          });
        });
        log.debug('yyyyy');
      } else {
        log.debug('xxx GO TO QRCODE MODAL');
        this._ngZone.run(() => {
          this.modalService.open(QrCodeModalComponent, {
            centered: true,
            backdrop: 'static',
            scrollable: true,
          });
        });
      }
    } catch (error: any) {
      log.debug('socailAuthenticationSetup error: %o', error);
      const _error = this._errorHandlerService.error(error).message;
      log.debug('_error => %o', _error);
      this.error = _error;
    }
  }
}
