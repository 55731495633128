<style>
  .wait-for-graph {
    height: calc(100vh - 100px);
    overflow: hidden;
  }
</style>
<div
  class="wrapper-content wrapper-100vw"
  [class.wait-for-graph]="!setupChartDone"
>
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-24 text-white fw-bold">{{
            "WALLET" | translate
          }}</span>
        </h2>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="text-center">
      <div class="wrapper-chart-canvas">
        <div>
          <canvas id="lineChart"></canvas>
        </div>
        <div class="text-chart-canvas">
          <h3 class="text-center" *ngIf="!loading">
            <img
              [src]="
                defaultToken.token.logo || 'assets/img/dashboard/coin-53x53.png'
              "
              width="46"
              height="46"
              alt="coin"
            />
            <span class="text-white size-36">{{
              defaultToken.balance | numeral : "0,0"
            }}</span>
          </h3>
          <h6 class="size-16 text-center fw-normal mt-3">
            {{ "TOTAL_POINT_BALANCE" | translate }}
          </h6>
        </div>
      </div>
    </div>
    <div class="d-flex gap-5 justify-content-center align-items-center my-4">
      <div class="d-flex align-items-center gap-2">
        <div class="wrapper-arrow-box">
          <i class="bi bi-arrow-down-left size-24"></i>
        </div>
        <div>
          <div class="size-12 text-white">{{ "INCOME" | translate }}</div>
          <div class="fw-bold size-14 text-white" *ngIf="!loading">
            {{ incomeAndExpense.income | numeral : "0,0" }}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center gap-2">
        <div class="wrapper-arrow-box">
          <i class="bi bi-arrow-up-right size-24"></i>
        </div>
        <div>
          <div class="size-12 text-white">{{ "EXPENSE" | translate }}</div>
          <div class="fw-bold size-14 text-white" *ngIf="!loading">
            {{ incomeAndExpense.expense | numeral : "0,0" }}
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-actions d-flex justify-content-around gap-3">
      <a class="box-action" (click)="openSendModal()">
        <div class="box-img d-flex align-item-center">
          <img src="assets/img/dashboard/send.png" alt="send img" />
          <p class="ms-2 text-red size-16 mt-0">{{ "SEND" | translate }}</p>
        </div>
      </a>
      <a class="box-action" (click)="openReceiveModal()">
        <div class="box-img d-flex align-item-center">
          <img src="assets/img/dashboard/recive.png" alt="recive img" />
          <p class="ms-2 text-green size-16 mt-0">
            {{ "RECEIVE" | translate }}
          </p>
        </div>
      </a>
      <!-- <a class="box-action">
        <div class="box-img">
          <img src="assets/img/dashboard/my-qr.png" alt="my qr img" />
        </div>
        <p>My QR</p>
      </a>
      <a class="box-action">
        <div class="box-img">
          <img src="assets/img/dashboard/scan.png" alt="scan img" />
        </div>
        <p>Scan</p>
      </a> -->
    </div>

    <ul class="nav nav-tabs mt-4" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <ng-container
          *ngIf="acticeTab !== 'ASSETS'; then tabButton; else tabSelect"
        >
        </ng-container>
        <ng-template #tabButton>
          <div class="d-flex align-items-center me-1">
            <button
              class="nav-link pe-2"
              id="assets-tab"
              data-bs-toggle="tab"
              data-bs-target="#assets-tab-pane"
              type="button"
              role="tab"
              aria-controls="assets-tab-pane"
              aria-selected="true"
              (click)="setActiveTab('ASSETS')"
            >
              {{ selectedAsset }}
              <i class="bi bi-chevron-down text-white size-12 lh-sm"></i>
            </button>
          </div>
        </ng-template>
        <ng-template #tabSelect>
          <select
            class="form-select form-select-assets"
            (change)="onSelectAsset($event)"
            [value]="selectedAsset"
          >
            <option value="TOKENS">{{ "TOKENS" | translate }}</option>
            <option value="NFTS">NFTS</option>
          </select></ng-template
        >
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="history-tab"
          data-bs-toggle="tab"
          data-bs-target="#history-tab-pane"
          type="button"
          role="tab"
          aria-controls="history-tab-pane"
          aria-selected="false"
          (click)="setActiveTab('HISTORY')"
        >
          {{ "HISTORY_WALLET" | translate }}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="tabs-content">
      <div
        class="tab-pane fade show active"
        id="assets-tab-pane"
        role="tabpanel"
        aria-labelledby="assets-tab"
        tabindex="0"
      >
        <ng-container [ngSwitch]="selectedAsset">
          <ng-container *ngSwitchCase="'TOKENS'">
            <ng-container *ngFor="let token of allToken?.results">
              <div
                class="row-assets my-4 d-flex align-items-center justify-content-between"
                (click)="openAssetsDetailModal(token)"
              >
                <div class="d-flex align-item-center gap-2">
                  <img
                    [src]="
                      token?.token?.logo ||
                      'assets/img/dashboard/coin-53x53.png'
                    "
                    alt="coin-53x53"
                    width="53"
                    height="53"
                  />
                  <div class="d-flex flex-column justify-content-center">
                    <h4 class="size-16 text-white mb-1">
                      {{ token?.token?.name }}
                    </h4>
                    <p class="size-14 text-secondary" *ngIf="!loading">
                      Total: {{ token.balance | numeral : "0,0" }}
                      {{ token?.token?.symbol }}
                    </p>
                  </div>
                </div>
                <div>
                  <i class="bi bi-chevron-right text-secondary size-14"></i>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'NFTS'">
            <div
              infinite-scroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="500"
              (scrolled)="onNftsScrollEnd()"
            >
              <ng-container *ngFor="let nftPayload of allNfts">
                <div
                  class="row-assets my-4 d-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-item-center gap-2">
                    <img
                      [src]="
                        nftPayload?.nft?.logo ||
                        'assets/img/dashboard/coin-53x53.png'
                      "
                      alt="coin-53x53"
                      width="53"
                      height="53"
                    />
                    <div class="d-flex flex-column justify-content-center">
                      <h4 class="size-16 text-white mb-1">
                        {{ nftPayload?.nft?.name }}
                      </h4>
                      <p class="size-14 text-secondary" *ngIf="!loading">
                        Total:
                        {{
                          nftPayload?.amount - nftPayload?.usedCount
                            | numeral : "0,0"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div *ngIf="!allNfts || !allNfts.length">
                <div class="my-5 py-3">
                  <div class="card bg-transparent">
                    <div class="card-body p-0">
                      <div class="empty-wrapper text-center">
                        <img
                          src="assets/img/empty/empty-nft.png"
                          alt="empty nft icon"
                        />
                        <p class="text-center mt-2 fw-normal size-14">
                          {{"YOU_HAVENT_ANY_TFT_NOW" | translate}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault> </ng-container>
        </ng-container>
      </div>
      <div
        class="tab-pane fade"
        id="history-tab-pane"
        role="tabpanel"
        aria-labelledby="history-tab"
        tabindex="0"
      >
        <div
          infinite-scroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onTransactionsScrollEnd()"
        >
          <ng-container *ngIf="allTransections && allTransections.length">
            <div *ngFor="let transection of allTransections">
              <div class="row-assets my-4 d-flex align-items-center">
                <div class="d-flex align-item-center w-100 gap-3">
                  <div class="img-row">
                    <div
                      *ngIf="
                        currentUser.id === transection.to;
                        then content;
                        else other_content
                      "
                    ></div>
                    <ng-template #content>
                      <i class="bi bi-arrow-down-left size-28"></i>
                    </ng-template>
                    <ng-template #other_content>
                      <i class="bi bi-arrow-up-right size-28"></i>
                    </ng-template>
                  </div>
                  <div class="d-flex flex-column justify-content-center w-100">
                    <h4
                      class="size-16 text-white mb-1 d-flex align-item-center justify-content-between"
                    >
                      <span>{{ transection?.ref?.name }}</span>
                      <span
                        >{{ currentUser.id === transection.to ? "+" : "-" }}
                        {{ transection.amount | numeral : "0,0" }}
                        <small>{{ transection?.ref?.symbol }}</small></span
                      >
                    </h4>
                    <p class="size-12 mb-0 text-date">
                      {{
                        transection.transactionDate | date : "dd MMMM yy HH:mm"
                      }}
                    </p>
                    <p class="size-14 mb-0 text-secondary">
                      {{ transection.status }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngIf="!allTransections || !allTransections.length">
            <div class="my-5 py-3">
              <div class="card bg-transparent">
                <div class="card-body p-0">
                  <div class="empty-wrapper text-center">
                    <img
                      src="assets/img/empty/empty-transaction.png"
                      alt="empty transaction icon"
                    />
                    <p class="text-center mt-2 fw-normal size-14">
                      {{ "YOU_HAVENT_ANY_TRANSACTION_NOW" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
