import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import {
  TransectionResult,
  TransectionType,
} from 'src/models/tokenTransection.model';
import { User } from 'src/models/user.model';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

@Component({
  selector: 'app-pointhistory',
  templateUrl: './pointhistory.component.html',
  styleUrls: ['./pointhistory.component.scss'],
})
export class PointhistoryComponent {
  tokenId: string;
  historyLoading = true;
  historyLoadMore = false;
  transections: TransectionResult[];
  error: '';
  currentUser: User;
  page = 1;

  constructor(
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _backendService: BackendService,
    private _errorService: ErrorHandlerService,
    private _router: Router,
    private _location: Location,
    public singletonService: SingletonService,
  ) {}

  async ngOnInit() {
    this.tokenId = this._route.snapshot.paramMap.get('tokenId')!;
    this.currentUser = this._authenticationService.currentUserSubject.value;
    this.historyLoading = true;
    const transection = await this.getAllPointTransactions(this.page);
    this.transections = transection?.results || [];
    this.historyLoading = false;
  }

  async getAllPointTransactions(page: number) {
    try {
      const transection = (await this._backendService.getAllPointTransactions({
        token: this.tokenId,
        limit: 10,
        page,
      })) as TransectionType;
      return transection;
    } catch (err) {
      const error = this._errorService.error(err);
      this.error = error.message;
      return {} as TransectionType;
    }
  }

  async onScrollEnd() {
    this.page++;
    this.historyLoadMore = true;
    const transection = await this.getAllPointTransactions(this.page);
    this.transections = [...this.transections, ...transection?.results];
    this.historyLoadMore = false;
  }

  backnav() {
    this.singletonService.back();
  }
}
