/* eslint-disable */

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, Input, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPasswordValidator } from 'src/validators/confirm-password.validator';
import { PasswordStrengthValidator } from 'src/validators/password-strength.validators';

import Swal from 'sweetalert2';
const log = new Logger('ForgotPasswordConfirmComponent');

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Logger } from 'src/app/@services/logger.service';
import { UserService } from 'src/app/@services/user.service';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { FormService } from 'src/app/@services/form.service';
import { TokenStorageService } from 'src/app/@services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';

@Component({
  selector: 'app-forgot-password-confirm',
  templateUrl: './forgot-password-confirm.component.html',
  styleUrls: ['./forgot-password-confirm.component.scss']
})
export class ForgotPasswordConfirmComponent {

  @Input() username:any
  @Input() registerType:any // mobilePhone | email
  @Input() otp:any
  /*
    "otp" : {
        "data": {
            "status": "success",
            "token": "o8drxnjLM1bv7Wzcbi1ZAPKXD6kWJRla"
        }
    }
  */

  // @Input() code:any
  forgotPasswordForm:any;
  sending:Boolean = false;
  error:Boolean = false
  errorText:any
  isShow: boolean = false
  isShowRecomfirm: boolean = false

  constructor(
    private modalService : NgbModal,
    private fb :FormBuilder,
    public modal: NgbActiveModal,
    public userService: UserService,
    private _authentication: AuthenticationService,
    public formService: FormService,
    private authService: SocialAuthService,
    private _storageService: TokenStorageService,
    private _userService: UserService,
    private router: Router,
    private translateService: TranslateService,
    private _errorHandlerService: ErrorHandlerService,
  ) {
  }

  ngOnInit() {
    log.debug('this.username =>',this.username)
    log.debug('this.registerType =>',this.registerType)
    log.debug('this.otp =>',this.otp)

    this.forgotPasswordForm = this.fb.group({
      code: ['',[Validators.required]],
      password: ['',
        [
          Validators.required,
          Validators.minLength(8),
          // PasswordStrengthValidator
        ]
      ],
      confirmPassword: ['',[Validators.required]]
    },
    {
      validator: ConfirmPasswordValidator("password", "confirmPassword")
    });
  }

  dismiss(){
    this.modalService.dismissAll()
  }

  async onSubmit() {

    const formValue = this.forgotPasswordForm.value
    if(this.forgotPasswordForm.invalid){
      // log.debug('error send form',this.formData.invalid)
      this.error = true;
      this.errorText = this.translateService.instant('ERROR_MSG.ERROR_FORGOT_PASSWORD')
      return
    }

    log.debug('this.registerType =>',this.registerType)

    if(this.registerType == 'email'){
      log.debug('forgotPasswordConfirmWithEmail...');

      let data = {
        email: this.username,
        code: formValue.code,
        password: formValue.password,
      }

      await this.userService.forgotPasswordConfirmWithEmail(data).then((res:any) => {
        log.debug('forgotPasswordConfirmWithEmail res',res);
        if(res){
            this.modalService.dismissAll()
            // const modalRef = this.modalService.open(ForgotPasswordConfirmComponent, {centered:true,backdrop:'static'})
            // modalRef.componentInstance.email = res.email

            Swal.fire({
              title: this.translateService.instant('SUCCESS'),
              icon: 'success',
              text: this.translateService.instant('YOUR_PASSWORD_HAS_BEEN_CHANGED'),
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonColor: '#4B0082',
              confirmButtonText: this.translateService.instant('OK'),
              customClass: "popup-success-custom",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // this.modalService.dismissAll()
                window.location.reload()
                // this.router.navigate(['/'])
              }
            });

        }

      },(err) => {
        this.error = true;

        const _error = this._errorHandlerService.error(err).message;
        log.debug("_error => %o",_error)
        this.errorText = _error

      })
    }else{
      log.debug('forgotPasswordConfirmWithMobilePhone...');
      let data = {
        mobilePhone: this.username,
        otpToken: this.otp.data.token,
        otpCode: formValue.code,
        password: formValue.password,
      }

      const _res = await this.userService.forgotPasswordConfirmWithMobilePhone(data).then((res:any) => {
        log.debug('forgotPasswordConfirmWithMobilePhone res',res);
        if(res){
            this.modalService.dismissAll()
            // const modalRef = this.modalService.open(ForgotPasswordConfirmComponent, {centered:true,backdrop:'static'})
            // modalRef.componentInstance.email = res.email

            Swal.fire({
              title: this.translateService.instant('SUCCESS'),
              icon: 'success',
              text: this.translateService.instant('YOUR_PASSWORD_HAS_BEEN_CHANGED'),
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonColor: '#4B0082',
              confirmButtonText: this.translateService.instant('OK'),
              customClass: "popup-success-custom",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // this.modalService.dismissAll()
                window.location.reload()
                // this.router.navigate(['/'])
              }
            });

        }
      },(err) => {
        this.error = true;

        const _error = this._errorHandlerService.error(err).message;
        log.debug("_error => %o",_error)
        this.errorText = _error

      })

      log.debug('_res forgotPasswordConfirmWithMobilePhone => %o',_res)

    }
  }

  showPassword(){
    this.isShow = !this.isShow
    log.debug(this.isShow);

  }

  showConfirm(){
    this.isShowRecomfirm = !this.isShowRecomfirm
  }
}
