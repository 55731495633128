/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { ModalVerifyPincodeComponent } from 'src/app/@components/modal-verify-pincode/modal-verify-pincode.component';
import { Subject } from 'rxjs';
import { Logger } from 'src/app/@services/logger.service';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import Swal from 'sweetalert2';
import {Location} from '@angular/common';
import { SingletonService } from 'src/app/@services/singleton.service';

const log = new Logger('SettingComponent');
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  currentLanguageDisplay:any
  userId:any;
  user:any;
  pincode = '';
  error = '';
  checking = false;
  clearPincode: Subject<void> = new Subject();
  sendForm: FormGroup;
  pointBalanceError = false;
  submitting = false;
  isLoading: boolean;
  modal: any;
  constructor(
    private _router:Router,
    private _translateConfigService:TranslateConfigService,
    private modalService: NgbModal,
    private backendService: BackendService,
    private errorService: ErrorHandlerService,
    private _authenticationService: AuthenticationService,
    private _location: Location,
    public singletonService: SingletonService,
  ) { }

  async ngOnInit() {
    const currentLanguage = await this._translateConfigService.getCurrentLanguage()!;
    switch (currentLanguage) {
      case 'en':
        this.currentLanguageDisplay = 'English'
        break;
      case 'th':
        this.currentLanguageDisplay = 'ไทย'
        break;
      default:
        this.currentLanguageDisplay = 'English'
        break;
    }
  }

  backnav() {
    this.singletonService.back();
  }
  async onSubmit() {
    try {
      const verifyRef = this.modalService.open(ModalVerifyPincodeComponent, {
        centered: true,
        backdrop: 'static',
      });
      const result = await verifyRef.result;
      if (!result) return;
      log.debug(`result %o`,result);
      this.getUserInfoToDeleteAccount();
      Swal.fire('Done!', '', 'success');
      await this.signOut()

      this.submitting = true;
      this.pointBalanceError = false;
      this.modal.close('success');
    } catch (err) {
      const error = this.errorService.error(err);
      this.error = error.message;
    } finally {
      this.submitting = false;
    }
  }
  async getUserInfoToDeleteAccount(){
    this.isLoading = true

    try{
      const getUserInfoToDeleteAccountRes:any = await this.backendService.getUserInfoToDeleteAccount(this.userId)
      log.debug("res getUserInfoToDeleteAccountRes : %o",getUserInfoToDeleteAccountRes)
      if(getUserInfoToDeleteAccountRes){
        this.user = getUserInfoToDeleteAccountRes.results
        log.debug("this.user(user ทั้งหมด): %o",this.user)
        this.isLoading = false
      }else{
        this.isLoading = false
      }
    }catch(error){
      log.debug("error: %o",error)
      this.isLoading = false
    }
  }
  signOut() {
    this._authenticationService.logout();
    this._router.navigate(['/']);
  }
  openConfirmDeleteID(){
    Swal.fire({
      title: "Confirm to delete account?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Confirm',
      denyButtonText: `Cancel`,
      customClass: 'popup-confirm-custom popup-content-custom',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.onSubmit();
      }

    })
  }
  //confirmDelete(){
    //Swal.fire('Done');
  //}
}
