<div class="modal-header">
  <h3 class="size-20">
    {{"SOCIAL_SHARING" | translate}}
  </h3>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <!-- [include]="['copy', 'facebook', 'email', 'messenger', 'mix', 'line', 'linkedin', 'pinterest', 'print', 'reddit', 'sms', 'telegram', 'tumblr', 'twitter', 'viber', 'vk', 'xing', 'whatsapp']" -->
    <share-buttons theme="modern-dark"
    [include]="['copy', 'facebook', 'email', 'line', 'twitter']"
    [showIcon]="true"
    [showText]="true"
    [url]="url">
    </share-buttons>
</div>
