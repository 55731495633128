<!-- <div class="row pt-3">
  <div class="col-3 align-self-center">
    <button
      type="button"
      class="btn text-white btn-44 back-btn"
      (click)="backnav()"
    >
      <i class="bi bi-arrow-left"></i>
    </button>
  </div>
  <div class="col align-self-center text-center px-0">
    <h6 class="title size-16">เกี่ยวกับเรา</h6>
  </div>
  <div class="col-3 align-self-center"></div>
</div> -->

<div class="wrapper-100vw">
  <div class="wrapper-top-header mb-0 border-0">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{"ABOUT_US" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="row-cover">
    <div class="card mb-4 overflow-hidden card-theme rounded-0 card-cover-banner">
      <div class="coverimg h-100 w-100" style="background-image: url(assets/img/cover/home-cover.png)"></div>
    </div>
  </div>

  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center">
    <div class="pb-5">
      <div>
        <h6 class="mb-2 size-15 font-weight-700">{{"ABOUT_US" | translate}}</h6>
        <p class="text-white-50">
          {{"ABOUT_US_PAGE.P1" | translate}}
        </p>
      </div>

      <div class="position-relative">
        <div class="row mt-3">
          <div class="col-12">
            <figure class="overflow-hidden rounded-15 text-center">
              <img
                src="https://0110techland.co/assets/images/landing/gallery/IMG_Press_Conference1.jpg"
                alt=""
                class="w-100 mx-auto"
              />
            </figure>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 mb-3">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    src="https://0110techland.co/assets/images/landing/gallery/IMG_Press_Conference3.jpg"
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
              </div>
              <div class="col-6">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    src="https://0110techland.co/assets/images/landing/gallery/IMG_Press_Conference27.jpg"
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="text-white-50">
        {{"ABOUT_US_PAGE.P2" | translate}}
      </p>
      <div class="col-12 mt-5">
        <div class="row">
          <div class="col-6 mb-3">
            <figure class="overflow-hidden rounded-15 text-center">
              <img
                src="https://0110techland.co/assets/images/landing/gallery/IMG_Press_Conference11.jpg"
                alt=""
                class="w-100 mx-auto"
              />
            </figure>
          </div>
          <div class="col-6">
            <figure class="overflow-hidden rounded-15 text-center">
              <img
                src="https://0110techland.co/assets/images/landing/gallery/IMG_Press_Conference16.jpg"
                alt=""
                class="w-100 mx-auto"
              />
            </figure>
          </div>
        </div>
      </div>
      <p class="text-white-50">
        {{"ABOUT_US_PAGE.P3" | translate}}
      </p>
      <div class="col-12 mt-5">
        <div class="row">
          <div class="col-6 mb-3">
            <figure class="overflow-hidden rounded-15 text-center">
              <img
                src="https://0110techland.co/assets/images/landing/gallery/IMG_Press_Conference17.jpg"
                alt=""
                class="w-100 mx-auto"
              />
            </figure>
          </div>
          <div class="col-6">
            <figure class="overflow-hidden rounded-15 text-center">
              <img
                src="https://0110techland.co/assets/images/landing/gallery/IMG_Press_Conference22.jpg"
                alt=""
                class="w-100 mx-auto"
              />
            </figure>
          </div>
        </div>
      </div>
      <p class="text-white-50">
        {{"ABOUT_US_PAGE.P4" | translate}}
      </p>
    </div>
  </div>

</div>

