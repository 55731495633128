<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button
              class="btn btn-sm btn-arrow-back ps-0"
              type="button"
              (click)="backnav()"
            >
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{ "TERMS_AND_CONDITIONS" | translate }}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div
    class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center"
  >
    <div class="pb-4">
      <h6 class="title size-15 mb-2">
        {{ "TERMS_AND_CONDITIONS" | translate }}
      </h6>
      <p class="text-secondary mb-3">
        Welcome to our website, “TEP X OIIO” ASIA TECHLAND 2023 (“the Site”). By
        using our Site, you agree to these terms and conditions (“Terms”) set
        forth herein. If you do not agree to these Terms, please do not use the
        Site.
      </p>

      <ol class="text-secondary">
        <li class="mb-3">
          <b>Registration</b> By registering for our event, you agree to provide
          accurate and complete information about yourself, including but not
          limited to your name, email address, and mobile number. You are solely
          responsible for maintaining the confidentiality of your account and
          password and for any activity that occurs under your account.
        </li>
        <li class="mb-3">
          <b>Code of Conduct</b> We expect all attendees to behave in a
          professional and respectful manner during our event. Any form of
          harassment or discrimination will not be tolerated and may result in
          removal from the event.
        </li>
        <li class="mb-3">
          <b>Intellectual Property</b> All content on our Site, including but
          not limited to text, graphics, logos, images, and software, is the
          property of [Event Registration Site Name] or its licensors and is
          protected by intellectual property laws. You may not use, reproduce,
          modify, or distribute any content from our Site without our prior
          written consent.
        </li>
        <li class="mb-3">
          <b>Disclaimer of Warranties</b> We do not warrant that the Site will
          be uninterrupted or error-free, that defects will be corrected, or
          that the Site or the server that makes it available are free of
          viruses or other harmful components. We do not make any warranties or
          representations regarding the use of the materials on this Site in
          terms of their correctness, accuracy, adequacy, usefulness,
          timeliness, reliability or otherwise.
        </li>
        <li class="mb-3">
          <b>Limitation of Liability</b> We shall not be liable for any direct,
          indirect, incidental, special, consequential, or exemplary damages
          arising from or relating to the use of the Site, including but not
          limited to loss of revenue or profits, loss of data, or other
          intangible losses.
        </li>
      </ol>

      <p class="text-secondary">
        If you have any questions or concerns about our Terms and Conditions or
        Privacy Policy, please contact us at: <b>linxcoperate@gmail.com</b>
      </p>
    </div>
  </div>
</div>
