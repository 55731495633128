import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BackendService } from 'src/app/@services/backend.service';
import { ErrorHandlerService } from 'src/app/@services/error-handler.service';
import { SingletonService } from 'src/app/@services/singleton.service';
import { TranslateConfigService } from 'src/app/@services/translate-config.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-newreward',
  templateUrl: './modal-newreward.component.html',
  styleUrls: ['./modal-newreward.component.scss'],
})
export class ModalNewrewardComponent implements OnInit {
  @Input() focusCoupon: any;
  submiting = false;

  constructor(
    public modal: NgbActiveModal,
    public singletonService: SingletonService,
    private translateService: TranslateService,
    private _errorHandlerService: ErrorHandlerService,
    private _backendService: BackendService,
    private router: Router,
    public translateConfigService: TranslateConfigService
  ) {}

  ngOnInit() {
    // console.log('focusCoupon');
    // console.log(this.focusCoupon);
  }

  async onClickMarkCouponAsUsed() {
    Swal.fire({
      title: this.translateService.instant('MARK_AS_USED'),
      text: this.translateService.instant('CONFIRM_MARK_AS_USED'),
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonColor: '#4B0082',
      confirmButtonText: this.translateService.instant('OK'),
      cancelButtonText: this.translateService.instant('CANCEL'),
      customClass: 'popup-confirm-custom popup-content-custom',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.markCouponAsUsed();
        this.modal.dismiss('mark as used');
      }
    });
  }

  async markCouponAsUsed() {
    try {
      this.submiting = true;
      await this._backendService.markCouponAsUsed(this.focusCoupon.id);
    } catch (error) {
      this._errorHandlerService.error(error, true);
    } finally {
      this.submiting = false;
    }
  }

  handleNavigareAction(route: string) {
    if (route) {
      this.router.navigate([route]);
    }
  }
}
