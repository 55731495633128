<div class="wrapper-100vw">
  <div class="" *ngIf="exhibitor">
    <div class="col-12 px-0">
      <div
        class="card mb-4 overflow-hidden card-theme text-white rounded-0"
      >
        <div class="overlay-5"></div>
        <div
          class="coverimg h-100 w-100 position-absolute opacity-3"
          [style]=" 'background-image: url(' + (exhibitor.bannerImage ? exhibitor.bannerImage : 'assets/img/cover/home-cover.png') +')'"
        >
          <img
            [src]="(exhibitor.bannerImage ? exhibitor.bannerImage : 'assets/img/cover/home-cover.png')"
            alt=""
            style="display: none"
          />
        </div>
        <div class="card-body card-body-height-custom d-flex flex-column">
          <div class="row mb-5">
            <div class="col">
              <button
                type="button"
                class="btn text-white btn-44 back-btn"
                (click)="backnav()"
              >
                <i class="bi bi-chevron-left size-20"></i>
              </button>
            </div>
            <div class="col-auto">
              <!-- <button
                (click)="openModalExhibitorBrochure()"
                class="btn text-white avatar avatar-36 p-0"
              >
                <i class="bi bi-file-arrow-down"></i>
              </button>
              <button
                (click)="doFlowBookmark()"
                class="btn btn-success text-white avatar avatar-36 p-0"
              >
                <i class="bi" [ngClass]="isBookmarked ? 'bi-bookmark-fill' : 'bi-bookmark'"></i>
              </button>
              <button
                (click)="openModalSocialSharing()"
                class="btn btn-info text-white avatar avatar-36 p-0"
              >
                <i class="bi bi-share"></i>
              </button> -->
            </div>
          </div>
          <div class="mt-auto mb-4">
            <h4 class="mb-1 w-100 text-white font-weight-700 text-2-line size-24" style="max-height: 54px;">{{exhibitor.name}}</h4>
            <div class="category-wrapper my-2 d-flex align-items-center">
              <span class="category-badge">{{exhibitor.category.title}}</span>
            </div>
            <p class="text-2-line size-16" style="max-height: 40px;">
              {{exhibitor.description}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper-detail-event container px-0" *ngIf="exhibitor">
    <div class="d-flex justify-content-center gap-4 border-bottom wrapper-actions-btn">
        <div class="text-center">
          <button
            (click)="openModalExhibitorBrochure()"
            class="btn text-white avatar avatar-36 p-0">
            <!-- <i class="bi bi-file-arrow-down"></i> -->
            <img src="assets/img/icon/icon-actions/brochure-pink.png" alt="brochure icon">
          </button>
          <p class="mt-2 text-secondary size-14">{{"BROCHURE" | translate}}</p>
        </div>

        <div class="text-center">
            <button
              (click)="doFlowBookmark()"
              class="btn text-white avatar avatar-36 p-0">
              <i class="bi size-18" [ngClass]="isBookmarked ? 'bi-bookmark-fill' : 'bi-bookmark'"></i>
            </button>
          <p class="mt-2 text-secondary size-14">{{"NOTEMASK" | translate}}</p>
        </div>

        <div class="text-center">
          <button
            (click)="singletonService.openModalSocialSharing()"
            class="btn text-white avatar avatar-36 p-0">
            <!-- <i class="bi bi-share"></i> -->
            <img src="assets/img/icon/icon-actions/share-pink.png" alt="share icon">
          </button>
          <p class="mt-2 text-secondary size-14">{{"SHARE" | translate}}</p>
        </div>
    </div>

    <mat-tab-group dynamicHeight>

      <mat-tab>
        <ng-template mat-tab-label>
          <p class="size-14">{{"ABOUT" | translate}}</p>
        </ng-template>

        <div>
          <div>
            <h5 class="size-20 text-white mt-4 mb-3">
              {{exhibitor.name}}
              <p class="text-secondary size-14 mt-1 fw-normal">{{exhibitor.category.title}}</p>
            </h5>
            <p class="size-16 mt-1 text-white">
              {{exhibitor.description}}
            </p>
          </div>

          <div class="position-relative" *ngIf="exhibitor.images && exhibitor.images.length" (click)="singletonService.openGallery(exhibitor.images)">
            <div class="row mt-3">
              <div class="col-12" *ngIf="exhibitor.images[0]">
                <figure class="overflow-hidden rounded-15 text-center">
                  <img
                    [src]="exhibitor.images[0]"
                    alt=""
                    class="w-100 mx-auto"
                  />
                </figure>
              </div>
              <div class="col-12" *ngIf="exhibitor.images.length >= 2">
                <div class="row">
                  <div class="col-6 mb-3" *ngIf="exhibitor.images[1]">
                    <figure class="overflow-hidden rounded-15 text-center">
                      <img
                        [src]="exhibitor.images[1]"
                        alt=""
                        class="w-100 mx-auto"
                      />
                    </figure>
                  </div>
                  <div class="col-6" *ngIf="exhibitor.images[2]">
                    <figure class="overflow-hidden rounded-15 text-center">
                      <img
                        [src]="exhibitor.images[2]"
                        alt=""
                        class="w-100 mx-auto"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="exhibitor.images.length > 3"
              class="more-image-container d-flex align-items-center px-2 end-0">
              <i class="bi bi-image me-1"></i>
              <p class="size-12">+{{exhibitor.images.length - 3}}</p>
            </div>
          </div>

          <!-- <div class="text-center wrapper-qr-code">
            <qrcode
            [qrdata]="customCurrentPageUrl"
            [width]="162" [errorCorrectionLevel]="'M'"
            [margin]="2"
            [colorDark]="'#ffffff'"
            [colorLight]="'#00000000'"
            ></qrcode>
          </div> -->



        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <p class="size-14">{{"COMMENT" | translate}}</p>
        </ng-template>
        <div>
          <div *ngIf="currentUser" class="card card-comment my-4">
            <div class="card-body py-4 px-3 text-center">
              <!-- <figure class="avatar avatar-60 rounded-15">
                <img [src]="currentUser.profile ? currentUser.profile.picture : 'assets/img/user2.jpg'" alt="" />
              </figure> -->

              <h4 class="mb-3 size-16">{{"ADD_RATING_AND_COMMENT" | translate}}</h4>

              <span class="size-24" (click)="openModalReview()">
                <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
                <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
                <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
                <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
                <i class="bi text-warning mx-1" [ngClass]="false ? 'bi-star-fill' : 'bi-star'"></i>
              </span>

              <!-- <div class="mt-3">
                <div class="wrapper-write-comment d-flex align-items-center gap-1">
                  <input type="text" class="form-control" placeholder="Write comment">
                  <label class="btn btn-upload-img" for="btn-upload-img">
                    <input type="file" hidden id="btn-upload-img">
                    <img
                      src="assets/img/icon/upload-img.png"
                      alt=" upload image icon"
                      class=""
                    />
                  </label>
                </div>
                <div class="preview-uplaod-img my-3">
                  <div class="show-img-preview">
                    <div class="wrapper-img-preview">
                      <img src="assets/img/cover/mock-image.png" alt="mock img">
                    </div>
                    <div class="wrapper-img-preview">
                      <img src="assets/img/cover/mock-image.png" alt="mock img">
                    </div>
                    <div class="wrapper-img-preview">
                      <img src="assets/img/cover/mock-image.png" alt="mock img">
                    </div>
                    <div class="wrapper-img-preview">
                      <img src="assets/img/cover/mock-image.png" alt="mock img">
                    </div>
                    <div class="more-image-container d-flex align-items-center px-2">
                      <i class="bi bi-image me-1"></i>
                      <p class="size-12">+2</p>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button class="btn btn-default size-16" disabled type="button">{{"SEND" | translate}}</button>
                </div>
              </div> -->

            </div>
          </div>

          <div>
            <card-exhibitor-review
              *ngFor="let feedback of exhibitor.feedbacks"
              [feedback]="feedback">
            </card-exhibitor-review>
          </div>

          <div class="my-2 py-2 text-center border-top border-bottom">
            <a class="me-2 text-muted size-14" [routerLink]="'/exhibitorreview/' + exhibitorId"
              >
              {{"ALL" | translate}} {{"REVIEW" | translate}} ({{exhibitor.feedbacks.length}} Reviews)
              <i class="bi bi-chevron-right"></i
            ></a>
          </div>

        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <p class="size-14">{{"SIMILAR" | translate}}</p>
        </ng-template>
        <div class="my-4">
          <!-- get data take here -->
          <card-exhibitor
            *ngFor="let suggestExhibitor of suggestExhibitorList"
            (click)="jumpToExhibitorDetail(suggestExhibitor)"
            [exhibitor]="suggestExhibitor"
            class="exhibitor-item"
          ></card-exhibitor>

          <!-- empty data -->
          <div *ngIf="!suggestExhibitorList.length">
            <div class="text-center">
                <div class="my-5 py-3">
                  <div class="card bg-transparent">
                    <div class="card-body p-0">
                      <div class="empty-wrapper text-center">
                        <img src="assets/img/empty/empty-brochure.png" alt="empty brochure">
                        <p class="text-center mt-2 fw-normal size-14">
                          {{"NO_RESULT" | translate}}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- end empty data -->
        </div>
      </mat-tab>

    </mat-tab-group>

  </div>

  <!-- Suggestion Exhibitor -->
  <!-- <div *ngIf="suggestExhibitorList.length">
    <div class="mt-2 mb-1">
      <h6 class="title size-16">{{"SUGGEST_EXHIBITOR" | translate}}</h6>
    </div>
    <div class="row my-3">
      <div class="col-12 px-0">
        <swiper
          class="cardswiper"
          [slidesPerView]="'auto'"
          [spaceBetween]="0"
          [pagination]="false"
        >
          <ng-template swiperSlide  *ngFor="let suggestExhibitor of suggestExhibitorList">
            <card-exhibitor
              (click)="jumpToExhibitorDetail(suggestExhibitor)"
              [exhibitor]="suggestExhibitor"
              class="exhibitor-item"
            ></card-exhibitor>
          </ng-template>
        </swiper>
      </div>
    </div>
  </div> -->

</div>




