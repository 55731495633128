import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { Logger } from 'src/app/@services/logger.service';
import { SingletonService } from 'src/app/@services/singleton.service';
import { UserService } from 'src/app/@services/user.service';

const log = new Logger('SignInComponent');
@Component({
  selector: 'app-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss']
})
export class QrCodeModalComponent implements OnInit {

  @Input() qrLogin:any;
  @Input() code:any;
  @Input() survey:any = false
  qrFlag!: boolean;
  qrUrl: any
  ticketId: any

  public loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate!: TemplateRef<any>;

  returnurl:any;

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private _userService: UserService,
    private _authService: AuthenticationService,
    public singletonService: SingletonService,
    public _router: Router,
    private _route: ActivatedRoute,
  ) {
    this.returnurl = this._route.snapshot.queryParams['returnurl'];
    log.debug("this.returnurl => %o",this.returnurl);
  }

  ngOnInit() {
    this.loading = true;
    log.debug("this.qrLogin => %o",this.qrLogin);
    log.debug("this.code => %o",this.code);
      let data = {
        eventCode: 'event-01'
      }
      this._userService.requestQrCode(data).then((resp:any) => {
        log.debug('hello',resp.qrCode);
        this.qrUrl = resp.qrCode
        this.ticketId = resp.id
      }).then(() => this.loading = false)
    if(this.qrLogin){
      this.qrFlag = this.qrLogin
    }

  }

  openSignIn() {
    this.modalService.dismissAll()
    // window.location.href = '/';

    if(this.returnurl){
      window.location.href = this.returnurl;
    }else{
      this._router.navigate(['/']);
    }

  }
}
