import { interval } from 'rxjs';
/* eslint-disable */

import { SingletonService } from './../../@services/singleton.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from 'src/app/@services/logger.service';
import { BackendService } from 'src/app/@services/backend.service';

const log = new Logger('QuestHuntingComponent');

@Component({
  selector: 'app-modal-qrcode-reward',
  templateUrl: './modal-qrcode-reward.component.html',
  styleUrls: ['./modal-qrcode-reward.component.scss']
})
export class ModalQrcodeRewardComponent implements OnInit {

  @Input('userId') userId: any;
  @Input('questId') questId: any;

  qrCodeString:any
  isLoadingQuest:any = false
  interval:any


  constructor(
    public singletonService: SingletonService,
    public modal: NgbActiveModal,
    private backendService: BackendService,
  ) {

  }

  ngOnInit() {
    log.debug("this.userId => %o",this.userId)
    log.debug("this.questId => %o",this.questId)
    this.qrCodeString = this.userId + ':' + this.questId
    log.debug("this.qrCodeString => %o",this.qrCodeString)

    this.setGetQuestInfoInterval()
  }

  async getQuestInfosById(){
    this.isLoadingQuest = true
    const getQuestInfosByIdRes:any = await this.backendService.getQuestInfosById(this.questId)
    log.debug("res getQuestInfosByIdRes: %o",getQuestInfosByIdRes)
    if(getQuestInfosByIdRes && getQuestInfosByIdRes.isCompleted){
      if(this.interval){
        clearInterval(this.interval);
      }
      this.singletonService.successAlert(undefined,'ยินดีด้วย! คุณทำเควสสำเร็จแล้ว');
      this.modal.close('success');
    }else{
      this.isLoadingQuest = false
    }
  }

  ngOnDestroy(): void {
    if(this.interval){
      clearInterval(this.interval);
    }
  }

  setGetQuestInfoInterval(){
    log.debug("start interval...");
    this.interval = setInterval(async () => {
      this.getQuestInfosById()
    }, 10 * 1000);
  }
}
