<div class="modal-header py-3 align-items-center justify-content-between">
  <button
    class="btn btn-arrow-back"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h3 class="text-white size-20">{{"SIGNIN.ENTER_OTP" | translate}}</h3>
  <!-- <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
  <button class="btn btn-top-right-arrow" type="button">
  </button>
</div>
<div class="modal-body modal-otp-checking">
  <div class="otp-body">
    <div *ngIf="error" class="alert alert-danger d-flex align-items-center w-100 mb-4" role="alert">
      <i class="bi bi-exclamation-triangle size-26"></i>
      <div class="ms-3 text-start size-14">
        {{errorText}}
      </div>
    </div>
    <div class="otp-text px-3">
      <span *ngIf="type">{{"SIGNIN.OTP_TEXT" | translate}} {{username}}</span>
      <span *ngIf="!type">{{"SIGNIN.OTP_TEXT_EMAIL" | translate}} {{username}}</span>
      <!-- <p *ngIf="type" class="pt-2 ref">รหัสอ้างอิง WWFK</p> -->
    </div>
    <ngx-otp-input (otpChange)="error = false" #ngxotp [config]="otpInputConfig" (fill)="handleFillEvent($event)"></ngx-otp-input>
    <div class="otp-end-text px-3">
      <span>
        {{"SIGNIN.RESEND_TEXT" | translate}}
        <countdown [hidden]="!showCountdown" #countdownx [config]="countdownConfig" (event)="timeup($event)"></countdown>
        <span class="send-again" *ngIf="!showCountdown" (click)="sendAgain()">
          {{"SIGNIN.RESEND" | translate}}
        </span>
      </span>
    </div>
  </div>
  <div class="option">
    <div class="footer-power size-12">
      {{"POWERED_BY" | translate}} Linxlab.io
    </div>
  </div>
</div>
<ngx-loading
  [show]="loading"
  [config]="{
    animationType: ngxLoadingAnimationTypes.wanderingCubes,
    primaryColour: '#4b0082',
    secondaryColour: '#ffd42a',
    backdropBorderRadius: '3px'
  }"
  [template]="loadingTemplate">
</ngx-loading>


