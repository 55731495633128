<div class="wrapper-100vw">
  <div class="cover-image"></div>
  <div class="px-3 pb-5">
    <div class="content-container">
      <card-pointbalance></card-pointbalance>
      <!-- menu -->
      <div class="row mt-4">
        <div class="col-12">
          <div class="card bg-theme text-white">
            <div class="card-body">
              <div class="row justify-content-between gx-0 mx-0 pb-3">
                <div class="col-auto text-center">
                  <a
                    routerLink="/qrticket"
                    class="avatar avatar-60 p-1 rounded-15 bg-event-action mb-2"
                  >
                    <div class="icons text-white rounded-12">
                      <img src="assets/img/icon/qr-ticket.png" alt="" />
                    </div>
                  </a>

                  <p class="size-10">{{"QR_TICKET" | translate}}</p>
                </div>

                <div class="col-auto text-center">
                  <a
                    routerLink="/sendmoney"
                    class="avatar avatar-60 p-1 rounded-15 bg-event-action mb-2"
                  >
                    <div class="icons text-white rounded-12">
                      <img src="assets/img/icon/agenda.png" alt="" />
                    </div>
                  </a>
                  <p class="size-10">{{"AGENDA" | translate}}</p>
                </div>

                <div class="col-auto text-center">
                  <a
                    routerLink="/exhibitor"
                    class="avatar avatar-60 p-1 rounded-15 bg-event-action mb-2"
                  >
                    <div class="icons text-white rounded-12">
                      <img src="assets/img/icon/exhibitor.png" alt="" />
                    </div>
                  </a>
                  <p class="size-10">{{"EXHIBITOR" | translate}}</p>
                </div>

                <div class="col-auto text-center">
                  <a
                    routerLink="/highlightroom"
                    class="avatar avatar-60 p-1 rounded-15 bg-event-action mb-2"
                  >
                    <div class="icons text-white rounded-12">
                      <img src="assets/img/icon/highlight-room.png" alt="" />
                    </div>
                  </a>
                  <p class="size-10">{{"HIGHLIGHT_ROOMS_1" | translate}}<br />{{"HIGHLIGHT_ROOMS_2" | translate}}</p>
                </div>
              </div>

              <div
                class="row justify-content-between gx-0 mx-0 collapse show"
                id="collpasemorelink"
              >
                <div class="col-auto text-center">
                  <a
                    routerLink="/bills"
                    class="avatar avatar-60 p-1 rounded-15 bg-event-action mb-2"
                  >
                    <div class="icons text-white rounded-12">
                      <img src="assets/img/icon/map-event.png" alt="" />
                    </div>
                  </a>
                  <p class="size-10">{{"MAP_EVENT" | translate}}</p>
                </div>

                <div class="col-auto text-center">
                  <a
                    routerLink="/namecards"
                    class="avatar avatar-60 p-1 rounded-15 bg-event-action mb-2"
                  >
                    <div class="icons text-white rounded-12">
                      <!-- <img src="assets/img/icon/map-event.png" alt="" /> -->
                    </div>
                  </a>
                  <p class="size-10">{{"NAMECARD" | translate}}</p>
                </div>
                <hr>
                <div class="col-auto text-center">
                  <a
                    routerLink="/couponreward"
                    class="avatar avatar-60 p-1 rounded-15 bg-event-action mb-2"
                  >
                    <div class="icons text-white rounded-12">
                      <img src="assets/img/icon/coupon-reward.png" alt="" />
                    </div>
                  </a>
                  <p class="size-10">{{"COUPON" | translate}}<br />{{"REWARD" | translate}}</p>
                </div>

                <div class="col-auto text-center">
                  <a
                    routerLink="/rewards"
                    class="avatar avatar-60 p-1 rounded-15 bg-event-action mb-2"
                  >
                    <div class="icons text-white rounded-12">
                      <img src="assets/img/icon/mini-gachapon.png" alt="" />
                    </div>
                  </a>
                  <p class="size-10">{{"MINI_GASHAPON_1" | translate}}<br />{{"MINI_GASHAPON_2" | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end menu -->

      <!-- news -->
      <div class="row mt-4 mb-3">
        <div class="col d-flex align-self-center">
          <h6 class="title">{{"NEWS" | translate}}</h6>
        </div>
        <div class="col-auto d-flex align-self-center">
          <a routerLink="/news" class="small text-view-more">{{"VIEW_MORE" | translate}}</a>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <a routerLink="/newsdetail/fjok34">
            <img src="assets/img/cover/news-cover.png" alt="" />
          </a>
        </div>
        <div class="col-12">
          <a routerLink="/newsdetail/fjok34">
            <img src="assets/img/cover/news-cover.png" alt="" />
          </a>
        </div>
      </div>
      <!-- end news -->
    </div>
  </div>
</div>


<style>
  .cover-image {
    background-image: url("assets/img/cover/home-cover.png");
    height: 190px;
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
  }
  .content-container {
    margin-top: -50px;
  }
</style>
