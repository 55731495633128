import { ModalSocialsSharingComponent } from './../../@components/modal-socials-sharing/modal-socials-sharing.component';
/* eslint-disable */

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/@services/authentication.service';
import { BackendService } from 'src/app/@services/backend.service';
import { Logger } from 'src/app/@services/logger.service';
import { SingletonService } from 'src/app/@services/singleton.service';
const log = new Logger('ArticledetailComponent');
import {Location} from '@angular/common';

@Component({
  selector: 'app-newsdetail',
  templateUrl: './newsdetail.component.html',
  styleUrls: ['./newsdetail.component.scss'],
})
export class NewsdetailComponent {
  articleId:any

  currentUser:any
  currentUserSubscription:any

  article:any
  suggestArticleList:any = [];
  bookmarkedArticleList:any = [];

  isBookmarked = false;

  customCurrentPageUrl:any;

  constructor(
    private backendService: BackendService,
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private modalService: NgbModal,
    public singletonService: SingletonService,
    private _location: Location,
  ) {
    this.articleId = this._route.snapshot.paramMap.get('articleid')!;
    // this.customCurrentPageUrl = window.location.origin + `/detect-link-page/EX${SPLIT_STRING}${environment.eventCode}${SPLIT_STRING}${this.articleId}`
    log.debug("this.customCurrentPageUrl => %o",this.customCurrentPageUrl)
  }


  async ngOnInit() {
    this.currentUserSubscription = this._authenticationService.currentUserSubject.subscribe((_currentUser) => {
      this.currentUser = _currentUser
      log.debug("this.currentUser => %o",this.currentUser)
    })

    await this.getArticleInfos()
    await this.getSuggestArticleList()
  }

  ngOnDestroy(): void {
    if(this.currentUserSubscription) this.currentUserSubscription.unsubscribe();
  }

  async getArticleInfos(){
    const getArticleInfosRes:any = await this.backendService.getArticleInfos(this.articleId)
    log.debug("res getArticleInfosRes: %o",getArticleInfosRes)
    if(getArticleInfosRes){
      this.article = getArticleInfosRes
      log.debug("this.article: %o",this.article)
    }
  }

  async getSuggestArticleList(){
    const getSuggestArticleListRes:any = await this.backendService.getSuggestArticleList(
      this.articleId,
      5, // limit
      1, // page
    )
    log.debug("res getSuggestArticleListRes: %o",getSuggestArticleListRes)
    if(getSuggestArticleListRes){
      this.suggestArticleList = getSuggestArticleListRes.results
      log.debug("this.suggestArticleList: %o",this.suggestArticleList)
    }
  }

  openModalSocialSharing() {
    let modalRef = this.modalService.open(ModalSocialsSharingComponent, {
      centered: true,
      backdrop: 'static',
      size: 'sm'
    });
  }

  backnav() {
    this.singletonService.back();
  }

  jumpToArticleDetail(article:any){
    let path = '/newsdetail/'+ article.id
    let href = this._router.url;
    if(href.startsWith('/newsdetail/')){ // same route
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this._router.navigate([path]);
      });
    }else{
      this._router.navigate([path]);
    }
  }

}
