<div class="modal-header flex flex-column p-4 mb-0">
  <div class="d-flex align-items-start justify-content-between w-100">
    <h3>
      <div class="size-20 text-white fw-bold">QR-Code</div>
      <div class="size-16 text-white fw-bold">{{"TO_RECEIVE_THE_REWARD" | translate}}</div>
    </h3>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <p class="text-secondary size-14 fw-500 mt-2 lh-sm">{{"ASK_TO_SCAN_REDEMPTION_POINT" | translate}}</p>
</div>
<div class="modal-body p-4">
  <div class="wrapper-qr-code mb-4 mt-2">
    <div>
      <!-- <img src="{{qrUrl}}" class="qr-code-img"> -->
      <qrcode
        #parent
        [qrdata]="qrCodeString"
        [cssClass]="'center qr-code-img'"
        [colorDark]="'#000000ff'"
        [colorLight]="'#ffffffff'"
        [elementType]="'img'"
        [errorCorrectionLevel]="'M'"
        [margin]="2"
        [scale]="1"
        [width]="168"
      ></qrcode>
      <!-- (qrCodeURL)="onChangeURL($event)" -->
    </div>
  </div>
  <div class="wrapper-btn-actions gap-4 mt-3 mb-2 d-flex justify-content-center">
    <a (click)="singletonService.saveAsImage(parent,'img')" download class="btn-action btn-purple">
        <span class="size-14 me-2">
        {{"DOWNLOAD_QRCODE" | translate}}
      </span>
      <img src="assets/img/icon/icon-actions/download.png" alt="download" />
    </a>
  </div>
</div>
