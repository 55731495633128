import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { innerSlideInAnimation } from '../@helpers/route-animations';

@Component({
  selector: 'app-innerlayout',
  templateUrl: './innerlayout.component.html',
  styleUrls: ['./innerlayout.component.scss'],
  animations: [innerSlideInAnimation],
})
export class InnerlayoutComponent {
  // @ViewChild('HeaderEl', { read: ElementRef, static: false })
  // headerView: ElementRef;
  @ViewChild('mainPage', { read: ElementRef, static: false })
  mainPageView: ElementRef;
  @ViewChild('mainContainer', { read: ElementRef, static: false })
  mainContainerView: ElementRef;
  @ViewChild('FooterEl', { read: ElementRef, static: false })
  footerView: ElementRef;

  previousUrl: string = '';
  currentUrl: string = '';

  histories = new Array<string>();

  constructor(
    private renderer: Renderer2,
    private contexts: ChildrenOutletContexts
  ) {}

  ngAfterViewInit() {
    // this.renderer.setStyle(
    //   this.mainPageView.nativeElement,
    //   'padding-top',
    //   this.headerView.nativeElement.offsetHeight + 10 + 'px'
    // );
    this.renderer.setStyle(
      this.mainPageView.nativeElement,
      'min-height',
      window.outerHeight + 'px'
    );
    // this.renderer.setStyle(
    //   this.mainContainerView.nativeElement,
    //   'min-height',
    //   window.outerHeight -
    //     this.headerView.nativeElement.offsetHeight -
    //     10 -
    //     this.footerView.nativeElement.offsetHeight +
    //     'px'
    // );
  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   let header = document.getElementsByTagName('app-headerback')[0];
  //   let main = document.getElementsByTagName('html')[0];

  //   if (main.scrollTop > 15) {
  //     header.classList.add('active');
  //   } else {
  //     header.classList.remove('active');
  //   }
  // }

  getRouteAnimationState() {
    const data = this.contexts.getContext('primary')?.route?.snapshot?.data;
    return data?.animateState;
  }
}
