<div class="wrapper-100vw">
  <div class="wrapper-top-header mt-0 mb-0 pb-3">
    <div class="container">
      <div class="row">
        <div class="col-auto align-self-center">
          <button
            type="button"
            class="btn text-white btn-sm ps-0 back-btn"
            routerLink="/wallet"
          >
            <i class="bi bi-chevron-left text-white size-16"></i>
          </button>
        </div>
        <div class="col align-self-center text-center px-1">
          <h6 class="title size-18">
            {{"PAYMONEY" | translate}}
          </h6>
        </div>
        <div class="col-auto align-self-center"></div>
      </div>
    </div>
  </div>

  <div *ngIf="loading || checkingTxLoading" class="text-center">
    <div class="text-center my-5 py-3 empty-wrapper">
      <div class="spinner-border"></div>
      <p class="text-center fw-normal size-14 mt-3">
        {{ "LOADING" | translate }}
      </p>
    </div>
  </div>

  <div *ngIf="!checkingTxLoading && !loading && transaction && !isSuccess" class="wrapper-content-receive mt-4">
    <!-- <div class="text-center">
      <h2>
        <span class="size-32 fw-bold me-1 text-logo">LINX</span>
        <span class="size-20 text-white fw-bold">WALLET</span>
      </h2>
    </div> -->

    <div class="wrapper-qr-code-inner mt-3 px-0 bg-white">
      <!-- <div class="wrapper-qr-code">
        <div>
          <img [src]="transaction.data.image" alt="QR Code" class="receive-qrcode qr-code-img" /> -->
          <!-- <img src="userQrCode.qrCode" alt="QR Code" class="receive-qrcode qr-code-img" /> -->
        <!-- </div> -->
      <!-- </div> -->

      <div class="qr-code-wrapper">
        <img class="text-center" src="assets/img/payment/header-thai-qr.png" alt="thai qr payment" />
        <img class="text-center my-3" src="assets/img/payment/logo-prompt-pay.png" alt="logo prompt pay" style="max-width: 150px;" />
        <div class="inner-qr-code-wrapper">
          <img [src]="transaction.data.image" alt="QR Code" class="receive-qrcode qr-code-img" />
        </div>
      </div>

      <!-- <h3 class="size-20 my-3 text-center">
        Name User
      </h3> -->

      <p class="mb-2 mt-3 text-center">
        <label class="size-16 text-secondary me-2">Total : </label>
        <span class="size-20 text-dark">{{transaction.data.total}} Baht</span>
      </p>

      <p class="mt-2 size-14 text-secondary text-center pb-5">
        {{"REFERENCE" | translate}} {{transaction.data.referenceNo}}
        <br> {{"EXPIREDATE" | translate}}: {{transaction.data.expiredate}}
      </p>

    </div>

    <div class="border-top pt-3 mb-4 mt-4 px-3">
      <p class="text-14 text-secondary text-center">
        {{"REMARK_CLICK_ON_DOWNLOAD_QR_CODE" | translate}}
      </p>
    </div>

    <div class="border-top pt-3 mb-4 mt-4 px-3">
      <p class="text-14 text-yellow text-center">
        {{"REMARK_CLICK_ON_DOWNLOAD_QR_CODE2" | translate}}
      </p>
    </div>

    <div class="wrapper-btn-actions gap-3 mt-4 mb-2 px-3 d-flex justify-content-center flex-column">
      <!-- <a (click)="singletonService.downloadMediaFromUrl(userQrCode.qrCode)" class="btn-action btn-purple"> -->
      <a [download]="'payment_qrcode_' + transaction.data.referenceNo" [href]="transaction.data.image" class="btn-action btn-outline-gradient">
        <span class="bg-btn-download">
          <span class="size-16 me-3">{{"DOWNLOAD_QRCODE" | translate}}</span>
          <i class="bi bi-download size-24"></i>
        </span>
      </a>
      <!-- <a class="btn-action btn-default" (click)="generateQrcode()">
        <span class="size-16 fw-bold">{{"OK" | translate}}</span>
      </a> -->
    </div>

  </div>

  <div *ngIf="isSuccess" class="wrapper-content-receive mt-4">
    <div class="text-center">
      <h2>
        {{"TOP_UP_COMPLETED" | translate}}
      </h2>
    </div>
    <div class="wrapper-btn-actions gap-3 mt-4 mb-2 px-3 d-flex justify-content-center flex-column">

      <a *ngIf="itemType === 'coupon' || itemType === 'bundle'" class="btn-action btn-default" routerLink="/mycouponreward">
        <span class="size-16 fw-bold">{{"SPIN_THE_WHEEL_PAGE.VIEW_REWARD_BUTTON" | translate}}</span>
      </a>

      <a *ngIf="itemType !== 'coupon' && itemType !== 'bundle'" class="btn-action btn-default" routerLink="/">
        <span class="size-16 fw-bold">{{"PRESS.BACK_TO_HOME" | translate}}</span>
      </a>

    </div>
  </div>

  <!-- <div class="container">
    <ngx-loading
      [show]="loading"
      [config]="loadingConfig"
      [template]="loadingTemplate"
    >
    </ngx-loading>
  </div> -->
</div>
