import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'card-mycoupon',
  templateUrl: './card-mycoupon.component.html',
  styleUrls: ['./card-mycoupon.component.scss'],
})
export class CardMycouponComponent {
  @Input() coupon: any;
  @Input() buttonDisabled: boolean;
  @Output('buttonClick') buttonClick = new EventEmitter<void>();
}
