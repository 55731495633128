<div class="modal-header pb-3">
  <button
    class="btn btn-arrow-back ps-0"
    type="button"
    (click)="modal.dismiss('Cross click')"
  >
    <i class="bi bi-chevron-left text-white size-16"></i>
  </button>
  <h1 class="mb-0">
    <div class="size-20 fw-bold text-white">{{"BROCHURE" | translate}}</div>
  </h1>
  <button class="btn btn-top-right-arrow pe-0" type="button" (click)="modal.dismiss('Cross click')" routerLink="/download">
    <img src="assets/img/icon/icon-actions/download-file.png" alt="download file icon">
    <span class="badge-count-noti">{{ downloadedTotalResults }}</span>
  </button>
</div>
<div class="modal-body px-0">
  <p class="text-secondary size-14 text-white px-3">{{"DOWNLOAD_BROCHURE" | translate}}</p>
  <!-- <h3 class="">
    Brochure list
  </h3> -->
  <div class="wrapper-brochure-list" *ngIf="brochureList">
    <div>
      <ul>
        <li class="border-bottom" *ngFor="let brochure of brochureList">
          <!-- {{brochure.name}} -->
          <!-- {{brochure.description}} | -->
          <!-- <a *ngIf="brochure.files && brochure.files.length ? brochure.files[0] : false"
            (click)="doDownloadProcess(brochure.name,brochure.files[0],brochure.id)">
            download
          </a> -->

          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar avatar-img avatar-50 card rounded-15">
                <!-- no image -->
                <div *ngIf="!brochure.coverImage" class="icons icons-no-img text-white rounded-15">
                  <img class="opacity-5" src="assets/img/icon/my-download/brochure.svg">
                </div>
                <!-- end no image -->

                <!-- have image -->
                <div *ngIf="brochure.coverImage" class="icons icons-have-img text-white rounded-15">
                  <img [src]="brochure.coverImage">
                </div>
                <!-- end have image -->

              </div>
            </div>
            <div class="col align-self-center ps-0">
              <p class="size-12 mb-0 text-capitalize">
                <!-- File Name: -->
                {{brochure.name}}
              </p>
              <p class="text-secondary text-muted size-12 mb-0">
                <!-- category -->
                {{"BROCHURE" | translate}}
              </p>
              <p class="text-secondary text-muted size-12 mb-0">
                <!-- date:  -->
                {{ brochure.updatedAt | date : "dd/MM/yy"}}
              </p>
            </div>
            <div class="col-2 d-flex justify-content-end">
              <div class="text-center" *ngIf="brochure.files && brochure.files.length ? brochure.files[0] : false" (click)="doDownloadProcess(brochure.name,brochure.files[0],brochure.id)">
                <i class="bi bi-download size-18"></i>
                <p class="text-secondary size-10 mb-0 lh-sm fw-bold">{{"DOWNLOAD" | translate}}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- <textarea
        [(ngModel)]="comment"
        class="form-control"
        id="text"
        name="text"
        maxlength="60"
        placeholder="Note something about this exhibitor.."
        rows="5"
      ></textarea>
      <div class="text-end">{{comment.length}}/60</div> -->
    </div>
  </div>
  <div *ngIf="!brochureList.length">
    <div class="text-center">
        <div class="my-5 py-3">
          <div class="card bg-transparent">
            <div class="card-body p-0">
              <div class="empty-wrapper text-center">
                <img src="assets/img/empty/empty-brochure.png" alt="empty brochure">
                <p class="text-center mt-2 fw-normal size-14">
                  {{"DONT_HAVE_BROCHURE_NOW_01" | translate}} <br>
                  {{"DONT_HAVE_BROCHURE_NOW_02" | translate}}</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <!-- <div class="row mb-4 mt-5">
    <div class="col-12">
      <button
        [disabled]="isLoading"
        (click)="saveNotemask()"
        class="btn btn-default btn-lg w-100"
        >Save</button
      >
    </div>
  </div> -->
</div>
