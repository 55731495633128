<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button
              class="btn btn-sm btn-arrow-back ps-0"
              type="button"
              (click)="back()"
            >
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{ wheelDetail?.title || "SPIN_THE_WHEEL" | translate }}
          </span>
        </h2>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="text-center">
    <div class="text-center my-5 py-3 empty-wrapper">
      <div class="spinner-border"></div>
      <p class="text-center fw-normal size-14 mt-3">
        {{ "LOADING" | translate }}
      </p>
    </div>
  </div>

  <div
    *ngIf="!isLoading && prizes && prizes.length > 0"
    class="text-center mt-2"
  >
    <!-- <div class="text-center mt-5">
    <h3>{{"SPIN_THE_WHEEL" | translate}}</h3>
    <p class="mt-2 text-muted">
      {{"SPIN_A_VIRTUAL_WHEEL" | translate}}
    </p>
  </div> -->

    <div class="text-center mb-2">
      <p class="mt-2 text-muted">
        {{ wheelDetail.description }}
      </p>
    </div>
    <!--
    <ngx-wheel
      *ngIf="prizes && prizes.length"
      [width]="wheelWidth"
      [height]="wheelHeight"
      [spinDuration]="spinDuration"
      [disableSpinOnClick]="true"
      [items]="prizes"
      [spinAmount]="prizes.length"
      [textOrientation]="textOrientation"
      [textAlignment]="textAlignment"
      pointerStrokeColor="black"
      pointerFillColor="gold"
      [idToLandOn]="idToLandOn"
      (onSpinComplete)="after()"
    >
    </ngx-wheel> -->

    <div class="position-relative">
      <img src="assets/img/wheels/needle.png" class="needle" />
      <app-wheel-core
        *ngIf="prizes && prizes.length"
        [width]="wheelWidth"
        [height]="wheelHeight"
        [spinDuration]="spinDuration"
        [disableSpinOnClick]="true"
        [items]="prizes"
        [spinAmount]="prizes.length"
        [textOrientation]="textOrientation"
        [textAlignment]="textAlignment"
        pointerStrokeColor="black"
        pointerFillColor="gold"
        [idToLandOn]="idToLandOn"
        (onSpinComplete)="after()"
      >
      </app-wheel-core>
    </div>

    <app-confetti></app-confetti>

    <div
      class="card coupon-remains text-center mt-4"
      *ngIf="wheelDetail.wheelConditions.length"
    >
      <ng-container *ngFor="let condition of wheelDetail.wheelConditions">
        <h6 class="title mb-1 me-2" *ngIf="condition.type === 'token'">
          <img
            [src]="condition.ref.logo || 'assets/img/dashboard/coin-53x53.png'"
            width="24"
            height="24"
            alt="coin"
            class="me-1"
          />
          {{ condition.amount | numeral : "0,0" }}
          <br />
          {{ condition.ref.name }}
        </h6>
        <h6 class="title mb-1 me-2" *ngIf="condition.type === 'coupon'">
          <img
            [src]="condition.ref.logo || 'assets/img/dashboard/coupon.png'"
            width="24"
            height="24"
            alt="coin"
            class="me-1"
          />
          {{ couponRemains }}/{{ condition.amount | numeral : "0,0" }}
          <br />
          <br />
          {{ condition.ref.name }}
        </h6>
      </ng-container>
    </div>

    <div class="text-center mt-4">
      <button
        [disabled]="
          currentState === STATE.SPINNING || couponRemains <= 0 || disableBtn
        "
        (click)="spin()"
        class="btn btn-lg btn-default"
      >
        {{ "SPIN" | translate }}
      </button>
    </div>
  </div>
</div>
