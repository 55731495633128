<!-- <div class="row pt-3">
  <div class="col-3 align-self-center">
    <button
      type="button"
      class="btn text-white btn-44 back-btn"
      (click)="backnav()"
    >
      <i class="bi bi-arrow-left"></i>
    </button>
  </div>
  <div class="col align-self-center text-center px-0">
    <h6 class="title size-16">ติดต่อเรา</h6>
  </div>
  <div class="col-3 align-self-center"></div>
</div> -->

<div class="wrapper-100vw">
  <div class="wrapper-top-header">
    <div class="content-header">
      <div class="container">
        <h2>
          <span class="size-18 text-white fw-bold text-capitalize">
            <button class="btn btn-sm btn-arrow-back ps-0" type="button" (click)="backnav()">
              <i class="bi bi-chevron-left text-white size-16"></i>
            </button>
            {{"CONTACT_US" | translate}}
          </span>
        </h2>
      </div>
    </div>
  </div>

  <div class="col-11 col-sm-11 col-md-8 col-lg-6 col-xl-4 mx-auto px-2 align-self-center">
    <div class="mt-4">
      <h6 class="title size-16 mb-2">{{"CUSTOMER_SERVICE" | translate}}</h6>
      <p class="text-secondary">
        {{"WANT_TO_ASK_SERVICE" | translate}}
      </p>
    </div>

    <!-- <div class="mt-4">
      <button class="btn btn-lg btn-block w-100 text-start text-white">
        <i class="bi bi-envelope size-22 me-2 align-bottom text-secondary"></i>
        {{"NOTIFICATION_TO_EMAIL" | translate}}
      </button>
    </div> -->

    <div class="mt-3">
      <button (click)="singletonService.openExternalLink('https://www.facebook.com/messages/t/108793225555233')" class="btn btn-lg btn-block w-100 text-start text-white mx-auto d-flex align-items-center justify-content-between">
        <div>
          <img src="assets/img/icon/FacebookIcon.png" alt="facebook img" />
          <span class="ms-2 ps-1 size-16">Facebook</span>
        </div>
        <div>
          <i class="bi bi-chevron-right size-14 text-secondary"></i>
        </div>
      </button>
    </div>
    <div class="mt-3">
      <button (click)="singletonService.openExternalLink('https://line.me/ti/p/~@linxwallet')" class="btn btn-lg btn-block w-100 text-start text-white mx-auto d-flex align-items-center justify-content-between">
        <div>
          <img src="assets/img/icon/LineIcon.png" alt="line img" />
          <span class="ms-2 ps-1 size-16">LINE Official Account</span>
        </div>
        <div>
          <i class="bi bi-chevron-right size-14 text-secondary"></i>
        </div>
      </button>
    </div>

  </div>
</div>

